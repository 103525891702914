import {
  type AnyRoute,
  type ParsedLocation,
  type RegisteredRouter,
} from '@tanstack/react-router'

let _router: RegisteredRouter | undefined

/** Safely retrieves the `router` instance without introducing circular dependencies */
export function getRouter(): RegisteredRouter {
  if (!_router) {
    throw Error('Called `getRouter()` before router was set')
  }
  return _router
}

/** Sets the router so it can safely be retrieved from other files without circular dependencies */
export function setRouter(router: RegisteredRouter): void {
  if (_router) {
    throw Error('Router is already set')
  }
  _router = router
}

export function getRouteMatchByLocation(location: ParsedLocation): AnyRoute {
  const router = getRouter()
  // Find matches based on the current location since the current `router.state` may not be
  // updated yet, so we cannot rely on it
  const matches = router.matchRoutes(location)

  return router.routesById[matches[matches.length - 1]!.routeId]
}

export function getCurrentRouteId(): string {
  const router = getRouter()
  return router.state.matches[router.state.matches.length - 1]!.routeId
}
