import * as React from 'react'
import { apollo, graphql } from '#app/apollo/index'
import { type IsoCountry } from '#app/graphql/graphql'
import { t } from '#app/lib/i18n/index'
import { actions as musicLibraryActions } from '#app/lib/music-library'
import { selectors as accountSelectors } from '#app/store/current-account'
import { useDispatch, useSelector } from '#app/store/redux'
import { GetTracksFromPromptDoc } from './use-playlist-from-prompt'

export default function useCreatePlaylistFromPrompt() {
  const dispatch = useDispatch()
  const accountId = useSelector(accountSelectors.id)
  const [loading, setLoading] = React.useState(false)

  const createPlaylist = React.useCallback(
    (
      prompt: string,
      saveAs: 'playlist' | 'station' = 'playlist',
      defaultPlaylistTitle: string,
      trackIds: string[],
      country: IsoCountry,
      totalNrOfTracks: number = 0,
    ) => {
      setLoading(true)

      const haveAllTracks = trackIds.length === totalNrOfTracks

      return (
        haveAllTracks
          ? Promise.resolve(trackIds)
          : // fetch all tracks if not already in cache
            apollo
              .query({
                query: GetTracksFromPromptDoc,
                fetchPolicy: 'network-only',
                variables: {
                  prompt,
                  market: country,
                  first: totalNrOfTracks,
                },
              })
              .then(({ data }) =>
                data.getTracksFromPrompt.edges.map(
                  (edge) => edge.node?.id || '',
                ),
              )
      )
        .then((tracks) =>
          // fetch recommended playlist title and description
          apollo
            .query({
              query: PlaylistPackagingRecommendationDoc,
              fetchPolicy: 'network-only',
              variables: {
                input: {
                  prompt,
                  trackIds: tracks,
                },
              },
            })
            .then(({ data }) => {
              const {
                playlistPackagingRecommendation: { playlistPackaging },
              } = data

              if (saveAs === 'station') {
                return apollo
                  .mutate({
                    mutation: CreateStationFromPromptDoc,
                    variables: {
                      input: {
                        name: playlistPackaging.title ?? defaultPlaylistTitle,
                        tracks,
                        prompt,
                      },
                    },
                  })
                  .then(({ data }) => {
                    return data?.createStationFromPrompt?.playlist
                  })
              }
              // create playlist
              return apollo
                .mutate({
                  mutation: CreateManualPlaylistDoc,
                  variables: {
                    input: {
                      ownerId: accountId!,
                      name:
                        playlistPackaging.title ??
                        t(
                          'marvinPlaylistFromPrompt.defaultPlaylistTitle',
                          false,
                        ),
                      description: playlistPackaging.description,
                      annotations: [
                        {
                          key: 'source',
                          value: 'playlist-from-prompt',
                        },
                        {
                          key: 'prompt',
                          value: prompt,
                        },
                      ],
                      trackIds: tracks,
                    },
                  },
                })
                .then(({ data }) => data?.createManualPlaylist)
            })
            .then((playlist) => {
              return dispatch(
                musicLibraryActions.add(playlist!.id, {
                  libraryId: accountId!,
                }),
              ).then(() => playlist)
            }),
        )
        .finally(() => setLoading(false))
    },
    [accountId, dispatch],
  )

  return {
    createPlaylist,
    loading,
  }
}

export const PlaylistPackagingRecommendationDoc = graphql(/* GraphQL */ `
  query PlaylistPackagingRecommendation(
    $input: PlaylistPackagingRecommendationInput!
  ) {
    playlistPackagingRecommendation(input: $input) {
      playlistPackaging {
        title
        description
      }
    }
  }
`)

const CreateManualPlaylistDoc = graphql(/* GraphQL */ `
  mutation CreateManualPlaylist($input: CreateManualPlaylistInput!) {
    createManualPlaylist(input: $input) {
      id
      type: presentAs
      name
      composer: composerType
    }
  }
`)

const CreateStationFromPromptDoc = graphql(/* GraphQL */ `
  mutation CreateStationFromPrompt($input: CreateStationFromPromptInput!) {
    createStationFromPrompt(input: $input) {
      playlist {
        id
      }
    }
  }
`)
