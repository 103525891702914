import { log } from '@soundtrack/utils/log'
import { isApolloError } from '@soundtrackyourbrand/apollo-client'
import { debounce } from '@soundtrackyourbrand/ui'
import { graphql } from '#app/graphql/gql'
import { type FlagApplyEventInput } from '#app/graphql/graphql'
import { captureError } from '#app/lib/error/reporting'

// TODO: Both this and `fetchFlags` could be moved to the same OOP class for
// managing and resetting state between fetches and applies.

export const ApplyFlagsMutation = graphql(/* GraphQL */ `
  mutation ApplyFlags(
    $events: [FlagApplyEventInput!]!
    $resolveToken: String!
    $currentClientTime: DateTime!
  ) {
    applyFlags(
      events: $events
      resolveToken: $resolveToken
      currentClientTime: $currentClientTime
    )
  }
`)

/** Flags are only applied once per session */
const appliedFlags = new Set<string>()

/** An array of events pending to be applied */
const pendingEvents: FlagApplyEventInput[] = []

export function applyFlag(flagName: string) {
  if (appliedFlags.has(flagName)) {
    return // Already applied once
  }

  appliedFlags.add(flagName)
  pendingEvents.push({ flag: flagName, applyTime: new Date().toISOString() })

  applyFlagsDebounced()
}

/** Debounced version of `applyFlags` to make sure we batch send events */
const applyFlagsDebounced = debounce(() => {
  return applyFlags().catch((e) => captureError(e))
}, 2e3)

/**
 * Whenever a flag is evaluated in the client we notify the backend about this so we can track its usage.
 * In Confidence this is called "Applying the flag"
 */
async function applyFlags(): Promise<void> {
  if (pendingEvents.length === 0) {
    log.debug('[flags] Skipped applying flags, no pending events')
    return
  }

  const apollo = (await import('#app/apollo/index')).apollo
  const store = (await import('#app/store/index')).store

  const state = store.getState()
  const { serverFlags, resolveToken } = state.flags

  // Only apply flags that have been returned by the server (otherwise 400s will be thrown)
  const filteredEvents = pendingEvents.filter((event) =>
    serverFlags.includes(event.flag),
  )

  // Reset pending events
  pendingEvents.length = 0

  if (filteredEvents.length === 0) {
    return
  }

  if (!resolveToken) {
    const msg = 'Could not apply flags, missing required `resolveToken`'
    log.warn(`[flags] ${msg}`)
    captureError(new Error(msg))
    return
  }

  log.debug(`[flags] Applying ${filteredEvents.length} flag events`)

  try {
    const query = await apollo.mutate({
      mutation: ApplyFlagsMutation,
      variables: {
        resolveToken,
        events: filteredEvents,
        currentClientTime: new Date().toISOString(),
      },
    })

    if (query.data?.applyFlags !== true) {
      throw query.errors?.[0] || new Error('Mutation failed')
    }
  } catch (cause: any) {
    if (!isApolloError(cause) || !cause.gqlContext.isExpected) {
      const error = Object.assign(new Error('Failed to apply flags'), {
        cause,
        filteredEvents,
      })
      captureError(error)
    }

    // Add failed events back to pending queue
    pendingEvents.push(...filteredEvents)
  }
}

export function resetAppliedFlags() {
  appliedFlags.clear()
}
