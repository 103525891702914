const $$splitComponentImporter = () => import(/* webpackChunkName: "route-create" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/create/import-spotify-playlist.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { loggedInOr } from '#app/route-lib';

// This route is only available when a feature flag is enabled (curators)

export const Route = createFileRoute('/create/import-spotify-playlist')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: ({
    location
  }) => {
    loggedInOr({
      to: '/create',
      search: {
        // @ts-expect-error Types for `to` not correctly inferred in helper
        signup: ''
      }
    }, location, true);
  }
});