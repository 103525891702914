// eslint-disable-next-line no-restricted-imports
import * as Apollo from '@apollo/client'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { log } from '@soundtrack/utils/log'
import { isTruthy } from '@soundtrack/utils/typePredicates'
import * as a from '@soundtrackyourbrand/apollo-client'
import sybBrowserStorage from '@soundtrackyourbrand/browser-storage.js'
import { getIn } from '@soundtrackyourbrand/object-utils.js'
import { debounce } from '@soundtrackyourbrand/ui'
import { LocalStorageWrapper } from 'apollo3-cache-persist'
import gqlIntrospection from '#app/graphql/graphql'
import { faroEnabled, pushFaroEvent } from '#app/lib/faro-reporting'
import overrides from '#app/lib/overrides'
import { authSlice } from '#app/store/auth'
import { extraThunk } from '#app/store/lib/thunk'
import { startReduxListening } from '#app/store/middleware/listener'
import * as misc from '#app/store/misc'
import { tysonSlice } from '#app/store/reducers'
import { captureError } from '../lib/error'
import { initializeTypePolicies, typePolicies } from './type-policies'

export { graphql } from '#app/graphql/gql'
export { useQuery } from '@apollo/client'
export { useQueryTtl } from '@soundtrackyourbrand/apollo-client'

const logger = log.scope('apollo')

/** Reset without re-fetching active queries */
export function resetApollo() {
  apollo.stop()
  return apollo.clearStore()
}

/** Apollo cache instance */
export const cache = new Apollo.InMemoryCache({
  possibleTypes: gqlIntrospection.possibleTypes,
  typePolicies,
})

/** Custom GC function that evicts non-critical data */
export function manualGc(): void {
  // TODO: Investigate if it's possible to target normalized tracks first before evicting all of the below fields
  const fieldRegex = /^(editorial\w*|search|tracks|playlist|schedule|browse)\b/
  a.invalidateCacheFields(
    cache,
    (key) => {
      return key.match(fieldRegex)?.[1]
    },
    { broadcast: false },
  )
}

const cachePersistor = a.createCachePersistor({
  storage: new LocalStorageWrapper(sybBrowserStorage.local),
  cache,
  version: SYB.env + '-' + SYB.version,
  key: 'syb.cache',
  versionKey: 'syb.cacheVersion',
  manualGc,
  logger: SERVER_ENV === 'production' ? undefined : log.scope('apollo-cache'),
})

a.patchApolloError(Apollo.ApolloError)

export let apollo: a.AugmentedApolloClient<Apollo.NormalizedCacheObject> =
  undefined as any

export const useApolloClient = Apollo.useApolloClient as () => typeof apollo

/**
 * Creates the Apollo client, must be called early during startup as everything
 * using it will expect it to be available.
 */
export function initializeApollo(
  // eslint-disable-next-line @typescript-eslint/consistent-type-imports
  store: typeof import('#app/store/index')['store'],
) {
  initializeTypePolicies({ store })

  /** Websocket instance used for Apollo subscriptions */
  const websocket = new a.WebSocketLink({
    url: () => a.graphqlApiUrl(SYB.env, 'wss'),
    connectionParams: () =>
      getToken(false).then((token) => ({
        Authorization: token ? 'Bearer ' + token : undefined,
      })),
  })
  websocket.onRestart = () => {
    logger.debug('Gracefully restarting websocket connection')
  }
  // @ts-ignore: Expose the websocket client on the dev object if it exists
  if (window.dev) window.dev.websocketLink = websocket

  // Configure slower rate limit retry when logged out as their tokens
  // refill at a much lower speed
  const loggedInRateLimitDelay = a.buildDelayFunction({ initial: 1500 })
  const loggedOutRateLimitDelay = a.buildDelayFunction({ initial: 3000 })
  const retryLink = new a.RetryLink({
    shouldRetry: a.retryStrategy({
      rateLimitDelay: (attempt, op, error) =>
        authSlice.selectors.loggedIn(store.getState())
          ? loggedInRateLimitDelay(attempt, op, error)
          : loggedOutRateLimitDelay(attempt, op, error),
    }),
  })

  apollo = a.augmentApolloClient(
    new Apollo.ApolloClient({
      cache,
      connectToDevTools: SERVER_ENV !== 'production',
      link: Apollo.ApolloLink.from(
        [
          new a.TracingLink(),
          new a.FetchAllLink(),
          new a.HeadersLink({
            resolveAuthToken: () => getToken(false),
            before(ctx) {
              ctx.headers = Object.assign({}, ctx.headers, {
                'User-Agent': `business-${SYB.version}`,
                'X-User-Agent': misc.selectors.editorialAgent(store.getState()),
                'X-Canary': overrides.get('canary'),
                'X-Request-Flags': overrides.get('nocache')
                  ? { nocache: true }
                  : undefined,
              })
            },
          }),
          faroEnabled() &&
            new a.FaroLink(pushFaroEvent, SYB.faro!.sampleRates.apollo),
          removeTypenameFromVariables(),
          a.errorLink({
            refreshAuthToken: () => getToken(true),
            onError(error, err) {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              const { identifier, isExpected } = error.gqlContext || {}
              logger.warn(
                `${isExpected ? '(Expected) ' : ''}error @ ` +
                  identifier.join('.') +
                  ': ' +
                  error.message,
              )
              if (!isExpected && err.operation.getContext().reportErrors) {
                captureError(error)
              }
            },
          }),
          retryLink,
          // Forward subscriptions to WebSocketLink
          Apollo.split(
            ({ query, operationName, variables }) => {
              const def = a.getMainDefinition(query)
              if (NODE_ENV === 'development') {
                // Get the id we were querying/acting on
                const idPath =
                  findIdProp(variables) || findIdProp(variables.input, 'input.')
                const idValue = idPath ? getIn(variables, idPath) : undefined

                logger.debug(
                  `${def.operation} ${operationName}${idValue ? ` ${idValue} (${idPath})` : ''}`,
                )
                // logger.debug(variables)
              }
              return def.operation === 'subscription'
            },
            websocket,
            new Apollo.HttpLink({
              uri: (operation) =>
                `${a.graphqlApiUrl(SYB.env)}?op=${operation.operationName}`,
              credentials: 'omit',
            }),
          ),
        ].filter(isTruthy),
      ),
    }),
  )

  const stopObservingStore = store.observe(
    misc.selectors.editorialAgent,
    debounce(() => {
      const invalidatedFields = a.invalidateCacheFields(apollo.cache)
      if (!invalidatedFields.length) return
      logger.debug(
        `Invalidated editorial data on agent change: ${invalidatedFields.join(
          ', ',
        )}`,
      )
    }, 100),
  )

  // Add apollo to extraThunk here (preventing circular dependencies)
  extraThunk.apollo = apollo

  function onReset() {
    a.resetQueryTtl()
    return cachePersistor.reset().finally(() => {
      websocket.gracefullyRestart()
    })
  }
  apollo.onResetStore(onReset)
  apollo.onClearStore(onReset)

  /** Retrieve API token (refreshes token if expired) */
  function getToken(forceRefresh = false): Promise<string | null | undefined> {
    const state = store.getState()

    const loggedIn = authSlice.selectors.loggedIn(state)
    if (loggedIn) {
      return forceRefresh
        ? store.dispatch(authSlice.actions.refreshToken()).unwrap()
        : store.dispatch(authSlice.actions.maybeUpdateToken())
    }

    const playerId = overrides.get<string>('player-id')
    if (playerId) {
      const token = tysonSlice.selectors.token(playerId, state)
      if (token) return Promise.resolve(token)
    }

    return Promise.resolve(null)
  }

  const stopReduxListening = startReduxListening({
    actionCreator: authSlice.actions.reset,
    effect: () => {
      apollo.stop()
      apollo.resetStore()
    },
  })

  return {
    rehydrate: () => cachePersistor.rehydrate(),
    dispose() {
      stopObservingStore()
      stopReduxListening()
    },
  }
}

function findIdProp(variables: unknown, prefix = ''): string | undefined {
  if (!variables || typeof variables !== 'object') return
  let idKey =
    'id' in variables
      ? 'id'
      : Object.keys(variables).find((k) => /I[dD]$/.test(k))
  if (!idKey && 'soundZone' in variables) idKey = 'soundZone'
  if (prefix && idKey) idKey = prefix + idKey
  return idKey
}
