import { SortObject } from '@soundtrackyourbrand/ui';
import { useContext, useEffect, useState } from 'react';
import tracking from '#app/lib/tracking';
export function useDelayedSort(loadRemaining) {
  const [sortLoading, setSortLoading] = useState(null);
  const [sort, setSort] = useState(() => new SortObject());
  const trackingContext = useContext(tracking.UsageContext);
  const sortColumn = (column, {
    remove = false,
    append = false,
    tristate = false
  } = {}) => {
    let newSort = sort;
    if (column) {
      if (tristate && !remove && !append && sort.getColumnDirection(column) === 'desc') {
        // Third normal click is equal to an alt-click
        remove = true;
      }
      newSort = remove ? newSort.remove(column) : newSort.column(column, append);
    } else {
      newSort = new SortObject();
    }
    tracking.track('Track List - Sorted', {
      ...trackingContext,
      Sorting: newSort.toString(),
      'Caused Load Remaining': !!loadRemaining
    });
    if (loadRemaining) {
      loadRemaining();
      setSortLoading({
        column,
        sort: newSort
      });
      return;
    }
    setSort(newSort);
  };
  useEffect(() => {
    if (!loadRemaining && sortLoading) {
      setSortLoading(null);
      setSort(sortLoading.sort);
    }
  }, [loadRemaining, sortLoading]);
  return {
    current: sort,
    loading: sortLoading,
    sortColumn
  };
}