import { storedVariation } from '@soundtrackyourbrand/experiments.js';
import expert_1323598535 from '#app/assets/product-expert/expert_1323598535.jpeg';
import expert_1501604298 from '#app/assets/product-expert/expert_1501604298.jpeg';
import expert_1578088666 from '#app/assets/product-expert/expert_1578088666.png';
import { expertRE06, expertRE13, expertRE14 } from '#app/lib/experiments';
import { getCurrentRouteId } from '#app/lib/router';
import { selectors as accountSelectors } from '#app/store/current-account';
import { useSelector } from '#app/store/redux';
export const CONFIG = {
  RE06: expertRE06,
  RE13: expertRE13,
  RE14: expertRE14
};
export const HUBSPOT_EXPERTS_IDS = {
  carl: '1323598535',
  erik: '1578088666',
  jennie: '1501604298'
};
export const EXPERTS = {
  RE06: {
    calenderURL: 'https://meetings-eu1.hubspot.com/jennie-andren',
    name: 'Jennie',
    expertId: HUBSPOT_EXPERTS_IDS.jennie,
    actionKeys: ['ama', 'meeting'],
    image: expert_1501604298
  },
  RE13: {
    calenderURL: 'https://meetings-eu1.hubspot.com/carl-pettersson',
    name: 'Carl',
    actionKeys: ['ama', 'meeting'],
    expertId: HUBSPOT_EXPERTS_IDS.carl,
    image: expert_1323598535
  },
  RE14: {
    href: 'https://calendar.app.google/RMuQdR9mWYWDBDTu8',
    expertId: HUBSPOT_EXPERTS_IDS.erik,
    name: 'Erik',
    image: expert_1578088666,
    actionKeys: ['feedback', 'googleCalendar']
  }
};

/**
 * Prevent rendering lower prio cards when risking multiple cards on shared experiment routes.
 * This also avoids triggering misleading Mixpanel events on shared routes.
 * If experiment routes change, condition should be updated/replaced by more sustainable solution handling floating card positioning or card prio/hierarchy.
 */
export function usePreventRenderCard(id) {
  const isLowPrio = id === 'RE13';
  const hasRE13 = useSelector(state => storedVariation(state, CONFIG.RE13.name)) === 'treatment';
  const hasRE14 = useSelector(state => storedVariation(state, CONFIG.RE14.name)) === 'treatment';
  if (!isLowPrio) return false;
  if (!hasRE13 || !hasRE14) return false;
  const isHomeRoute = getCurrentRouteId().startsWith('/home');
  return !!isHomeRoute;
}
export function useCanLaunch(experiment, potentialZones) {
  const hasRE13 = useSelector(state => storedVariation(state, CONFIG.RE13.name)) === 'treatment';
  const account = useSelector(accountSelectors.account);
  if (!account) return false;
  // Created during the past 24 hours?
  const isNew = new Date(account.get('created_at')).valueOf() - Date.now() < 24 * 60 * 60e3;
  if (potentialZones < experiment.potential) return false;
  switch (experiment.id) {
    case 'RE13':
      return !account.getIn(['feature_flags', 'onboarding_enabled']);
    case 'RE14':
      return hasRE13 && !isNew;
    default:
      return true;
  }
}

/** Update preventRenderCard if changing routes */
export const REQS = [{
  id: 'RE06',
  potential: 2,
  routes: ['/setup-guide']
}, {
  id: 'RE13',
  potential: 6,
  routes: ['/home', '/search', '/create', '/your-music', '/accounts/$accountId/your-music/playlists', '/accounts/$accountId/your-music/schedules', '/accounts/$accountId/settings/overview', '/accounts/$accountId/settings/subscriptions', '/accounts/$accountId/settings/billing', '/accounts/$accountId/settings/users', '/accounts/$accountId/zones', '/messaging', '/settings']
}, {
  id: 'RE14',
  potential: 6,
  routes: ['/home']
}];