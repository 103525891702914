const $$splitComponentImporter = () => import(/* webpackChunkName: "route-addons" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/addons/activated.$addon.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { ADDONS_WITH_SUCCESS_SCREEN } from '#app/modules/add-ons/activated';
export const Route = createFileRoute('/addons/activated/$addon')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: ({
    params
  }) => {
    if (!params.addon || !ADDONS_WITH_SUCCESS_SCREEN.includes(params.addon)) {
      throw redirect({
        to: '/home',
        replace: true
      });
    }
  }
});