const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-your-music" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/your-music_.schedules.$scheduleId.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-your-music" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/your-music_.schedules.$scheduleId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensurePaddedId } from '#app/lib/urls';
// This lives outside of the your-music/ folder and is named `your-music_` to
// break out of the layout structure of Your Music routes.

// TODO: Should we instead have all schedules directly live at /schedules/$id instead?
// Or is there some added value in having the same detail pages under different URLs?

export const Route = createFileRoute('/accounts/$accountId/your-music_/schedules/$scheduleId')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'schedule.meta'
  },
  beforeLoad: ({
    params
  }) => {
    ensurePaddedId(params, 'scheduleId');
  },
  loader: lazyFn($$splitLoaderImporter, 'loader')
});