import { createFileRoute, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/accounts/$accountId/your-music/schedules/$scheduleId/copy')({
  beforeLoad: ({
    location
  }) => {
    throw redirect({
      to: '/accounts/$accountId/your-music/schedules/edit/$scheduleId',
      from: Route.fullPath,
      state: location.state,
      search: {
        action: 'copy'
      }
    });
  }
});