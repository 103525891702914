const $$splitComponentImporter = () => import(/* webpackChunkName: "route-pairing-guide" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/(pairing-guide)/connect.$vendor/$pairingCode.pair.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensureLoggedIn } from '#app/route-lib';
import { ensureValidVendor } from './-ensureValidVendor';

/** For Linkplay (WiiM) + Old way of pairing Sonos */

export const Route = createFileRoute('/(pairing-guide)/connect/$vendor/$pairingCode/pair')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'pairingGuide.pairing.meta',
    showNotifications: false
  },
  beforeLoad: ({
    location,
    params
  }) => {
    // throw redirect if logged out or invalid vendor
    ensureLoggedIn(location);
    ensureValidVendor(params.vendor);
  }
});