const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-upgrade-plan" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/upgrade-plan/index.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-upgrade-plan" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/upgrade-plan/index.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
export const Route = createFileRoute('/accounts/$accountId/upgrade-plan/')({
  loader: lazyFn($$splitLoaderImporter, 'loader'),
  staticData: {
    showSidebarLayout: false
  },
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr)
});