const $$splitComponentImporter = () => import(/* webpackChunkName: "route-misc" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/(misc)/invoice.$uuid.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
export const Route = createFileRoute('/(misc)/invoice/$uuid')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    appearance: 'none',
    meta: 'invoiceSpec.meta',
    showFooter: false,
    showNotifications: false,
    showSidebarLayout: false
  }
});