const $$splitComponentImporter = () => import(/* webpackChunkName: "route-create" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/create/playlist.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ModalErrorView } from '#app/components/error/index';
import { tier3Or } from '#app/lib/authenticated';
export const Route = createFileRoute('/create/playlist')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: () => {
    tier3Or('/create');
  },
  errorComponent: ModalErrorView
});