import { z } from 'zod';
import { subscriptionActionSchema } from '#app/components/zone/subscription-action';
import { noopStringify } from '#app/route-lib';

// This route only allows a subset of subscription actions
export const allowedActionsSchema = z.enum(['activate', 'upgrade', 'resubscribe', 'deactivate']);

// Allow all actions for this schema to simplify typings to different subscription routes
const paramsSchema = z.object({
  action: subscriptionActionSchema
});
export const routeParams = {
  parse: paramsSchema.parse,
  stringify: noopStringify
};