import ifEmoji from 'emoji-supported'

/**
 * A zero-width space character, used to keep elements taking up the line height
 * when not rendering any other content.
 */
export const zeroWidthSpace = '\u200B'

/** Remove diacritics so that e.g. Motorhead matches Motörhead */
export function removeDiacritics(string: string) {
  // Not supported by IE, lets just skip the normalization
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!String.prototype.normalize) return string
  return (
    string
      .normalize('NFD')
      // https://en.wikipedia.org/wiki/Combining_Diacritical_Marks
      .replace(/[\u0300-\u036f]/g, '')
  )
}

let supportsEmoji: boolean | null = null

/**
 * @param countryCode string
 * @returns A flag emoji if supported, otherwise falls back to parenthesized country code
 */
export function countryCodeToFlagEmoji(countryCode: string) {
  supportsEmoji ??= ifEmoji('🇸🇪')
  const code = countryCode.toUpperCase()
  return supportsEmoji
    ? charCodeToRegionalIndicatorCode(code.charCodeAt(0)) +
        charCodeToRegionalIndicatorCode(code.charCodeAt(1))
    : `(${code})`
}

const ascii_A = 65
const regionalIndictor_A = 56806

function charCodeToRegionalIndicatorCode(charCode: number): string {
  charCode -= ascii_A
  return String.fromCharCode(55356, regionalIndictor_A + charCode)
}
