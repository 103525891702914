import { Base64 } from 'js-base64'

/**
 * Decodes a base64 string.
 *
 * @param {String} input A base64 string
 * @returns {String} The previously encoded string
 */
export function decode(input) {
  // Handle url-safe base64 strings (/ replaced with _) from syb-core
  input = input.replace(/_/g, '/')
  return Base64.decode(input)
}

/**
 * Encodes string into base64, replacing any padded `=` characters with `.` due to syb-core legacy.
 *
 * @param {String} input The string to be encoded
 * @returns {String} A base64 string
 */
export function encode(input) {
  return Base64.encode(input).replace(/=/g, '.')
}

/**
 * Returns the base64 string with padding characters readded (if missing).
 * Also removes anything after # and ? in case they've sneaked through.
 *
 * @param {String} input - base64 string which may have #? suffixes in it
 * @param {boolean} [stripSuffix=true] - Toggles stripping of # ? suffixes
 * @return {String} Cleaned & padded base64 string
 */
export function pad(input, stripSuffix = true) {
  if (typeof input !== 'string') {
    return input
  }
  if (stripSuffix) {
    input = input.replace(/[#?].*/, '')
  }
  while (input.length % 4 !== 0) {
    input += '.'
  }
  return input
}

export default {
  encode,
  decode,
  pad,
}
