const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-settings" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/settings/billing/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { loadQuery } from '@soundtrackyourbrand/capsule/dist/graph/query';
import { createFileRoute } from '@tanstack/react-router';
import { allowedOr } from '#app/lib/authenticated';
import BillingSettings from '#app/modules/accounts/settings/billing/index';
export const Route = createFileRoute('/accounts/$accountId/settings/billing')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'account.settings.tabs.billing.meta',
    accountSwitch: '~/settings/billing'
  },
  beforeLoad: async ({
    context,
    params
  }) => {
    await allowedOr(['zone.activate'], '/accounts/$accountId/settings');
    loadQuery(context.store,
    // @ts-expect-error TS and Fetch component
    BillingSettings.query(null, null, {
      params
    }));
  }
});