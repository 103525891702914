import type { ValidationErrors } from '@soundtrackyourbrand/ui'

export * from './localizer'
export * from './not-found'
export * from './reporting'

export type DecoratedError<T extends Error = Error> = T & {
  /** Localized message to be displayed to end user, for example by {@link errorLocalizer} */
  localized?: string | any
  /** List of missing i18n keys that was tried */
  localizedMissing?: string[]
  /** Status code */
  status?: number
  /** Error code */
  code?: string
  /** Field-level validation errors accessed by `useForm()` */
  validationErrors?: ValidationErrors
  /** Prevent error from being reported to Sentry */
  isExpected?: boolean
  /** Sentry error ID if already reported to Sentry backend */
  sentryId?: string
  /** Fingerprint used to differentiate between different Sentry errors */
  sentryFingerprint?: Array<string | number>
  /** Error response body */
  body?: any
  /** Error response body as text */
  text?: any
  /** @deprecated Capsule error type */
  errorType?: string
}

/** An expected error that won't be reported to Sentry */
export class ExpectedError extends Error implements DecoratedError {
  isExpected = true
}

/**
 * Attempts to extract the best available error message string from a remote
 * request error.
 *
 * @deprecated Prefer using `errorLocalizer()`
 * @return Renderable error message
 */
export function errorMessage(error: DecoratedError): React.ReactNode {
  if (!(error as unknown)) return null
  return (
    error.localized ||
    (error.body && error.body.error) ||
    (!error.body && error.text) ||
    error.message
  )
}
