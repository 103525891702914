import { Api, Graph } from '@soundtrackyourbrand/capsule'
import im from 'immutable'
import overrides from '#app/lib/overrides'
import tracking from '#app/lib/tracking'

export default Graph.Node.makeQueryable({
  type: 'country',
  sideload: true,

  get: {
    remote: (params) => {
      const loc = overrides.get('country')

      if (loc && typeof loc === 'string' && loc.length === 2) {
        return Promise.resolve(loc.toUpperCase())
      }

      // We'll first attempt to request the user's location through Google (proxied through our API).
      // If that fails, we try our Cloudflare worker.
      return Api.request({ path: '/determine_location' }, params)
        .then((google) => {
          // Use Cloudflare worker as a fallback
          if (google.country && google.country !== 'ZZ') {
            return google.country
          }

          return Api.request(
            {
              url: 'https://business.soundtrackyourbrand.com/determine-location',
            },
            params,
          ).then((cf) => {
            // Only track failure event if Google failed
            tracking.track('Client - Country Lookup Failure', {
              'Google Country Code': google.country,
              'Cloudflare Country Code': cf.country,
            })
            return cf.country || 'ZZ'
          })
        })
        .then((country) => {
          overrides.set('country', country)
          return country
        })
    },
    cache: (state) => {
      // Returned as object to allow expanding pricing inside it
      return state
        ? im.fromJS({
            iso: state,
          })
        : undefined
    },
  },

  reducer(state = null, action) {
    switch (action.type) {
      case 'REQUEST_COUNTRY_GET_SUCCESS':
        return action.data
      default:
        return state
    }
  },

  edges: {
    pricing: Graph.Graph.Edge(
      { type: 'pricing', query: 'get' },
      (country, params) => ({
        iso_country: country.get('iso'),
        voucher_code: params?.voucher,
      }),
    ),
    required_tax_fields: Graph.Graph.Edge(
      { type: 'required_tax_fields', query: 'get' },
      (country, params) => ({
        buyer_country: country.get('iso'),
        // TODO: Should be set to US when country is US once SYB inc. is live
        seller_country: 'SE',
      }),
    ),
  },
})
