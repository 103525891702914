import {
  type RefCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import ResizeObserver from 'resize-observer-polyfill'

let _resizeObserver: ReturnType<typeof createResizeObserver> | undefined

/**
 * React hook that fires a callback whenever ResizeObserver detects a change to
 * its content size. This excludes changes to the element's border, padding, or
 * margin. The `width` and `height` reported to the callback will however
 * represent the border-box (including the aforementioned properties).
 *
 * @see https://github.com/jaredLunde/react-hook/tree/master/packages/resize-observer
 * @returns A React ref created by `useRef()` that should be attached to the element you want to observe the size of.
 *
 * @example
 * ```tsx
 * function MyComponent() {
 *   const sizeObserverRef = useResizeObserver((entry) => {
 *     const { width, height } = entry.rect
 *     console.log({ width, height })
 *   })
 *   return <div ref={sizeObserverRef} />
 * }
 * ```
 */
export function useResizeObserver<T extends HTMLElement>(
  /** Invoked with a single `ResizeObserverEntry` any time the `target` resizes */
  callback: (
    entry: { target: T; rect: { width: number; height: number } },
    observer: ResizeObserver,
  ) => void,
  /** Optional configuration */
  { active = true } = {},
): RefCallback<T> {
  const lastCallback = useRef(callback)
  const [target, setTargetRef] = useState<HTMLElement>()

  useLayoutEffect(() => {
    if (!target || !active) {
      return
    }

    function cb(entries: ResizeObserverEntry[], observer: ResizeObserver) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const borderRect = entries[0]!.borderBoxSize?.[0] as
        | ResizeObserverSize
        | undefined
      // Assume writing-mode is horizontal - if not then we should just unconditionally rely on `getClientRects()`
      const rect = borderRect
        ? { width: borderRect.inlineSize, height: borderRect.blockSize }
        : entries[0]!.target.getClientRects()[0]!
      if (!(rect as unknown)) {
        return
      }
      lastCallback.current({ target: entries[0]!.target as T, rect }, observer)
    }

    const observer = getResizeObserver()

    // Fire the callback immediately
    cb(
      [
        {
          target,
          rect: target.getClientRects()[0],
        } as any,
      ],
      observer.observer,
    )

    observer.subscribe(target, cb)
    return () => {
      observer.unsubscribe(target, cb)
    }
  }, [target, active])

  useEffect(() => {
    lastCallback.current = callback
  }, [callback])

  return setTargetRef as RefCallback<T>
}

export function getResizeObserver() {
  return (_resizeObserver ||= createResizeObserver())
}

/**
 * ResizeObserver singleton.
 * Based off @react-hook/resize-observer.
 * Using a single observer is supposedly much more performant:
 * https://groups.google.com/a/chromium.org/forum/#!msg/blink-dev/z6ienONUb5A/F5-VcUZtBAAJ
 *
 * @see https://github.com/jaredLunde/react-hook/tree/master/packages/resize-observer
 */
function createResizeObserver() {
  const callbacks = new Map<unknown, ResizeObserverCallback[]>()
  const pendingEntries: ResizeObserverEntry[] = []

  const observer = new ResizeObserver((entries, observer) => {
    const hasPending = pendingEntries.length > 0
    pendingEntries.push(...entries)
    if (!hasPending) {
      window.requestAnimationFrame(() => {
        // Only trigger callback for the most recent update of each tracked element
        const updatedEntries = new Set<unknown>()
        for (let p = pendingEntries.length - 1; p >= 0; p--) {
          const entry = pendingEntries[p]!
          if (!updatedEntries.has(entry)) {
            updatedEntries.add(entry)
            const cbs = callbacks.get(entry.target)
            cbs?.forEach((cb) => cb([entry], observer))
          }
        }
        // Empty pending queue to allow subsequent triggers
        pendingEntries.length = 0
      })
    }
  })

  return {
    observer,
    subscribe(
      target: HTMLElement,
      callback: ResizeObserverCallback,
      { box = true } = {},
    ) {
      observer.observe(target)
      const cbs = callbacks.get(target) || []
      cbs.push(callback)
      callbacks.set(target, cbs)
    },
    unsubscribe(
      target: HTMLElement,
      callback: ResizeObserverCallback,
      { box = true } = {},
    ) {
      const cbs = callbacks.get(target)
      if (!cbs) {
        return
      }
      if (cbs.length < 2) {
        observer.unobserve(target)
        callbacks.delete(target)
        return
      }
      const cbIndex = cbs.indexOf(callback)
      if (cbIndex !== -1) {
        cbs.splice(cbIndex, 1)
        callbacks.set(target, cbs)
      }
    },
  }
}
