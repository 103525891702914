import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import {
  PartialAddModal,
  TrackLimitModal,
  UpdateErrorModal,
} from '#app/components/collection/update-modals'
import type { IsoCountry } from '#app/graphql/graphql'
import { graphql } from '#app/graphql/index'
import { errorLocalizer } from '#app/lib/error/localizer'
import { t } from '#app/lib/i18n/index'
import toast from '#app/lib/toast'
import { urlForId } from '#app/lib/urls'
import type { AppThunk } from '.'
import * as miscSlice from './misc'
import modalActions from './modals'
import { TRACKS_MAX_LIMIT, updatePlaylistTracks } from './playlist-editor'
import { currentAccountSlice } from './reducers'

export type AddTracksToPlaylistResult = {
  addedTracks: string[]
} | null

/**
 * Adds tracks to the end of a playlist.
 *
 * Automatically shows toasts for result/error, asks user if they want to do partial add if limit would be reached
 */
export function addTracksToPlaylist(options: {
  playlistID: string
  trackIDs: Array<string>
  noToasts?: boolean
}): AppThunk<Promise<AddTracksToPlaylistResult>> {
  return async (dispatch, getState, { apollo }) => {
    const { trackIDs, playlistID, noToasts } = options
    try {
      // Set last updated playlist, used in UI for quick actions
      dispatch(miscSlice.actions.setLastUpdatedPlaylistId(playlistID))

      const market = currentAccountSlice.selectors.country(
        getState(),
      ) as IsoCountry
      const { total } = await getPreupdateValidationData(
        apollo,
        playlistID,
        market,
      )

      // Limit is reached, cannot add
      if (total === TRACKS_MAX_LIMIT) {
        dispatch(modalActions.show(TrackLimitModal))
        return null
      }

      if (total + trackIDs.length > TRACKS_MAX_LIMIT) {
        // Ask if user wants do a partial add
        const shouldContinue = await new Promise((onClose) => {
          dispatch(modalActions.show(PartialAddModal, { onClose }))
        })
        if (!shouldContinue) {
          return null
        }
        trackIDs.length = TRACKS_MAX_LIMIT - total
      }

      await updatePlaylistTracks({
        apollo,
        input: {
          id: playlistID,
          trackIds: trackIDs,
          start: -1, // negative value means start at the end
          length: 0, // end 0 together causes tracks to just be added to the end
        },
        market,
      })

      if (!noToasts) {
        // const key = deleteCount > 0 ? 'updated' : 'added'
        toast.success(
          t(`addTracksToPlaylist.toast.added`, {
            count: trackIDs.length,
          }),
          {
            duration: 5e3,
            action: {
              label: t(`addTracksToPlaylist.toast.viewAction`),
              to: urlForId(playlistID),
            },
          },
        )
      }

      return {
        addedTracks: trackIDs,
      }
    } catch (error: any) {
      const errorAction = handleError({ error })
      dispatch(errorAction)
      return null
    }
  }
}

/**
 * Removes track from playlist
 *
 * Automatically shows toasts for result/error
 */
export function removeTrackFromPlaylist(options: {
  playlistID: string
  /** Index of track to remove */
  index: number
  /** Snapshot to validate the user was looking at the most recent version */
  validateSnapshot: string
}): AppThunk<Promise<boolean>> {
  const { index, playlistID, validateSnapshot } = options
  return async (dispatch, getState, { apollo }) => {
    try {
      // Set last updated playlist, used in UI for quick actions
      dispatch(miscSlice.actions.setLastUpdatedPlaylistId(playlistID))

      const market = currentAccountSlice.selectors.country(
        getState(),
      ) as IsoCountry
      const { snapshot } = await getPreupdateValidationData(
        apollo,
        playlistID,
        market,
      )

      if (validateSnapshot && snapshot !== validateSnapshot) {
        throw new Error('Playlist snapshot mismatch between dispatch and cache')
      }

      await updatePlaylistTracks({
        apollo,
        input: {
          id: playlistID,
          trackIds: [],
          start: index,
          length: 1,
          snapshot: validateSnapshot,
        },
        market,
      })

      toast.success(t(`addTracksToPlaylist.toast.updated`), {
        duration: 5e3,
        action: {
          label: t(`addTracksToPlaylist.toast.viewAction`),
          to: urlForId(playlistID),
        },
      })

      return true
    } catch (error: any) {
      const errorAction = handleError({ error })
      dispatch(errorAction)
      return false
    }
  }
}

function handleError({ error }: { error: any }) {
  const status = error.gqlContext?.code || 'unknown'
  const message = error.graphQLErrors?.[0]?.message?.trim() || error.message
  errorLocalizer(error, 'playlistEditor.errors', {
    type: 'playlist',
    key: [message, status],
    status,
  })
  return modalActions.show(UpdateErrorModal, {
    type: 'playlist',
    error,
  })
}

const PlaylistUpdateValidationDoc = graphql(/* GraphQL */ `
  query PlaylistUpdateValidation($playlistId: ID!, $market: IsoCountry!) {
    playlist(id: $playlistId) {
      id
      snapshot
      tracks(market: $market, first: 0) {
        total
      }
    }
  }
`)

async function getPreupdateValidationData(
  apollo: ApolloClient<NormalizedCacheObject>,
  playlistId: string,
  market: IsoCountry,
): Promise<{
  snapshot: string
  total: number
}> {
  const preUpdateValidationResult = await apollo.query({
    query: PlaylistUpdateValidationDoc,
    variables: { playlistId, market },
    fetchPolicy: 'cache-first',
  })

  const playlist = preUpdateValidationResult.data.playlist
  if (!playlist || !playlist.tracks) {
    throw new Error('Playlist does not exist', {
      cause: preUpdateValidationResult,
    })
  }

  return {
    snapshot: playlist.snapshot,
    total: playlist.tracks.total,
  }
}
