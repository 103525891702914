/** @see https://github.com/soundtrackyourbrand/ui/blob/master/sass/_settings.scss */
export const BREAKPOINTS = {
  0: 0,
  xxs: 320, // not really used on the web
  xs: 360,
  s: 420,
  sm: 600, // small to medium
  m: 720,
  l: 1000,
  xl: 1240,
  xxl: 1600,
} as const
export const BREAKPOINTS_ARRAY = Object.values(BREAKPOINTS)

type BreakpointKey = keyof typeof BREAKPOINTS
type SelectValues<T = any> = {
  [Property in BreakpointKey]?: T
}

const SORTED_ENTRIES = Object.entries(BREAKPOINTS).sort((a, b) => b[1] - a[1])
const FULFILLED_CACHE: {
  [key: number]: {
    [Property in BreakpointKey]?: boolean
  }
} = {}

/**
 * Returns an object with breakpoint names as keys and values set to `true`
 * if that breakpoint is matched based on passed `width`
 */
export function fulfilled(width: number) {
  if (!FULFILLED_CACHE[width]) {
    FULFILLED_CACHE[width] = {}
    SORTED_ENTRIES.filter(([, value]) => width >= value)
      .map(([key]) => key)
      .forEach((key) => {
        FULFILLED_CACHE[width]![key] = true
      })
  }
  return FULFILLED_CACHE[width]
}

/**
 * Returns a value based on the closest matching breakpoint set in passed `values`
 * object, where its keys are known breakpoints and values could be anything.
 */
export function select<T, G>(
  width: number,
  values: SelectValues<T> | G,
): T | G {
  if (!isValid(values)) {
    return values
  }

  const biggestFulfilledBreakpoint = SORTED_ENTRIES.find(
    ([key, value]) =>
      width >= value && Object.prototype.hasOwnProperty.call(values, key),
  )?.[0]

  if (!biggestFulfilledBreakpoint) {
    return undefined as any
  }

  return values[biggestFulfilledBreakpoint]
}

/**
 * Returns whether or not the passed `values` are valid or not.
 */
function isValid<T, G>(
  values?: SelectValues<T> | G,
): values is SelectValues<T> {
  if (!values || typeof values !== 'object' || Array.isArray(values)) {
    return false
  }
  /*
     Check if at least one of the breakpoint identifiers are present as keys on
     the object. This should be enough since the breakpoint identifiers are
     unlikely to become valid style property names.
     The only outlier is `0`, since it corresponds to an array index - but as
     we've already ruled out `values` being an array we should be safe.
  */
  return SORTED_ENTRIES.some((entry) =>
    Object.prototype.hasOwnProperty.call(values, entry[0]),
  )
}
