import {
  type ThunkDispatch,
  type UnknownAction,
  createListenerMiddleware,
} from '@reduxjs/toolkit'
import type { RootState } from '#app/store/index'
import { type AppThunkExtra, extraThunk } from '../lib/thunk'

// Create a listener middleware to listen to actions
const listenerMiddleware = createListenerMiddleware<
  RootState,
  /** We can't pass `AppDispatch` here due to circular reference. Needs to be manually typed if needed. */
  ThunkDispatch<RootState, unknown, UnknownAction>,
  AppThunkExtra
>({
  extra: extraThunk,
})

// Clear listeners on hot reload
listenerMiddleware.clearListeners()

export const startReduxListening = listenerMiddleware.startListening
export const stopReduxListening = listenerMiddleware.stopListening

// Export these for store to use in configuration
export const { middleware } = listenerMiddleware
