/**
 * Transform string search parameters from one value to another
 */
export function transformSearchParam(
  encodedValue: string,
  from: string,
  to: string,
) {
  return encodedValue
    .split('&')
    .map((part) => {
      if (!part || !part.includes('=')) {
        return part
      }

      const [key, value] = part.split('=')

      if (!value) {
        return part
      }

      // If the value doesn't look like an object or array, we replace `from` with `to`
      if (!value.startsWith('%7B') && !value.startsWith('%5B')) {
        return `${key}=${value.replaceAll(from, to)}`
      }

      return `${key}=${value}`
    })
    .join('&')
}
