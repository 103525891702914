import { v4 as uuid } from 'uuid'
import overrides from './overrides'

/**
 * Create and return a new session ID and store it in the cookies
 * @returns {string} A new session ID
 */
function createAndStoreUUIDv4(): string {
  const sessionId = uuid()
  overrides.set('session-id', sessionId)
  return sessionId
}

export { createAndStoreUUIDv4 }
