import { entityId } from '@soundtrackyourbrand/capsule/dist/utils/immutable';
/**
 * Filters a list of tracks/track edges based on various criteria.
 */
export function filterTracks(tracks, options) {
  let {
    limit,
    account,
    country,
    explicit,
    unavailable,
    unpreviewable,
    excludedTracks
  } = options;
  if (explicit == null) {
    // If we have an account, use account settings to determine whether explicit content is allowed.
    if (account?.getIn(['settings', 'filter_explicit']) === false) {
      explicit = true;
    }
  }
  if (country === undefined && account) {
    country = account.get('iso_country');
  }

  // Include unavailable tracks by default
  if (unavailable === undefined) {
    unavailable = true;
  }

  // Include unpreviewable tracks by default
  if (unpreviewable === undefined) {
    unpreviewable = true;
  }

  // Track ids to exclude
  const excludedIds = excludedTracks instanceof Set ? excludedTracks : excludedTracks ? new Set([...excludedTracks].map(entityId)) : undefined;

  // Apply track filters
  // Note: This needs to be a KeyedSeq in order to preserve the original track indices
  const filteredTracks = [];
  const trackIdCounts = {};
  let added = 0;
  for (let realIndex = 0; realIndex < tracks.length; realIndex++) {
    const edge = tracks[realIndex];
    const isEdge = !!(edge && 'node' in edge);
    const t = isEdge ? edge.node : edge;
    if (!t) continue;
    const id = t.id;
    if ((explicit || !t.explicit) && (unavailable || t.isAvailable) && (unpreviewable || t.previewUrl) && !excludedIds?.has(id) && (!limit || added++ < limit)) {
      trackIdCounts[id] = (trackIdCounts[id] || 0) + 1;
      const key = id + (trackIdCounts[id] > 1 ? '-' + trackIdCounts[id] : '');
      filteredTracks.push(isEdge ? {
        realIndex,
        key,
        node: t
      } : t);
    }
  }
  return filteredTracks;
}