import { type AnySource } from '@soundtrack/playback/base'
import { COMPOSER_SUBTYPE_MAP } from '@soundtrack/playback/composers'
import { parseId } from '@soundtrackyourbrand/apollo-client/keys'
import { Models } from '@soundtrackyourbrand/capsule'
import { PlaylistComposer } from '@soundtrackyourbrand/capsule/dist/models/collection'
import { ScheduleComposer } from '@soundtrackyourbrand/capsule/dist/models/schedule'
import { type ImmutableEntity } from '@soundtrackyourbrand/capsule/dist/utils/immutable'
import { getIn } from '@soundtrackyourbrand/object-utils.js'
import type im from 'immutable'
import { graphql } from '#app/graphql/gql'
import type { PlaylistPresentAs } from '#app/graphql/graphql'
import { t } from './i18n'

type ImmutableSource = im.Collection<string, any> | ImmutableEntity

export type SourceWithComposer = {
  __typename: 'Playlist' | 'Schedule'
  id: string
  curated?: boolean | null
  composerType: string
} & (
  | {
      __typename: 'Playlist'
      presentAs?: PlaylistPresentAs
    }
  | {
      __typename: 'Schedule'
    }
)

type SourceWithCurator = (
  | {
      __typename: 'Playlist'
      curator?: { accountId: string } | null
    }
  | {
      __typename: 'Schedule'
      scheduleCurator?: { accountId: string } | null
    }
) &
  SourceWithComposer

export type MixedSourceWithCurator = ImmutableSource | SourceWithCurator
export type MixedSourceWithComposer = ImmutableSource | SourceWithComposer
export type SourceInfoInput =
  | MixedSourceWithComposer
  | { __typename: string; [key: string]: unknown }

export function sourceInfo(source: SourceInfoInput) {
  const typename = getIn(source, '__typename') as string | undefined
  if (typename) {
    return {
      type: (getIn(source, 'presentAs') as string) || typename.toLowerCase(),
      subtype: COMPOSER_SUBTYPE_MAP[getIn(source, 'composerType') as string],
    }
  }
  return Models.Musiclibrary.sourceInfo(source)
}

export function isGqlPlaylist(source: unknown): source is SourceWithComposer {
  return (
    !!source &&
    typeof source === 'object' &&
    '__typename' in source &&
    source.__typename === 'Playlist'
  )
}

export function isOwner(
  source: SourceWithCurator,
  account?: ImmutableEntity,
): boolean {
  if (!(source as unknown) || !account) return false
  const owner =
    source.__typename === 'Playlist'
      ? source.curator?.accountId
      : source.scheduleCurator?.accountId
  return owner === account.get('id')
}

export function isEditable(
  source: MixedSourceWithCurator,
  account?: ImmutableEntity,
): boolean {
  if ((source as unknown) && '__typename' in source) {
    if (!isOwner(source, account)) {
      return false
    }
    switch (source.composerType) {
      case PlaylistComposer.Manual:
        return account!.get('tier') === 'tier-3'
      case PlaylistComposer.Seed:
      case PlaylistComposer.Recipe:
      case PlaylistComposer.SimpleRecipe:
        // TODO: Check if we can remove SimpleRecipe
        return true
      case ScheduleComposer.Manual:
      case ScheduleComposer.Zone:
        return true
    }
    return false
  }
  return Models.Collection.isEditable(source, account)
}

export function sourceComposer(
  source: MixedSourceWithComposer,
): string | undefined {
  if (!(source as unknown)) return undefined
  return (
    ('__typename' in source &&
      ['Schedule', 'Playlist'].includes(source.__typename) &&
      source.composerType) ||
    ('get' in source &&
      typeof source.get === 'function' &&
      source.get('composer'))
  )
}

/** Returns a string describing the source in question, suitable for use as a subtitle. */
export function sourceDescription(
  source: {
    __typename?: string
    composerType?: string
    shortDescription?: string
    presentAs?: PlaylistPresentAs
  },
  showDescription = true,
): string {
  return (
    (showDescription && source.shortDescription) ||
    t(
      [
        `source.descriptionFallback.${source.composerType}`,
        `source.types.${source.presentAs || source.__typename?.toLowerCase()}`,
      ],
      { defaultValue: null },
      false,
    ) ||
    ''
  )
}

export function isManualPlaylist(source: MixedSourceWithComposer) {
  return sourceComposer(source) === PlaylistComposer.Manual
}

export function isStationPlaylist(source: MixedSourceWithComposer) {
  const composer = sourceComposer(source)
  return (
    composer === PlaylistComposer.Seed ||
    composer === PlaylistComposer.Recipe ||
    composer === PlaylistComposer.SimpleRecipe
  )
}

export function isAvailableSource(
  source: ImmutableSource | SourceWithComposer,
  tier3: boolean,
): boolean {
  return (
    !!(source as unknown) &&
    (!isManualPlaylist(source) ||
      tier3 ||
      (getIn(source, 'curated') as boolean) ||
      false)
  )
}

export function isShuffleAllowed(
  source: SourceInfoInput,
  tier3: boolean,
): boolean {
  return (
    !!tier3 && !!(source as unknown) && sourceInfo(source).type === 'playlist'
  )
}

export function canModifyPlayOrder(
  source: SourceWithCurator,
  account: im.Map<string, any>,
): boolean {
  return (
    isShuffleAllowed(source, account.get('tier') === 'tier-3') &&
    isOwner(source, account)
  )
}

export type MixedMaybeExplicitSource =
  | {
      __typename: 'Playlist'
      trackStatistics?: { explicit?: number | null } | null
    }
  | ImmutableEntity

export function isExplicit(source: MixedMaybeExplicitSource): boolean {
  return (source as unknown) && '__typename' in source
    ? (source.trackStatistics?.explicit || 0) > 0
    : Models.Collection.isExplicit(source)
}

export function isZoneSchedule(
  input: MixedSourceWithComposer | AnySource | {},
): boolean {
  if (!(input as unknown)) return false
  return (
    ('__typename' in input &&
      input.__typename === 'Schedule' &&
      input.composerType === ScheduleComposer.Zone) ||
    ('get' in input &&
      typeof input.get === 'function' &&
      input.get('type') === 'Schedule' &&
      input.get('composer') === ScheduleComposer.Zone)
  )
}

export function getZoneIdFromSchedule(
  input:
    | MixedSourceWithComposer
    | { composer: { __typename: string; id?: string } },
): string | undefined {
  if (!(input as unknown)) return undefined
  if (
    'composer' in input &&
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    input.composer?.__typename === 'ZoneScheduleComposer'
  ) {
    return input.composer.id
  }
  if ('getIn' in input && typeof input.getIn === 'function') {
    return input.getIn(['composer_data', 'zone', 'sound_zone_id'])
  }
  return undefined
}

export function displaySpotifyBadge(
  source: SourceWithCurator,
  forAccountId?: string,
) {
  const type = source.composerType
  return (
    type === 'external-spotify-composer' ||
    (type === 'spotify-composer' &&
      source.__typename === 'Playlist' &&
      !!forAccountId &&
      source.curator?.accountId === forAccountId)
  )
}

/**
 * Counts the number of IDs whose `__typename` corresponds to the given value.
 */
export function countOfType(ids: string[], __typename: string): number {
  let count = 0
  for (const id of ids) {
    if (parseId(id).__typename === __typename) {
      count++
    }
  }
  return count
}

export const UpdateManualPlaylistPlaybackModeDoc = graphql(/* GraphQL */ `
  mutation UpdateManualPlaylistPlaybackMode(
    $input: UpdateManualPlaylistInfoInput!
  ) {
    updateManualPlaylist(input: $input) {
      ...PlaybackModePlaylist
    }
  }
`)

export const UpdateSpotifySyncedPlaylistPlaybackModeDoc = graphql(
  /* GraphQL */ `
    mutation UpdateSpotifySyncedPlaylistPlaybackMode(
      $input: UpdateSpotifySyncedPlaylistInput!
    ) {
      updateSpotifyPlaylist(input: $input) {
        ...PlaybackModePlaylist
      }
    }
  `,
)

graphql(/* GraphQL */ `
  fragment PlaybackModePlaylist on Playlist {
    id
    presets {
      playbackMode
    }
  }
`)
