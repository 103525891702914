import '@soundtrack/utils/types';
import { Models } from '@soundtrackyourbrand/capsule';
import { getIn } from '@soundtrackyourbrand/object-utils.js';
import { hexToHsluv, hsluvToHex, hsluvToRgb } from 'hsluv';
export function getContrastingColor(...hexColors) {
  const best = hexColors.reduce((best, candidate) => {
    if (!candidate) {
      return best;
    }
    let [H, S, L] = hexToHsluv(candidate);
    L = Math.min(65, L);
    return S > best.S ? {
      H,
      S,
      L
    } : best;
  }, {
    H: 0,
    S: 0,
    L: 65
  });
  return hsluvToHex([best.H, best.S, best.L]);
}
export function getGradientColor(color, type) {
  const best = [color].reduce((best, candidate) => {
    if (!candidate) {
      return best;
    }
    let [H, S, L] = hexToHsluv(candidate);
    if (S < best.S) {
      return best;
    }
    if (type === 'station') {
      S = Math.max(100, S);
    }
    if (type !== 'schedule') {
      L = Math.min(10, L);
    }
    return {
      H,
      S,
      L
    };
  }, {
    H: 0,
    S: 0,
    L: 0
  });
  return hsluvToRgb([best.H, best.S, best.L]);
}

/** Converts HEX color (leading # optional) into a comma separated RGB string. Lets existing RGB values through unchanged */
export function hexToRgb(hex) {
  if (typeof hex !== 'string') return undefined;
  if (/^\d{1,3}(, ?\d{1,3}){2}$/.test(hex)) return hex;
  const m = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
  return m ? m.slice(1).map(p => parseInt(p, 16)).join() : undefined;
}
export function sourceColor(source) {
  const primary = Models.Collection.color(source);
  return primary ? getContrastingColor(primary) : undefined;
}
export function displayableColor(source, type = 'primary', strict = true) {
  const color = getIn(source, ['display', 'colors', type, 'hex']);
  if (color || strict) {
    return color;
  }
  return collectionColor(source) || trackColor(source, type);
}
export function trackColor(track, type = 'primary') {
  const album = getIn(track, 'album');
  if (!album) return undefined;
  return getIn(album, ['display', 'colors', type, 'hex']) ||
  // This will only hold value when data is coming from REST API
  getIn(album, [`album_${type}_color`]);
}
export function collectionColor(source) {
  // Type is an attribute that only exist on the REST API data
  const type = getIn(source, 'type') || getIn(source, '__typename');
  const isSchedule = type === 'Schedule';
  const value = isSchedule ? getIn(source, 'color')?.replace(/,.+/, '') : getIn(source, ['presentation', 'soundtrack', 'colors', 'background']);

  // Avoid returning white
  if (value && /^#fff(?:fff)?$/i.test(value)) {
    return undefined;
  }
  return value;
}