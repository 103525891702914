// Singleton reference to audioContext
let audioContext

export default function getAudioContext() {
  if (!audioContext || audioContext.state === 'closed') {
    audioContext = createAudioContext()
    if (!audioContext) {
      return null
    }

    // close is asynchronous so audioContext.state may not be immediately
    // set to closed after calling close. Therfore we monkey-patch close
    // to clear our singleton and make sure we never return an
    // audioContext that's on its way to be closed.
    const originalClose = audioContext.close
    audioContext.close = () => {
      const promise = originalClose.call(audioContext)
      audioContext = undefined
      return promise
    }
  }
  return audioContext
}

export function createAudioContext() {
  /* eslint-disable @typescript-eslint/no-unnecessary-condition */
  // @ts-ignore
  const constructor = window.AudioContext || window.webkitAudioContext
  if (!constructor) {
    return null
  }
  const context = new constructor()

  if (context.resume) {
    context.resume()
  } else {
    // webkitAudioContext needs us to start playback within the context of the click.
    // At least on iOS8 this was broken.
    // Let's fake some audio playback so the audio context gets initialized.
    const oscillator = context.createOscillator()
    oscillator.frequency.value = 80

    const amp = context.createGain()
    amp.gain.value = 0

    oscillator.connect(amp)
    amp.connect(context.destination)
    oscillator.start(0)
    oscillator.stop(0)
    amp.disconnect()
  }

  /* eslint-enable @typescript-eslint/no-unnecessary-condition */
  return context
}
