import { Api, Utils } from '@soundtrackyourbrand/capsule'
import overrides from './overrides'

export const SSO_PROVIDERS = ['google', 'apple'] as const

export function getSsoURL(provider: string, cbPath: string) {
  const callbackURL = encodeURIComponent(location.origin + cbPath)
  return `${Api.env().auth}/auth/${provider}?callbackURL=${callbackURL}`
}

export function getSamlURL(orgSlug: string, returnToPath: string) {
  const returnToURL = encodeURIComponent(location.origin + returnToPath)
  return `${Api.env().login}/login/saml/${orgSlug}?returnTo=${returnToURL}`
}

export async function connectSaml(args: {
  slug: string
  user: string
  returnToPath: string
}) {
  const returnToURL = encodeURIComponent(location.origin + args.returnToPath)
  const response = await fetch(`${Api.env().login}/connect/saml/${args.slug}`, {
    method: 'POST',
    body: JSON.stringify({
      user: args.user,
      returnTo: returnToURL,
    }),
    headers: {
      authorization: `Bearer ${Api.token()}`,
    },
  })

  const json = (await response.json()) as { redirect?: string }
  window.location.href = `${json.redirect}?returnTo=${returnToURL}` || ''
}

export function getSsoToken(token = overrides.get<string>('sso-token')) {
  if (!token) {
    return {}
  }

  let payload: any = {}

  try {
    payload = Utils.Strings.parseToken(token)
  } catch (error: any) {
    // Only swallow parsing errors
    if (error.name !== 'InvalidCharacterError') {
      throw error
    }
  }

  const { sso_id, email = '', iss, provider = 'syb' } = payload

  if (!iss) {
    // Handles invalid tokens
    return {}
  }

  return {
    type: sso_id ? 'sso' : 'refresh',
    value: token,
    email,
    provider,
  }
}

export default {
  getSsoToken,
  getSamlURL,
  getSsoURL,
  SSO_PROVIDERS,
}
