import { log } from '@soundtrack/utils/log'
import { isDefined } from '@soundtrack/utils/typePredicates'
import { apollo, graphql } from '#app/apollo/index'
import { type GetFlagsContextInput } from '#app/graphql/graphql'
import { waitFor } from '#app/lib/async-action'
import { captureError } from '#app/lib/error/reporting'
import shallowEquals from '#app/lib/shallow-equals'
import { store } from '#app/store/index'
import { miscSlice } from '#app/store/reducers'
import { createSelector } from '#app/store/redux'
import * as flagsSlice from '../store'
import { defaultEphemeralContext } from './defaultEphemeralContext'

// TODO: Both this and `applyFlag` could be moved to the same OOP class for
// managing and resetting state between fetches and applies.

const AllFlagsDoc = graphql(/* GraphQL */ `
  query AllFlags($context: GetFlagsContextInput!) {
    flags(properties: $context) {
      trackingKey
      expiresAt
      resolveToken
      flags {
        name
        properties
      }
    }
  }
`)

/** A reference to a currently running fetch request */
let _fetchPromise = null as Promise<any> | null
let _fetchAgent = null as any

type FetchFlagsOptions = {
  /** Skip the stored `expiresAt` check? */
  force?: boolean
  /** Why are we refetching flags? Used in logging only. */
  reason?: string
  /** Any properties that should be forwarded in the fetch request. */
  context?: GetFlagsContextInput
  /** Reset the flags store before fetching? Useful when you want to undo all overrides. */
  reset?: boolean
}

/**
 * Fetches all flags for the current user based on the Editorial Agent for the current account and/or zone.
 * Any `properties` are sent in for the evaluation of the flags configuration.
 *
 * This can also be used to set "stored" properties.
 *
 * TODO: Turn into redux thunk (part of flags slice) which on dispatch sets `reduxState.userAgent`
 * On failure unsets `reduxState.userAgent`
 * On success sets `reduxState.flags` and leaves `.userAgent` as is
 */
export async function fetchFlags(options: FetchFlagsOptions = {}) {
  // We should only fire the request when we have a complete Editorial Agent (X-User-Agent)
  // which we can't do immediately on app start-up due to dependencies on Account data being unavailable
  await waitFor({
    store,
    selectors: [selectHasCompleteUserAgent],
    timeout: 10e3,
  })

  const getAgent = () => miscSlice.selectors.editorialAgent(store.getState())

  const lastAgent = _fetchAgent
  const agent = getAgent()
  const agentIsUnchanged = shallowEquals(lastAgent, agent)

  // Use the existing promise if it's still running and no `force` or `context` are passed
  if (
    _fetchPromise &&
    options.force !== true &&
    options.reset !== true &&
    !options.context &&
    agentIsUnchanged
  ) {
    return _fetchPromise
  }

  const logSuffix = [
    options.reason && `reason: ${options.reason}`,
    options.force && 'force: true',
  ]
    .filter(isDefined)
    .join(', ')

  // Bail if user agent is unchanged as flags are still valid
  // Also passing in any `options.context` will force a fetch regardless of expiry.
  if (agentIsUnchanged && !options.force && !options.context) {
    const expiresAt = flagsSlice.selectors.expiresAt(store.getState())
    if (expiresAt) {
      const now = new Date().toISOString()
      if (expiresAt && expiresAt > now) {
        log.debug(
          `[flags] Skipping fetch, flags are not expired (${logSuffix})`,
        )
        return
      }
    }
  }

  if (options.reset) {
    store.dispatch(flagsSlice.actions.resetFlags())
  }

  log.debug(`[flags] Fetching flags... (${logSuffix})`)
  _fetchAgent = getAgent()

  async function performRequest() {
    const query = await apollo.query({
      query: AllFlagsDoc,
      fetchPolicy: 'network-only', // force fetch
      variables: {
        context: {
          ...options.context,
          ephemeral: [
            ...defaultEphemeralContext(),
            ...(options.context?.ephemeral || []),
          ],
        },
      },
    })

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!query.data.flags?.flags) {
      const err = Object.assign(new Error('No flags returned from API'), {
        cause: query.error,
      })
      captureError(err)
      return
    }

    log.debug(
      `[flags] Fetched ${query.data.flags.flags.length} flag(s) from API`,
    )

    return store.dispatch(
      flagsSlice.actions.setFlagsFromBackend(query.data.flags),
    )
  }

  const promise = (_fetchPromise = performRequest().finally(() => {
    // Reset the promise reference if it's still pointing to this request
    if (_fetchPromise === promise) {
      _fetchPromise = null
    }
  }))

  return _fetchPromise
}

export const selectHasCompleteUserAgent = createSelector(
  [miscSlice.selectors.editorialAgent],
  (agent) => {
    // If we have an account, wait until we've loaded all relevant data before continuing
    // to prevent having the UserAgent change and causing refetches
    if (agent.account && (!agent.country || !agent.businessType)) {
      return false
    }

    return true
  },
)
