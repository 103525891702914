function generateId(communicationId: string, accountId?: string) {
  return communicationId + '/' + (accountId || '*')
}

export const actions = {
  dismiss: (communicationId: string, accountId?: string) => ({
    type: 'DISMISS_COMMUNICATION',
    communicationId,
    accountId,
  }),
}

type State = Readonly<Record<string, boolean>>
type RootState = { communication: State }

export function reducer(state: State = {}, action) {
  switch (action.type) {
    case 'DISMISS_COMMUNICATION':
      return {
        ...state,
        [generateId(action.communicationId, action.accountId)]: true,
      }
    default:
      return state
  }
}

export const selectors = {
  dismissed:
    (communicationId: string, accountId?: string) => (state: RootState) =>
      state.communication[generateId(communicationId, accountId)],
}

export default {
  actions,
  selectors,
}
