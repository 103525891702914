import type { ResultOf, VariablesOf } from '@graphql-typed-document-node/core'
import type { Get } from '@soundtrackyourbrand/object-utils.js'
import { useQueryTtl } from '#app/apollo/index'
import type { Track_TracklistFragment } from '#app/graphql/graphql'
import { graphql } from '#app/graphql/index'

export type TrackListRowData = {
  realIndex: number
  key: string
  node: TrackFragment
}

export type TrackListData = ResultOf<typeof TracklistDoc>

export function useTrackListQuery(variables: VariablesOf<typeof TracklistDoc>) {
  return useQueryTtl(TracklistDoc, {
    skip: !variables.playlistId || !variables.market,
    fetchPolicy: 'cache-first',
    variables,
    notifyOnNetworkStatusChange: true,
    // Omit `first` from TTL id to avoid unnecessary refetches
    ttlId: ['tracklist', variables.playlistId, variables.market].join('/'),
    ttlVary: false,
  })
}

export const TracklistDoc = graphql(/* GraphQL */ `
  query Tracklist(
    $playlistId: ID!
    $market: IsoCountry!
    $first: Int!
    $after: String
  ) {
    playlist(id: $playlistId) {
      ...Playlist_Tracklist
    }
  }
`)

export const TracklistFragmentDoc = graphql(/* GraphQL */ `
  fragment Playlist_Tracklist on Playlist {
    id
    composerType # required for preview-player
    snapshot
    updatedAt
    tracks(market: $market, first: $first, after: $after) {
      total
      updatedAt # used to invalidate cached tracklists through Playlist.merge type policy
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        addedAt
        node {
          ...Track_Tracklist
        }
      }
    }
  }
`)

// For some reason vite refuses to compile if this export is named `Track_TracklistFragmentDoc`
export const TracklistTrackFragmentDoc = graphql(/* GraphQL */ `
  fragment Track_Tracklist on Track {
    __typename
    id
    ...Track_List
    ...Displayable
    durationMs
    isAvailable(market: $market)
  }
`)

export const TracklistIdsDoc = graphql(/* GraphQL */ `
  query TracklistIds($playlistId: ID!, $market: IsoCountry!, $first: Int!) {
    playlist(id: $playlistId) {
      ...Playlist_TracklistIds
    }
  }
`)

export const TracklistIdsFragmentDoc = graphql(/* GraphQL */ `
  fragment Playlist_TracklistIds on Playlist {
    id
    tracks(first: $first, market: $market) {
      total
      edges {
        node {
          id
        }
      }
    }
  }
`)

export type TrackFragment = Track_TracklistFragment

export type TrackListConnection = NonNullable<
  Get<ResultOf<typeof TracklistDoc>, 'playlist.tracks'>
>
export type TrackListEdge = Pick<
  NonNullable<TrackListConnection['edges']>[number],
  'node'
>
