import { createFileRoute } from '@tanstack/react-router';
import { loggedOutOr } from '#app/route-lib';

// This is the (login) route group, all of the routes in this folder
// will be wrapped in this route logic, specifically the beforeLoad
// that will redirect to /home if the user is already logged in.

export const Route = createFileRoute('/(logged-out)')({
  staticData: {
    showSidebarLayout: false
  },
  beforeLoad: () => {
    loggedOutOr('/home');
  }
});