const $$splitComponentImporter = () => import(/* webpackChunkName: "dev" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/dev/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/dev')({
  staticData: {
    meta: 'dev.route.meta'
  },
  beforeLoad: () => {
    // Don't allow any /dev/* routes in production
    if (!ENABLE_ADMIN) {
      throw redirect({
        replace: true,
        to: '/'
      });
    }
  },
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr)
});