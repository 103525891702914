import * as Sentry from '@sentry/react'
import { isApolloError } from '@soundtrackyourbrand/apollo-client'
import { Selectors } from '@soundtrackyourbrand/capsule'
import { type RegisteredRouter } from '@tanstack/react-router'
import { formatDuration, t } from '#app/lib/i18n/index'
import { anonymizeIds } from '#app/lib/urls'
import type { Store } from '#app/store/index'
import modalActions from '#app/store/modals'
import { type DecoratedError } from './index'
import { isAppNotFoundError } from './not-found'

/** Used to track session duration */
const startTime = new Date().valueOf()

/** Injected via {@link initializeSentry} to avoid circular dependencies */
let store: Store | undefined

/** Error reporting */
export function initializeSentry(options: {
  store: Store
  router: RegisteredRouter
}) {
  store = options.store

  if (!SYB.sentry) {
    return
  }

  Sentry.init({
    dsn: SYB.sentryDsn,
    release: SYB.version,
    environment: SYB.env,
    ignoreErrors: [
      /getHostNode/,
      /_currentElement/,
      // Browser extension crap
      /_avast_submit|vid_mate_check|fidoCallback|__gCrWeb\.autofill\.extractForms/,
      /DOMNodeInsertedByJs/,
      /Object Not Found Matching Id/, // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/35
    ],
    integrations: (defaultIntegrations) => [
      ...defaultIntegrations,
      Sentry.tanstackRouterBrowserTracingIntegration(options.router),
    ],
    denyUrls: [/extensions\//i, /^chrome:\/\//i],
    normalizeDepth: 8,
    beforeSend(event, hint) {
      const error = hint.originalException as DecoratedError

      event.extra = event.extra || {}

      if ((error as unknown) != null && typeof error === 'object') {
        if (error.sentryId) {
          // Don't report errors more than once
          return null
        }

        if (
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          error.message?.includes('Request has been terminated') &&
          !window.navigator.onLine
        ) {
          // Don't report network errors when offline, but we do want them otherwise to
          // help track down CORS issues.
          return null
        }

        try {
          // Expose sentry event id
          error.sentryId = event.event_id

          if (isApolloError(error)) {
            if (error.gqlContext.isExpected) {
              return null
            }
            const gqlIdentifier = error.gqlContext.identifier.map((e) =>
              String(e).replace(/^\d+$/, '@'),
            )
            event.tags!['gql.traceId'] = error.gqlContext.traceId
            event.tags!['gql.operation'] = error.gqlContext.operation
            event.tags!['gql.type'] = error.gqlContext.type
            // the first item in the identifier is the operation name
            event.tags!['gql.path'] =
              gqlIdentifier.slice(1).join('.') || undefined
            // When code is missing, add the text undefined so we can search for that in Sentry
            event.tags!['gql.code'] = error.gqlContext.code ?? 'undefined'
            event.tags!.requestStatus = error.gqlContext.status
            event.transaction = 'apollo-client: ' + error.gqlContext.operation
            event.fingerprint = ['apolloClient', ...gqlIdentifier]
          } else if (isAppNotFoundError(error)) {
            event.fingerprint = ['not-found', anonymizeIds(error.pathName)]
            event.tags!.errorType = 'notFound'
          } else if ('req' in error && error.req && error.status) {
            // Capsule request error
            event.tags!.requestStatus = error.status
            event.fingerprint = [
              (error.req as any).method,
              anonymizeIds((error.req as any).url),
              error.status,
            ]
          }

          // Assign enumerable error properties to extras
          const keys = Object.keys(error)
          if (keys.length) {
            event.extra.errorProperties = {}
            keys.forEach((key) => {
              event.extra!.errorProperties![key] = error[key]
            })
          }

          // Determine if error was thrown from Google Tag Manager
          if (
            (error.stack?.indexOf('www.googletagmanager.com/gtm.js') || -1) >= 0
          ) {
            event.tags!.logger = 'gtm'
            event.extra.dataLayer = window.dataLayer?.map((row) => {
              try {
                return JSON.stringify(row, null, '  ')
              } catch (error: any) {
                return `omitted: ${error.message}`
              }
            })
          }
        } catch (error2) {
          console.warn(
            '[sentry] Failed to parse additional error metadata:\n',
            error2,
          )
        }
      }

      // Include human readable session duration as extra + grouped tag
      const duration = new Date().valueOf() - startTime
      event.extra.sessionDuration = formatDuration(duration, 'digits', 3)
      event.tags!.sessionDuration =
        duration < 5e3
          ? 'startup'
          : duration < 60e3
            ? 'seconds'
            : duration < 3600e3
              ? 'minutes'
              : duration < 86400e3
                ? 'hours'
                : 'days'

      if (event.request?.url) {
        // Include tag containing URL path with IDs replaced by {id}
        event.tags!.urlPath = anonymizeIds(
          event.request.url.replace(/^.*\/\/[^/]+/, ''),
        )
      }

      // Don't report when sentry integration is inactivated
      if (
        (SYB.sentry === 'inactive' || (error as any)?.isExpected) &&
        !event.extra.forceSend
      ) {
        console.error(error, { tags: event.tags, extra: event.extra })
        return null
      }

      return event
    },
  })
}

/**
 * Provides a simple way to inform users of an error occurring.
 * If `error.localized` is provided, a simple dialog is shown with the error message.
 * Otherwise, the Sentry error reporting dialog will be triggered.
 *
 * @example
 * ```ts
 * showErrorDialog(error)
 * ```
 */
export function showErrorDialog(error: DecoratedError<Error>) {
  if (error.localized && store) {
    store.dispatch(
      modalActions.showDialog({
        title: t('app.errors.clientTitle'),
        body: error.localized,
      }),
    )
  } else {
    showErrorReportDialog(error)
  }
}

/**
 * Displays the Sentry error reporting dialog, informing the user that an error
 * occurred as well as allowing them to provide more information.
 *
 * Ensures that the passed error is reported before showing the dialog.
 */
export function showErrorReportDialog(
  /** Javascript exception, Sentry `eventId`, or a function returning either */
  errorOrEventId: DecoratedError | (() => DecoratedError),
  /** Gives access to Sentry event scope before error is reported (unless already reported) */
  scopeCallback?: (scope: Sentry.Scope, error: DecoratedError) => void,
  /** Dialog options */
  options = {},
) {
  if (typeof errorOrEventId === 'function') {
    errorOrEventId = errorOrEventId()
  }

  let eventId =
    typeof errorOrEventId === 'number'
      ? errorOrEventId
      : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        errorOrEventId?.sentryId || null

  // Report error if not yet done
  if (!eventId) {
    if (!(errorOrEventId as unknown)) {
      throw Object.assign(
        new Error(`Empty value passed to showErrorReportDialog`),
        {
          originalError: errorOrEventId,
        },
      )
    }
    eventId = captureError(errorOrEventId, scopeCallback)!
  }

  const dialogConfig: Sentry.ReportDialogOptions = Object.assign(
    {
      eventId,
      title: `Something went wrong`,
      // TODO: Allow customization via error.localized?
      subtitle: `Our team has been notified. If you'd like to help, tell us what happened below.`,
      subtitle2: ``,
      labelComments: `What where you doing when encountering this error?`,
      labelClose: `Close`,
      labelSubmit: `Report issue`,
    },
    (options as unknown) && typeof options === 'object' ? options : undefined,
  )

  // Attempt to pre-fill email/name from current user
  try {
    if (store) {
      const user = Selectors.currentUser(store.getState())
      dialogConfig.user = {
        email: user.get('email'),
        name: user.get('name'),
      }
    }
  } catch (error) {
    /* Ignore */
  }

  if (SYB.sentry) {
    Sentry.showReportDialog(dialogConfig)
  } else {
    alert(
      `${dialogConfig.title}\n\n(This would've shown a Sentry error reporting dialog if Sentry wasn't disabled)`,
    )
  }
}

export function addContext(key: string | Record<string, any>, value?: any) {
  if (!key || !SYB.sentry) return
  const values = typeof key === 'object' ? key : { [key]: value }
  Sentry.getCurrentScope().setTags(values)
}

export function removeContext(keys: string | string[] | Record<string, any>) {
  if (!keys) {
    return
  }
  if (!Array.isArray(keys) && typeof keys === 'object') {
    keys = Object.keys(keys)
  }
  const emptyObj = (keys as string[]).reduce((obj, prop) => {
    obj[prop] = null
    return obj
  }, {})
  addContext(emptyObj)
}

export function onRouteUpdate(router: RegisteredRouter) {
  const currentRouteMatch =
    router.state.matches[router.state.matches.length - 1]
  if (currentRouteMatch) {
    addContext({ route: currentRouteMatch.routeId })
  }
}

/**
 * Reports the passed error-like object to Sentry.
 *
 * @return Sentry `eventId` for the reported error (if `SYB.sentry === 'active'`)
 */
export function captureError(
  /** Error to report */
  error: DecoratedError,
  /** Nice error message describing the situation where the error occured */
  message?: string,
  /** Gives access to Sentry event scope before error is reported */
  scopeCallback?: (scope: Sentry.Scope, error: DecoratedError) => void,
): string | null
export function captureError(
  /** Error to report */
  error: DecoratedError,
  /** Gives access to Sentry event scope before error is reported */
  scopeCallback?: (scope: Sentry.Scope, error: DecoratedError) => void,
): string | null
export function captureError(
  error: DecoratedError,
  messageOrScopeCallback?:
    | string
    | ((scope: Sentry.Scope, error: DecoratedError) => void),
  scopeCallback?: (scope: Sentry.Scope, error: DecoratedError) => void,
): string | null {
  let eventId: string | null = null
  if (typeof messageOrScopeCallback === 'string') {
    error = Object.assign(new Error(messageOrScopeCallback), { cause: error })
  } else if (typeof messageOrScopeCallback === 'function') {
    scopeCallback = messageOrScopeCallback
  }
  if (SERVER_ENV !== 'production') {
    console.error(error)
  }
  if (SYB.sentry) {
    Sentry.withScope((scope) => {
      if (scopeCallback) {
        scopeCallback(scope, error)
      }
      // Ignore flag if `captureError()` is manually called
      if ((error as any)?.isExpected) {
        error.isExpected = false
      }
      eventId = Sentry.captureException(error)
    })
  }
  return eventId
}
