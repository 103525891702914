import {
  reducer as experimentsReducer,
  participate,
} from '@soundtrackyourbrand/experiments.js'
import { type Experiment } from '@soundtrackyourbrand/experiments.js/dist/experiment'
import base64 from '#app/lib/base64'
import * as experimentsExport from '#app/lib/experiments'
import overrides from '#app/lib/overrides'
import { getRouter } from '#app/lib/router'
import { type Store } from '.'

export const reducer = experimentsReducer

/**
 * Allow specifying experiment variation via ?exp=payload URL.
 * Payload is a base64 encoded string with the format `experimentName:variation`,
 * where experimentName may be defined in lib/experiments.js, and variation
 * can be either a number corresponding to a index of said defined
 * experiment, or a plain string.
 */
export function handleExperimentsOverrides(store: Store) {
  const exp = overrides.get<string>('exp')
  if (exp) {
    let [experiment, variation] = base64.decode(base64.pad(exp)).split(':')
    if (experiment && variation !== undefined) {
      // eslint-disable-next-line import/namespace
      const definition = experimentsExport[experiment]
      if (definition) {
        experiment = definition.name as string
        if (
          // eslint-disable-next-line eqeqeq
          parseInt(variation as any, 10) == (variation as any) &&
          definition.variations[variation]
        ) {
          variation = definition.variations[variation] as string
        }
        store.dispatch(participate(experiment, variation))
      }
    }
    // Unset `exp` query param
    getRouter().navigate({
      to: '.',
      search: (prev) => ({
        ...prev,
        exp: undefined,
      }),
      resetScroll: false,
    })
  }
}

/**
 * Set all the experiments to the original variational, because when
 * running e2e tests we want to avoid of being randomly selected to an experiment.
 */
export function allExperimentsToOriginal() {
  const allExperimentsOriginal = Object.keys(experimentsExport).reduce(
    (allExperiments, expName) => {
      const exp = experimentsExport[expName] as Experiment | string
      // Not all exports may be an experiment
      if (exp && typeof exp === 'object' && exp.name) {
        allExperiments[exp.name] = exp.variations[0]
      }
      return allExperiments
    },
    {},
  )
  return JSON.stringify(allExperimentsOriginal)
}
