import { createFileRoute } from '@tanstack/react-router';
import { ensureLoggedIn } from '#app/route-lib';
export const Route = createFileRoute('/partner-portal')({
  staticData: {
    meta: 'partnerPortal.dashboard.meta',
    accountSwitch: '/partner-portal/$accountId'
  },
  beforeLoad: async ({
    location
  }) => {
    ensureLoggedIn(location);
  }
});