const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-messaging" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/messaging/messages.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-messaging" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/messaging/messages.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { campaignFilterSchema } from '#app/modules/messaging/common/filter-header';
export const Route = createFileRoute('/accounts/$accountId/messaging/messages')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  validateSearch: zodSearchValidator(campaignFilterSchema),
  beforeLoad: () => {
    return {
      showHeader: true // Overwrites parent route's `showHeader` value
    };
  },
  loader: lazyFn($$splitLoaderImporter, 'loader')
});