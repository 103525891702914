import { createFileRoute, redirect } from '@tanstack/react-router';
import { loggedInOr } from '#app/route-lib';
import { currentAccountSlice } from '#app/store/reducers';
export const Route = createFileRoute('/create/schedule')({
  beforeLoad: ({
    location,
    context
  }) => {
    loggedInOr({
      to: '/create',
      // @ts-expect-error Types for `to` not correctly inferred in helper
      search: {
        signup: ''
      }
    }, location, true);
    const accountId = currentAccountSlice.selectors.id(context.store.getState());
    throw redirect({
      to: '/accounts/$accountId/your-music/schedules/edit',
      params: {
        accountId: accountId || '_'
      },
      state: {
        returnPath: '/create'
      }
    });
  }
});