import type { OnboardingStepStatus } from '#app/graphql/graphql'
import accountSetup from '#app/modules/accounts/onboarding/steps/account-setup/index'
import address from '#app/modules/accounts/onboarding/steps/address/index'
import aiPlaylist from '#app/modules/accounts/onboarding/steps/ai-playlist'
import businessDescription from '#app/modules/accounts/onboarding/steps/business-description/index'
import explicitSetting from '#app/modules/accounts/onboarding/steps/explicit-setting/index'
import getStarted from '#app/modules/accounts/onboarding/steps/get-started'
import openingHours from '#app/modules/accounts/onboarding/steps/opening-hours/index'
import paymentMethod from '#app/modules/accounts/onboarding/steps/payment-method/index'
import questions from '#app/modules/accounts/onboarding/steps/questions/index'
import spotifyImport from '#app/modules/accounts/onboarding/steps/spotify-import/index'
import trialSelect from '#app/modules/accounts/onboarding/steps/trial-select/index'
import type { OnboardingSearch } from '..'

/**
 * Array of all available onboarding steps.
 * Put in the order they should be initially displayed.
 * @type {OnboardingStepName} is derived from the name property of each step.
 */
export const onboardingStepArray: OnboardingStep[] = [
  trialSelect,
  address,
  paymentMethod,
  accountSetup,
  questions,
  getStarted,
  businessDescription,
  spotifyImport,
  explicitSetting,
  openingHours,
  aiPlaylist,
] as const

export type StepCompletedProps = {
  skipped?: boolean
  skipSteps?: boolean // Complete the onboarding by skipping the rest of steps
  abort?: boolean // Abort the onboarding, don't report the onboarding as complete and don't navigate anywhere
  stepStatus?: OnboardingStepStatus
  trackingProps?: Record<string, any>
}

export type StepCompletedType = (props?: StepCompletedProps) => any

export interface OnboardingProps {
  params: {
    // TODO: Rename to `accountId`, but requires all the indidivual steps to be updated as well and get their props typed
    account_id: string
    step?: OnboardingStepName
  }
  search: OnboardingSearch
}

export type OnboardingStepComponentProps = OnboardingProps & {
  onStepCompleted: StepCompletedType
  completeOnboarding: () => void
}

// Any function that returns a boolean as a promise indicating if this step should be displayed
export type StepPredicate = () => Promise<boolean>
export interface OnboardingStep {
  StepComponent: React.FC<OnboardingStepComponentProps>
  isStepValid?: StepPredicate
  name: string
}

// Infer a union of all the names of the steps.
type ExtractNames<T> = T extends { name: infer U }[] ? U : never

export type OnboardingStepName = ExtractNames<typeof onboardingStepArray>
