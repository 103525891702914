import { useCallback, useState } from 'react'

export function useToggle(initialValue) {
  const [value, setValue] = useState(initialValue)
  const toggleValue = useCallback(() => {
    setValue((v) => !v)
  }, [])
  return [value, toggleValue, setValue]
}

export function useToggleOnce(initialValue, nextValue = !initialValue) {
  const [value, setValue] = useState(initialValue)
  const toggleValue = useCallback(() => {
    setValue((v) => nextValue)
  }, [nextValue])
  return [value, toggleValue, setValue]
}
