import { FLAG_DEFINITIONS } from '#app/features/flags/definitions'
import {
  type FlagDefinition,
  type FlagDefinitions,
  type FlagName,
} from '#app/features/flags/types'

// Defined in a separate file to prevent circular dependencies

/**
 * Map of all flag definitions by name.
 */
export const FLAG_MAP = FLAG_DEFINITIONS.reduce(
  (acc, flag: FlagDefinition) => {
    acc[flag.name] = flag
    return acc
  },
  {} as Record<FlagName, FlagDefinition>,
) as FlagDefinitions
