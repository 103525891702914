import { createFileRoute, redirect } from '@tanstack/react-router';
import { t } from 'i18next';
import toast from '#app/lib/toast';
import { selectors as accountSelectors } from '#app/store/current-account';
export const Route = createFileRoute('/setup-guide/pair')({
  // Make sure we don't load any of the routes, since they assume there are zones and they'll pick the first one
  beforeLoad: ({
    context
  }) => {
    const currentAccountId = accountSelectors.id(context.store.getState());
    const zones = accountSelectors.soundZones(context.store.getState());
    if (!zones.first()) {
      toast.error(t('setupGuide.pairing.noZoneToast.body'), {
        action: {
          label: t('setupGuide.pairing.noZoneToast.action'),
          to: `/accounts/${currentAccountId}/zones/create`
        }
      });
      throw redirect({
        to: '/setup-guide',
        resetScroll: false
      });
    }
  }
});