import { type ApolloError } from '@apollo/client'
import * as React from 'react'
import { graphql, useQuery } from '#app/apollo/index'
import type { TrackListEdge } from '#app/components/track-list/graphql'
import { type IsoCountry } from '#app/graphql/graphql'

interface PlaylistInfo {
  total: number
  totalDuration: number
  hasMore: boolean
  nextCursor?: string | null
}
export default function usePlaylistFromPrompt(
  prompt: string,
  country: IsoCountry,
  options: {
    first?: number
    after?: string
    onCompleted?(data: any): void
    onError?(error: ApolloError): void
  } = {},
) {
  const { data, loading, error, fetchMore } = useQuery(GetTracksFromPromptDoc, {
    fetchPolicy: 'network-only',
    skip: !prompt,
    notifyOnNetworkStatusChange: true,
    onCompleted: options.onCompleted,
    onError: options.onError,
    variables: {
      prompt,
      market: country,
      first: options.first,
      after: options.after,
    },
  })

  const { getTracksFromPrompt } = data ?? {}

  const playlistInfo: PlaylistInfo | undefined = React.useMemo(() => {
    if (!getTracksFromPrompt) return undefined

    return {
      total: getTracksFromPrompt.total,
      totalDuration: getTracksFromPrompt.totalDurationSeconds * 1000,
      hasMore: getTracksFromPrompt.pageInfo.hasNextPage,
      nextCursor: getTracksFromPrompt.pageInfo.endCursor,
    }
  }, [getTracksFromPrompt])

  return {
    fetchMore,
    data,
    tracks: getTracksFromPrompt?.edges as TrackListEdge[] | undefined,
    playlistInfo,
    loading,
    isFetchingMore: Boolean(loading && data),
    error,
  }
}

export const GetTracksFromPromptDoc = graphql(/* GraphQL */ `
  query GetTracksFromPrompt(
    $prompt: String!
    $first: Int
    $after: String
    $market: IsoCountry!
  ) {
    getTracksFromPrompt(prompt: $prompt, first: $first, after: $after) {
      total
      totalDurationSeconds
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          ...Track_Tracklist
        }
      }
    }
  }
`)
