const $$splitComponentImporter = () => import(/* webpackChunkName: "route-search" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/search/$.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';

// Note: This route exists to match the rest of the `/search/*` routes
// All of the rendering is handled by `search/route.tsx` since we want
// to keep the `SearchIndex` component mounted.

export const Route = createFileRoute('/search/$')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr)
});