import { createFileRoute, redirect } from '@tanstack/react-router';
import { ensureLoggedIn } from '#app/route-lib';
export const Route = createFileRoute('/(misc)/legal-certificate/')({
  beforeLoad: ({
    location
  }) => {
    ensureLoggedIn(location);
    throw redirect({
      to: '/legal-certificate/print'
    });
  }
});