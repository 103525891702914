import * as tysonSlice from '@soundtrack/playback/tyson/slice'
import { Models, Selectors, Utils } from '@soundtrackyourbrand/capsule'
import type { ImmutableEntity } from '@soundtrackyourbrand/capsule/dist/utils/immutable'
import im from 'immutable'
import type { ExplicitModalType } from '#app/components/explicit-modal'
import { t } from '#app/lib/i18n/index'
import overrides from '#app/lib/overrides'
import toast from '#app/lib/toast'
import modalActions from '#app/store/modals'
import { createSelector } from '#app/store/redux'
import type {
  ActionsType,
  AppThunk,
  ExtractActions,
  SelectorsType,
  UntypedAction,
} from '.'
import { authSlice } from './auth'
import { startReduxListening } from './middleware/listener'
import { type ImmutableReducer } from './redux'

export const actions = {
  select: (user, account) => ({
    type: 'SELECT_ACCOUNT' as const,
    user,
    account,
  }),
  selectProduct: (product, force) => ({
    type: 'SELECT_PRODUCT' as const,
    product,
    force,
  }),
  unselect:
    (userId?: string): AppThunk =>
    (dispatch, getState) => {
      dispatch({
        type: 'UNSELECT_ACCOUNT' as const,
        userId: userId || Selectors.currentUserId(getState()),
      })
    },
  unforceProduct: () => ({ type: 'UNFORCE_PRODUCT' as const }),
  /**
   * Show a modal promoting the explicit filter account setting if user is
   * logged in to an account whose explicit filter is disabled, and whose
   * library doesn't already contain explicit playlists.
   *
   * This modal will only be shown once, unless the user logs out or accesses
   * business from another browser.
   */
  maybePromoteExplicitSetting:
    (type: ExplicitModalType) => (dispatch, getState) => {
      const state = getState()
      const account = currentAccount(state)
      if (
        state.currentAccount.get('seenExplicitFilterPromotion', false) ||
        !account ||
        // Explicit filter already enabled?
        account.getIn(['settings', 'filter_explicit'])
      ) {
        return false
      }
      const accountId = account.get('id')
      const country = currentCountry(state)
      const { collection } = state.entities
      const collectionIds = collection.getIn(['queries', 'library', accountId])
      const collectionStats = state.entities.track_statistics.get('data')
      if (!collectionIds || !collectionStats) {
        return false
      }
      // Only show modal if library contains <2 playlists with explicit content
      let found = 0
      const hasMultipleExplicitCollections = collectionIds.some((id) => {
        const snapshot = collection.getIn(['data', id, 'snapshot'])
        const stats = collectionStats.get(id)
        if (
          !stats ||
          (stats.get('snapshot') === snapshot &&
            stats.getIn([country, 'explicit'], 0) > 0)
        ) {
          found += 1
        }
        return found >= 2
      })
      if (hasMultipleExplicitCollections) {
        return false
      }
      return new Promise((resolve, reject) => {
        import('#app/components/explicit-modal').then((mod) => {
          dispatch(modalActions.show(mod.default, { type, onClose: resolve }))
        }, reject)
      })
    },
} satisfies ActionsType

export default actions

export const LEGACY_MARKET = 'LEGACY'

export function getDefaultAccount(accounts) {
  accounts = im.List.isList(accounts) ? accounts : im.fromJS(accounts)
  return accounts
    .sortBy((a) => -parseInt(a.get('iso8601_created_at'), 10))
    .first()
}

export function getDefaultAccountId(accounts) {
  const account = getDefaultAccount(accounts)
  return account ? account.get('id') : null
}

export function hasAccountChurned(soundZones) {
  const hasActiveZones = soundZones.some(Models.SoundZone.isActive)
  const hasChurnedZones = soundZones.some(Models.SoundZone.hasChurned)
  return !hasActiveZones && hasChurnedZones
}

export function hasMissingPaymentMethod(paymentMethod) {
  return (
    paymentMethod &&
    paymentMethod.getIn(['creditcard_data', 'card_type']) === '' &&
    paymentMethod.get('psp') === 'adyen_creditcard'
  )
}

export function tryBeforeYouBuySetupComplete(paymentMethod) {
  return !Utils.Dates.isZero(paymentMethod?.get('trial_expires_at'))
}

/** Map of user ID => active account ID */
export type State = im.Map<string, string | boolean>

export type Action =
  | ExtractActions<typeof actions>
  | { type: 'SEEN_EXPLICIT_FILTER_PROMOTION'; seen?: boolean }
  | { type: 'UNSELECT_ACCOUNT'; userId: string }
  | UntypedAction<
      | 'REQUEST_ACCOUNTS_FOR_USER_SUCCESS'
      | 'REQUEST_ACCOUNT_GET_FAILURE'
      | 'REGISTER_REQUEST_SUCCESS'
      | typeof authSlice.actions.reset.type
    >

export const reducer: ImmutableReducer<State> = (state, rawAction) => {
  const action = rawAction as Action

  if (!im.Map.isMap(state)) {
    state = im.Map()
  }

  switch (action.type) {
    case 'REQUEST_ACCOUNTS_FOR_USER_SUCCESS':
      const accounts = im.fromJS(action.data || []) as im.Collection<
        string,
        im.Map<string, any>
      >
      const userId = action.query.user
      const selectedAccount = state.get(userId) as string | undefined
      // Determine default account if user doesn't have one or can no longer access it
      if (
        userId &&
        (!selectedAccount ||
          !accounts.find((a) => a.get('id') === selectedAccount))
      ) {
        state = state.set(userId, getDefaultAccountId(accounts))
      }
      return state

    case 'REGISTER_REQUEST_SUCCESS':
      if (!action.user || !action.account) {
        return state
      }
      return state.set(action.user, action.account)

    case 'SELECT_ACCOUNT':
      if (!action.user) return state
      return state.set(action.user, action.account)

    case 'UNSELECT_ACCOUNT':
      return state.delete(action.userId)

    case 'SEEN_EXPLICIT_FILTER_PROMOTION':
      return state.set('seenExplicitFilterPromotion', action.seen ?? true)

    case authSlice.actions.reset.type:
      return im.Map()
  }
  return state
}

startReduxListening({
  type: 'REQUEST_ACCOUNT_GET_FAILURE',
  effect: (
    action: UntypedAction<'REQUEST_ACCOUNT_GET_FAILURE'>,
    { dispatch, getState, extra },
  ) => {
    const globalState = getState()

    // Attempt to select fallback account for user if current account is inaccessible
    const { userId } = globalState.auth
    if (
      userId &&
      action.error &&
      [401, 403, 404].indexOf(action.error.status) >= 0 &&
      globalState.currentAccount.has(userId)
    ) {
      const accounts = extra.store.cacheSelector(
        extra.store.select('account', globalState),
        'account',
        'user',
        { user: userId, typ: 'User' },
      )
      const newAccountValue = accounts ? getDefaultAccountId(accounts) : false
      dispatch(actions.select(userId, newAccountValue || false))
    }

    // TODO: Causes new toast to not be removed after being dismissed
    // Uncomment again if this is fixed: https://github.com/timolins/react-hot-toast/issues/192#issuecomment-1908109386
    // toast.dismiss('accountAccessError')
    toast.error(
      t('app.accountAccessError', action.error || { status: 'unknown' }),
      {
        duration: Infinity,
        id: 'accountAccessError',
      },
    )
  },
})

const currentAccountId = createSelector(
  [
    Selectors.currentUserId,
    (state) => state.currentAccount,
    (state) => {
      const playerId = overrides.get<string>('player-id')
      return playerId ? tysonSlice.selectors.account(playerId, state) : null
    },
  ],
  (currentUserId, accounts, tysonAccount) => {
    return (
      (currentUserId
        ? // `accounts` may be undefined in test environments
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (accounts?.get(currentUserId) as string | undefined)
        : undefined) || tysonAccount?.id
    )
  },
)

const currentAccount = createSelector(
  [currentAccountId, Selectors.accounts],
  (id, accounts: im.Map<string, ImmutableEntity>) =>
    id ? accounts.get(id) : undefined,
)

const currentAccountSubscription = createSelector(
  [
    currentAccountId,
    (state): ImmutableEntity | undefined =>
      state.entities.account_subscription.get('data') || undefined,
  ],
  (id, accountSubscription) =>
    (!!id && accountSubscription?.get(id)) || undefined,
)

const currentBillingGroups = createSelector(
  [
    currentAccountId,
    (state): im.List<ImmutableEntity> =>
      state.entities.billing_group.get('data'),
  ],
  (id, billingGroups) =>
    id ? billingGroups.filter((bg) => bg.get('account') === id) : null,
)

const currentTier = createSelector([currentAccount], tier)

const currentPlan = createSelector([currentAccount], plan)

const upcomingPlan = createSelector(
  [currentAccountSubscription],
  (accountSubscription: ImmutableEntity | undefined) => {
    return accountSubscription?.get('upcoming_plan')
  },
)

const currentCountry = createSelector(
  [currentAccount, Selectors.currentCountry],
  (account, country?: string, forced?: string): string | undefined => {
    if (forced) return forced
    return (account ? account.get('iso_country') : country) as string
  },
)

const paymentMethod = createSelector(
  [currentAccountId, Selectors.paymentMethods],
  (id, paymentMethods: im.List<ImmutableEntity>) =>
    paymentMethods.find((pm) => {
      return !pm.get('deactivated') && pm.get('account') === id
    }),
)

function billingVoucher(account): string {
  return account?.get('billing_voucher') || ''
}

const currentBillingVoucher = createSelector([currentAccount], billingVoucher)

function businessType(account: ImmutableEntity | undefined) {
  return account?.get('business_type') as string | undefined
}

const currentBusinessType = createSelector([currentAccount], businessType)

const soundZones = createSelector(
  [currentAccountId, Selectors.soundZones],
  (id, zones: im.List<ImmutableEntity>) =>
    zones.filter((zone) => {
      return !zone.get('deactivated') && zone.get('account') === id
    }),
)

export const onboardingEnabled = createSelector(
  [currentAccount, paymentMethod, soundZones, (state) => state.checkout],
  (account, paymentMethod, zones, checkout) => {
    // Ensure onboarding is enabled and relevant for the current account
    const enabled =
      account &&
      (checkout.active ||
        account.getIn(['feature_flags', 'onboarding_enabled']) ||
        hasMissingPaymentMethod(paymentMethod)) // TBYB with no payment added always gets onboarding

    return !!enabled
  },
)

export const shouldPair = createSelector([soundZones], (zones) => {
  if (zones.size !== 1) {
    return false
  }
  const zone = zones.first()!
  return zone.get('device_id') === '' || zone.get('paired') !== 'paired'
})

export const setupCompleted = createSelector(
  [currentAccount, paymentMethod, soundZones],
  (account, paymentMethod, zones) => {
    if (!account) return false

    const tryBeforeYouBuy = account.get('try_before_you_buy')
    const hasVoucher = !!account.get('billing_voucher')

    const hasZone = zones.size >= 1
    const isDefaultDistributor = Models.Account.isSYB(
      account.get('distributor'),
    )

    if (hasVoucher || !tryBeforeYouBuy || !isDefaultDistributor) return hasZone

    return hasZone && tryBeforeYouBuySetupComplete(paymentMethod)
  },
)

const trialExpiration = createSelector([paymentMethod], (pm) => {
  return pm && hasMissingPaymentMethod(pm) && pm.get('trial_expires_at')
    ? Utils.Dates.toUtcMoment(pm.get('trial_expires_at'))
    : undefined
})

// Returns the trial length in days or undefined if unable to determine trial length
// The trial length is extracted from the account subscription instead of the payment method
// as it is more reliable. The payment method may be created long before the trial is started
// which makes it impossible to determine trial length by comparing `created_at` and `trial_expires_at`
const trialLength = createSelector(
  [
    currentAccountId,
    (state) => state.entities.account_subscription.get('data'),
  ],
  (id, accountSubscription) => {
    if (!accountSubscription) return undefined
    const trialStart = new Date(accountSubscription.getIn([id, 'trial_start']))
    const trialEnd = new Date(accountSubscription.getIn([id, 'trial_end']))

    const diffTime = Math.abs(trialStart.getTime() - trialEnd.getTime())

    return Math.round(diffTime / (1000 * 60 * 60 * 24))
  },
)

const zonesCount = createSelector(
  [currentAccountId, Selectors.soundZones],
  (id, zones: im.List<ImmutableEntity>) =>
    zones.count((zone) => zone.get('account') === id),
)

const currentSettings = createSelector(
  [currentAccount],
  (account): ImmutableEntity => {
    return account ? account.get('settings') : im.Map<string, any>()
  },
)

const restrictedDiscoverMusic = createSelector(
  [authSlice.selectors.zoneId, currentSettings],
  (zoneId, settings): boolean | undefined => {
    const isPlaybackDevice = !!zoneId
    // We only want to respect the setting when you're a playback device.
    // Logged-in users who remote should not be restricted.
    // (this mimics our SPM implementation)
    return isPlaybackDevice && settings.get('restrict_discover_music')
  },
)

const restrictedUnpairing = createSelector(
  [authSlice.selectors.loggedIn, currentSettings],
  (loggedIn, settings): boolean | undefined => {
    // We only want to respect the setting when you're not logged in.
    return !loggedIn && settings.get('restrict_unpairing_from_paired_devices')
  },
)

export const selectors = {
  id: currentAccountId,
  account: currentAccount,
  billingVoucher: currentBillingVoucher,
  billingGroups: currentBillingGroups,
  tier: currentTier,
  tier3: (state) => currentTier(state) > 2,
  plan: currentPlan,
  upcomingPlan,
  country: currentCountry,
  businessType: currentBusinessType,
  defaultBillingGroup: createSelector(
    [currentBillingGroups],
    (billingGroups) => {
      return billingGroups?.find((bg) => bg.get('default') === true)
    },
  ),
  soundZones,
  paymentMethod,
  hasMissingPaymentMethod: createSelector([paymentMethod], (paymentMethod) =>
    hasMissingPaymentMethod(paymentMethod),
  ),
  overdueInvoiceSpecs: createSelector(
    Selectors.invoiceSpecs,
    (invoiceSpecs: im.List<ImmutableEntity>) =>
      invoiceSpecs.filter(Models.InvoiceSpec.isOverdue),
  ),
  scheduledTierChange: createSelector(
    [
      currentAccountId,
      (state) => state.entities.account_subscription.get('data'),
    ],
    (id, accountSubscription) => {
      const upcomingTier = accountSubscription.getIn([id, 'upcoming_tier'])
      return (
        !!upcomingTier && {
          upcomingTier,
          tierChangeDate: accountSubscription.getIn([id, 'recur_at']),
        }
      )
    },
  ),
  shouldPromoteExplicitSetting: createSelector(
    [
      currentAccount,
      currentCountry,
      (state) => state.currentAccount.get('seenExplicitFilterPromotion', false),
      (state) => state.entities.collection,
      (state) => state.entities.track_statistics.get('data'),
    ],
    (account, country, seen, collection, tracksStatistics) => {
      const accountId = account?.get('id')

      if (
        seen ||
        !accountId ||
        // Explicit filter already enabled?
        account?.getIn(['settings', 'filter_explicit'])
      ) {
        return false
      }

      const collectionIds = collection.getIn(['queries', 'library', accountId])

      // Required data not loaded?
      if (!tracksStatistics || !collectionIds) {
        return false
      }

      return collectionIds.some((id) => {
        const data = collection.getIn(['data', id])
        if (!data) {
          return false
        }
        const snapshot = data.get('snapshot')
        const stats = tracksStatistics.get(id)
        return (
          data.get('account') === accountId &&
          stats &&
          stats.get('snapshot') === snapshot &&
          stats.getIn([country, 'explicit'], 0) > 0
        )
      })
    },
  ),
  setupCompleted,
  onboardingEnabled,
  shouldPair,
  trialExpiration,
  trialLength,
  zonesCount,
  restrictedDiscoverMusic,
  restrictedUnpairing,
} satisfies SelectorsType

export function parseTier(tier: string) {
  return parseInt(tier.replace(/tier-/, ''), 10) || 0
}

/** Returns account tier as integer (0, 1, 3) */
export function tier(account: ImmutableEntity | null | undefined) {
  return account?.get('tier') ? parseTier(account.get('tier')) : 0
}

export function plan(account: ImmutableEntity | null | undefined) {
  return account?.get('plan')
}

export function tier3(
  account: ImmutableEntity | null | undefined,
): account is ImmutableEntity {
  return tier(account) > 2
}
