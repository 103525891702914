import { type ImmutableEntity } from '@soundtrackyourbrand/capsule/dist/utils/immutable'
import clamp from '#app/lib/clamp'
import { DAY_MS, FOURTHS_PER_DAY } from '../constants'

export function slotDimensions(props: {
  /** Constraints of schedular container */
  constraints: DOMRect
  /** Cursor X position */
  x: number
  /** Cursor Y position */
  y: number
  /** Associated slot, used to determine height */
  item?: { slot: ImmutableEntity }
  /** Snap to days & 15 minute multiples? */
  snapToGrid?: boolean
}) {
  /** Round dimensions to full pixels for non-retina screens */
  const maybeRound =
    window.devicePixelRatio < 1.5
      ? (x: number) => Math.round(x)
      : (x: number) => x

  let { constraints, x, y } = props
  const width = constraints.width / 7
  const day = clamp(Math.floor((x - constraints.left) / (width || 1)), 0, 6)

  /** Height of 15 minutes (shortest slot length to snap to) */
  const fourthHeight = Math.round(constraints.height / FOURTHS_PER_DAY)
  const fourths = clamp(
    Math.floor((y - constraints.top) / fourthHeight),
    0,
    FOURTHS_PER_DAY - 1,
  )

  if (props.snapToGrid) {
    x = maybeRound(constraints.left + day * width)
    y = maybeRound(constraints.top + fourths * fourthHeight)
  }

  return {
    x,
    y,
    width: maybeRound(width),
    day,
    offsetMs: fourths * 9e5,
    height: props.item
      ? Math.round(
          (parseInt(props.item.slot.get('DURATION'), 10) / DAY_MS) *
            constraints.height,
        )
      : undefined,
  } as const
}
