import { createFileRoute, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/accounts/$accountId/locations/$')({
  beforeLoad: ({
    params
  }) => {
    throw redirect({
      to: `/accounts/$accountId/zones/${params._splat}`,
      from: Route.fullPath
    });
  }
});