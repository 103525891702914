const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-settings" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/settings/subscriptions/streaming.$zoneId.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-settings" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/settings/subscriptions/streaming.$zoneId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ModalErrorView } from '#app/components/error/index';
export const Route = createFileRoute('/accounts/$accountId/settings/subscriptions/streaming/$zoneId')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'soundZone.meta'
  },
  loader: lazyFn($$splitLoaderImporter, 'loader'),
  errorComponent: ModalErrorView
});