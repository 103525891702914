import { HUBSPOT_EXPERTS_IDS } from '#app/components/product-expert/utils'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { defineFlag } from './lib/defineFlag'

/**
 * A collection of all known flags. Only flags defined here will be synced when received from the server.
 * See {@link defineFlag} on how to create simple and more advanced flags.
 */
export const FLAG_DEFINITIONS = [
  // defineFlag('demo-flag'),
  defineFlag('account-setting-unpairing-from-paired-devices'),
  defineFlag('playlist-header-edit-button'),
  defineFlag('theme-select'),
  defineFlag('is-on-basic-tier-test'),
  defineFlag('survey-zones-page'),
  defineFlag('mp-session-replay', {
    enabled: false,
    'start-on-mount': false as boolean,
  }),
  defineFlag('user-level-qr-app-login'),
  defineFlag('web-remote-v1-link'),
  defineFlag(`product-expert-${HUBSPOT_EXPERTS_IDS.carl}`),
  defineFlag(`product-expert-${HUBSPOT_EXPERTS_IDS.erik}`),
  defineFlag(`product-expert-${HUBSPOT_EXPERTS_IDS.jennie}`),

  // MX 2024 surveys
  defineFlag('survey-mx2024-home-localization-german'),
  defineFlag('survey-mx2024-home-localization-french'),
  defineFlag('survey-mx2024-home-localization-spanish'),

  defineFlag('business-sidebar-employee-beta'),
  defineFlag('zone-volume-control'),
] as const
