import { log } from '@soundtrack/utils/log';
import { throttle } from '@soundtrackyourbrand/ui';
import { symmetricDifference } from '#app/lib/sets';
const ONE_MINUTE = 60_000;
const ONE_HOUR = 60 * ONE_MINUTE;
function areTracklistsDifferent(a, b) {
  const size = Math.min(a.size, b.size);
  // Use symmetric diff to consider both added/removed, and ignore tracklist reordering.
  const diff = symmetricDifference(a, b);
  // For small tracklists, consider any difference significant, for larger tracklists 10%.
  return size <= 20 ? diff.size > 0 : diff.size > size * 0.1;
}
const cacheBusterMap = new Map();
function makeCacheBuster(cache, playlistId) {
  // Use const and mutate prevTrackIds in-place to prevent stale scope inside throttled callback.
  const prevTrackIds = new Set();
  // Make sure the cache buster is removed after 1 hour of inactivity for always-on displays.
  let timer;
  function scheduleCleanup() {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      log.debug('Removing inactive cache buster for: ', playlistId);
      cacheBusterMap.delete(playlistId);
    }, ONE_HOUR);
  }
  return throttle(function bustCacheIfDifferent(_trackIds) {
    const trackIds = new Set(_trackIds);
    if (areTracklistsDifferent(trackIds, prevTrackIds)) {
      log.debug('Invalidating similar playlists cache for: ', playlistId);
      cache.modify({
        id: cache.identify({
          __typename: 'Playlist',
          id: playlistId
        }),
        fields: {
          similarPlaylists: (existing, {
            DELETE
          }) => DELETE
        }
      });
      prevTrackIds.clear();
      trackIds.forEach(id => prevTrackIds.add(id));
    }
    scheduleCleanup();
  }, ONE_MINUTE);
}

/**
 * Invalidate the cache of similar playlists for a given `playlistId`.
 * Throttled to avoid excessive refetches, and is only ran when the tracklist is considered to have significantly changed.
 */
export function invalidateSimilarPlaylists(cache, playlistId, trackIds) {
  if (!cacheBusterMap.get(playlistId)) {
    cacheBusterMap.set(playlistId, makeCacheBuster(cache, playlistId));
  }
  cacheBusterMap.get(playlistId)?.(trackIds);
}