import { scope } from '@soundtrack/utils/log'
import { type ParsedLocation, redirect } from '@tanstack/react-router'
import overrides from '#app/lib/overrides'
import { type Store, store } from '#app/store/index'
import { authSlice, currentAccountSlice, tysonSlice } from '#app/store/reducers'

const log = scope('route-lib')

export function ensureLoggedIn(location: ParsedLocation) {
  return loggedInOr('/login', location)
}

export function ensureNotOnStarterPlan(store: Store) {
  const isOnStarterPlan =
    currentAccountSlice.selectors.plan(store.getState()) === 'starter'

  if (isOnStarterPlan) {
    throw redirect({
      to: '/create',
      replace: true,
    })
  }
}

// Get type of first param of `redirect` function
type RedirectOptions = Parameters<typeof redirect>[0]

export function loggedInOr(
  to: string | RedirectOptions,
  location: ParsedLocation,
  /** Whether the current pathname should strictly match passed `to` */
  strictRouteMatch = false,
) {
  // If logged in as a user, we don't need to check anything else
  if (authSlice.selectors.loggedIn(store.getState())) {
    return
  }

  // In Electron we should consider being "logged in" if we're paired with a code
  if (IS_ELECTRON) {
    const playerId = overrides.get<string>('player-id')
    if (playerId && tysonSlice.selectors.paired(playerId)(store.getState())) {
      return
    }
  }

  const options: RedirectOptions = !to || typeof to === 'string' ? { to } : to

  const isAlreadyAtPath = strictRouteMatch
    ? location.pathname === options.to
    : options.to && location.pathname.startsWith(options.to)
  if (isAlreadyAtPath) {
    log.debug(`already at path, not redirecting: ${to}`)
    return
  }

  overrides.set('redirect-to', location.href)
  log.debug(`not logged in, redirecting to: ${to}`)

  throw redirect({
    replace: true,
    ...options,
    search: options.search,
  })
}

export function loggedOutOr(to: string) {
  if (authSlice.selectors.loggedIn(store.getState())) {
    log.debug(`not logged out, redirecting to: ${to}`)
    throw redirect({ to, replace: true })
  }
}

/**
 * When defining params parsing the `stringify` methods is required.
 * If all params are already strings, we can simply pass these through.
 */
export function noopStringify(
  params: Record<string, string>,
): Record<string, string> {
  return params
}
