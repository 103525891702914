import {
  type Action,
  type Reducer,
  type ReducersMapObject,
  createAction,
} from '@reduxjs/toolkit'
import {
  type WrappedStorage,
  local,
  session,
} from '@soundtrackyourbrand/browser-storage.js'
import im from 'immutable'
import { debounce } from 'lodash'
import { captureError } from '#app/lib/error/reporting'
import type { Store } from '#app/store/index'

type Transform = {
  /** Encode state written to storage */
  encode: (any) => any
  /** Decode state read from storage */
  decode: (any) => any
}

export const immutableTransform: Transform = {
  encode(state) {
    if (!state) return null
    return state.toJS()
  },
  decode(state) {
    if (!state) return null
    return im.fromJS(state)
  },
}

export const jsonTransform: Transform = {
  encode(state) {
    if (!state) return null
    return JSON.stringify(state)
  },
  decode(state) {
    if (!state) return null
    return JSON.parse(state)
  },
}

export const sessionAndLocalAdapter = createFallbackStorageAdapter(
  session,
  local,
)

export const immutableAdapter = [immutableTransform, jsonTransform]

export const actions = {
  /**
   * Sent once from persistStore to discover all persistReducers
   * and initiate rehydration.
   *
   * Should be sent again if reducers are lazy loaded to discover
   * and initiate the new reducers
   */
  register: createAction<{
    register: (persistor: PersistReducerConfig) => void
  }>('persist/register'),

  /**
   * Loading state from storage. Sent once during registration and
   * then again every time another tab changes state in storage.
   */
  rehydrate: createAction<{ key: string; state: any; didMigrate?: boolean }>(
    'persist/rehydrate',
  ),

  /**
   * Immediately run all debounced writes. Sent once during
   * beforeunload.
   */
  flush: createAction(
    'persist/flush',
    // Use custom prepare function to set a default value (empty object) for `payload`
    function prepare(payload: { key?: string; force?: boolean } = {}) {
      return { payload }
    },
  ),
}

export function persistStore(store: Store) {
  const persistors: PersistReducerConfig[] = []

  store.dispatch(
    actions.register({
      register(persistor: PersistReducerConfig) {
        persistors.push(persistor)
      },
    }),
  )

  persistors.forEach((persistor) => {
    try {
      let didMigrate = false
      let storedData = persistor.storageAdapter.getItem(persistor.key)
      if (persistor.migrate) {
        const migratedData = persistor.migrate(storedData, persistor.key)
        didMigrate = migratedData !== storedData
        storedData = migratedData
      }
      if (storedData) {
        store.dispatch(
          actions.rehydrate({
            key: persistor.key,
            state: storedData,
            didMigrate,
          }),
        )
      }
    } catch (error: any) {
      captureError(error, (scope) => {
        scope.setTag('logger', 'redux-persistence')
        scope.setExtra('reduxNamespace', persistor.key)
      })
    }
    persistor.onRegistered?.(store, persistor.key)
  })

  window.addEventListener('beforeunload', () => {
    store.dispatch(actions.flush())
  })
}

export function createPersistReducers<M extends ReducersMapObject>(
  {
    keyPrefix = '',
    only,
    exclude,
    ...config
  }: {
    keyPrefix?: string
    only?: string[]
    exclude?: string[]
  } & Omit<PersistReducerConfig, 'key'>,
  reducers: M,
): M {
  const persistReducers = {} as ReducersMapObject

  Object.keys(reducers).forEach((key) => {
    let shouldPersist = true

    if (only && only.indexOf(key) === -1) {
      shouldPersist = false
    }
    if (exclude && exclude.indexOf(key) !== -1) {
      shouldPersist = false
    }

    persistReducers[key] = shouldPersist
      ? createPersistReducer(
          { ...config, key: `${keyPrefix}${key}` },
          reducers[key]!,
        )
      : reducers[key]!
  })

  return persistReducers as M
}

type PersistReducerConfig = {
  /** Unique key used in storage */
  key: string
  storageAdapter: Storage | WrappedStorage
  transforms?: Transform[]
  migrate?: (state: any, key: string) => any
  /** Minimum time between a redux action and serialization */
  minWait?: number
  /** Maxiumum time between a redux action and serialization */
  maxWait?: number
  /** When registered. Note that we cannot type `store` due to circular reference. */
  onRegistered?: (store: any, key: string) => void
}

export function createPersistReducer<R extends Reducer>(
  {
    key,
    storageAdapter,
    transforms = [jsonTransform],
    migrate,
    minWait = 300,
    maxWait = 600,
    onRegistered,
  }: PersistReducerConfig,
  baseReducer: R,
): R {
  let registered = false

  const debouncedWrite = debounce(
    (state) => {
      try {
        const encoded = transforms.reduce((state, transformer) => {
          return transformer.encode(state)
        }, state)
        storageAdapter.setItem(key, encoded)
      } catch (error: any) {
        captureError(error, (scope) => {
          scope.setTag('logger', 'redux-persistence')
          scope.setExtra('reduxNamespace', key)
        })
      }
    },
    minWait,
    { maxWait },
  )

  return function persistReducer(state, action: Action) {
    if (actions.register.match(action)) {
      if (!registered) {
        registered = true
        action.payload.register({ key, storageAdapter, migrate, onRegistered })
      }
    }

    if (actions.rehydrate.match(action)) {
      if (action.payload.key === key) {
        state = transforms.reduceRight((state, transformer) => {
          return transformer.decode(state)
        }, action.payload.state)
      }
    }

    if (actions.flush.match(action)) {
      if (action.payload.key === undefined || action.payload.key === key) {
        if (action.payload.force) {
          debouncedWrite(state)
        }
        debouncedWrite.flush()
      }
    }

    if (!registered) return baseReducer(state, action)

    const newState = baseReducer(state, action)
    if (
      newState !== state ||
      (actions.rehydrate.match(action) && action.payload.didMigrate)
    ) {
      debouncedWrite(newState)
    }
    return newState
  } as R
}

export function createFallbackStorageAdapter(
  primary: Storage | WrappedStorage,
  secondary: Storage | WrappedStorage,
) {
  return {
    setItem(key: string, value: any) {
      primary.setItem(key, value)
      secondary.setItem(key, value)
    },
    getItem(key: string) {
      return primary.getItem(key) ?? secondary.getItem(key)
    },
  }
}

/**
 * Set up a persistReducer for syncing state changes across tabs.
 */
export function handleCrosstabSync(store: any, key: string) {
  window.addEventListener('storage', handleStorageEvent)

  function handleStorageEvent(e) {
    if (e.key === key) {
      if (e.oldValue === e.newValue) {
        return
      }

      try {
        store.dispatch(actions.rehydrate({ key, state: e.newValue }))
      } catch (error: any) {
        captureError(error, (scope) => {
          scope.setTag('logger', 'redux-persistence')
          scope.setExtra('reduxNamespace', key)
        })
      }
    }
  }
}

/**
 * Force flush/write out the reducer state when the tab is focused.
 */
export function flushOnFocus(store: any, key: string) {
  window.addEventListener('focus', handleFocusEvent)

  function handleFocusEvent() {
    store.dispatch(actions.flush({ key, force: true }))
  }
}
