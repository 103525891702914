import { createFileRoute, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/accounts/$accountId/your-music/')({
  beforeLoad: ({
    params
  }) => {
    throw redirect({
      to: '/accounts/$accountId/your-music/playlists',
      replace: true,
      params
    });
  }
});