export const RETRY_POLLING = 'RETRY_POLLING'

/*
 * Poller which doesn't care about what it's polling - just that it happens.
 * Continues polling until the requestFn returns a falsey value or .stop() is called.
 * TODO: Error handling (timeouts, max retries)
 */
export default class Poller {
  static displayName = 'Poller'

  constructor(requestFn) {
    this.requestFn = requestFn
    this.timeout = null
    this.retries = 0
    this.maxRetries = 120
  }

  start() {
    this.stopped = false
    this.enqueue()
  }

  stop() {
    clearTimeout(this.timeout)
    this.stopped = true
  }

  enqueue() {
    if (this.stopped || this.timeout) {
      return
    }
    // Linearly increasing delays
    const delay = 2e3 + this.retries * 250
    this.timeout = setTimeout(this.request, delay)
  }

  request = () => {
    if (++this.retries >= this.maxRetries) {
      return this.stop()
    }

    const result = this.requestFn()

    if (result === RETRY_POLLING) {
      this._requeue()
    } else if (result && result.then) {
      result.then(this._requeue, this._requeue)
    } else {
      this.stop()
    }
  }

  _requeue = (res) => {
    if (
      res instanceof Error &&
      !/Request has been terminated/i.test(res.message)
    ) {
      throw res
    }
    if (this.stopped) {
      return
    }
    this.timeout = null
    this.enqueue()
  }
}
