import { createFileRoute, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/accounts/$accountId/messaging/')({
  beforeLoad: ({
    params
  }) => {
    throw redirect({
      to: '/accounts/$accountId/messaging/campaigns',
      params,
      replace: true
    });
  }
});