import { Models, Utils } from '@soundtrackyourbrand/capsule'
import im from 'immutable'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { connect } from '#app/store/redux'
import Poller, { RETRY_POLLING } from './poller'

const isZoneOnline = (zone) => {
  return zone.getIn(['device', 'pairing_state']) === 'paired'
}

export class PollerComponent extends PureComponent {
  static propTypes = {
    zone: PropTypes.instanceOf(im.Map).isRequired,
    getDevice: PropTypes.func,
    getZone: PropTypes.func,
    triggerPair: PropTypes.func.isRequired,
    onPair: PropTypes.func,
    children: PropTypes.node.isRequired,
    onReceivePairingCode: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.poller = new Poller(() => {
      // Must be this.props since we'd otherwise ignore prop updates from parent
      const id = this.props.zone.getIn(['device', 'id'])
      if (!id) {
        // Trigger pair to get a device ID and then retry polling request
        this.triggerPair()
        return RETRY_POLLING
      }

      return this.props.getDevice({ id }).catch((err) => {
        if (err.status === 403 || err.status === 404) {
          return this.props.getZone({ id: this.props.zone.get('id') })
        }
        throw err
      })
    })

    if (this.props.zone.get('device_id')) {
      this.poller.start()
    } else {
      this.triggerPair()
    }
  }

  componentDidUpdate(prevProps) {
    if (isZoneOnline(this.props.zone) && !isZoneOnline(prevProps.zone)) {
      this.poller.stop()
      this.props.onPair?.(true)
    } else {
      this.poller.start()
    }
  }

  componentWillUnmount() {
    this.poller.stop()
  }

  triggerPair = () => {
    const { onRequestPairingCode, onReceivePairingCode } = this.props

    onRequestPairingCode?.()

    const then = (res) => {
      const error = res instanceof Error ? res : null

      onReceivePairingCode?.(error)
      if (error && !error.status) {
        throw error
      }
    }

    this.props.triggerPair?.().then(then, then)
  }

  render() {
    return this.props.children
  }
}

export default connect(null, (dispatch) =>
  Utils.BindDispatchActions(dispatch, {
    getDevice: Models.Device.actions.get,
    getZone: Models.SoundZone.actions.get,
  }),
)(PollerComponent)
