import { graphql } from '#app/graphql/gql'

export const GetScheduleDoc = graphql(/* GraphQL */ `
  query GetSchedule($id: ID!) {
    schedule(id: $id) {
      ...ScheduleFields
    }
  }
`)

export const GetPlaylistDoc = graphql(/* GraphQL */ `
  query GetSchedulePlaylist($id: ID!) {
    playlist(id: $id) {
      ...SlotPlaylist
    }
  }
`)

graphql(/* GraphQL */ `
  fragment ScheduleFields on Schedule {
    id
    snapshot
    playlists {
      ...SlotPlaylist
    }
  }

  fragment ScheduleSlotFields on Slot {
    id
    rrule
    start
    duration
    playlistIds
    updatedAt
  }
`)
