import { throttle } from 'lodash'
import { useEffect } from 'react'
import clamp from '#app/lib/clamp'

/**
 * Smooth scrolls `container` when the cursor is within `treshold` pixels from any edge.
 * Container can be found with `findScrollParent`.
 * In business it's either a layout container or `body`, in other apps it's likely `document.scrollingElement`.
 *
 * Based off react-dnd-scrollzone:
 * https://github.com/frontend-collective/frontend-collective-react-dnd-scrollzone
 */

interface EdgeScrollingOptions {
  /** Enable edge scrolling */
  enabled?: boolean
  /** Enabled axes */
  axis?: 'x' | 'y' | 'both'
  /** Threshold from container edges where scrolling starts */
  threshold?: number
  /** Scroll speed */
  multiplier?: number
  /** Is something currently being dragged? Listens for `dragover` instead of `mousemove` when enabled */
  useDragEvent?: boolean
}

export default function useEdgeScrolling(
  maybeContainer?: HTMLElement | null,
  {
    enabled = true,
    /** Axis */
    axis = 'both',
    /** Threshold from container edges where scrolling starts */
    threshold = 100,
    /** Scroll speed */
    multiplier = 50,
    /** Is something currently being dragged? Listens for `dragover` instead of `mousemove` when enabled */
    useDragEvent = false,
  }: EdgeScrollingOptions = {},
) {
  useEffect(() => {
    if (!enabled || !multiplier || maybeContainer == null) {
      return
    }

    // making TS happy (const retains refined non-null)
    const container = maybeContainer

    let attached = false
    let frame: number | null = null
    const htmlEventName = useDragEvent ? 'dragover' : 'mousemove'
    const horizontal = { applied: 0, delta: 0 }
    const vertical = { applied: 0, delta: 0 }

    function startScrolling() {
      let i = 0
      vertical.applied = container.scrollTop
      horizontal.applied = container.scrollLeft
      tick()
      function tick() {
        // Stop scrolling if there's nothing to do
        if (horizontal.delta === 0 && vertical.delta === 0) {
          stopScrolling()
          return
        }

        // There's a bug in safari where it seems like we can't get
        // mousemove events from a container that also emits a scroll
        // event that same frame. So we double the multiplier and only adjust
        // the scroll position at 30fps.
        i += 1
        if (i % 2) {
          const { scrollWidth, scrollHeight, clientWidth, clientHeight } =
            container

          // FIXME: Immediately stops scrolling on touch devices
          // TODO: Ignore subsequent mouse moves until cursor leaves axis threshold?
          // Abort scrolling if position has been changed externally
          if (
            container.scrollTop !== vertical.applied ||
            container.scrollLeft !== horizontal.applied
          ) {
            stopScrolling()
            return
          }

          if (axis === 'y' || axis === 'both') {
            container.scrollTop = vertical.applied = axisScroll(
              vertical,
              scrollHeight,
              clientHeight,
            )
          }

          if (axis === 'x' || axis === 'both') {
            container.scrollLeft = horizontal.applied = axisScroll(
              horizontal,
              scrollWidth,
              clientWidth,
            )
          }
        }
        frame = requestAnimationFrame(tick)
      }
    }

    function stopScrolling() {
      detach()
      horizontal.delta = 0
      vertical.delta = 0
      if (frame) {
        cancelAnimationFrame(frame)
        frame = null
      }
    }

    // Update horizontal.delta and vertical.delta every 100ms or so
    // and start scrolling if necessary
    const updateScrolling = throttle(
      (event) => {
        // Use left/top since x/y isn't supported in IE
        const {
          left: x,
          top: y,
          width,
          height,
        } = container.getBoundingClientRect()
        const box = { x, y, width, height }
        const interaction =
          event.type === 'touchmove' ? event.changedTouches[0] : event
        const coords = { x: interaction.clientX, y: interaction.clientY }

        if (axis === 'x' || axis === 'both') {
          horizontal.delta =
            axisStrength(box, coords, 'x', threshold) * multiplier
        }
        if (axis === 'y' || axis === 'both') {
          vertical.delta =
            axisStrength(box, coords, 'y', threshold) * multiplier
        }

        if (!frame && (horizontal.delta || vertical.delta)) {
          startScrolling()
        }
      },
      100,
      { trailing: false },
    )

    function attach() {
      attached = true
      document.body.addEventListener(htmlEventName, updateScrolling)
      document.body.addEventListener('touchmove', updateScrolling)
    }

    function detach() {
      attached = false
      document.body.removeEventListener(htmlEventName, updateScrolling)
      document.body.removeEventListener('touchmove', updateScrolling)
    }

    function handleEvent(event) {
      if (!attached) {
        attach()
        updateScrolling(event)
      }
    }

    // TODO: also stopScrolling on window.mouseleave?
    container.addEventListener(htmlEventName, handleEvent)
    document.body.addEventListener('touchmove', handleEvent)

    return () => {
      container.removeEventListener(htmlEventName, handleEvent)
      document.body.removeEventListener('touchmove', handleEvent)
      stopScrolling()
    }
  }, [enabled, maybeContainer, threshold, multiplier, useDragEvent, axis])
}

function axisStrength(box, cursor, axis = 'x', threshold = 150) {
  const bx = box[axis]
  const cx = cursor[axis]
  const other = axis === 'x' ? 'y' : 'x'
  const length = axis === 'x' ? box.width : box.height
  const inRange = cx >= bx && cx <= bx + length
  const inBox =
    inRange &&
    cursor[other] >= box[other] &&
    cursor[other] <= box[other] + box[other === 'x' ? 'width' : 'height']

  // TODO: Threshold should be 1px less than cursor position if edge scrolling
  // was just enabled
  threshold = Math.min(length / 2, threshold)

  if (!inBox) {
    return 0
  } else if (cx < bx + threshold) {
    return +(cx - bx - threshold) / threshold
  } else if (cx > bx + length - threshold) {
    return -(bx + length - cx - threshold) / threshold
  }
  return 0
}

function axisScroll(axis, length, viewport) {
  return axis.delta
    ? Math.round(clamp(axis.applied + axis.delta, 0, length - viewport))
    : axis.applied
}
