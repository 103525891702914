const $$splitComponentImporter = () => import(/* webpackChunkName: "routes/signup" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/signup.$.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';

// Note: This route exists to match the rest of the `/signup/*` routes
// All of the rendering is handled by `signup.tsx` since we want
// to keep the `SignupIndex` component mounted.

export const Route = createFileRoute('/signup/$')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr)
});