import { createFileRoute } from '@tanstack/react-router';
import { syncCurrentAccountId } from '#app/lib/authenticated';
import { getRouteMatchByLocation } from '#app/lib/router';
import { ensurePaddedId } from '#app/lib/urls';
import { ensureLoggedIn } from '#app/route-lib';
export const Route = createFileRoute('/accounts/$accountId')({
  beforeLoad: async ({
    params,
    location,
    preload
  }) => {
    // Ensure user is logged in, if not a redirect will be thrown
    ensureLoggedIn(location);
    ensurePaddedId(params, 'accountId');

    // Don't update the accountId for preloading (= it would happen on hover)
    if (!preload) {
      // Find matches based on the current location since the current `router.state` may not be
      // updated yet, so we cannot rely on it
      const currentRoute = getRouteMatchByLocation(location);
      await syncCurrentAccountId(params.accountId, '/home', currentRoute.fullPath);
    }
  }
});