const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-zones" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/zones/$zoneId.subscription.$action.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';
import { subscriptionActionSchema } from '#app/components/zone/subscription-action';
import { allowedTo } from '#app/lib/authenticated';
import { noopStringify } from '#app/route-lib';

// Allow all actions for this schema to simplify typings to different subscription routes
const paramsSchema = z.object({
  action: subscriptionActionSchema
});
export const Route = createFileRoute('/accounts/$accountId/zones/$zoneId/subscription/$action')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  params: {
    parse: paramsSchema.parse,
    stringify: noopStringify
  },
  beforeLoad: ({
    params
  }) => {
    if (!allowedTo(['zone.activate'], params.accountId)) {
      throw redirect({
        from: Route.fullPath,
        to: '/accounts/$accountId/zones/$zoneId',
        replace: true
      });
    }
  }
});