const $$splitComponentImporter = () => import(/* webpackChunkName: "route-addons" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/addons/promo.messaging.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { featuresDisabledOr } from '#app/lib/authenticated';
export const Route = createFileRoute('/addons/promo/messaging')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'account.messaging.meta'
  },
  beforeLoad: () => {
    featuresDisabledOr('messaging_enabled', '/accounts/_/messaging');
  }
});