const $$splitComponentImporter = () => import(/* webpackChunkName: "route-setup-guide" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/setup-guide/pair.$action.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { allowedTo } from '#app/lib/authenticated';
import { allowedActionsSchema, routeParams } from '#app/modules/accounts/zone/subscription/action-route';
import { currentAccountSlice } from '#app/store/reducers';
export const Route = createFileRoute('/setup-guide/pair/$action')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  params: routeParams,
  beforeLoad: ({
    params,
    context
  }) => {
    const currentAccount = currentAccountSlice.selectors.id(context.store.getState());
    if (!currentAccount || !allowedActionsSchema.safeParse(params.action).success || !allowedTo(['zone.activate'], currentAccount)) {
      throw redirect({
        to: '..',
        from: Route.fullPath,
        replace: true
      });
    }
  }
});