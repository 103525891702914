import {
  type FlagDefinitions,
  type FlagEntries,
  type FlagEntry,
  type FlagName,
  type FlagProperties,
} from '#app/features/flags/types'
import { type Flag as IFlag } from '#app/graphql/graphql'
import { captureError } from '#app/lib/error/reporting'
import { FLAG_MAP } from './lib/constants'
import type { FlagDefinitionOptions } from './lib/defineFlag'

/** Returns a list of properties to be included in Mixpanel tracking events */
export function flagTrackingProps(flag: FlagEntry | undefined) {
  if (!flag) return {}

  let props: Record<string, any> = {
    'Feature Flag': flag.name,
    'Feature Flag Enabled': flag.props.enabled,
  }

  // Attach a list of any custom properties (not "enabled" or "value"")
  const restProperties = Object.keys(flag.props).filter(
    (key) => key !== 'enabled' && key !== 'value',
  )
  if (restProperties.length > 0) {
    props['Feature Flag Custom Properties'] = restProperties
  }

  if ('value' in flag) {
    const { value } = flag
    props = {
      ...props,
      'Feature Flag Boolean Value':
        typeof value === 'boolean' ? value : undefined,
      'Feature Flag Number Value':
        typeof value === 'number' ? value : undefined,
      'Feature Flag String Value':
        // Maximum 200 characters
        typeof value === 'string' ? value.substring(0, 200) : undefined,
    }
  }

  return props
}

/** Parse a flag's backend data structure into one that's more ergonomic to work with. */
export function parseBackendFlag(flagResponse: IFlag): FlagEntry {
  const propertiesJSON = flagResponse.properties || '{}'

  const defaultProps = FLAG_MAP[flagResponse.name]?.props
    ? deepCopy(FLAG_MAP[flagResponse.name].props)
    : {}

  const flag: FlagEntry = {
    name: flagResponse.name,
    props: defaultProps,
    state: 'pristine',
    fetchedAt: new Date().toISOString(),
  }

  let flagProperties: FlagProperties = {}
  try {
    flagProperties = JSON.parse(propertiesJSON)
  } catch (cause) {
    const err = Object.assign(
      new Error(
        `[flags] Failed to parse JSON properties for "${flagResponse.name}"`,
      ),
      { cause, flag: flagResponse },
    )
    captureError(err)
  }

  Object.assign(flag.props, flagProperties)

  return flag
}

/** Converts a flag definition map to a flag entries map. */
export function flagDefinitionsToEntries(map: FlagDefinitions): FlagEntries {
  return Object.entries(map).reduce(
    (acc, [name, flag]) => {
      acc[name] = {
        ...flag,
        fetchedAt: null,
        state: 'pristine',
      } satisfies FlagEntry
      return acc
    },
    {} as Record<FlagName, FlagEntry>,
  ) as FlagEntries
}

/** Stable reference */
const DEFAULT_FLAG_OPTIONS = {
  track: false,
  clientOnly: false,
}

/** Returns the options from the flag definitions based on a flag name. */
export function flagOptions(flagName: string): FlagDefinitionOptions {
  return FLAG_MAP[flagName]?.options || DEFAULT_FLAG_OPTIONS
}

function deepCopy<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj // If the input is not an object, return it as is
  }

  const copy: any = Array.isArray(obj) ? [] : {} // Determine whether the object is an array or not

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copy[key] = deepCopy(obj[key]) // Recursively copy nested objects
    }
  }

  return copy as T
}
