import { type AnyAction, createReducer } from '@reduxjs/toolkit'
import { Models } from '@soundtrackyourbrand/capsule'
import { authSlice } from './auth'

const INITIAL_STATE = {} as Record<string, true | undefined>

export const reducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(
      'REQUEST_SOUND_ZONES_FOR_ACCOUNT_SUCCESS',
      (state, action: AnyAction) => {
        state[action.query.account] = true
      },
    )
    .addCase(Models.Session.actions.logout, () => ({}))
    .addCase(authSlice.actions.reset, () => ({}))
    .addCase('@@capsule/RESET', () => ({}))
})
