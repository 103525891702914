import * as React from 'react'

const defaultColors = [
  '#b3e271',
  '#2f10ed',
  '#e8ed10',
  '#ed10aF',
  '#7e10ed',
  '#10eded',
  '#00ffa3',
  '#71cee2',
  '#ff0000',
] as const

export default function useColorSwitcher<T extends HTMLElement>({
  enabled = true,
  cssVariableName = '--bg-color',
  colors = defaultColors,
} = {}) {
  const elementRef = React.useRef<T>(null)

  const nextColor = React.useCallback(() => {
    if (!enabled) return
    const currentColor =
      elementRef.current?.style.getPropertyValue(cssVariableName)

    const index = colors.findIndex((color) => color === currentColor)
    const newColor = colors[(index + 1) % colors.length]!
    elementRef.current?.style.setProperty(cssVariableName, newColor)
  }, [cssVariableName, colors, enabled])

  return {
    elementRef,
    nextColor,
  }
}
