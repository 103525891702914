const $$splitComponentImporter = () => import(/* webpackChunkName: "route-create" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/create/ai-playlist.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';
import { tier3Or } from '#app/lib/authenticated';
export const Route = createFileRoute('/create/ai-playlist')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  validateSearch: zodSearchValidator(z.object({
    prompt: fallback(z.string(), '').optional()
  })),
  staticData: {
    meta: 'marvinPlaylistFromPrompt.meta'
  },
  beforeLoad: () => {
    tier3Or('/create');
  }
});