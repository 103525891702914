import { actions as musicLibraryActions } from '#app/lib/music-library'
import { addTracksToPlaylist } from '#app/store/playlist-editor-updatePartialTracklist'
import type { useDispatch } from '#app/store/redux'
import { type DNDItem, type DNDItemType } from './schema'
/**
 * The drop item handler takes care of the mapping between item types (Tracks, Playlists, Schedules, etc) and
 * the corresponding handler for dropping items of that type on it.
 *
 * Extend it with the allowed drop types for each target type.
 */

type DropHandlerMap = Partial<Record<DNDItemType, DropHandler | undefined>>

type DropHandler = (
  target: DNDItem,
  items: string[],
  dispatch: ReturnType<typeof useDispatch>,
) => void

/**
 * Get the drop handlers for a target type.
 */
export function getDropHandlers(targetType: DNDItemType): DropHandlerMap {
  switch (targetType) {
    case 'Playlist':
      return {
        Track: dropTracksOnPlaylist,
      }
    case 'Library':
      return {
        Playlist: addPlaylistToLibrary,
      }
  }

  return {}
}

/**
 * Add drop handlers below
 */
const dropTracksOnPlaylist: DropHandler = (target, tracks, dispatch) => {
  dispatch(
    addTracksToPlaylist({
      trackIDs: tracks,
      playlistID: target.id,
    }),
  )
}

const addPlaylistToLibrary: DropHandler = (target, playlists, dispatch) => {
  if (playlists.length === 0) {
    return
  }

  return dispatch(
    musicLibraryActions.add(playlists[0]!, {
      libraryId: target.id,
    }),
  )
}
