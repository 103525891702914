import { createFileRoute, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/accounts/$accountId/locations/')({
  beforeLoad: ({
    params
  }) => {
    throw redirect({
      to: `/accounts/$accountId/zones`,
      from: Route.fullPath
    });
  }
});