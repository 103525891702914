import * as React from 'react'

export default function useAbortController() {
  const abortControllerRef = React.useRef<AbortController>(null as any)
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  abortControllerRef.current ||= new AbortController()

  const abort = React.useCallback((reset: boolean = false) => {
    abortControllerRef.current.abort()
    if (reset) abortControllerRef.current = new AbortController()
  }, [])

  const abortSignal = React.useCallback(
    () => abortControllerRef.current.signal,
    [],
  )

  React.useEffect(() => () => abortControllerRef.current.abort(), [])

  return {
    abortSignal,
    abort,
  }
}
