import { type ReducersMapObject, combineReducers } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-unresolved -- eslint does not understand the exports field
import { desktopSlice } from '@soundtrack/desktop-shared-components/desktopSlice'
import { createPlaybackSlice } from '@soundtrack/playback/slice'
import * as tysonSlice from '@soundtrack/playback/tyson/slice'
import { reducer as themeReducer } from '@soundtrack/theme'
import { log } from '@soundtrack/utils/log'
import { local } from '@soundtrackyourbrand/browser-storage.js'
import { EXPERIMENTS_NAMESPACE } from '@soundtrackyourbrand/experiments.js'
import * as flagsSlice from '#app/features/flags/store'
import { surveySlice } from '#app/features/surveys/slice'
import overrides from '#app/lib/overrides'
import * as activationSlice from '#app/store/activation'
import * as checkoutSlice from '#app/store/checkout'
import * as communicationSlice from '#app/store/communication'
import countryNode from '#app/store/country'
import * as currentAccountSlice from '#app/store/current-account'
import * as editorialSlice from '#app/store/editorial'
import * as persistence from '#app/store/lib/redux-persistence'
import * as miscSlice from '#app/store/misc'
import * as modalsSlice from '#app/store/modals'
import * as notificationsSlice from '#app/store/notifications'
import * as onboardingSlice from '#app/store/onboarding'
import * as previewSlice from '#app/store/preview'
import * as scheduleEditorSlice from '#app/store/schedule-editor'
import * as searchPersistedSlice from '#app/store/search-persisted'
import * as spotifyTokenSlice from '#app/store/spotify-token'
import { authSlice } from './auth'
import * as experimentsSlice from './experiments'
import { capsuleStoreConfig } from './lib/capsule-config'
import * as loadedAccountsSlice from './loaded-accounts'

const playbackSlice = createPlaybackSlice({
  disableOptimisticUpdates: () => overrides.get('no-optimistic-updates'),
})

export {
  activationSlice,
  authSlice,
  checkoutSlice,
  communicationSlice,
  currentAccountSlice,
  desktopSlice,
  editorialSlice,
  miscSlice,
  modalsSlice,
  notificationsSlice,
  onboardingSlice,
  persistence,
  playbackSlice,
  previewSlice,
  scheduleEditorSlice,
  searchPersistedSlice,
  spotifyTokenSlice,
  experimentsSlice,
  loadedAccountsSlice,
  flagsSlice,
  tysonSlice,
  surveySlice,
}

const appReducers = {
  activation: activationSlice.reducer,
  modals: modalsSlice.reducer,
  scheduleEditor: scheduleEditorSlice.reducer,
  surveys: surveySlice.reducer,
  auth: IS_ELECTRON
    ? authSlice.reducer // Persistence for Electron lives in the main process (app/desktop/src/store)
    : persistence.createPersistReducer(
        {
          key: `syb:auth`,
          storageAdapter: local as any,
          // Migrate old capsule sessions
          // TODO: Remove this after 2023-12 once most users have been migrated
          migrate(stored) {
            const oldRaw = local.getItem('syb:session')
            local.removeItem('syb:session')
            if (!oldRaw) {
              // Reset broken SAML sessions that lacked an `userId`
              return stored?.includes('"userId"') ? stored : '{}'
            }
            try {
              const old = JSON.parse(oldRaw)
              if (!old || !old.user || !old.refreshToken || !old.intercomHash) {
                return stored
              }
              log.debug('[auth] Migrating old session')
              return JSON.stringify(authSlice.loggedInStateFromPayload(old))
            } catch (error) {
              log.warn('[auth] Unable to migrate old session', error)
            }
            return stored
          },
          onRegistered: persistence.handleCrosstabSync,
        },
        authSlice.reducer,
      ),
  currentAccount: IS_ELECTRON
    ? currentAccountSlice.reducer // Persistence for Electron lives in the main process (app/desktop/src/store)
    : persistence.createPersistReducer(
        {
          key: 'syb:currentAccount',
          storageAdapter: persistence.sessionAndLocalAdapter as any,
          transforms: persistence.immutableAdapter,
          // Force flush currentAccount on tab focus so that new tabs
          // opened will use the same active account
          onRegistered: persistence.flushOnFocus,
        },
        currentAccountSlice.reducer,
      ),
  misc: miscSlice.reducer,
  editorial: editorialSlice.reducer,
  checkout: checkoutSlice.reducer,
  onboarding: onboardingSlice.reducer,
  playback: playbackSlice.reducer,
  preview: previewSlice.reducer,
  notifications: notificationsSlice.reducer,
  loadedAccounts: loadedAccountsSlice.reducer,
  country: countryNode.reducer,
  spotifyToken: spotifyTokenSlice.reducer,
  searchPersisted: persistence.createPersistReducer(
    {
      key: `syb:searchPersisted:1`,
      storageAdapter: persistence.sessionAndLocalAdapter as any,
      onRegistered: persistence.handleCrosstabSync,
    },
    searchPersistedSlice.reducer,
  ),
  [EXPERIMENTS_NAMESPACE]: persistence.createPersistReducer(
    {
      key: `syb:${EXPERIMENTS_NAMESPACE}`,
      storageAdapter: local as any,
      onRegistered: (store, key) => {
        // sets the override once the persisted state is set, to avoid race conditions
        experimentsSlice.handleExperimentsOverrides(store)
        return persistence.handleCrosstabSync(store, key)
      },
      migrate: (state) => {
        if (NODE_ENV === 'test')
          return experimentsSlice.allExperimentsToOriginal()
        return state
      },
    },
    experimentsSlice.reducer,
  ),
  communication: communicationSlice.reducer,
  flags: flagsSlice.reducer,
  desktop: desktopSlice.reducer,
  tyson: tysonSlice.reducer,
  theme: themeReducer,
} satisfies ReducersMapObject

const persistedReducers = persistence.createPersistReducers(
  {
    keyPrefix: 'syb:',
    storageAdapter: local as any,
    onRegistered: persistence.handleCrosstabSync,
    only: [
      'communication',
      'editorial',
      'notifications',
      'spotifyToken',
      'surveys',
      'flags',
      'theme',
    ],
  },
  {
    ...capsuleStoreConfig.reducer,
    ...appReducers,
  },
)

// Create the root reducer separately so we can derive the RootState from its return type
const rootReducer = combineReducers(persistedReducers)

export default rootReducer
