import { createFileRoute, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/discover/categories/$type/$categoryId')({
  beforeLoad({
    params
  }) {
    throw redirect({
      to: `/discover/browse/${params.categoryId}`,
      replace: true
    });
  }
});