import { type ParsedLocation, redirect } from '@tanstack/react-router'

export function browserOnlyUrl(location: ParsedLocation, preload: boolean) {
  if (IS_ELECTRON && isInternalBrowserOnlyUrl(location)) {
    // Don't open the browser if are preloading
    if (!preload) {
      window.Syb?.openBrowser(location.href)
    }

    throw redirect({
      to: '/electron/browser-opened',
      replace: true,
    })
  }
}

const zonesPagePathPattern = /\/accounts\/\w+\/zones\//
const accountCheckoutPathPattern = /^\/accounts\/\w+\/checkout/
const accountPlanPattern = /^\/accounts\/\w+\/(change-plan|upgrade-plan)/

/**
 * Internal URLs that should always be opened in the browser.
 */
function isInternalBrowserOnlyUrl(url: ParsedLocation): boolean {
  try {
    return (
      // Forgot password page
      url.pathname.startsWith('/forgot-password') ||
      // Zones pages
      zonesPagePathPattern.test(url.pathname) ||
      // Account checkout page
      accountCheckoutPathPattern.test(url.pathname) ||
      // Account change plan page
      accountPlanPattern.test(url.pathname)
    )
  } catch {
    return false
  }
}
