import { useEffect, useRef } from 'react'

/**
 * Returns a ref object whose `.current` property is always equal to the value passed to `useLatest`.
 * Useful for e.g. async callbacks where you want to make sure you're using the latest value.
 */
export function useLatest<T>(value: T): React.MutableRefObject<T> {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref
}
