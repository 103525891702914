const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-settings" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/settings/billing/update-payment.$type.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { loadQuery } from '@soundtrackyourbrand/capsule/dist/graph/query';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { ModalErrorView } from '#app/components/error/index';
import { t } from '#app/lib/i18n/index';
import toast from '#app/lib/toast';
import BillingSettings from '#app/modules/accounts/settings/billing/index';
import checkout from '#app/store/checkout';
import { selectors as accountSelectors } from '#app/store/current-account';
export const Route = createFileRoute('/accounts/$accountId/settings/billing/update-payment/$type')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  errorComponent: ModalErrorView,
  beforeLoad: async ({
    context,
    params
  }) => {
    // Make sure we've got all the data
    await loadQuery(context.store,
    // @ts-expect-error TS and Fetch component
    BillingSettings.query(null, null, {
      params
    }));
    const state = context.store.getState();
    const checkoutEnabled = checkout.enabled(state);
    const {
      hasBillingAddress
    } = checkout.hasFullAddress(state);
    if (hasBillingAddress) {
      return; // all good
    }
    if (checkoutEnabled) {
      throw redirect({
        to: '/accounts/$accountId/checkout/$',
        from: Route.fullPath,
        params: {
          _splat: 'address'
        },
        replace: true
      });
    }
    const billingGroup = accountSelectors.defaultBillingGroup(state);
    const groupId = billingGroup?.get('id');
    if (groupId) {
      throw redirect({
        to: '/accounts/$accountId/settings/billing/invoice-group/edit/$groupId',
        from: Route.fullPath,
        params: {
          groupId
        },
        search: {
          fromUpdate: true
        },
        replace: true
      });
    }

    // Last resort, shouldn't really happen
    toast.error(t('paymentMethod.missingBillingGroupRedirect'));
    throw redirect({
      to: '/accounts/$accountId/settings/billing',
      from: Route.fullPath
    });
  }
});