const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-your-music" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/your-music/schedules.edit.$scheduleId.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-your-music" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/your-music/schedules.edit.$scheduleId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { ModalErrorView } from '#app/components/error/index';
import { EditSchedule } from '#app/modules/accounts/your-music/edit-schedule/index';
export const Route = createFileRoute('/accounts/$accountId/your-music/schedules/edit/$scheduleId')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  errorComponent: ModalErrorView,
  validateSearch: zodSearchValidator(EditSchedule.searchSchema),
  staticData: {
    meta: 'scheduleEditor.meta'
  },
  loader: lazyFn($$splitLoaderImporter, 'loader')
});