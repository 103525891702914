import { scope } from '@soundtrack/utils/log'
import { queryAndObserve } from '@soundtrack/utils/queryAndObserve'
import { Utils } from '@soundtrackyourbrand/capsule'
import {
  loadQuery,
  resolveQuery,
} from '@soundtrackyourbrand/capsule/dist/graph/query'
import { type ImmutableEntity } from '@soundtrackyourbrand/capsule/dist/utils/immutable'
import { redirect, useRouter } from '@tanstack/react-router'
import { useEffect } from 'react'
import overrides from '#app/lib/overrides'
import { getRouter } from '#app/lib/router'
import { authSlice } from '#app/store/auth'
import currentAccountActions, {
  selectors as currentAccountSelectors,
} from '#app/store/current-account'
import { store } from '#app/store/index'
import { useSelector, useStore } from '#app/store/redux'
import type { NavigateToNonStrict } from './types'
import { ACCOUNT_ID_PLACEHOLDER } from './urls'

const log = scope('lib/authenticated')

const SESSION_QUERY = { session: { user: { acls: {}, accounts: {} } } }
const ACCOUNT_QUERY = (accountId: string | undefined) => ({
  account: accountId ? { params: { id: accountId } } : null,
})

export function onLoginRedirect(replace = false, throwRedirect = false) {
  const toOverride = overrides.get<string>('redirect-to')
  if (toOverride) {
    overrides.set('redirect-to', '')
  }
  const to =
    toOverride && !/^\/(login|signup)\b/.test(toOverride) ? toOverride : '/home'
  log.debug(`performing redirect on login to: ${to} (override: ${toOverride})`)
  if (throwRedirect) {
    throw redirect({ to, replace })
  } else {
    return getRouter().navigate({ to, replace })
  }
}

export function allowedOr(actions: string[], to: NavigateToNonStrict) {
  return assertOr((params) => allowedTo(actions, params.accountId), to)
}

export function allowedTo(
  actions: string[] | string,
  accountId: string,
  acls: null | any = null,
) {
  if (typeof actions === 'string') {
    actions = [actions]
  }

  const _accountId = parseAccountIdWithFallback(accountId)
  const validate = (acls) => {
    if (!acls) {
      return false
    }
    const acl = acls.find((acl) => acl.get('account') === _accountId)
    return (
      !!acl &&
      actions.every((action) => Utils.Permissions.isAllowedTo(acl, action))
    )
  }
  return acls
    ? validate(acls)
    : loadQuery(store, SESSION_QUERY).then((data) =>
        validate(data.getIn(['session', 'user', 'acls'])),
      )
}

export function assertAccountPropertiesOr(
  properties: string | Array<string | ((...args: any) => boolean)>,
  to: NavigateToNonStrict,
) {
  return assertOr(
    (params) => assertAccountProperties(properties, params.accountId),
    to,
  )
}

// Properties allowed in space separated string form or an array with functions and strings.
// Functions will be given the account as an argument
export function assertAccountProperties(
  properties: string | Array<string | ((...args: any) => boolean)>,
  accountId: string | undefined,
) {
  accountId = parseAccountIdWithFallback(accountId)
  if (!accountId) {
    return false
  }
  const result = resolveQuery(store, ACCOUNT_QUERY(accountId))
  const account = result?.get('account')
  // Basic sanity check
  if (!account || !properties) {
    return false
  }

  if (typeof properties === 'string') {
    properties = properties.split(' ')
  }

  // Either run the function with the account as an arg or check if the account has that property
  return properties.every((property) => {
    if (typeof property === 'function') {
      return property(account)
    }
    // If not a function, check the account properties.
    return !!account.get(property)
  })
}
export function featuresEnabledOr(
  features: string[] | string,
  to: NavigateToNonStrict,
  accountId?: string,
) {
  return assertOr((params) => featuresEnabled(features, accountId), to)
}

export function featuresDisabledOr(
  features: string[] | string,
  to: NavigateToNonStrict,
) {
  return assertOr((params) => !featuresEnabled(features, params.accountId), to)
}

export function featuresEnabled(
  features: string[] | string,
  accountId: string | undefined,
) {
  const id = accountId || getCurrentAccountId()
  const result = resolveQuery(store, ACCOUNT_QUERY(id))
  const accountFeatures = result?.getIn(['account', 'feature_flags']) as
    | ImmutableEntity
    | undefined
  if (!accountFeatures) {
    return false
  }
  if (typeof features === 'string') {
    features = features.split(' ')
  }
  return features.every((feature) => accountFeatures.get(feature))
}

export function rolesEnabledOr(roles, to: NavigateToNonStrict) {
  return assertOr((params) => rolesEnabled(roles, params.accountId), to)
}

export function rolesDisableOr(roles, to: NavigateToNonStrict) {
  return assertOr((params) => !rolesEnabled(roles, params.accountId), to)
}

export function rolesEnabled(roles, accountId) {
  accountId = parseAccountIdWithFallback(accountId)
  const result = resolveQuery(store, ACCOUNT_QUERY(accountId))
  const accountRoles = result?.getIn(['account', 'roles']) as
    | ImmutableEntity
    | undefined
  if (!accountRoles) {
    return false
  }
  if (typeof roles === 'string') {
    roles = roles.split(' ')
  }
  return roles.every((role) => accountRoles.get(role))
}

export function tier3Or(to: NavigateToNonStrict) {
  return assertOr(() => currentAccountSelectors.tier3(store.getState()), to)
}

export function paramOneOfOr(
  paramName: string,
  values: string[] = [],
  to: NavigateToNonStrict,
) {
  return assertOr((params) => values.indexOf(params[paramName]) > -1, to)
}

export function assertOr(
  assertionFn: (params?: any) => any | Promise<any>,
  fallbackTo: NavigateToNonStrict,
): Promise<any> {
  const handle = (outcome: boolean) => {
    if (!outcome) {
      throw redirect({ to: fallbackTo, replace: true })
    }

    return Promise.resolve(!!outcome)
  }

  const matches = getRouter().state.matches
  const params = matches[matches.length - 1]!.params
  const result = assertionFn(params)
  return result && typeof result.then === 'function'
    ? result.then(handle)
    : handle(result)
}

/** Keep `account_id` URL param and currentAccount.id redux state in sync */
export async function syncCurrentAccountId(
  /** The accountId passed from the URL */
  paramAccountId: string,
  /** Where to redirect to in case a `_` is passed but no accountId is set in the store */
  fallbackRedirectTo = null as NavigateToNonStrict | null,
  /** We need to know `from` if we're going to replace the current route param */
  from: string,
) {
  const storedId = getCurrentAccountId()

  if (paramAccountId && paramAccountId !== ACCOUNT_ID_PLACEHOLDER) {
    // Sync store with requested id
    if (storedId !== paramAccountId) {
      updateCurrentAccountId(paramAccountId)
    }
    return
  }

  const updateUrl = (accountId: string) => {
    if (accountId === paramAccountId) {
      log.debug(
        'syncCurrentAccountId: Account ID already matched, no update needed',
        { from, accountId },
      )
      return
    }
    log.debug('syncCurrentAccountId: Updating account ID in URL', {
      from,
      accountId,
    })
    throw redirect({
      from,
      to: from,
      params: (prev) => {
        return { ...prev, accountId }
      },
    })
  }

  if (storedId) {
    // Sync URL with stored id
    updateUrl(storedId)
    return
  }

  // Neither requested nor stored id, fallback to default available account
  await loadQuery(store, SESSION_QUERY)
  const resolvedId = getCurrentAccountId()
  if (resolvedId) {
    updateUrl(resolvedId)
    return
  }

  // User doesn't have any account
  if (fallbackRedirectTo) {
    throw redirect({ to: fallbackRedirectTo })
  }
}

export function logoutAndRedirect(to: string) {
  store.dispatch(authSlice.actions.reset())
  throw redirect({ to, replace: true })
}

function updateCurrentAccountId(id) {
  const userId = authSlice.selectors.userId(store.getState())
  if (userId) {
    store.dispatch(currentAccountActions.select(userId, id))
  }
}

/**
 * Makes sure we don't consider any passed "_" account placeholders as valid account IDs,
 * and rely on the result of {@link getCurrentAccountId} instead.
 */
function parseAccountIdWithFallback(accountId: string | undefined) {
  return accountId && accountId !== ACCOUNT_ID_PLACEHOLDER
    ? accountId
    : getCurrentAccountId()
}

function getCurrentAccountId() {
  return currentAccountSelectors.id(store.getState())
}

/**
 * Protects a route from being accessed when the "Limit music selection" setting is enabled.
 *
 * You should also call {@link useLimitMusicSelectionGuard} inside the Route component
 * to react to changes of this setting.
 */
export function ensureCanSelectMusicOrRedirectToLibrary() {
  const accountId = currentAccountSelectors.id(store.getState())
  const restrictedDiscoverMusic =
    currentAccountSelectors.restrictedDiscoverMusic(store.getState())
  if (restrictedDiscoverMusic) {
    throw redirect({
      to: '/accounts/$accountId/your-music',
      params: { accountId },
    })
  }
}

/**
 * Only mount this inside a route that should not be available with the "Limit music selection" setting enabled.
 *
 * @important This only invalidates the router to re-trigger the `beforeLoad()` callbacks.
 * Make sure you also call {@link ensureCanSelectMusicOrRedirectToLibrary} in the `beforeLoad()` callback.
 */
export function useLimitMusicSelectionGuard() {
  const store = useStore()
  const router = useRouter()

  useEffect(() => {
    return queryAndObserve(
      currentAccountSelectors.restrictedDiscoverMusic,
      (restrictedDiscoverMusic) => {
        if (restrictedDiscoverMusic) {
          router.invalidate()
        }
      },
      store,
    )
  }, [store, router])
}

export function useCurrentUserAcl(
  account?: ImmutableEntity,
): ImmutableEntity | undefined {
  const userId = useSelector(authSlice.selectors.userId)
  if (userId == null) {
    return
  }
  return account
    ?.get('acls')
    .find((acl) => acl.getIn(['denorm_user', 'id']) === userId)
}

export function useIsCurrentUserAdmin(account?: ImmutableEntity) {
  const currentUserAcl = useCurrentUserAcl(account)
  return !!(currentUserAcl?.get('admin') || currentUserAcl?.get('owner'))
}
