import { Models } from '@soundtrackyourbrand/capsule'

const { PSP } = Models.PaymentMethod

const ADYEN_TYPE_TO_PSP = {
  card: PSP.ADYEN_CREDITCARD,
  scheme: PSP.ADYEN_CREDITCARD,
  paywithgoogle: PSP.ADYEN_GOOGLE_PAY,
  applepay: PSP.ADYEN_APPLE_PAY,
  bcmc: PSP.ADYEN_CREDITCARD,
  paypal: PSP.ADYEN_PAYPAL,
}

/**
 * Internally we use our own PSP notations (e.g. "adyen_apple_pay") which we need to map to from
 * the payment method types Adyen uses (e.g. "applepay").
 *
 * @param {string} adyenType An Adyen payment method type, e.g. "applepay" or "scheme"
 * @returns {string}
 */
export function adyenTypeToPsp(adyenType) {
  const psp = ADYEN_TYPE_TO_PSP[adyenType]
  if (!psp) {
    throw new Error(
      `adyenTypeToPsp: Could not map Adyen Payment Type "${adyenType}" to internal PSP`,
    )
  }
  return psp
}
