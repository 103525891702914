import { queryAndObserve } from '@soundtrack/utils/queryAndObserve'
import shallowEquals from '#app/lib/shallow-equals'
import { tracking } from '#app/lib/tracking'
import { store } from '#app/store/index'
import { miscSlice } from '#app/store/reducers'
import { resetAppliedFlags } from './lib/applyFlag'
import { fetchFlags } from './lib/fetchFlags'

let _initialized = false

export async function initializeFlags(): Promise<void> {
  // Prevent initializing multiple times with Vite HMR
  if (_initialized) return Promise.resolve()
  _initialized = true

  // Reset the Flags Session ID on app start
  tracking.mixpanel?.register({
    'Flags Session ID': null,
  })

  await fetchFlags({ reason: 'app-startup', force: true }) // Always force on app startup

  // Re-fetch flags when Editorial Agent changes (account change, user login/logout, etc.)
  queryAndObserve(
    miscSlice.selectors.editorialAgent,
    (agent, prevAgent) => {
      if (shallowEquals(agent, prevAgent)) return
      resetAppliedFlags()
      fetchFlags({
        reason: 'agent-changed',
        reset: true,
      })
    },
    store,
    { invokeImmediately: false },
  )
}
