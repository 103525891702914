import { type AnyAction } from '@reduxjs/toolkit'
import { PlaylistComposer } from '@soundtrackyourbrand/capsule/dist/models/collection'
import { COMPOSER_SUBTYPE_MAP } from '@soundtrackyourbrand/capsule/dist/models/musiclibrary'
import { participate } from '@soundtrackyourbrand/experiments.js'
import im from 'immutable'
import type { MixedSourceWithComposer } from '#app/lib/source'
import tracking from '#app/lib/tracking'
import { startReduxListening } from '#app/store/middleware/listener'

export function setupTrackingListeners() {
  // Connect @soundtrackyourbrand/tracking.js with @soundtrackyourbrand/experiments.js
  startReduxListening({
    actionCreator: participate,
    effect: (action) => {
      const { name, variation } = action.payload

      tracking.participate(name, variation)

      // Send a tracking event for recording and heatmap filtering
      tracking.hotjar?.('tagRecording', [name, variation])
    },
  })

  startReduxListening({
    type: 'REQUEST_COLLECTION_CREATE_SUCCESS',
    effect: (action: AnyAction) => {
      const collection = action.data?.collection
      const spotifyImport =
        action.query?.size && action.query.get('trackingContext')?.toJS()
      const spotifyContext = spotifyImport && {
        Context: 'Create - Spotify Playlist',
        ...spotifyImport,
      }
      if (!collection) {
        return
      }
      tracking.track(
        'Music - Created',
        tracking.musicContext(im.Map(collection) as MixedSourceWithComposer, {
          intercom: true,
          ...spotifyContext,
        }),
      )
    },
  })

  startReduxListening({
    type: 'REQUEST_TAG_STATION_CREATE_SUCCESS',
    effect: (action: AnyAction) => {
      if (!action.data) {
        return
      }
      tracking.track('Music - Created', {
        'Music ID': action.data.id,
        'Music Type': 'station',
        'Music Subtype': COMPOSER_SUBTYPE_MAP[PlaylistComposer.Recipe],
        'Music Composer Type': PlaylistComposer.Recipe,
        'Music Name': action.data.name,
        intercom: true,
      })
    },
  })

  startReduxListening({
    type: 'REQUEST_TAG_RECIPE_UPDATE_SUCCESS',
    effect: () => {
      tracking.gtm('soundtrack', 'recipe-update')
    },
  })
}
