const $$splitLoaderImporter = () => import(/* webpackChunkName: "route-discover" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/discover/schedule.$id.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
const $$splitComponentImporter = () => import(/* webpackChunkName: "route-discover" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/discover/schedule.$id.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensurePaddedId } from '#app/lib/urls';
export const Route = createFileRoute('/discover/schedule/$id')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'schedule.meta'
  },
  beforeLoad: ({
    params
  }) => {
    ensurePaddedId(params, 'id');
  },
  loader: lazyFn($$splitLoaderImporter, 'loader')
});

// Lazy-load schedular stuff since the implicitly include react-dnd etc.
// This should mirror the lazy-loaded ScheduleEdit chunk in routes.tsx