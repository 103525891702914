// Importing English directly because it's pretty much always used.
import englishTranslation from '#app/locale/en/translation.json'
import defaultLocale from './default-locale'

export default class Backend {
  constructor(services, options = {}) {
    this.services = services
    this.options = options
  }

  read(language, namespace, callback) {
    if (language === 'en' && namespace === 'translation') {
      callback(null, englishTranslation)
      return
    }

    const result = import(`../../locale/${language}/${namespace}.json`)
    result.then((data) => {
      if (data.default && typeof data.default === 'function') {
        // Webpack
        data.default((locale) => {
          callback(null, locale || defaultLocale)
        })
      } else if (data && typeof data === 'object') {
        // Vite
        callback(null, data || defaultLocale)
      }
    })
  }

  readMulti(languages, namespaces, callback) {
    console.warn('[i18n/backend.readMulti]', languages, namespaces, callback)
  }

  create(languages, namespace, key, fallbackValue) {
    // TODO: Implement?
    // https://github.com/i18next/i18next-xhr-backend/blob/master/src/index.js#L113
    console.warn(
      '[i18n/backend.create]',
      languages,
      namespace,
      key,
      fallbackValue,
    )
  }
}

Backend.type = 'backend'
