const $$splitComponentImporter = () => import(/* webpackChunkName: "route-create" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/create/station.from-tags.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';
import { ensureLoggedIn, ensureNotOnStarterPlan } from '#app/route-lib';
export const Route = createFileRoute('/create/station/from-tags')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: ({
    context,
    location
  }) => {
    ensureLoggedIn(location);
    ensureNotOnStarterPlan(context.store);
  },
  // TODO: NYI, could be used to preserve the active tags in the URL for a better refresh/share experience
  validateSearch: zodSearchValidator(z.object({
    tags: z.array(z.string()).optional().catch([])
  })),
  staticData: {
    meta: 'station.create.meta'
  }
});