const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-zones" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/zones/$zoneId_.player.history.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';
// Note: This route is rendered outside of the zone modal layout by it being named
// `$zoneId_.player..` instead of `$zoneId.player..`. This is to make sure there's no race condition on the modals
// showing and being rendered in the wrong order.

export const Route = createFileRoute('/accounts/$accountId/zones/$zoneId_/player/history')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  validateSearch: zodSearchValidator(z.object({
    date: z.string().optional().catch(undefined)
  }))
});