import { type AnyAction, type Reducer } from '@reduxjs/toolkit'
import { Models, Utils } from '@soundtrackyourbrand/capsule'
import im from 'immutable'
import moment from 'moment'
import { groupBy } from '#app/lib/fp'
import { createSelector } from '#app/store/redux'

export const actions = {
  beginActivation: (account, zone, pricing, params = {}) => ({
    type: 'ACTIVATION_BEGIN',
    account,
    zone,
    pricing,
    ...params,
  }),
  resetActivation: () => ({ type: 'ACTIVATION_RESET' }),
  updateData: (data) => ({ type: 'ACTIVATION_UPDATE_DATA', data }),
  updatePaymentProvider: (paymentProvider) => ({
    type: 'ACTIVATION_UPDATE_PAYMENT_PROVIDER',
    paymentProvider,
  }),
  updatePaymentType: (paymentType) => ({
    type: 'ACTIVATION_UPDATE_PAYMENT_TYPE',
    paymentType,
  }),
}

export default actions

export const reducer: Reducer<any, AnyAction> = (state = {}, action) => {
  switch (action.type) {
    case 'ACTIVATION_BEGIN': {
      let {
        account,
        zone,
        pricing,
        paymentType,
        useAvailableDiscount,
        tier,
        plan,
        billingCycle,
        forcePaymentType,
      } = action

      // Validate input
      Utils.AssertMapProps(
        'account',
        account,
        ['payment_method', true] as any,
        ['months_to_invoice', true] as any,
      )

      Utils.AssertMapProps('zone', zone, ['location', true] as any)
      Utils.AssertMapProps('pricing', pricing)
      if (!includesDistributor(account)) {
        throw new Error(`'Missing required entity 'distributor' in 'account'`)
      }

      // Ensure zone.location.billing_group is populated
      const billing_group = zone.getIn(['location', 'billing_group'])
      if (!im.Map.isMap(billing_group)) {
        const bgs = account.get('billing_groups')
        if (!bgs) {
          throw new Error(
            `Must provide populated 'zone.location.billing_group' or 'account.billing_groups' array`,
          )
        }
        // Replace id with object (or null if billing_group doesn't exist)
        zone = zone.setIn(
          ['location', 'billing_group'],
          bgs.find((bg) => bg.get('id') === billing_group),
        )
      }

      paymentType =
        paymentType ||
        Models.PaymentMethod.paymentType(account.get('payment_method')) ||
        'cc'

      return {
        zone,
        account,
        pricing,
        voucher_code: pricing.getIn(['voucher', 'code']) || '',
        paymentType,
        useAvailableDiscount,
        billingCycle: billingCycle || account.get('billing_cycle'),
        tier: tier || account.get('tier'),
        plan,
        forcePaymentType,
        monthsToInvoice: accountMonthsToInvoice(account, paymentType),
        initiallyActivated: Models.SoundZone.hasActiveSubscription(zone),
        initiallyReadyForPayment:
          account.get('incomplete') === false &&
          !!account.getIn(['payment_method', 'psp']),
      }
    }
    case 'ACTIVATION_UPDATE_DATA':
      return state.account
        ? {
            ...state,
            ...action.data,
          }
        : state
    case 'ACTIVATION_UPDATE_PAYMENT_PROVIDER':
      const { paymentProvider } = action
      return {
        ...state,
        paymentProvider,
      }
    case 'ACTIVATION_UPDATE_PAYMENT_TYPE':
      return {
        ...state,
        paymentType: action.paymentType,
        monthsToInvoice: accountMonthsToInvoice(
          state.account,
          action.paymentType,
        ),
      }
    case 'ACTIVATION_RESET':
    case '@@capsule/RESET':
      return {}
    default:
      return state
  }
}

const activation = (state) => state.activation
const pricing = (state) => state.activation.pricing
const billingCycle = (state) => state.activation.billingCycle
const tier = (state) => state.activation.tier
const plan = (state) => state.activation.plan

const priceListEntry = createSelector(
  pricing,
  billingCycle,
  tier,
  plan,
  (pricing, billingCycle, tier, plan) => {
    const priceList = pricing?.get('price_list').filter((val) => {
      return plan ? true : val.get('plan') !== 'starter'
    })

    return priceList?.find(
      (val) =>
        val.get('billing_cycle') === billingCycle &&
        (plan ? val.get('plan') === plan : val.get('tier') === tier),
    )
  },
)

const monthsToInvoice = createSelector(
  activation,
  pricing,
  billingCycle,
  (activation, pricing, billingCycle) => {
    if (!pricing) {
      return null
    }
    if (pricing.get('price_list')?.size > 1) {
      switch (billingCycle) {
        case 'monthly':
          return 1
        case 'quarterly':
          return 3
        case 'yearly':
          return 12
        default:
          return 1
      }
    }
    return activation.monthsToInvoice
  },
)

const shouldApplyDiscount = createSelector(
  priceListEntry,
  activation,
  (priceListEntry, activation) => {
    return (
      !!activation.useAvailableDiscount &&
      !!priceListEntry?.getIn(['available_discount', 'prices'])
    )
  },
)

const upcomingPrices = createSelector(
  priceListEntry,
  activation,
  (priceListEntry, activation) => {
    if (!priceListEntry) {
      return null
    }
    return (
      (activation.useAvailableDiscount &&
        priceListEntry.getIn(['available_discount', 'prices'])) ||
      priceListEntry.get('prices') ||
      null
    )
  },
)

const currency = createSelector(
  pricing,
  billingCycle,
  tier,
  (pricing, billingCycle, tier) => {
    if (!pricing) {
      return null
    }
    const listCurrency = pricing
      .get('price_list')
      ?.find((price) => {
        return (
          price.get('tier') === tier &&
          price.get('billing_cycle') === billingCycle
        )
      })
      ?.get('iso_currency')

    return listCurrency || pricing?.get('iso_currency') || null
  },
)

const invoiceFee = createSelector(pricing, (pricing) =>
  pricing?.get('invoice_fee'),
)

const isOngoingTrial = (state) => {
  if (!state.activation) return false
  const value = moment.utc(
    state.activation.account?.getIn(['payment_method', 'trial_expires_at']) ||
      '',
  )
  return !Utils.Dates.isZero(value) && value.isAfter(/* now */)
}

const startsFree = (state) => {
  if (!state.activation.account) {
    return null
  }

  return priceListEntry(state)?.get('trial_days') > 0 || null
}

const itemName = createSelector(
  priceListEntry,
  (priceListEntry) => priceListEntry && priceListEntry.get('item_name'),
)

const zonePrices = createSelector(
  activation,
  monthsToInvoice,
  upcomingPrices,
  invoiceFee,
  currency,
  billingCycle,
  tier,
  plan,
  startsFree,
  priceListEntry,
  (
    activation,
    monthsToInvoice,
    upcoming,
    invoiceFee,
    currency,
    billingCycle,
    plan,
    tier,
    startsFree,
    priceListEntry,
  ) => {
    if (!upcoming || !Models.Account.hasPayment(activation.account)) {
      return null
    }

    return im.fromJS({
      ...priceTable({
        upcoming,
        monthsToInvoice,
        invoiceFee,
        startsFree,
        priceListEntry,
      }),
      monthsToInvoice,
      isoCurrency: currency,
      billingCycle,
      tier,
      plan,
      internationalPayment: activation.account.get('iso_country') !== 'SE',
    })
  },
)

export const selectors = {
  activation,
  monthsToInvoice,
  zonePrices,
  pricing,
  billingCycle,
  tier,
  plan,
  priceTable,
  startsFree,
  isOngoingTrial,
  shouldApplyDiscount,
  itemName,
}

export function includesDistributor(account) {
  if (!im.Map.isMap(account)) return false
  const d = account.get('distributor')
  // Distributor accounts (like SYB) has no distributor themselves
  return d === '' || (im.Map.isMap(d) && d.get('business_name'))
}

function accountMonthsToInvoice(account, paymentType) {
  return (
    account.getIn(['months_to_invoice', 'months_to_invoice']) ||
    (paymentType === 'cc' ? 1 : 12)
  )
}

function priceTable({
  upcoming,
  monthsToInvoice,
  invoiceFee,
  startsFree,
  priceListEntry,
}) {
  // upcoming is an immutable.Map, toList() and valueSeq() doesn't
  // always preserve the key/value order, which is important for us.
  const input = upcoming.reduce((ary, v, k) => {
    ary[k] = parseFloat(v)
    return ary
  }, [])

  const startingPayMonth = startsFree ? 0 : monthsToInvoice
  const alignmentCost = priceListEntry.get('alignment_cost')
  const alignmentCostTotal = alignmentCost ? alignmentCost.get('net_total') : 0

  const inputRepeated = input.concat(
    repeat(last(input), Math.max(0, 12 - input.length)),
  )

  const breakdown = alignmentCostTotal
    ? []
    : groupBy(inputRepeated.slice(0, startingPayMonth)).map(toLine)

  if (alignmentCostTotal) {
    alignmentCost.get('entries').forEach((entry) => {
      const count =
        daysBetween(
          entry.getIn(['period', 'from']),
          entry.getIn(['period', 'to']),
        ) || 1
      const amount = entry.get('net_amount') / count
      breakdown.unshift({
        type: 'day',
        count,
        amount,
      })
    })
  }

  if (!startsFree && invoiceFee > 0 && sumLines(breakdown) > 0) {
    breakdown.unshift({ amount: invoiceFee, count: 1, type: 'fee' })
  }

  const initial = {
    type: startsFree ? 'trial' : 'total',
    amount: sumLines(breakdown),
    count: startsFree ? priceListEntry.get('trial_days') : 1,
    breakdown,
  }

  let following = frequencies(input.slice(startingPayMonth, input.length)).map(
    toLine,
  )

  // Restore last month price if consumed to be able to display then "xx SEK"
  if (!following.length) {
    following = [{ type: 'month', amount: last(input) }] as any
  }

  const fin = last(following)
  fin.count = 'infinite'

  return {
    type: type(breakdown),
    initial,
    catchup: monthsToInvoice !== 1 && monthsToInvoice !== 12,
    alignment: alignmentCostTotal > 0,
    following,
    singleFollowingCharge: following.length === 1,
  }
}

const toLine = (l) => ({
  type: 'month',
  amount: parseFloat(l.value),
  count: l.count,
})

const sumLines = (xs) =>
  xs.reduce((memo, { amount, count }) => amount * count + memo, 0)

const type = (breakdown) => {
  if (
    breakdown.length > 1 ||
    (breakdown.length && breakdown[0].count > 1 && breakdown[0].amount > 0)
  ) {
    return 'breakdown'
  }

  return 'regular'
}

function frequencies(xs) {
  const res: any[] = []
  let prev

  for (let i = 0; i < xs.length; i++) {
    if (xs[i] === prev) {
      res[res.length - 1].count++
      continue
    }

    prev = xs[i]
    res.push({ value: prev, count: 1 })
  }

  return res
}

function daysBetween(_from, _to) {
  const dayInMs = 1000 * 60 * 60 * 24
  const from = new Date(_from)
  const to = new Date(_to)
  const diff = (to.valueOf() - from.valueOf()) / dayInMs

  return Math.floor(diff)
}

const last = (xs) => xs[xs.length - 1]
const repeat = (value, n) => new Array(n).fill(value)
