import { useEffect } from 'react';
import { t } from '#app/lib/i18n/index';
import toast from '#app/lib/toast';
import tracking from '#app/lib/tracking';

/** A static toast ID makes sure we never show multiple update toasts */
const TOAST_ID = 'business-update-available';

/** Wait this long after page load before checking for updates */
const INITIAL_CHECK_DELAY = 6 * 3600 * 1000; // 6 hours

/** When a user dismisses the toast we won't bother them again for this amount of time */
const DISMISS_COOLDOWN = 12 * 3600 * 1000; // 12 hours

/** Once we started looking for updates, how often to check for updates */
const UPDATE_CHECKING_INTERVAL = 30 * 60 * 1000; // 30 minutes

/** How many time the user has dismissed the counter. Used for tracking */
let dismissCounter = 0;

/** Keep a stable reference to the current running timeout so it can be cleaned up. Mainly useful when developing */
let timeoutId;
export default function UpdateChecker() {
  useEffect(() => {
    scheduleUpdateCheck(INITIAL_CHECK_DELAY);
    // Clean-up when the component unmounts, or risk having multiple timeouts running with hot-reloading
    return () => clearTimeout(timeoutId);
  }, []);
  return null;
}
export function showUpdateAvailableToast(trackingDefaults = {}) {
  const trackingProps = {
    ...trackingDefaults,
    'Updates Dismissed': dismissCounter
  };
  toast.success(t('app.updates.available'), {
    id: TOAST_ID,
    icon: 'arrow-up',
    duration: Infinity,
    trackingProps,
    action: {
      label: t('app.updates.reload'),
      onClick: () => {
        tracking.track('Update Available - Reload', trackingProps);
        // Wait 100ms to make sure we've sent the Mixpanel event
        setTimeout(() => window.location.reload(), 100);
      }
    },
    onClose: isDismissedByUser => {
      if (!isDismissedByUser) return;
      dismissCounter++;
      tracking.track('Update Available - Dismissed', trackingProps);
      scheduleUpdateCheck(DISMISS_COOLDOWN);
    }
  });
}
function checkForUpdates() {
  fetch('/env', {
    cache: 'no-cache'
  }).then(res => res.json()).then(data => {
    if (data.version && data.version !== SYB.version) {
      const trackingVars = {
        'Update Version': data.version,
        'Update Timestamp': data.timestamp
      };
      showUpdateAvailableToast(trackingVars);
    } else {
      scheduleUpdateCheck();
    }
  }).catch(error => {
    if (error.message !== 'Failed to fetch') {
      throw error;
    }
  });
}
function scheduleUpdateCheck(delay = UPDATE_CHECKING_INTERVAL) {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(checkForUpdates, delay);
}