import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useVirtual } from 'react-virtual'
import { findScrollParent } from '#app/lib/scroll'

export default function useVirtualScrollParent(options) {
  const sizeKey = options.horizontal ? 'width' : 'height'
  const { parentRef } = options
  const [rowSize, setRowSize] = useState(options.estimateSize(0))

  // Mock the API surface currently used through parentRef
  const mockedParentRef = useRef(null)

  useLayoutEffect(() => {
    const scrolled = parentRef.current
    if (!scrolled) {
      return
    }

    const scrollParent = findScrollParent(scrolled)

    const getScrollTop = () => {
      return scrollParent.scrollTop - scrolled.offsetTop
    }

    let originalScrollListener = null
    const scrollListener = (originalEvent) => {
      const target = {
        scrollLeft: scrollParent.scrollLeft - scrolled.offsetLeft,
        scrollTop: getScrollTop(),
      }
      originalScrollListener({ target })
    }

    mockedParentRef.current = {
      get scrollLeft() {
        return scrollParent.scrollLeft - scrolled.offsetLeft
      },
      set scrollLeft(x) {
        scrollParent.scrollLeft = x + scrolled.offsetLeft
      },
      get scrollTop() {
        return getScrollTop()
      },
      set scrollTop(x) {
        scrollParent.scrollTop = x + scrolled.offsetTop
      },
      getBoundingClientRect: () => ({
        width: scrollParent.clientWidth,
        height: scrollParent.clientHeight,
      }),
      addEventListener: (type, listener, ...args) => {
        // Only proxy 'scroll' event listeners
        if (type === 'scroll') {
          originalScrollListener = listener
          listener = scrollListener
          args = [] // has to be reset for IE11 to be able to later remove it
          listener()
        }
        return scrollParent.addEventListener(type, listener, ...args)
      },
      removeEventListener: (type, listener, ...args) => {
        if (type === 'scroll') {
          listener = scrollListener
          args = []
        }
        return scrollParent.removeEventListener(type, listener, ...args)
      },
    }
  }, [parentRef])

  const rowVirtualizer = useVirtual({
    ...options,
    parentRef: mockedParentRef,
    estimateSize: useCallback(() => rowSize, [rowSize]),
  })

  // Calculate row size from first item only
  const sizedElementRef = useRef()
  const estimateSizeRef = useCallback(
    (element) => {
      sizedElementRef.current = element
      if (!element) {
        return
      }
      setRowSize(element.getBoundingClientRect()[sizeKey] + 1)
    },
    [setRowSize, sizeKey],
  )

  // Re-calculate row size on window resize
  useLayoutEffect(() => {
    // TODO: Debounce with min=50 max=300 or similar
    const onResize = () => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!sizedElementRef.current) {
        return
      }
      setRowSize(sizedElementRef.current.getBoundingClientRect()[sizeKey] + 1)
    }
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [sizeKey, estimateSizeRef])

  return {
    ...rowVirtualizer,
    estimateSizeRef,
    rowSize,
  }
}
