import { createFileRoute, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/accounts/$accountId/zones/$zoneId/')({
  beforeLoad: ({
    params
  }) => {
    throw redirect({
      to: '/accounts/$accountId/zones/$zoneId/player',
      params,
      replace: true
    });
  }
});