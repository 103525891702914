const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-onboarding" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/onboarding/$step.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
export const Route = createFileRoute('/accounts/$accountId/onboarding/$step')({
  // TODO: Refactor the Onboarding wrapping component so that it has proper <Outlet>s for the steps.
  loader: lazyFn($$splitLoaderImporter, 'loader'),
  onError: error => {
    // Log the error
    console.error(error);
    throw error;
  }
});