import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { SurveyInteraction, SurveyPayload } from './types'

/** An ID for a viewer, could be a user, account, location, zone */
type ViewerIdentifier = string
type ViewerEntry = {
  /** When this viewer last completed or dismissed a survey */
  lastSurveyCompletedAt: string | null
  entries: Record<SurveyName, SurveyEntry>
}

type SurveyName = string
type SurveyEntry = {
  interaction: SurveyInteraction
  date: string
  payload?: SurveyPayload | null
}

type SliceState = {
  /** Version of the slice. Useful if we ever need to change the shape and migrate existing persisted slices */
  version: number
  /** Last time any survey was completed or dismissed */
  lastSurveyCompletedAt: string | null
  /** A map of different viewers by ID, could be users, accounts, locations, zones */
  viewers: Record<ViewerIdentifier, ViewerEntry>
}

const INITIAL_STATE: SliceState = {
  version: 1,
  lastSurveyCompletedAt: null,
  viewers: {},
} as const

export const surveySlice = createSlice({
  name: 'surveys',

  initialState: INITIAL_STATE,

  reducers: {
    setSurveyInteraction: (
      state,
      action: PayloadAction<{
        viewerId: ViewerIdentifier
        name: SurveyName
        interaction: SurveyInteraction
        payload?: SurveyEntry['payload']
      }>,
    ) => {
      const { viewerId, name, interaction, payload } = action.payload
      const date = new Date().toISOString()

      const viewer: ViewerEntry = state.viewers[viewerId] || {
        lastSurveyCompletedAt: null,
        entries: {},
      }

      // Anything but "progressed" is considered "completed"
      if (interaction !== 'progressed') {
        viewer.lastSurveyCompletedAt = date
      }

      viewer.entries[name] = {
        interaction,
        payload,
        date,
      }

      state.lastSurveyCompletedAt = date
      state.viewers[viewerId] = viewer
    },

    reset: () => INITIAL_STATE,
  },

  selectors: {
    /** Whether the survey has been dismissed or completed */
    dismissedOrCompletedSurvey: (
      state: SliceState,
      viewerId: ViewerIdentifier,
      name: SurveyName,
    ): boolean => {
      return !!state.viewers[viewerId]?.entries[name]
    },

    surveysByViewer: (state: SliceState) => {
      return state.viewers
    },
  },
})
