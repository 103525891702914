/**
 * Implementation for `Set.prototype.symmetricDifference`, until we can be sure that that’s properly polyfilled on outdated devices.
 * (it’s only supported cross-browser since June 2024)
 */
export function symmetricDifference<A, B>(a: Set<A>, b: Set<B>): Set<A | B> {
  const diff = new Set<A | B>(a)
  for (const value of b) {
    if (diff.has(value)) {
      diff.delete(value)
    } else {
      diff.add(value)
    }
  }
  return diff
}
