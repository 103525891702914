import type { FieldPolicy } from '@apollo/client'
import { makeReference, relayStylePagination } from '@apollo/client/utilities'
import {
  type Reference,
  addOrRemoveFromCachedList,
  cachedFieldArgs,
  defaultTypePolicies,
  relayPagination,
} from '@soundtrackyourbrand/apollo-client'
import {
  blockTrack,
  unblockTrack,
} from '@soundtrackyourbrand/apollo-client/typePolicies/blockedTracks'
import { playbackHistory } from '@soundtrackyourbrand/apollo-client/typePolicies/playbackHistory'
import type { TypedTypePolicies } from '#app/graphql/graphql'
import { selectors as currentAccount } from '#app/store/current-account'
import type { Store } from '#app/store/index'
import { graphql } from '../graphql'

/** Injected via {@link initializeTypePolicies} to avoid circular dependencies */
let store: Store | undefined

export function initializeTypePolicies(options: { store: Store }) {
  store = options.store
}

function currentAccountId() {
  if (!store) {
    throw new Error('typePolicies: store not set')
  }
  return currentAccount.id(store.getState())
}

export const typePolicies = defaultTypePolicies<TypedTypePolicies>()

const inMusicLibraryPolicy: FieldPolicy = {
  read(existing, { args, cache, readField }) {
    const sourceId = readField({ fieldName: 'id' }) as string | null
    if (!sourceId) return existing
    const ids: null | undefined | readonly string[] = readField({
      fieldName: 'ids',
      typename: 'MusicLibrary',
      from: makeReference(
        cache.identify({
          __typename: 'MusicLibrary',
          id: args!.library,
        })!,
      ),
    })
    if (!ids) return existing
    return ids.includes(sourceId)
  },
}

typePolicies.Schedule.fields = {
  ...typePolicies.Schedule.fields,
  inMusicLibrary: inMusicLibraryPolicy,
}

typePolicies.Playlist.fields = {
  ...typePolicies.Playlist.fields,
  tracks: relayPagination(['market'], { all: true }), // tightly coupled with `updatePlaylistTracks()`
  inMusicLibrary: inMusicLibraryPolicy,
}

typePolicies.Playlist.merge = (existing, incoming, c) => {
  const updatedAtWas = existing ? c.readField('updatedAt', existing) : undefined
  const updatedAt = c.readField('updatedAt', incoming)
  if (updatedAtWas && updatedAt && updatedAtWas < updatedAt) {
    // Invalidate `playlist.tracks` where `playlist.tracks.updatedAt < incoming.updatedAt`
    setTimeout(() => {
      c.cache.modify({
        id: c.cache.identify({
          __typename: 'Playlist',
          id: c.readField('id', existing),
        }),
        fields: {
          tracks: (cached, ctx) => {
            if (
              !cached.updatedAt ||
              cached.updatedAt >= updatedAt ||
              // Optimization: avoid invalidating cache when (likely) triggered through `updatePlaylistTracks()`
              c.fieldName === 'spliceManualPlaylist'
            ) {
              return cached
            }
            return ctx.DELETE
          },
        },
      })
    }, 0)
  }
  return c.mergeObjects(existing, incoming)
}

// All browse pages for the same browse category share header image
// This makes sure we store it on the basepage so it's easy to resolve
// regardless of what tab is currently loaded
typePolicies.BrowsePage.merge = (existing, incoming, c) => {
  setTimeout(() => {
    const pageId = c.readField({
      fieldName: 'id',
      from: incoming,
    }) as string | null
    const headerImage = c.readField({
      fieldName: 'headerImage',
      from: incoming,
    }) as string | null
    if (!headerImage) return
    const category = c.readField({
      fieldName: 'browseCategory',
      from: incoming,
    }) as Readonly<Reference> | null
    if (!category) return
    const categoryId = c.readField({ fieldName: 'id', from: category }) as
      | string
      | null
    const cacheId = c.cache.identify({
      __typename: 'BrowsePage',
      id: categoryId,
    })
    // No need to write the base page header image if we are alrady storing it
    if (cacheId === pageId) return
    c.cache.writeFragment({
      id: cacheId,
      fragment: BrowsePageHeaderImageFragment,
      data: {
        __typename: 'BrowsePage',
        headerImage,
      },
    })
  }, 0)
  return c.mergeObjects(existing, incoming)
}

const BrowsePageHeaderImageFragment = graphql(/* GraphQL */ `
  fragment BrowsePageHeaderImage on BrowsePage {
    headerImage
  }
`)

typePolicies.Mutation.fields.createAnnouncement =
  typePolicies.Mutation.fields.updateAnnouncement = {
    merge: (existing, incoming, c) => {
      c.cache.modify({
        fields: {
          getAccountAnnouncements(entries: Reference[], d): any {
            const field = cachedFieldArgs(d)
            if (
              field.accountId !==
              (c.args?.options?.accountId || currentAccountId())
            ) {
              return
            }
            return addOrRemoveFromCachedList({
              ref: incoming.__ref,
              adding: d.readField('archived', incoming) ? false : 'start',
              array: entries,
            })
          },
        },
      })
      return c.mergeObjects(existing, incoming)
    },
  }

typePolicies.Mutation.fields.createAnnouncementCampaign =
  typePolicies.Mutation.fields.updateAnnouncementCampaign = {
    merge: (existing, incoming, c) => {
      c.cache.modify({
        fields: {
          getAccountCampaigns(entries: Reference[], d): any {
            const field = cachedFieldArgs(d)
            if (
              field.accountId !==
              (c.args?.options?.accountId || currentAccountId())
            ) {
              return
            }
            return addOrRemoveFromCachedList({
              ref: incoming.__ref,
              adding: d.readField('archived', incoming) ? false : 'start',
              array: entries,
            })
          },
        },
      })
      return c.mergeObjects(existing, incoming)
    },
  }

typePolicies.Mutation.fields.blockTrack = blockTrack
typePolicies.Mutation.fields.unblockTrack = unblockTrack

typePolicies.Query.fields.getTracksFromPrompt = relayStylePagination(['prompt'])
typePolicies.SoundZone.fields.playbackHistory = playbackHistory({
  onError: (msg) => console.error(msg),
})
