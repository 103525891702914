import { useQuery } from '@apollo/client'
import React from 'react'
import { graphql } from '#app/graphql/gql'
import { currentAccountSlice } from '#app/store/reducers'
import { useSelector } from '#app/store/redux'

/**
 * Custom hook that retrieves Spotify URIs from the music library.
 * @returns An object containing a Set of Spotify URIs and the spread of all things returned by useQuery.
 */
export function useSpotifyURIs() {
  // Keep track of imported playlists. They can be synced so only one instance should be imported.
  const account = useSelector(currentAccountSlice.selectors.id)!
  // NOTE: Seems to return empty data for staging.
  const query = useQuery(MusicLibraryGetPlaylistComposerTypeDoc, {
    variables: { account },
    fetchPolicy: 'cache-and-network',
  })

  const edges = query.data?.musicLibrary?.playlists?.edges
  const spotifyURIs = React.useMemo(() => {
    const spotifyURIs: string[] = []
    if (edges) {
      for (const edge of edges) {
        const node = edge.node
        if (node?.composer && 'spotifyPlaylistUri' in node.composer) {
          spotifyURIs.push(node.composer.spotifyPlaylistUri)
        }
      }
    }
    return spotifyURIs
  }, [edges])
  return { ...query, spotifyURIs }
}

export const MusicLibraryGetPlaylistComposerTypeDoc = graphql(/* GraphQL */ `
  query PlaylistsComposerType($account: ID!) {
    musicLibrary(id: $account) {
      id
      playlists(first: 1000) {
        edges {
          node {
            id
            composer {
              ... on ExternalSpotifyComposer {
                id
                spotifyPlaylistUri
              }
            }
          }
        }
      }
    }
  }
`)
