const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-settings" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/settings/overview.company-details.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ModalErrorView } from '#app/components/error/index';
import { allowedOr } from '#app/lib/authenticated';
export const Route = createFileRoute('/accounts/$accountId/settings/overview/company-details')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  errorComponent: ModalErrorView,
  beforeLoad: async () => {
    await allowedOr(['zone.activate'], '/accounts/$accountId/settings');
  }
});