import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client'
import type { IsoCountry } from '@soundtrack/playback/slice'
import { cachedFieldArgs } from '@soundtrackyourbrand/apollo-client'
import {
  TracklistFragmentDoc,
  TracklistTrackFragmentDoc,
} from '#app/components/track-list/graphql'
import {
  type Playlist_EditorSpliceMetaFragment,
  type Playlist_TracklistFragment,
  type SplicePlaylistInput,
  type UpdateManualPlaylistInfoInput,
} from '#app/graphql/graphql'
import { graphql } from '#app/graphql/index'
import { invalidateSimilarPlaylists } from '#app/modules/discover-music/source/similar-playlists-invalidator'

export const TRACKS_MAX_LIMIT = 3000

export function updatePlaylistTracks({
  input,
  apollo,
  market,
}: {
  input: SplicePlaylistInput
  apollo: ApolloClient<NormalizedCacheObject>
  market: IsoCountry
}) {
  const optimistic = optimisticUpdateResponse(input, market, apollo)
  return apollo.mutate({
    mutation: PlaylistEditorSpliceMutation,
    variables: { input, market },
    optimisticResponse: optimistic
      ? { __typename: 'Mutation', spliceManualPlaylist: optimistic }
      : undefined,
    update: (cache, { data }) => {
      const playlist = data?.spliceManualPlaylist
      if (!playlist) return
      const cacheId = apollo.cache.identify({
        __typename: 'Playlist',
        id: input.id,
      })
      const canUpdateInPlace = !!optimistic?.tracks
      const updatedAt = playlist.updatedAt
      if (canUpdateInPlace) {
        // Write optimistic `playlist.tracks` to cache since the mutation doesn't request it
        optimistic.tracks!.updatedAt = updatedAt
        // We need to provide the original `first` (before splicing) value to
        // `writeFragment` to tell the relayPagination logic to remove old edges
        const first =
          optimistic.tracks!.edges.length + input.length - input.trackIds.length
        cache.writeFragment({
          id: cacheId,
          fragment: TracklistFragmentDoc,
          fragmentName: 'Playlist_Tracklist',
          variables: { market, first },
          data: {
            ...optimistic,
            ...playlist,
          },
        })
      }
      // Invalidate all variations of `playlist.tracks` except for the one we just fetched
      cache.modify({
        id: cacheId,
        fields: {
          tracks: (existing, c) => {
            if (
              canUpdateInPlace &&
              cachedFieldArgs(c).market === market &&
              existing.updatedAt >= updatedAt
            ) {
              return existing
            }
            return c.DELETE
          },
        },
      })
      invalidateSimilarPlaylists(cache, playlist.id, input.trackIds)
    },
  })
}

/**
 * Generates an optimistic `Playlist` response for a `spliceManualPlaylist`
 * mutation, including updated `tracks` and `trackStatistics` fields.
 *
 * Expects all tracks nodes to be present in the apollo cache.
 * Returns `undefined` if unable to generate a fully resolvable response.
 */
export function optimisticUpdateResponse(
  input: SplicePlaylistInput,
  market: string,
  apollo: ApolloClient<NormalizedCacheObject>,
):
  | (Playlist_TracklistFragment & Playlist_EditorSpliceMetaFragment)
  | undefined {
  const cacheId = apollo.cache.identify({
    __typename: 'Playlist',
    id: input.id,
  })
  const cached = apollo.readFragment({
    id: cacheId,
    fragment: TracklistFragmentDoc,
    fragmentName: 'Playlist_Tracklist',
    variables: { market, first: 0 },
  })
  if (!cached?.tracks) return

  // Verify that `playlist.tracks` uses a compatible cursor format
  const cursorRegex = /^cursor:(\d+)\b/
  try {
    if (
      !cached.tracks.pageInfo.endCursor ||
      !cursorRegex.test(atob(cached.tracks.pageInfo.endCursor))
    ) {
      return
    }
  } catch (error: any) {
    return
  }

  const cursorFor = (existingCursor: string, index: number): string => {
    const newCursor = `cursor:${index}`
    return existingCursor
      ? btoa(atob(existingCursor).replace(cursorRegex, newCursor))
      : btoa(newCursor)
  }

  const now = new Date().toISOString()
  let allEdgesCached = true as boolean
  const edges = cached.tracks.edges.slice()
  edges.splice(
    input.start || 0,
    input.length,
    ...input.trackIds.map((id) => {
      const node = apollo.readFragment({
        fragment: TracklistTrackFragmentDoc,
        fragmentName: 'Track_Tracklist',
        id: apollo.cache.identify({ __typename: 'Track', id }),
      })
      if (!node && allEdgesCached) {
        allEdgesCached = false
      }
      return {
        __typename: 'PlaylistTracksEdge' as const,
        addedAt: now,
        cursor: '',
        node: node!, // We'll bail out if any `node` is falsy (ensured through `allEdgesCached`)
      }
    }),
  )

  if (!allEdgesCached) {
    return
  }

  // Re-generate cursors - existing objects are immutable and have to be cloned
  for (let i = 0; i < edges.length; i++) {
    const edge = edges[i]!
    edges[i] = { ...edge, cursor: cursorFor(edge.cursor, i) }
  }
  const stats = apollo.readFragment({
    id: cacheId,
    fragment: PlaylistEditorSpliceMetaFragment,
    fragmentName: 'Playlist_EditorSpliceMeta',
    variables: { market },
  })
  const total = cached.tracks.total + input.trackIds.length - input.length
  return {
    ...cached,
    updatedAt: now,
    tracks: {
      ...cached.tracks,
      pageInfo: {
        ...cached.tracks.pageInfo,
        startCursor: edges.at(0)?.cursor || null,
        endCursor: edges.at(-1)?.cursor || null,
      },
      total,
      updatedAt: now,
      edges,
    },
    trackStatistics: {
      __typename: 'TrackStatistics' as const,
      ...stats?.trackStatistics,
      total,
    },
  }
}

export async function updatePlaylistMetadata({
  input,
  market,
  apollo,
}: {
  input: UpdateManualPlaylistInfoInput
  market: IsoCountry
  apollo: ApolloClient<NormalizedCacheObject>
}) {
  const result = await apollo.mutate({
    mutation: PlaylistEditorMetadataMutation,
    variables: {
      input,
      market,
    },
  })
  return result.data?.updateManualPlaylist
}

export async function updateLegacySpotifyPlaylistMetadata({
  input,
  market,
  apollo,
}: {
  input: UpdateManualPlaylistInfoInput
  market: IsoCountry
  apollo: ApolloClient<NormalizedCacheObject>
}) {
  const result = await apollo.mutate({
    mutation: LegacySpotifyPlaylistMetadataMutation,
    variables: {
      input,
      market,
    },
  })
  return result.data?.updateSpotifyPlaylist
}

const PlaylistEditorSpliceMutation = graphql(/* GraphQL */ `
  mutation PlaylistEditorSplice(
    $input: SplicePlaylistInput!
    $market: IsoCountry!
  ) {
    spliceManualPlaylist(input: $input) {
      id
      snapshot
      updatedAt
      ...Playlist_EditorSpliceMeta
      # ...Playlist_LastUpdatedMeta
    }
  }
`)

const PlaylistEditorMetadataMutation = graphql(/* GraphQL */ `
  mutation PlaylistEditorMetadata(
    $input: UpdateManualPlaylistInfoInput!
    $market: IsoCountry!
  ) {
    updateManualPlaylist(input: $input) {
      id
      snapshot
      # ...Playlist_LastUpdatedMeta
      ...SourceViewPlaylist
    }
  }
`)

const LegacySpotifyPlaylistMetadataMutation = graphql(/* GraphQL */ `
  mutation LegacySpotifyPlaylistMetadata(
    $input: UpdateSpotifySyncedPlaylistInput!
    $market: IsoCountry!
  ) {
    updateSpotifyPlaylist(input: $input) {
      id
      snapshot
      # ...Playlist_LastUpdatedMeta
      ...SourceViewPlaylist
    }
  }
`)

const PlaylistEditorSpliceMetaFragment = graphql(/* GraphQL */ `
  fragment Playlist_EditorSpliceMeta on Playlist {
    trackStatistics(market: $market) {
      total
      totalDuration
      playable
      playableDuration
      explicit
    }
  }
`)
