const $$splitComponentImporter = () => import(/* webpackChunkName: "route-create" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/create/index.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';
export const Route = createFileRoute('/create/')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'create.meta'
  },
  validateSearch: zodSearchValidator(z.object({
    signup: z.coerce.string().optional()
  }))
});