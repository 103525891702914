import { NetworkStatus } from '@apollo/client'
import type { ResultOf } from '@graphql-typed-document-node/core'
import {
  type FetchAllOptions,
  useQueryTtl,
} from '@soundtrackyourbrand/apollo-client'
import type { Get } from '@soundtrackyourbrand/object-utils.js'
import { useEffect, useMemo, useRef } from 'react'
import { graphql } from '#app/graphql/gql'
import type {
  YourMusic_PlaylistFragment,
  YourMusic_ScheduleFragment,
} from '#app/graphql/graphql'
import {
  type SkeletonItem,
  createSkeletonItems,
} from '#app/lib/editorial/utils/createEditorial'
import useInfiniteScroll from '#app/lib/use-infinite-scroll'
import { useEvent } from './use-event'

export function yourMusicQueryTtlOptions({
  libraryId,
  initialCount = 40,
  fetchAll,
}: {
  libraryId: string
  initialCount?: number
  fetchAll?: FetchAllOptions
}) {
  return {
    // `first` is the number of items to fetch if not yet cached - all cached items will be shown
    // TODO: Figure out how to make refetches cover all fetched pages rather than only first 40 edges
    variables: { libraryId, first: initialCount },
    context: {
      fetchAll,
    },
    notifyOnNetworkStatusChange: true,
    ttl: '10m',
    errorPolicy: 'all',
  } as const
}

type YourMusicQueryDocument =
  | YourMusicPlaylistsDocument
  | YourMusicSchedulesDocument

export function useYourMusicData<
  QueryDocument extends YourMusicQueryDocument,
  FieldName extends 'schedules' | 'playlists',
  NodeType = YourMusicConnection<FieldName>,
>(args: {
  libraryId: string
  queryDocument: QueryDocument
  fieldName: FieldName
  /** Filter items by name? */
  searchString?: string
  /** Fetch all pages? (default = false) */
  shouldFetchAll?: boolean
  /** Initial number of items to fetch (default = 40) */
  initialCount?: number
  /** Disable loading of more results through infinite scroll? Can't be toggled after mounting */
  infiniteScroll?: boolean
}) {
  const {
    libraryId,
    queryDocument,
    fieldName,
    searchString,
    initialCount = 40,
    infiniteScroll = true,
  } = args
  const shouldFetchAll = args.shouldFetchAll || !!searchString?.trim()
  // `context.fetchAll` is for refetches triggered by apollo-client set to the value at the time of the most recent query triggered by `useQuery()`.
  // Since `fetchAll` may have changed since the last executed query we store it in a stable ref, ensuring that the most recent values are passed to FetchAllLink.
  const fetchAll = useRef({
    enabled: shouldFetchAll,
    path: `musicLibrary.${fieldName}`,
    perPage: 1000,
  })
  fetchAll.current.path = `musicLibrary.${fieldName}`
  const queryTtlOptions = yourMusicQueryTtlOptions({
    libraryId,
    fetchAll: fetchAll.current,
    initialCount,
  })
  const query = useQueryTtl(queryDocument, queryTtlOptions)
  const unchangedVars = libraryId === query.previousData?.musicLibrary?.id
  const displayedData =
    query.data || (unchangedVars ? query.previousData : null)
  const connection = displayedData?.musicLibrary?.[fieldName as string] as Get<
    ResultOf<YourMusicPlaylistsDocument>,
    'musicLibrary.playlists'
  >
  const pageInfo = connection?.pageInfo

  fetchAll.current.enabled =
    unchangedVars && query.data
      ? !query.data.musicLibrary?.[fieldName as string]?.pageInfo.hasNextPage
      : false

  const fetchingMore =
    query.networkStatus === NetworkStatus.fetchMore ||
    (unchangedVars && query.networkStatus === NetworkStatus.setVariables)
  const nodes: NodeType[] = useMemo(() => {
    const nodes: NodeType[] =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (connection?.edges?.map((p) => p.node) as any) || []
    if (!connection?.edges || fetchingMore) {
      nodes.push(...(createSkeletonItems(fetchingMore ? 6 : 12) as any))
    }
    if (!searchString) {
      return nodes
    }
    // TODO: Consider using fuzzysort + add support for sorting
    const lowerSearchString = searchString.toLowerCase().trimStart()
    return nodes.filter((source: any) => {
      return (
        source &&
        (source.__typename === 'Skeleton' ||
          (source.name || source.display?.title || '')
            .toLowerCase()
            .includes(lowerSearchString))
      )
    })
  }, [connection?.edges, fetchingMore, searchString])

  // Only use the latest correct data
  const canFetchMore = !query.loading && pageInfo?.hasNextPage
  const gridRef = useRef<HTMLDivElement>(null)
  if (infiniteScroll) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useInfiniteScroll({
      elementRef: gridRef,
      enabled: canFetchMore,
      thresholdY: 300,
      onUpdate: () => {
        return query.fetchMore({
          variables: { first: 1000, after: pageInfo?.endCursor },
        })
      },
    })
  }

  const fetchRemaining = useEvent(() => {
    if (!canFetchMore) return Promise.resolve()
    return query.fetchMore({
      context: {
        fetchAll: { path: `musicLibrary.${fieldName}`, perPage: 1000 },
      },
      variables: { after: pageInfo.endCursor },
    })
  })

  useEffect(() => {
    shouldFetchAll && fetchRemaining()
  }, [shouldFetchAll, canFetchMore, fetchRemaining])

  return {
    query,
    nodes,
    fetchRemaining,
    fetchingMore,
    searchString,
    gridRef,
    canFetchMore,
  } as const
}

export const YourMusicPlaylistFragmentDoc = graphql(/* GraphQL */ `
  fragment YourMusic_Playlist on Playlist {
    ...Displayable
    ...Playlist_List
  }
`)

export const YourMusicScheduleFragmentDoc = graphql(/* GraphQL */ `
  fragment YourMusic_Schedule on Schedule {
    ...Displayable
    ...Schedule_List
  }
`)

export const YourMusicPlaylistsDoc = graphql(/* GraphQL */ `
  query YourMusicPlaylists($libraryId: ID!, $first: Int!, $after: String) {
    musicLibrary(id: $libraryId) {
      id
      playlists(first: $first, after: $after, orderBy: { direction: DESC }) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            ...YourMusic_Playlist
          }
        }
      }
    }
  }
`)

export const YourMusicSchedulesDoc = graphql(/* GraphQL */ `
  query YourMusicSchedules($libraryId: ID!, $first: Int!, $after: String) {
    musicLibrary(id: $libraryId) {
      id
      ids
      schedules(first: $first, after: $after, orderBy: { direction: DESC }) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            ...YourMusic_Schedule
          }
        }
      }
    }
  }
`)

export type YourMusicPlaylistsDocument = typeof YourMusicPlaylistsDoc
export type YourMusicSchedulesDocument = typeof YourMusicSchedulesDoc

/** Playlist as rendered in the "your music" list view */
export type YourMusicPlaylist = YourMusic_PlaylistFragment

/** Schedule as rendered in the "your music" list view */
export type YourMusicSchedule = YourMusic_ScheduleFragment

export type YourMusicSource = YourMusicPlaylist | YourMusicSchedule

export type YourMusicConnection<FieldName extends 'playlists' | 'schedules'> =
  | (FieldName extends 'schedules' ? YourMusicSchedule : YourMusicPlaylist)
  | SkeletonItem
  | undefined
