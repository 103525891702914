import { Utils } from '@soundtrackyourbrand/capsule'
import { z } from 'zod'
import { t } from '#app/lib/i18n/index'
import tracking from '#app/lib/tracking'
import { ExpectedError } from './error'
import toast from './toast'

export type AuthorizeSpotifyOptions = {
  /** The callback path sent to Spotify */
  callbackPath?: string
  /** The scope used with authorization */
  scope?: string
  /** The client ID used with authorization */
  clientId?: string
  /** User context used for tracking */
  trackingContext?: any
}

const SPOTIFY_AUTH_RESULT = 'spotify_auth_result'

export default function authorize(options?: AuthorizeSpotifyOptions) {
  const {
    callbackPath = '/spotify-callback',
    scope = 'playlist-read-private playlist-read-collaborative user-follow-read user-top-read user-library-read user-read-recently-played',
    clientId = SYB.spotifyClientId,
    trackingContext = {},
  } = options || {}

  const requestId = Utils.Guid()
  // For the desktop app, use a special URI, which then forwards to a custom protocol URI that will be opened by Electron.
  const redirectUri = `${IS_ELECTRON ? 'https://open.soundtrack.app' : window.location.origin}${callbackPath}`
  const query = new URLSearchParams({
    redirect_uri: redirectUri,
    scope,
    response_type: 'code',
    state: requestId,
    show_dialog: 'true',
    ...(clientId && { client_id: clientId }),
  }).toString()

  const size = 700
  const left = (window.screen.width - size) / 2
  const top = (window.screen.height - size) / 2
  const popupWindow = window.open(
    `https://accounts.spotify.com/authorize?${query}`,
    t(`spotifyConnect.title`, false),
    `width=${size}, height=${size}, left=${left}, top=${top}`,
  )

  let cleanup: (() => void) | undefined

  return new Promise<{ code: string; redirectUri: string }>(
    (resolve, reject) => {
      tracking.track('Spotify Connect - Connect Intent', {
        ...trackingContext,
      })

      function handleAuthResult(result: SpotifyAuthResult) {
        if ((result.type as unknown) !== SPOTIFY_AUTH_RESULT) return
        if (result.requestId !== requestId) return
        if (result.error !== undefined) {
          toast.error(
            t([
              `spotifyToken.errorMessages.${result.error}`,
              'spotifyToken.errorMessages.unknown',
            ]),
          )
        } else if (result.data.code) {
          resolve({ code: result.data.code, redirectUri })
        }
      }

      // The callback url/data will come in via different mechanisms depending on if we are in the desktop app or in web.
      if (IS_ELECTRON) {
        cleanup = window.Syb?.onSpotifyCallbackUrl((url) => {
          if (URL.canParse(url)) {
            handleAuthResult(parseSpotifyAuthResultFromURL(new URL(url)))
          }
        })
      } else {
        // Can only check whether the window is closed if we're authorising from web.
        const checkWindowClosed = setInterval(() => {
          if (popupWindow?.closed) {
            reject(new ExpectedError('User aborted authorization process'))
          }
        }, 1000)

        const onAuthMessage = (event: MessageEvent<SpotifyAuthResult>) => {
          // Make sure we talking to the right window.
          if (event.origin === window.location.origin) {
            handleAuthResult(event.data)
          }
        }
        window.addEventListener('message', onAuthMessage, false)

        cleanup = () => {
          window.removeEventListener('message', onAuthMessage, false)
          popupWindow?.close()
          clearInterval(checkWindowClosed)
        }
      }
    },
  ).finally(cleanup)
}

/** Used by the router to parse and validate search params */
export const spotifyCallbackSearchSchema = z.object({
  state: z.coerce.string(),
  code: z.coerce.string().optional(),
  error: z.coerce.string().optional(),
})

export type SpotifyCallbackSearch = z.infer<typeof spotifyCallbackSearchSchema>

type SpotifyAuthData = {
  code: string
  tokenData: {
    access_token?: string
    expires_in: number
    token_type?: string
  }
}

export type SpotifyAuthResult = {
  type: typeof SPOTIFY_AUTH_RESULT
  requestId: string
} & (
  | { data?: undefined; error: string }
  | { data: SpotifyAuthData; error?: undefined }
)

export function parseSpotifyAuthResultFromURL(url: URL) {
  const search = Object.fromEntries(url.searchParams)
  return parseSpotifyAuthResult({
    search: spotifyCallbackSearchSchema.parse(search),
    hash: url.hash,
  })
}

export function parseSpotifyAuthResult({
  search,
  hash,
}: {
  search: SpotifyCallbackSearch
  hash: string
}): SpotifyAuthResult {
  const parsedHash = new URLSearchParams(hash)

  const type = SPOTIFY_AUTH_RESULT
  const requestId = parsedHash.get('state') || search.state || ''
  const error = parsedHash.get('error') || search.error

  if (error) return { type, requestId, error }

  const data: SpotifyAuthData = {
    code: search.code ?? '',
    tokenData: {
      access_token: parsedHash.get('access_token') ?? undefined,
      expires_in: parseInt(parsedHash.get('expires_in') ?? '', 10),
      token_type: parsedHash.get('token_type') ?? undefined,
    },
  }

  return { type, requestId, data }
}
