// eslint-disable-next-line no-restricted-imports
import type { DragTypes, DropEvent } from '@react-aria/dnd'
import type { useDispatch } from '#app/store/redux'
import { getDropHandlers } from './drop-handlers'
import {
  type DNDItem,
  type DNDItemType,
  getTypeFromMimeType,
  mappedMimeType,
} from './schema'

export async function handleDrop(
  target: DNDItem,
  dropEvent: DropEvent,
  dispatch: ReturnType<typeof useDispatch>,
) {
  // Extract and group all dropped items by type
  const grouped = await extractAndGroupDropItems(dropEvent)

  // For each group of items, call the corresponding drop handler
  Object.entries(grouped).forEach(([type, items]) => {
    if (items.length > 0) {
      getDropHandlers(target.__typename)[type]?.(target, items, dispatch)
    }
  })
}

/**
 * A map between the item types and the corresponding allowed item types to be dropped on it
 */
export function hasAllowedDropTypes(target: DNDItem, types: DragTypes) {
  const allowedTypes = Object.keys(getDropHandlers(target.__typename))
  return allowedTypes.some((type) => types.has(mappedMimeType[type]))
}

async function extractAndGroupDropItems(dropEvent: DropEvent) {
  // Get the contents of all dropped items as a flat array of promises

  const promises: Promise<[keyof typeof mappedMimeType, string]>[] = []

  for (const item of dropEvent.items) {
    if (item.kind !== 'text') {
      continue
    }

    for (const type of item.types) {
      const mappedType = getTypeFromMimeType(type)
      if (mappedType && mappedType !== 'Description') {
        promises.push(item.getText(type).then((text) => [mappedType, text]))
      }
    }
  }

  const resolvedEntries = await Promise.all(promises)

  // Group all items by type
  const grouped = resolvedEntries.reduce((acc, [type, item]) => {
    const group = (acc[type] ||= [])
    group.push(item)
    return acc
  }, {}) as Record<DNDItemType, string[]>

  return grouped
}
