import { Forms } from '@soundtrackyourbrand/ui'
import im from 'immutable'
import { t } from '#app/lib/i18n/index'

/**
 * Function to ensure business types are always in the "new" format.
 * This will ensure everything keeps working smoothly when we roll out the API changes.
 *
 * We're changing from the format {bar: 'Bar'} to {bar: {display_name: 'Bar', deprecated: false}})
 *
 * @param {im.Map<string, string|im.Map>} businessTypes Map of business type translations, coming from our backend
 * @returns {im.Map<string, im.Map>}
 */
export function ensureBusinessTypesFormat(businessTypes) {
  if (typeof businessTypes.first() === 'string') {
    businessTypes = businessTypes.map((displayName) =>
      im.Map({ display_name: displayName, deprecated: false }),
    )
  }
  return businessTypes
}

/**
 * Employs different strategies for showing the correct business type name.
 *
 * @param {string} businessType The business type to lookup (e.g. `bar` or `waiting_room`)
 * @param {im.Map<string, any>} i18n Map of business type translations, coming from our backend
 * @param {string} fallback Fallback to render in case all lookups fail
 * @returns {string}
 */
export function getBusinessTypeLabel(
  businessType,
  i18n,
  fallback = businessType,
) {
  return (
    ensureBusinessTypesFormat(i18n).getIn([businessType, 'display_name']) ||
    t(`values.businesses.${businessType}`, { defaultValue: fallback }, false)
  )
}

/**
 * Formats the businessTypes map into an Array with label and values, ready to be used in dropdowns.
 *
 * @param {im.Map<string, im.Map>} businessTypes
 * @returns {Array<{ label: string, value: string }>}
 */
export function getBusinessTypeOptions(businessTypes) {
  // todo: remove ensureBusinessTypesFormat() wrapper after launching the new format
  return ensureBusinessTypesFormat(businessTypes)
    .toSeq()
    .filter((type) => !type.get('deprecated'))
    .map((type, key) => ({ label: type.get('display_name'), value: key }))
    .valueSeq()
    .sortBy((entry) => {
      // Put "Other" at the bottom
      if (entry.value === 'other') {
        return 'zzz'
      }
      return Forms.Select.labelSort(entry)
    })
    .toArray()
}
