import { formatCurrency } from '#app/lib/i18n/index'
import { parseTier } from '#app/store/current-account'

/**
 * Returns the price the customer will first be paying. If there's a reduced-price trial this price
 * will be returned. If there's a free trial, the regular price will be returned.
 *
 * @param {im.Map} pricing
 * @returns {string|null} The formatted currency, e.g. "299 kr" or "$29.99"
 */
export function getInitialZonePrice(pricing) {
  const priceListEntity = pricing
    .get('price_list')
    .find(
      (price) =>
        price.get('billing_cycle') === 'yearly' &&
        parseTier(price.get('tier')) < 3,
    )
  if (!priceListEntity) {
    return null
  }
  const currency = pricing.get('iso_currency')
  const hasFreeTrial = priceListEntity.get('trial_days') > 0
  const regularPrice = getRegularPrice(pricing, false)

  return formatCurrency(
    hasFreeTrial ? regularPrice : priceListEntity.get('prices').first(),
    currency,
    true,
  )
}

/**
 * Returns the regular price the customer will be paying.
 *
 * @param {im.Map} pricing
 * @param {boolean} format
 * @returns {string|null} The formatted currency, e.g. "299 kr" or "$29.99"
 */
export function getRegularPrice(pricing, format = true) {
  const priceListEntity = pricing
    .get('price_list')
    .find(
      (price) =>
        price.get('billing_cycle') === 'yearly' &&
        parseTier(price.get('tier')) < 3,
    )
  if (!priceListEntity) {
    return null
  }
  const currency = pricing.get('iso_currency')
  const regularPrice = priceListEntity.get('prices').reduce(
    (price, f) => {
      return f > price ? f : price
    },
    priceListEntity.getIn(['prices', 0]),
  )

  return !format ? regularPrice : formatCurrency(regularPrice, currency, true)
}

export function getAvailableTiers(priceList, returnCycles = false) {
  const availableTiers = (priceList || []).reduce((tiers, price) => {
    if (price.get('plan') === 'starter') return tiers

    const tier = price.get('tier')
    const billingCycle = price.get('billing_cycle')
    if (returnCycles) {
      tiers[tier] ||= { tier }
      tiers[tier][billingCycle] = price
    } else {
      tiers[tier] = tier
    }

    return tiers
  }, {})
  return Object.values(availableTiers).sort((a, b) => {
    return returnCycles ? b.tier.localeCompare(a.tier) : b.localeCompare(a)
  })
}

export function getAvailablePlans(priceList, returnCycles = false) {
  const availablePlans = (priceList || []).reduce((plans, price) => {
    const plan = price.get('plan')
    const billingCycle = price.get('billing_cycle')
    if (returnCycles) {
      plans[plan] ||= { plan }
      plans[plan][billingCycle] = price
    } else {
      plans[plan] = plan
    }

    return plans
  }, {})

  return Object.values(availablePlans)
}
