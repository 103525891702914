const $$splitComponentImporter = () => import(/* webpackChunkName: "route-electron" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/electron/select.$accountId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensurePaddedId } from '#app/lib/urls';

// This route exists to allow for route param $accountId,
// but is handled entirely by its parent `select.tsx`

export const Route = createFileRoute('/electron/select/$accountId')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: ({
    params
  }) => {
    ensurePaddedId(params, 'accountId');
  }
});