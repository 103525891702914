import type { Displayable, Track } from '#app/graphql/graphql'
import { formattedArtists } from '#app/lib/artists'
import type { DNDItem } from '../schema'
import type { DragData } from './types'

/**
 * Get default drag data for a dragged items
 */
export function getDefaultDragData(item: DNDItem): DragData | null {
  let description = getTitle(item)

  if (isTrack(item)) {
    description = `${item.display?.title} - ${formattedArtists(item.artists, { joiner: ', ' })}`
  }

  return {
    description,
    id: item.id,
    type: item.__typename,
  }
}

/**
 * Get some displayable title for the item
 */
function getTitle(item: DNDItem): string {
  if (isDisplayable(item) && item.display?.title) {
    return item.display.title
  }

  if ('title' in item && typeof item.title === 'string') {
    return item.title
  }

  return item.id
}

function isDisplayable(item: DNDItem): item is Displayable & DNDItem {
  return 'display' in item
}

function isTrack(item: DNDItem): item is Track {
  return item.__typename === 'Track'
}
