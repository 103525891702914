import { useMutation } from '@apollo/client'
import { graphql } from '#app/apollo/index'
import useAbortController from '#app/hooks/use-abort-controller'

export default function useSavePlaylistsToProfile() {
  const { abortSignal, abort } = useAbortController()
  const [saveToProfile, queryData] = useMutation(UpdateTasteProfileDoc, {
    context: {
      fetchOptions: {
        signal: abortSignal(),
      },
    },
  })

  return {
    saveToProfile,
    queryData: {
      ...queryData,
      abort,
    },
  }
}

export const UpdateTasteProfileDoc = graphql(/* GraphQL */ `
  mutation UpdateTasteProfile(
    $playlistIds: [String!]!
    $source: String!
    $trackingId: String
    $context: String
  ) {
    writeTasteProfile(
      playlistsIds: $playlistIds
      source: $source
      trackingId: $trackingId
      context: $context
    )
  }
`)
