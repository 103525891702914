import { redirect } from '@tanstack/react-router'
import {
  VENDORS,
  isSupportedVendor,
} from '#app/components/pairing-guide/vendors'
import toast from '#app/lib/toast'

/** Throws a redirect if the vendor is invalid */
export function ensureValidVendor(
  vendor: string,
): asserts vendor is keyof typeof VENDORS {
  if (!isSupportedVendor(vendor)) {
    toast.error(`Pairing attempt with unknown vendor: ${vendor}`)
    throw redirect({ to: '/home' })
  }
}

export function ensureAllowSoftPair(vendor: keyof typeof VENDORS) {
  if (!VENDORS[vendor].allowSoftPair) {
    toast.error(`Missing pairing code`)
    throw redirect({ to: '/home' })
  }
}
