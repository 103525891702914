import { createFileRoute, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/accounts/$accountId/your-music/playlists/$musicId')({
  beforeLoad: ({
    params
  }) => {
    throw redirect({
      to: '/discover/music/$musicId',
      params,
      replace: true,
      mask: {
        to: Route.fullPath,
        params
      }
    });
  }
});