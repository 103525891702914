/*
 * Groups and counts the items in {Array}
 *
 * @param {Array} xs the list to count frequencies in
 * @return {Object}
 *
 * @example
 * groupBy([1, 1, 2, 1, 1]) // => [{value: 1, count: 4}, {value: 2, count: 1}]
 * */
export const groupBy = (list) => {
  const m = list.reduce((xs, x) => {
    xs[x] = (xs[x] || 0) + 1
    return xs
  }, {})

  return Object.keys(m).map((k) => ({ count: m[k], value: k }))
}
