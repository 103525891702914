const $$splitComponentImporter = () => import(/* webpackChunkName: "route-discover" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/discover/music.$musicId.edit.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { loggedInOr } from 'app/route-lib';
export const Route = createFileRoute('/discover/music/$musicId/edit')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: ({
    location
  }) => {
    loggedInOr('..', location);
  },
  staticData: {
    meta: 'musicRoute.editMeta'
  }
});