import { useSelector } from '#app/store/redux';
import * as flagsSlice from './store';
/**
 * Returns a single flag based on its name.
 *
 * Example:
 * ```
 * const newCreateFlow = useFlag('new-create-flow')
 * if (newCreateFlow.enabled) {
 *  // ...
 * }
 * ```
 */
export function useFlag(flagName, options) {
  return useSelector(state => flagsSlice.selectors.flag(state, flagName, options));
}

/**
 * Returns whether a single flag is enabled.
 *
 * Example:
 * ```ts
 * const newFlowEnabled = useFlagEnabled('new-create-flow')
 * if (newFlowEnabled) {
 *  // ...
 * }
 * ```
 */
export function useFlagEnabled(name, options) {
  return useSelector(state => flagsSlice.selectors.flagEnabled(state, name, options));
}