const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-settings" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/settings/subscriptions/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { allowedOr } from '#app/lib/authenticated';
export const Route = createFileRoute('/accounts/$accountId/settings/subscriptions')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'account.settings.tabs.subscriptions.meta',
    accountSwitch: '~/settings/subscriptions'
  },
  beforeLoad: async () => {
    await allowedOr(['zone.activate'], '/accounts/$accountId/settings');
  }
});