import { isTruthy } from '@soundtrack/utils/typePredicates'
// eslint-disable-next-line no-restricted-imports
import type { DragItem } from 'react-aria'
import {
  type DNDItem,
  type DNDItemType,
  DNDTypeSchema,
  type MaybeDNDItem,
  getTypeFromMimeType,
  mappedMimeType,
} from '../schema'
import { getDefaultDragData } from './default-drag-data'
import type { DragData } from './types'

export function isDNDItem(item: MaybeDNDItem): item is DNDItem {
  const result = DNDTypeSchema.safeParse(item.__typename)

  return result.success && typeof item.id === 'string'
}

export function dragDataContainsType(
  type: DNDItemType,
  dragData: DNDItemType[] | null,
) {
  if (!dragData) {
    return false
  }

  return dragData.includes(type)
}

export function getDragData(dragData: DragData, textValue: string): DragItem {
  return {
    [mappedMimeType[dragData.type]]: dragData.id,
    [mappedMimeType['Description']]: dragData.description,
    'text/plain': textValue,
  }
}

export function getDragItems(item: DNDItem): DragItem[] {
  const data = getDefaultDragData(item)

  if (!data) {
    return []
  }

  return [getDragData(data, item.id)]
}

export function getDragTypes(data: DragItem | DragItem[] | readonly string[]) {
  const items = Array.isArray(data)
    ? data.flatMap((item) =>
        typeof item === 'string' ? item : Object.keys(item),
      )
    : Object.keys(data)

  return items
    .map((mimeType) => getTypeFromMimeType(mimeType))
    .filter<keyof typeof mappedMimeType>(isTruthy)
}
