const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-your-music" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/your-music_.$musicId.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
import { createFileRoute, redirect } from '@tanstack/react-router';

// This lives outside of the your-music/ folder and is named `your-music_` to
// break out of the layout structure of Your Music routes.

// TODO: Should we instead have all playlists directly live at /playlists/$id instead?
// Or is there some added value in having the same detail pages under different URLs?

export const Route = createFileRoute('/accounts/$accountId/your-music_/$musicId')({
  beforeLoad: ({
    params
  }) => {
    // Assume this is an invalid route rather than a requested playlist
    if (params.musicId.length < 10) {
      // Leave throwing of `notFound()` to the loader so we can show an error message and preserve template hierarchy
      return;
    }

    // Also redirect to the correct URL if there's a sub-path, e.g. /edit
    // The sub-path must be the same between the your-music and discover music routes.
    const subPath = params['**'] ? `/${params['**']}` : '';
    throw redirect({
      to: `/discover/music/$musicId${subPath}`,
      params,
      replace: true,
      mask: {
        to: Route.fullPath,
        params
      }
    });
  },
  loader: lazyFn($$splitLoaderImporter, 'loader')
});