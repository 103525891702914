// eslint-disable-next-line no-restricted-imports
import {
  type DropOptions,
  type DropResult,
  useDrag,
  useDrop as useReactAriaDrop,
} from '@react-aria/dnd'
import { type DragEventHandler, useContext } from 'react'
import tracking from '#app/lib/tracking'
import { useDispatch } from '#app/store/redux'
import { useDragContext } from './context'
import { getDragItems, getDragTypes, isDNDItem } from './internal/drag/utils'
import { handleDrop, hasAllowedDropTypes } from './internal/handle-drop'
import { type MaybeDNDItem } from './internal/schema'

type ItemDropOptions = {
  isEnabled?: boolean
} & Pick<DropOptions, 'ref' | 'onDrop'>

/**
 * Enable dropping things on an item
 *
 * @target item
 * @returns DropResult
 */
export function useDropTarget(
  target: MaybeDNDItem,
  options: ItemDropOptions,
): DropResult {
  const { isEnabled = true, onDrop, ref } = options
  const trackingContext = useContext(tracking.UsageContext)
  const dispatch = useDispatch()

  const onDropWrapper: DropOptions['onDrop'] = (event) => {
    tracking.track('Drag & Drop - Dropped Item', {
      dropped: target,
      ...trackingContext,
    })

    if (isDNDItem(target)) {
      handleDrop(target, event, dispatch)
    }

    return onDrop?.(event)
  }

  return useReactAriaDrop({
    isDisabled: !isEnabled,
    onDrop: onDropWrapper,
    getDropOperation: (types, allowedOperations) => {
      if (
        isDNDItem(target) &&
        hasAllowedDropTypes(target, types) &&
        allowedOperations.includes('copy')
      ) {
        return 'copy'
      }
      return 'cancel'
    },
    ref,
  })
}
/**
 * Enable dragging a single item
 *
 * @returns dragProps - Props to spread onto the draggable element
 */
export function useDraggableItem(
  item: MaybeDNDItem,
  { isEnabled = true }: { isEnabled?: boolean } = {},
) {
  const { previewRef, startDrag } = useDragContext()
  const trackingContext = useContext(tracking.UsageContext)

  const dragItems = isEnabled && isDNDItem(item) ? getDragItems(item) : []
  const getItems = () => dragItems

  const isDisabled = !isEnabled || dragItems.length === 0
  const { dragProps } = useDrag({
    isDisabled,
    getItems,
    preview: previewRef,
    onDragStart: () => {
      startDrag?.(getDragTypes(dragItems))
      tracking.track('Drag & Drop - Begin drag', {
        dragged: item,
        ...trackingContext,
      })
    },
  })

  // We need to wrap the onDragStart handler to prevent the default behavior
  // of the browser, before it reaches the onDragStart handler called in `useDrag`,
  // since that event doesn't have the `preventDefault` method, and could cause
  // an issue with duplicate `text/plain` data in the drag event.
  const onDragStart: DragEventHandler<HTMLElement> = (e) => {
    // If the item is disabled for some reason, prevent any default drag behavior
    if (isDisabled) {
      e.preventDefault()
      return
    }

    // If the user drags a link, the browser will by default add
    // the URL to the drag data in text/plain. This collides with
    // our custom drag data, so we clear it here.
    if (e.dataTransfer.types.includes('text/plain')) {
      e.dataTransfer.clearData('text/plain')
    }
    dragProps.onDragStart?.(e)
  }

  return isDisabled
    ? { draggable: false, onDragStart }
    : {
        ...dragProps,
        onDragStart,
      }
}
