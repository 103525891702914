const spotifyBaseUrlPattern = new RegExp('^(https?://)?open.spotify.com/')
const searchParamsPattern = new RegExp('[?#].*$')

// List of Spotify types we'll try to parse from urls, add as needed:
const spotifyTypes = [
  'user',
  'artist',
  'album',
  'track',
  'playlist',
  'genre',
  'podcast',
] as const

export type SpotifyType = (typeof spotifyTypes)[number]
export type SpotifyToken<T extends SpotifyType = SpotifyType> = {
  type: T
  value: string
}

const isType = (str: string): str is SpotifyType =>
  spotifyTypes.includes(str as SpotifyType)

/** Verify whether a given {@link SpotifyToken} describes a particular {@link SpotifyType}. */
export function isTokenOfType<T extends SpotifyType>(
  type: T,
  token: SpotifyToken,
): token is SpotifyToken<T> {
  return token.type === type
}

/**
 * Try to extract a {@link SpotifyToken} collection (ordered) from a given URL if it looks like a Spotify url.
 * If it doesn't look like a Spotify url, return `null` instead.
 *
 * @examples
 * - `https://open.spotify.com/track/2aBpM2g1EsuNcSjsUqcOeH`
 * - `https://open.spotify.com/user/carlsagan/playlist/37i9dQZF1E8ENVzLGMb8Zz`
 */
export function tokeniseSpotifyUrl(url: string): SpotifyToken[] | null {
  if (!spotifyBaseUrlPattern.test(url)) return null
  const parts = url
    .replace(spotifyBaseUrlPattern, '')
    .replace(searchParamsPattern, '')
    .split('/')
    .filter(Boolean)
  const tokens: SpotifyToken[] = []

  while (parts.length > 0) {
    const type = parts.shift()
    const value = parts.shift()
    if (!value || !type || !isType(type)) return null
    tokens.push({ type, value })
  }

  return tokens
}

/**
 * Construct a legacy Spotify URI from a {@link SpotifyToken} collection.
 * @legacy Spotify primarily uses URLs nowadays, but our backend still expects a URI for e.g. playlists
 */
export function toSpotifyURI(tokens: SpotifyToken[]): string {
  return `spotify:${tokens
    .map((token) => `${token.type}:${token.value}`)
    .join(':')}`
}

/**
 * Construct a Spotify URL from a playlist URI (spotify:playlist:...)
 * If the input is not a playlist URI it will be returned as is.
 */
export function spotifyPlaylistUriToURL(uri: string): string {
  if (!uri.startsWith('spotify:playlist:')) {
    return uri
  }
  return `https://open.spotify.com/playlist/${uri.slice(17)}`
}
