import { ModalDialog, type ModalDialogProps } from '@soundtrackyourbrand/ui'
import { useRouter } from '@tanstack/react-router'
import { createElement, memo, useEffect } from 'react'
import { visibleModals } from '#app/store/modals'
import { useSelector, useStore } from '#app/store/redux'

export default memo(ModalRenderer)

function ModalRenderer() {
  const store = useStore()
  const modals = useSelector(visibleModals)
  const router = useRouter()

  // Close modals when the URL pathname changes
  useEffect(() => {
    router.subscribe('onBeforeNavigate', ({ pathChanged }) => {
      if (!pathChanged) {
        return
      }
      // Close modals in reverse order (topmost first)
      const modals = visibleModals(store.getState())
      for (let i = modals.length - 1; i >= 0; i--) {
        if (modals[i]?.options.closeOnNavigation) {
          modals[i]!.close()
        }
      }
    })
  }, [store, router])

  return modals.map((item) => {
    const props = {
      key: item.id,
      ...item.props,
      onClose: item.close,
    } as Record<string, any>

    if (item.component === ModalDialog) {
      props.onOutcome = (
        ...args: Parameters<ModalDialogProps['onOutcome']>
      ) => {
        ;(item.props as ModalDialogProps | null)?.onOutcome(...args)
        item.close()
      }
    }

    return createElement(item.component, props)
  })
}
