import { useEffect, useRef } from 'react';
import { getData, matchesDataType } from '#app/lib/native-drag-source';
import { useLatest } from '#app/lib/use-latest';
/**
 * A handle for listening to {@link DragEvent DragEvents} on the window.
 * @IDEA Allow passing a specific target element to listen on.
 */
export function DragDropWrapper(props) {
  const propsRef = useLatest(props);
  // Drag enter and exit events fire in a nested way from the DOM, so we need to keep track of the nesting depth.
  const isDraggingCount = useRef(0);
  useEffect(() => {
    const isValidEvent = event => !propsRef.current.disabled &&
    // Don't interfere with react-dnd items (application/json payloads)
    event.dataTransfer?.types[0] !== 'application/json' &&
    // Ignore any item type we know for sure we can't handle in the parser.
    matchesDataType(propsRef.current.parser, event.dataTransfer);
    const handleDragOver = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    const handleDragEnter = event => {
      if (!isValidEvent(event)) return;
      event.preventDefault();
      event.stopPropagation();
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'copy';
      }
      isDraggingCount.current++;
      if (isDraggingCount.current === 1) {
        propsRef.current.onEnter?.(event);
      }
    };
    const handleDragLeave = event => {
      if (!isValidEvent(event)) return;
      event.preventDefault();
      event.stopPropagation();
      isDraggingCount.current--;
      if (isDraggingCount.current <= 0) {
        propsRef.current.onLeave?.(event);
      }
    };
    const handleDrop = event => {
      if (!isValidEvent(event)) return;
      event.preventDefault();
      event.stopPropagation();
      isDraggingCount.current = 0;
      const value = getData(propsRef.current.parser, event.dataTransfer);
      if (value !== null) {
        propsRef.current.onDrop(event, value);
      } else {
        propsRef.current.onDropFailed?.(event);
      }
    };
    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);
    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, [propsRef]);
  return null;
}