import * as React from 'react'
import { findScrollParent } from '#app/lib/scroll'

/**
 * Triggers `onUpdate` when scrolling within the given threshold(s).
 *
 * Thresholds are specified in pixels, and providing `-1` will disable that axis.
 */
export default function useInfiniteScroll({
  elementRef,
  onUpdate,
  enabled = true,
  thresholdX = -1,
  thresholdY = 100,
  interval = 100,
}: {
  /** The HTML element that contains the rendered items */
  elementRef: React.RefObject<HTMLElement>
  /** Callback fired on scroll within threshold(s) */
  onUpdate: (params: {
    horizontal: boolean
    vertical: boolean
    x: number
    y: number
  }) => unknown
  /** Controls the firing of `onUpdate`, should be set to `false` while fetching and when there is nothing more to fetch */
  enabled?: boolean
  /** Pixels from the right edge */
  thresholdX?: number
  /** Pixels from the bottom edge */
  thresholdY?: number
  /** Interval (in ms) between threshold checks */
  interval?: number
}) {
  const pendingRef = React.useRef<null | Promise<any>>(null)
  const callbackRef = React.useRef(onUpdate)
  React.useEffect(() => {
    callbackRef.current = onUpdate
  }, [onUpdate])

  React.useEffect(() => {
    const element = elementRef.current
    if (!enabled || !element) {
      return
    }
    const scrollParent = findScrollParent(element)

    const tick = () => {
      if (pendingRef.current) return
      // TODO: Compensate for potential <body> offset (due to disabled scrolling for example)
      /*
      const bodyOffset = scrollParent === documentElement
        ? parseInt(document.body.style.top || '0', 10)
        : 0
      */
      // Calculate distances from the right/bottom edges of the elementRef to
      // the visible right/bottom of the scrollParent
      let x =
        element.offsetWidth -
        (scrollParent.scrollLeft + scrollParent.clientWidth)
      let y =
        element.offsetHeight -
        (scrollParent.scrollTop + scrollParent.clientHeight)
      let offsetParent = element as HTMLElement | null
      outerLoop: while (offsetParent && offsetParent !== scrollParent) {
        x += offsetParent.offsetLeft
        y += offsetParent.offsetTop
        // Traverse up from the offsetParent to the newOffsetParent to check if we would've jumped over scrollParent. If so, bail.
        const newOffsetParent = offsetParent.offsetParent as HTMLElement
        while (offsetParent && offsetParent !== newOffsetParent) {
          offsetParent = offsetParent.parentElement
          if (offsetParent === scrollParent) break outerLoop
        }
        offsetParent = newOffsetParent
      }
      const horizontal = thresholdX >= 0 && thresholdX >= x
      const vertical = thresholdY >= 0 && thresholdY >= y
      if (horizontal || vertical) {
        const result = callbackRef.current({ horizontal, vertical, x, y })
        if (
          result &&
          typeof result === 'object' &&
          'finally' in result &&
          typeof result.finally === 'function'
        ) {
          pendingRef.current = result as Promise<any>
          result.finally(() => {
            if (result === pendingRef.current) {
              pendingRef.current = null
            }
          })
        }
      }
    }

    let intervalId

    const startChecking = () => {
      intervalId = setInterval(tick, interval)
      scrollParent.removeEventListener('scroll', startChecking)
    }

    scrollParent.addEventListener('scroll', startChecking)
    return () => {
      clearInterval(intervalId)
      scrollParent.removeEventListener('scroll', startChecking)
    }
  }, [elementRef, enabled, interval, thresholdX, thresholdY])
}
