import { createSelector } from './redux'

export default {
  addRecentSearch: (item) => ({
    type: 'ADD_RECENT_SEARCH',
    item: {
      ...item,
      _addedAt: new Date().toISOString(),
    },
  }),
  clearRecentSearches: () => ({ type: 'CLEAR_RECENT_SEARCHES' }),
}

export function reducer(state, action) {
  if (!state) {
    state = {
      recentSearches: [],
      layouts: {},
    }
  }

  switch (action.type) {
    case 'ADD_RECENT_SEARCH':
      return {
        ...state,
        recentSearches: [
          action.item,
          ...state.recentSearches.filter((item) => item.id !== action.item.id),
        ].slice(0, 10),
      }
    case 'CLEAR_RECENT_SEARCHES':
      return {
        ...state,
        recentSearches: [],
      }
  }

  return state
}

export const selectors = {
  /** Returns recent searches newer than 7 days by request from Content Services */
  recentSearches: createSelector(
    [(state) => state.searchPersisted],
    (searchPersisted) => {
      const cutoff = Date.now() - 60_000 * 60 * 24 * 7 // 7 days
      return searchPersisted.recentSearches?.filter(
        (item) => item._addedAt && new Date(item._addedAt).getTime() > cutoff,
      )
    },
  ),
}
