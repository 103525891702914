const $$splitLoaderImporter = () => import(/* webpackChunkName: "route-misc" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/(misc)/legal-certificate.print.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
const $$splitComponentImporter = () => import(/* webpackChunkName: "route-misc" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/(misc)/legal-certificate.print.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensureLoggedIn } from '#app/route-lib';
export const Route = createFileRoute('/(misc)/legal-certificate/print')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: ({
    location
  }) => {
    ensureLoggedIn(location);
  },
  loader: lazyFn($$splitLoaderImporter, 'loader')
});