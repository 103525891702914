import { local } from '@soundtrackyourbrand/browser-storage.js'
import { getIn, setIn } from '@soundtrackyourbrand/object-utils.js'
import extend from 'extend'
import download from 'in-browser-download'
import Backend from './backend'

export default {
  type: 'backend',

  init(services, options, i18nextOptions) {
    this.backend = new Backend(services, options, i18nextOptions)

    // Proxy parent backend methods
    /*
      'read readMulti create'.split(' ').forEach(method => {
        this[method] = (...args) => this.backend[method].apply(this.backend, args)
      })
    */

    this.services = services
    this.options = {
      cachePrefix: 'i18n_res_',
      ...options,
    }

    // In memory cache of original and edited translations
    this.original = {}
    this.edited = {}

    // this.services.on('languageChanged', this.loadChanges.bind(this))
  },

  get prefix() {
    return this.options.cachePrefix
  },

  read(lang, ns, callback) {
    this.backend.read(lang, ns, (err, original) => {
      if (err) {
        return callback(err, null)
      }

      setIn(this.original, [lang, ns], original)

      const edited = this.loadLanguage(lang)
      const merged = extend(true, {}, original, edited[ns])

      // Remove immutable momentLocale data
      if (getIn(this.original, [lang, ns, 'momentLocale'])) {
        delete this.original[lang][ns].momentLocale
      }

      callback(null, merged)
    })
  },

  loadLanguage(lang) {
    if (!this.edited[lang]) {
      const langKey = this.prefix + lang
      const langJson = local.getItem(langKey)
      if (langJson) {
        this.edited[lang] = JSON.parse(langJson)
        /*
          for (let ns in langData) {
            // Save original before applying local changes
            this.getOriginals(lang, ns)
            this.services.resourceStore.addResourceBundle(
              lang, ns, langData[ns], true, true
            )
          }
        */
        console.info(`[i18n/editable-backend] Loaded changes from ${langKey}`)
      } else {
        this.edited[lang] = {}
        console.info(`[i18n/editable-backend] No changes in ${langKey}`)
      }
    }
    return this.edited[lang]
  },

  persistLanguage(lang) {
    local.setItem(this.prefix + lang, JSON.stringify(this.edited[lang] || {}))
    console.info(`[i18n/editable-backend] ${this.prefix + lang} saved`)
  },

  discardLanguage(lang) {
    delete this.edited[lang]
    local.removeItem(this.prefix + lang)
    for (const ns in this.original[lang]) {
      this.services.resourceStore.addResourceBundle(
        lang,
        ns,
        this.original[lang][ns],
        true,
        true,
      )
    }
  },

  exportLanguage(lang) {
    const json = JSON.stringify(this.edited[lang], null, 2)
    download(json, `${this.prefix + lang}.json`)
  },

  getOriginal(lang, ns, ...path) {
    return getIn(this.original, this.getPath(lang, ns, path))
  },

  getEdit(lang, ns, ...path) {
    return getIn(this.edited, this.getPath(lang, ns, path))
  },

  updateEdit(lang, ns, key, translation) {
    const path = this.getPath(lang, ns, key)

    if (getIn(this.original, path) === translation) {
      this.removeEdit(lang, ns, key)
    } else {
      setIn(this.edited, path, translation)
      this.services.resourceStore.addResource(lang, ns, key, translation)

      console.info('[i18n/editable-backend] update', {
        lang,
        ns,
        key,
        translation,
      })
      this.persistLanguage(lang)
    }
  },

  removeEdit(lang, ns, key) {
    const path = this.getPath(lang, ns, key)

    // Reset i18next resource
    this.services.resourceStore.addResource(
      lang,
      ns,
      key,
      getIn(this.original, path),
    )

    // Remove from in-memory cache
    const tail = path[path.length - 1]
    const parent = getIn(this.edited, path.slice(0, path.length - 1))
    if (parent && parent[tail] != null) {
      delete parent[tail]
    }

    console.info('[i18n/editable-backend] remove', { lang, ns, key })
    this.persistLanguage(lang)
  },

  getPath(lang, ns, key) {
    return [lang, ns].concat(Array.isArray(key) ? key : key.split('.'))
  },
}
