/**
 * Format timezone offset in minutes into a RFC3339 compatible timezone string
 *
 * @default new Date().getTimezoneOffset() // Local timezone when the app was started
 */
export function formatTimezone(
  offset: number = new Date().getTimezoneOffset(),
) {
  if (offset === 0) {
    return 'Z'
  }
  const abs = Math.abs(offset)
  const hours = Math.floor(abs / 60)
    .toString()
    .padStart(2, '0')
  const minutes = (abs % 60).toString().padStart(2, '0')
  // Offset has the opposite sign of the timezone. For example CEST (UTC+2)
  // should be +0200 but has offset -120
  const sign = offset < 0 ? '+' : '-'
  return `${sign}${hours}:${minutes}`
}
