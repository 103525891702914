const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-zones" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/zones/create.$locationId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensurePaddedId } from '#app/lib/urls';
export const Route = createFileRoute('/accounts/$accountId/zones/create/$locationId')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: ({
    params
  }) => {
    ensurePaddedId(params, 'locationId');
  }
});