import {
  type PayloadAction,
  type UnknownAction,
  createSlice,
} from '@reduxjs/toolkit'
import { Models } from '@soundtrackyourbrand/capsule'
import type {
  EditorialPage,
  EditorialSectionEdge,
} from '#app/lib/editorial/types'
import {
  createEditorialPage,
  createEditorialSection,
  createSkeletonItems,
} from '#app/lib/editorial/utils/createEditorial'
import { useStore } from '#app/store/redux'
import type { RootState } from '.'

type UpdateSkeletonLayoutPayload = {
  layoutKey: string
  data: EditorialPage
  options?: {
    /**
     * Normally actual items are replaced with a skeleton, set this
     * to true to skip that and keep the actual items.
     */
    keepItems?: boolean
    /**
     * Opt in to clear headings in skeleton views
     */
    clearSkeletonHeadings?: boolean
    /**
     * Set to true to not clear this layout on logout
     */
    surviveLogout?: boolean
    alias?: string
    removeTitles?: boolean
    forceTabs?: boolean
  }
}

const slice = createSlice({
  name: 'editorial',

  initialState: {
    layouts: {} as Record<string, EditorialPage | undefined>,
    stickyLayouts: [] as Array<string>,
    layoutAliases: {} as Record<string, string>,
  },

  reducers: {
    updateSkeletonLayout(
      state,
      action: PayloadAction<UpdateSkeletonLayoutPayload>,
    ) {
      try {
        const { layoutKey, options, data } = action.payload
        const edges = data.sections.edges
        const layout = { ...action.payload.data }

        if (edges) {
          const sections = edges
            .filter((section) => !!section?.node && section!.density > 0)
            .map((section, skeletonIndex): EditorialSectionEdge => {
              return createEditorialSection(
                createSkeletonItems(section!.density),
                {
                  __typename: section!.node!.__typename,
                  id: data.id,
                  title: section!.node!.title
                    ? options?.removeTitles
                      ? ''
                      : section!.node!.title
                    : undefined,
                  description: section!.node!.description
                    ? options?.removeTitles
                      ? ''
                      : section!.node!.description
                    : undefined,
                  component: section!.node!.component,
                },
              )
            })

          const page: EditorialPage = createEditorialPage(sections, {
            id: data.id,
            title: data.title,
            __typename: layout.__typename,
          })

          page.skeletonTabs =
            options?.forceTabs ?? (layout.tabs?.length ?? 0) > 1
          page.tabs = data.tabs

          if (options?.alias) {
            state.layoutAliases[options.alias] = layoutKey
          }

          state.layouts[layoutKey] = page
        }

        if (
          options?.surviveLogout &&
          !state.stickyLayouts.includes(layoutKey)
        ) {
          state.stickyLayouts = [...state.stickyLayouts, layoutKey]
        }
      } catch (error) {
        console.error(error)
      }

      return state
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      (action: UnknownAction) => {
        return (
          action.type === '@@capsule/RESET' ||
          Models.Session.actions.logout.match(action)
        )
      },
      (state) => {
        const layoutsToKeep = {}

        state.stickyLayouts.forEach((layoutKey) => {
          const layout = state.layouts[layoutKey]
          if (layout as unknown) {
            layoutsToKeep[layoutKey] = layout
          }
        })

        state.layouts = layoutsToKeep
        return state
      },
    )
  },
})

export const { reducer, actions } = slice

export default actions

export const selectors = {
  layout: (state: RootState, layoutKey: string) => {
    return state.editorial.layouts[layoutKey]
  },
}

export function useSkeletonLayout(layoutKey: string) {
  const store = useStore()
  return selectors.layout(store.getState(), layoutKey)
}
