import { isTruthy } from '@soundtrack/utils/typePredicates'
import type { ImmutableEntity } from '@soundtrackyourbrand/capsule/dist/utils/immutable'
import { useLayoutConfig } from '#app/lib/layout-config'
import { type NavItem, generateNavItems } from '#app/modules/app/navigation'
import { currentAccountSlice } from '#app/store/reducers'
import { useSelector } from '#app/store/redux'

export type NavigationGroup = {
  name: string
  items: NavItem[]
}

/**
 * Get navigation items into grouped sidebar and library groups
 */
export function useNavigationAreas(
  user: ImmutableEntity | undefined,
  account: ImmutableEntity | undefined,
): Record<string, NavigationGroup[]> {
  const { appearance } = useLayoutConfig()
  const isPartner = account?.getIn(['roles', 'is_partner'])
  const restrictedDiscoverMusic = useSelector(
    currentAccountSlice.selectors.restrictedDiscoverMusic,
  )

  const items = generateNavItems({
    isLoggedIn: !!user,
    account,
    appearance,
    includePartner: isPartner,
    includeSignupAndLogin: false, // We don't want to show signup and login links in the sidebar
    restrictedDiscoverMusic,
  })

  // Group navigation items by area and group
  const groups: Record<string, NavigationGroup | undefined> = {}
  for (const item of items) {
    const group = item.group || 'default'
    groups[group] ||= { name: group, items: [] }
    groups[group].items.push(item)
  }

  return {
    navigation: ['partner', 'default', 'setup']
      .map((area) => groups[area])
      .filter<NavigationGroup>(isTruthy),
    library: ['library']
      .map((area) => groups[area])
      .filter<NavigationGroup>(isTruthy),
  }
}
