/**
 * A Flag always has an `enabled` field and can have multiple custom properties.
 *
 * Custom properties are defined in the Schema inside Confidence and should be represented here as well.
 */
export type FlagDefinition<
  N extends string = string,
  P extends FlagProperties = FlagProperties,
  O extends FlagDefinitionOptions = FlagDefinitionOptions,
> = {
  /** Name of the flag */
  name: N
  /** Custom properties for the flag with their default value */
  props: P
  /** Options for the flag */
  options: O
}

export interface FlagDefinitionOptions {
  /** Whether to always set this as a Sentry tag, and toggling these manually will trigger a Mixpanel event. */
  track: boolean
  /** Whether this flag is only used on the client */
  clientOnly: boolean
}

export type FlagEntry<F extends FlagDefinition = FlagDefinition> = Omit<
  F,
  'options'
> & {
  /** Whether this flag has been modified by the client */
  state: 'pristine' | 'dirty'
  /** ISO string when this flag was last fetched */
  fetchedAt: string | null
}

type FlagPropertyValue = string | boolean | number | undefined | null
export type FlagProperties = Record<string, FlagPropertyValue>

/**
 * Define flag with appropriate types.
 *
 * Note that the type for each default property is narrowed down by default, so
 * you will likely want to widen the type when defining them. See the examples
 * for how to accomplish this.
 *
 * @example Define a basic flag with a default `enabled` value of `false`
 * ```ts
 * const flag = defineFlag('my-flag')
 *   // Type: { name: 'my-flag', properties: { enabled: boolean }, meta: FlagDefinitionMeta }
 *
 * flag.properties.enabled // false
 *
 * ```
 * @example Define flag with a custom `color` property
 * ```ts
 * const flag = defineFlag('my-flag', { enabled: true, color: '#fff' as string })
 *   // Type: { name: 'my-flag', properties: { enabled: boolean, color: string }, meta: FlagDefinitionMeta }
 *
 * flag.properties.enabled // true
 * flag.properties.color   // '#fff'
 * ```
 */
export function defineFlag<N extends string, P extends FlagProperties>(
  name: N,
  defaultProperties?: P,
  options: Partial<FlagDefinitionOptions> = {},
): FlagDefinition<N, Omit<P, 'enabled'> & { enabled?: boolean }> {
  // Assume that each flag has an `enabled` property if no properties are passed
  return {
    name,
    props: defaultProperties ?? ({ enabled: false } as any),
    options: {
      track: options.track ?? false,
      clientOnly: options.clientOnly ?? false,
    },
  }
}
