// Sets redirect path for add ons on activation / already active, default is home
export const ADDON_REDIRECTS = {
  messaging: `/accounts/$accountId/messaging`,
  hifi: '/addons/activated/hifi',
} as const

// Checks if account has feature flag enabled for add on
export const ADDONS_FEATURE_FLAGS = {
  hifi: 'extreme_audio_quality_option_enabled',
  saml: 'saml_enabled',
  messaging: 'messaging_enabled',
} as const
