const $$splitComponentImporter = () => import(/* webpackChunkName: "route-electron" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/electron/browser-opened.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
export const Route = createFileRoute('/electron/browser-opened')({
  staticData: {
    showHeader: true,
    showFooter: true,
    showNotifications: true
  },
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr)
});