const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-settings" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/settings/referrals.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { Models } from '@soundtrackyourbrand/capsule';
import { createFileRoute } from '@tanstack/react-router';
import { assertAccountPropertiesOr } from '#app/lib/authenticated';
export const Route = createFileRoute('/accounts/$accountId/settings/referrals')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'account.settings.tabs.referrals.meta'
  },
  beforeLoad: () => {
    assertAccountPropertiesOr([Models.Account.hasPayment, 'distributor'], '/accounts/_/settings');
  }
});