import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client'
import type { Store } from '#app/store/index'
import { capsuleStoreConfig } from '#app/store/lib/capsule-config'

export type AppThunkExtra = {
  store: Store
  apollo: ApolloClient<NormalizedCacheObject>
  request: typeof capsuleStoreConfig.thunkExtra.request
}

export const extraThunk: AppThunkExtra = {
  store: null as any,
  apollo: null as any, // We can't assign `apollo` here due to circular dependency
  request: capsuleStoreConfig.thunkExtra.request,
}

/**
 * @see https://redux-toolkit.js.org/api/createAsyncThunk#checking-if-a-promise-rejection-was-from-an-error-or-cancellation
 * @returns Whether the passed error is a result of an async thunk condition error.
 */
export function isConditionError(
  error: any,
): error is { name: 'ConditionError'; message: string } {
  return 'name' in error && error.name === 'ConditionError'
}
