import { createFileRoute, redirect } from '@tanstack/react-router';
import { ACCOUNT_ID_PLACEHOLDER } from '#app/lib/urls';
import { currentAccountSlice } from '#app/store/reducers';
export const Route = createFileRoute('/discover/schedule/$id/edit')({
  beforeLoad: ({
    params,
    context
  }) => {
    const currentAccountId = currentAccountSlice.selectors.id(context.store.getState());
    throw redirect({
      to: '/accounts/$accountId/your-music/schedules/edit/$scheduleId',
      params: {
        accountId: currentAccountId || ACCOUNT_ID_PLACEHOLDER,
        scheduleId: params.id
      },
      state: state => state // pass-through any redirectUrl state
    });
  }
});