const $$splitComponentImporter = () => import(/* webpackChunkName: "routes/spotify-callback" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/spotify-callback.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { spotifyCallbackSearchSchema } from '#app/lib/spotify-authorization';
export const Route = createFileRoute('/spotify-callback')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  validateSearch: zodSearchValidator(spotifyCallbackSearchSchema),
  staticData: {
    showNotifications: false
  }
});