import {
  type EditorialItem,
  type EditorialPage,
  type EditorialSectionEdge,
} from '../types'

/** Converts array of editorial section, into data compatible with `<EditorialPage/>` and `<EditorialSections />`. For non editorial data to be used, you need to convert it using `createEditorialSection()` */
export function createEditorialPage(
  /** array of editorial section items, use `createEditorialSection()` for non editorial data into compatible section items */
  sections: Array<EditorialSectionEdge>,
  options?: {
    /**
     * SectionType to create, defaults to SearchPage if not specified as
     * EditorialPage is an interface and can not be used directly.
     */
    __typename?: EditorialPage['__typename']
    id?: string
    title?: EditorialPage['title']
  },
): EditorialPage {
  return {
    __typename: options?.__typename ?? 'SearchPage',
    id: options?.id ?? '',
    sections: {
      __typename: 'EditorialSectionConnection',
      total: sections.length,
      pageInfo: {
        __typename: 'PageInfo',
        hasNextPage: false,
        endCursor: sections[sections.length - 1]?.cursor,
      },
      edges: sections,
    },
  }
}

/**
 * Creates an editorial section item array used for the `sections` array passed to `createEditorialPage(sections, options)`.
 * `createEditorialPage()` creates the rest of the data needed for `<EditorialPage />` and `<EditorialSections />` to render.
 * @param items array of displayable data objects to be rendered inside the section.
 * If source is radio data, use `sourceFromRadioToGraphql()` to convert the data to the correct format.
 * If using non editorial gql data, make sure to include needed displayable data fields.
 * Use `createSkeletonItems()` to create skeleton items to pass as items for loading states.
 * @param options can be used to specify section title, component to render and other options.
 */
export function createEditorialSection(
  /**
   * Items rendered inside a single `<EditorialSection/>`, displayed as portraitCards for example.
   * Takes displayable data items in a flat array to be rendered in a `<EditorialSection />`
   */
  items: Array<EditorialItem | null | undefined>,

  options: {
    /**
     * Section type to create, defaults to SearchEditorialSection if not specified
     * as EditorialSection is an interface and can not be used directly.
     */
    __typename?: NonNullable<EditorialSectionEdge['node']>['__typename']
    id?: string
    /** The heading for the section  */
    title?: string
    /** The desctiption for the section  */
    description?: string
    /** The component to use for the rendering section items, You can specify component, palette, layout, cols etc. Example use: `PortraitCards?palette=white&layout=grid&cols=2` */
    component: Array<string>
  },
): EditorialSectionEdge {
  return {
    __typename: 'EditorialSectionEdge',
    cursor: options.id ?? '',
    density: items.length,
    node: {
      __typename: options.__typename ?? 'SearchEditorialSection',
      id: options.id ?? '',
      title: options.title ?? null,
      description: options.description ?? null,
      component: options.component,
      items: {
        __typename: 'DisplayableConnection',
        total: items.length,
        pageInfo: {
          __typename: 'PageInfo',
          hasNextPage: false,
          endCursor: items.length ? items[items.length - 1]?.id : null,
        },
        edges: items.map((item) =>
          item
            ? {
                __typename: 'DisplayableEdge',
                cursor: item.id,
                node: item,
              }
            : null,
        ),
      },
    },
  }
}

export interface SkeletonItem {
  __typename: 'Skeleton'
  id: string
  display: EditorialItem['display']
  name?: string
}

export function isSkeletonItem(item: unknown): item is SkeletonItem {
  return (
    !!item &&
    typeof item === 'object' &&
    '__typename' in item &&
    item.__typename === 'Skeleton'
  )
}

export function createSkeletonItems(count: number): Array<SkeletonItem> {
  return Array.from({ length: count }).map((_, index) => ({
    __typename: 'Skeleton',
    id: `skeleton-${index}`,
    name: '',
    display: {
      // Should be set so it doesn't get filtered out
      __typename: 'Display',
      title: '',
    },
  }))
}
