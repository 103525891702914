const $$splitComponentImporter = () => import(/* webpackChunkName: "route-discover" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/discover/album.$releaseId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { redirectUrisToShortIds } from '#app/lib/route-helpers';
export const Route = createFileRoute('/discover/album/$releaseId')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'discoverMusic.release.meta'
  },
  beforeLoad: ({
    params
  }) => {
    redirectUrisToShortIds(params);
  }
});