const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-your-music" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/your-music/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';
export const Route = createFileRoute('/accounts/$accountId/your-music')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  validateSearch: zodSearchValidator(z.object({
    q: z.coerce.string().optional().catch(undefined)
  }))
});