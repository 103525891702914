import type { GetFlagsContextInput } from '#app/graphql/graphql'
import overrides from '#app/lib/overrides'
import { selectors as accountSelectors } from '#app/store/current-account'
import { store } from '#app/store/index'

/** The default context sent in with each `fetchFlags` request. */
export function defaultEphemeralContext() {
  const accountId = accountSelectors.id(store.getState())
  const tier = accountSelectors.tier(store.getState())

  return [
    {
      key: 'app.is_dev',
      value: String(NODE_ENV === 'development'),
    },
    { key: 'app.env', value: SYB.env },
    { key: 'app.platform', value: 'business' },
    { key: 'app.platform_version', value: SYB.version },
    { key: 'app.platform_branch', value: SYB.branch || '' },
    {
      key: 'app.is_e2e',
      value: overrides.get<boolean>('is-e2e')?.toString() ?? 'false',
    },
    {
      key: 'account.tier',
      value: String(tier),
    },
    {
      key: 'account.id',
      value: String(accountId),
    },
  ] satisfies GetFlagsContextInput['ephemeral']
}
