const $$splitComponentImporter = () => import(/* webpackChunkName: "route-pairing-guide" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/(pairing-guide)/sonos.connect.v2.$householdId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import PairingGuideConnect from '#app/modules/pairing-guide/connect';
export const Route = createFileRoute('/(pairing-guide)/sonos/connect/v2/$householdId')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  validateSearch: zodSearchValidator(PairingGuideConnect.searchSchema),
  staticData: {
    meta: 'pairingGuide.sonos.connect.meta',
    showNotifications: false
  }
});