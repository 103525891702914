const $$splitComponentImporter = () => import(/* webpackChunkName: "route-logged-out" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/(logged-out)/login.index.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';
export const Route = createFileRoute('/(logged-out)/login/')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  validateSearch: zodSearchValidator(z.object({
    email: z.string().email().optional().catch(undefined),
    redirectTo: z.string().optional().catch(undefined)
  })),
  staticData: {
    showHeader: false,
    showFooter: false,
    popPage: true,
    showSidebarLayout: false,
    showNotifications: false
  },
  beforeLoad: () => {
    if (IS_ELECTRON) {
      throw redirect({
        to: '/electron/login',
        replace: true
      });
    }
  }
});