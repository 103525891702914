import { createFileRoute, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/')({
  beforeLoad: ({
    location
  }) => {
    // Some quirky behaviour where this got triggered for the /login route
    if (location.pathname === '/') {
      throw redirect({
        to: '/home',
        replace: true
      });
    }
  }
});