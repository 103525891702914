import qs from 'query-string'
import { createContext } from 'react'
import overrides from '#app/lib/overrides'

export const VendorContext = createContext<keyof typeof VENDORS | undefined>(
  undefined,
)

export interface AppDefinition {
  name: string
  uri: string
  /** Whether to show the success button in desktop. If not `true` this is only shown on mobile. */
  showSuccessButtonInDesktop?: boolean
}

// TODO: Remove all tracking event names in this file and all places using them (will cause
// typing errors) after 2025-04-04 or earlier if we are sure we do not use them
// New vendors should not set them. All vendors, old and new, are tracked with generic
// Vendor Connect events instead, using a Vendor property to distinguish.
export const VENDORS = {
  sonos: {
    allowSoftPair: false,
    deviceType: 'sonos',
    pairingPath: ({ householdId }, _search, { version = 2 }) =>
      version === 2 ? `/sonos/pair/v2/${householdId}` : undefined,
    app: (): AppDefinition => ({
      name: 'Sonos',
      uri: 'sonos://',
    }),
    setOverrides: () => {},
    setOnboardingOverrides: setSonosOnboardingOverrides,
    tracking: {
      connectView: 'Sonos Connect - View',
      connectViewProps: (_, __, { version = 2 }) => ({
        'Sonos Connect Version': version,
      }),
      authenticate: 'Sonos Connect - Authenticate',
      createZone: 'Sonos Connect - Create Zone',
      paired: 'Sonos Connect - Paired',
      unpaired: 'Sonos Connect - Unpaired',
      unpairCancelled: 'Sonos Connect - Unpair Cancelled',
      connectClicked: 'Sonos Connect - Connect Clicked',
      openApp: 'Sonos Connect - Open Sonos App',
    },
  },
  linkplay: {
    allowSoftPair: false,
    deviceType: 'embedded',
    app: (): AppDefinition => {
      let appId = overrides.get('linkplay-app-id')
      if (!appId) {
        appId = qs.parse(window.location.search).appId as any
      }
      const app =
        VENDORS.linkplay.apps[appId as keyof typeof VENDORS.linkplay.apps]
      if (!(app as unknown)) {
        throw Error(`Unknown linkplay appId "${appId}"`)
      }
      return app
    },
    setOverrides: (_, { appId }: { appId?: string }) => {
      if (!VENDORS.linkplay.apps[appId!]) {
        throw Error(`Unknown linkplay appId "${appId}"`)
      }
      overrides.set('linkplay-app-id', appId)
    },
    setOnboardingOverrides: createSetOnboardingOverrides('linkplay'),
    tracking: {
      connectView: 'Linkplay Connect - View',
      connectViewProps: (_, { appId }) => ({
        'Linkplay App Id': appId,
      }),
      authenticate: 'Linkplay Connect - Authenticate',
      createZone: 'Linkplay Connect - Create Zone',
      paired: 'Linkplay Connect - Paired',
      unpaired: 'Linkplay Connect - Unpaired',
      unpairCancelled: 'Linkplay Connect - Unpair Cancelled',
      connectClicked: 'Linkplay Connect - Connect Clicked',
      openApp: 'Linkplay Connect - Open App',
    },
    apps: {
      wiimhome: {
        name: 'WiiM Home',
        uri: 'com.linkplay.wiimhome://callback?category=pair&service=soundtrack',
        showSuccessButtonInDesktop: true,
      },
    },
  },
  unifi: {
    allowSoftPair: true,
    deviceType: 'embedded',
    app: (pairingCode?: string): AppDefinition => {
      let redirectUrl = 'unifi-play://soundtrack/pair'
      if (pairingCode) {
        const url = new URL(redirectUrl)
        url.searchParams.set('code', pairingCode)
        redirectUrl = url.toString()
      }
      return {
        name: 'UniFi Play',
        uri: redirectUrl,
        showSuccessButtonInDesktop: true,
      }
    },
    setOverrides: () => {},
    setOnboardingOverrides: createSetOnboardingOverrides('unifi'),
    tracking: undefined,
  },
  generic: {
    allowSoftPair: true,
    deviceType: 'embedded',
    app: (pairingCode?: string): AppDefinition => {
      let redirectUrl = overrides.get<string>('general-redirect-url')
      if (!redirectUrl) {
        redirectUrl = qs.parse(window.location.search).redirect as string
      }
      if (pairingCode) {
        const url = new URL(redirectUrl)
        url.searchParams.set('code', pairingCode)
        redirectUrl = url.toString()
      }
      return {
        name: 'SDK pairing',
        uri: redirectUrl,
        showSuccessButtonInDesktop: true,
      }
    },
    setOverrides: (_, { redirect }: { redirect?: string }) => {
      let url: URL
      try {
        if (!redirect) throw Error()
        url = new URL(redirect)
      } catch (err) {
        throw Error('Must provide a redirect url in the redirect parameter')
      }
      if (url.hostname !== 'localhost') {
        throw Error('Invalid redirect url')
      }
      overrides.set('general-redirect-url', redirect)
    },
    setOnboardingOverrides: createSetOnboardingOverrides('generic'),
    tracking: undefined,
  },
}

export function isSupportedVendor(
  vendor: string,
): vendor is keyof typeof VENDORS {
  return vendor in VENDORS
}

/**
 * Sets overrides for the onboarding flow to automatically pair the
 * soundzone or location with given pairing code after the activation
 * step is completed.
 */
function setSonosOnboardingOverrides(
  {
    householdId,
    pairing_code,
  }: {
    householdId?: string
    pairing_code?: string
  },
  {
    linkCode,
  }: {
    linkCode?: string
  },
): void {
  // Only set referral and related overrides if we have all the data
  if (!pairing_code || !(householdId || linkCode)) {
    return
  }

  overrides.set('pairing-guide', true)
  overrides.set('connect-vendor', 'sonos')

  if (householdId && linkCode) {
    overrides.set('sonos-household-id', householdId)
    overrides.set('sonos-linking-code', linkCode)
  }
  overrides.set('pairing-code', pairing_code) // keep for legacy?
}

/**
 * Sets overrides for the onboarding flow to automatically pair the
 * soundzone or location with given pairing code after the activation
 * step is completed.
 */
function createSetOnboardingOverrides(
  vendor: string,
): (params: { pairing_code?: string }) => void {
  return (params) => {
    // This should just be typed to only accept vendors, however since it's used inside the vendor definitions
    // it creates a cirulair type definition and VENDORS just becomes any
    if (!isSupportedVendor(vendor)) throw Error('Unsupported vendor')

    // Only set referral and related overrides if we have all the data
    if (!params.pairing_code && !VENDORS[vendor].allowSoftPair) {
      return
    }

    overrides.set('pairing-guide', true)
    overrides.set('connect-vendor', vendor)

    if (params.pairing_code) {
      overrides.set('pairing-code', params.pairing_code)
    }
  }
}

/**
 * Returns redirect path for automatic pairing step or
 * null if pairing-guide is not active
 */
export function getPairingGuideReturnPath(zoneId?: string) {
  const inPairingGuide = overrides.get('pairing-guide')
  const vendor = overrides.get<string>('connect-vendor')
  const pairingCode = overrides.get('pairing-code')
  if (!inPairingGuide) return null

  if (!vendor || !VENDORS[vendor])
    throw Error(`Unset or unknown vendor ${vendor}`)
  if (!pairingCode && !VENDORS[vendor]?.allowSoftPair) {
    return '/home'
  }

  return pairingCode
    ? zoneId
      ? `/connect/${vendor}/${pairingCode}/pair/${zoneId}`
      : `/connect/${vendor}/${pairingCode}/pair`
    : `/connect/${vendor}/pair`
}
