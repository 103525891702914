const $$splitComponentImporter = () => import(/* webpackChunkName: "route-partner-portal" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/partner-portal/$accountId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { rolesEnabledOr, syncCurrentAccountId } from '#app/lib/authenticated';
import { getRouteMatchByLocation } from '#app/lib/router';
import { ensurePaddedId } from '#app/lib/urls';
import { PartnerDashboard } from '#app/partner-portal/dashboard/index';
export const Route = createFileRoute('/partner-portal/$accountId')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  validateSearch: zodSearchValidator(PartnerDashboard.searchSchema),
  staticData: {
    meta: 'partnerPortal.dashboard.meta',
    accountSwitch: '/partner-portal/$accountId'
  },
  beforeLoad: async ({
    location,
    params,
    preload
  }) => {
    ensurePaddedId(params, 'accountId');
    if (!preload) {
      // Find matches based on the current location since the current `router.state` may not be
      // updated yet, so we cannot rely on it
      const currentRoute = getRouteMatchByLocation(location);
      await syncCurrentAccountId(params['accountId'], '/home', currentRoute.fullPath);
    }
    await rolesEnabledOr('is_partner', '/home');
  }
});