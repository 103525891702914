import type { Obj } from '#app/lib/types'
import type { Store as AppStore } from '#app/store/index'

/** Possible actions dispatched by {@link dispatchAsyncResult} */
export type AsyncAction<BaseAction extends string, Params = any, Result = any> =
  | {
      type: BaseAction
      params?: Params
    }
  | {
      type: `${BaseAction}_SUCCESS`
      params?: Params
      data: Result
    }
  | {
      type: `${BaseAction}_FAILURE`
      params?: Params
      error: Error & {
        asyncAction: BaseAction
        asyncParams: Params | undefined
      }
    }

/**
 * Light-weight version of capsules REMOTE_ACTION middleware that doesn't need
 * to be installed and can be called within any redux-thunk action creator.
 *
 * Dispatches {@link AsyncAction} actions to the store and returns a promise.
 */
export function dispatchAsyncResult<
  PromiseResult,
  BaseAction extends string,
  Params = any,
>(
  args: Readonly<{
    dispatch: (action: Obj) => void
    action: BaseAction
    promise: Promise<PromiseResult>
    params?: Params
  }>,
): Promise<PromiseResult> {
  type Action = AsyncAction<BaseAction, Params, PromiseResult>
  args.dispatch({
    type: args.action,
    params: args.params,
  } satisfies Action)
  return args.promise
    .then((data) => {
      args.dispatch({
        type: (args.action + '_SUCCESS') as `${BaseAction}_SUCCESS`,
        params: args.params,
        data,
      } satisfies Action)
      return data
    })
    .catch((error) => {
      if (!(error instanceof Error)) {
        error = Object.assign(
          new Error('dispatchAsyncResult: ' + error.toString()),
          error,
        )
      }
      error.asyncAction = args.action
      error.asyncParams = args.params
      args.dispatch({
        type: (args.action + '_FAILURE') as `${BaseAction}_FAILURE`,
        params: args.params,
        error,
      } satisfies Action)
      throw error
    })
}

export interface WaitForSelector<S, R> {
  (state: Readonly<S>): boolean
  /** Selector value at time of resolution */
  selectorValue?: R
}

/**
 * Returns a promise that resolves once `action` has resolved (if specified) and either:
 * 1. All `selectors` return true
 * 2. `timeout` milliseconds has elapsed
 *
 * If `timeoutThrows` is true then the promise will instead reject with an error
 * if `timeout` elapses before (1) is met.
 *
 * @example
 * ```
 * const result = await waitFor(store, {
 *  action: store.dispatch(login(...)),
 *  selectors: [
 *    state => state.someValue === 'ready', // can be any valid redux selector
 *    waitFor.change(auth.selectors.user), // use `waitFor.*` helpers to add conditions
 *  ],
 *  timeout: 10e3, // default value
 * )
 * if (result.timedOut) { ... } // act on timeout
 * ```
 */
export function waitFor<
  Store extends AppStore,
  State extends ReturnType<Store['getState']>,
  Paths extends Readonly<Array<(state: Readonly<State>) => any>>,
  Action,
  TimeoutThrows extends boolean = false,
>(args: {
  /** Redux store to observe for changes */
  store: Store
  /** Array of redux selectors to check after every change */
  selectors: Paths
  /** Optional additional promise to await resolution of */
  action?: Action
  /** Maximum wait period in milliseconds */
  timeout?: number
  /** Should the returned promise be rejected if `timeout` has elapsed? */
  timeoutThrows?: TimeoutThrows
}): Promise<{
  /** Snapshot of store state at time of resolution */
  state: State
  /** The return value of each selector at time of resolution */
  values: {
    -readonly [K in keyof Paths]: (
      Paths[K] extends WaitForSelector<any, infer R>
        ? R
        : ReturnType<Paths[K]>
    ) extends infer Value
      ? TimeoutThrows extends true
        ? Exclude<Value, undefined | null | false | 0 | ''>
        : Value
      : never
  }
  /** The return value of the optional `action` promise at time of resolution */
  action: Action extends Promise<infer Result> ? Result : Action
  /** True if `timeout` elapsed before all conditions were met, and `timeoutThrows` was false */
  timedOut: boolean
}> {
  const { store, selectors } = args
  const timeout = args.timeout ?? 10e3
  let timedOut = false

  function allSatisfied(state: State): boolean {
    return selectors.every((selector) => selector(state))
  }

  if (args.action) {
    // Initialize any `waitFor.*` initial values before awaiting `action`
    const state = store.getState()
    selectors.forEach((selector) => selector(state))
  }

  return Promise.resolve(args.action)
    .then((actionResult) => {
      if (allSatisfied(store.getState())) {
        return actionResult
      }

      let timeoutId: any
      let unsubscribe: undefined | (() => void)

      // Promise resolving when all selectors are satisfied, or after `timeout`
      return new Promise<void>((resolve, reject) => {
        // Set up timeout
        timeoutId = setTimeout(() => {
          timedOut = true
          if (args.timeoutThrows) {
            const state = store.getState()
            reject(
              Object.assign(
                new Error(`waitForUpdates timed out after ${timeout} ms`),
                {
                  selectors: selectors.map((selector) => !!selector(state)),
                },
              ),
            )
          } else {
            resolve()
          }
        }, timeout)

        // Set up store subscription
        // TODO: Debounce this 1ms or so to avoid repeated calculations?
        unsubscribe = store.subscribe(() => {
          if (allSatisfied(store.getState())) {
            return resolve()
          }
        })
      })
        .finally(() => {
          clearTimeout(timeoutId)
          unsubscribe?.()
        })
        .then(() => actionResult)
    })
    .then((actionResult) => {
      const state = store.getState()
      return {
        state,
        values: selectors.map((selector) => {
          return 'selectorValue' in selector
            ? selector['selectorValue']
            : selector(state)
        }) as any,
        action: actionResult as any,
        timedOut,
      }
    })
}

/** Used internally by `waitFor.compare()` to denote a unset value */
waitFor.NO_VALUE = '__waitFor.UNSET__' as const

/**
 * Creates a selector that resolves once the result of
 * `comparator(selector(state))` becomes truthy.
 */
waitFor.compare = function waitForCompare<S, R>(
  selector: (state: S) => R,
  comparator: (value: R, initialValue: R) => boolean,
) {
  let initial: R | typeof waitFor.NO_VALUE = waitFor.NO_VALUE
  const callback: WaitForSelector<S, R> = (state) => {
    const value = selector(state)
    if (initial === waitFor.NO_VALUE) {
      initial = value
    }
    const outcome = comparator(value, initial)
    if (outcome) {
      callback.selectorValue = value
      return true
    }
    delete callback.selectorValue
    return false
  }
  return callback
}

/**
 * Creates a selector that resolves once the result of `selector(state)` changes
 * from its initial value, optionally requiring the final value to be truthy.
 */
waitFor.change = function waitForChange<S, R>(
  selector: (state: S) => R,
  requireTruthy = false,
) {
  return waitFor.compare(selector, (value, initial) => {
    return value !== initial && (!requireTruthy || !!value)
  })
}

/**
 * Creates a selector that resolves once the result of `selector(state)` returns
 * null or undefined.
 */
waitFor.empty = function waitForChange<S, R>(selector: (state: S) => R) {
  return waitFor.compare(selector, (value) => value == null)
}

/**
 * Returns a promise that resolves when the given selector returns a truthy value.
 */
export function waitForSingle<
  Store extends AppStore,
  State extends ReturnType<Store['getState']>,
  Selector extends (state: Readonly<State>) => any,
  TimeoutThrows extends boolean = false,
>(
  store: Store,
  selector: Selector,
  options?: {
    /** Maximum wait period in milliseconds */
    timeout?: number
    /** Should the returned promise be rejected if `timeout` has elapsed? */
    timeoutThrows?: TimeoutThrows
  },
) {
  return waitFor({
    store,
    selectors: [selector],
    timeout: options?.timeout,
    timeoutThrows: options?.timeoutThrows,
  }).then(({ values }) => values[0])
}
