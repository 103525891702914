import { Models, Selectors } from '@soundtrackyourbrand/capsule';
import { selectors as currentAccountSelectors } from '#app/store/current-account';
export default function sentryMiddleware({
  addBreadcrumb,
  getGlobalScope
}) {
  let lastAction;
  return store => {
    getGlobalScope().addEventProcessor(event => {
      try {
        const state = store.getState();
        // Inject additional metadata into Sentry event
        Object.assign(event, {
          tags: {
            ...event.tags,
            country: currentAccountSelectors.country(state),
            account: currentAccountSelectors.id(state),
            lastAction: lastAction && lastAction.type
          },
          user: {
            ...event.user,
            id: Selectors.currentUserId(state)
          },
          extra: {
            ...event.extra,
            lastAction
          }
        });
      } catch (err) {
        console.error('[sentry] Redux middleware threw error', err);
      }
      return event;
    });
    return next => action => {
      const breadcrumb = breadcrumbForAction(action);
      if (breadcrumb) {
        addBreadcrumb({
          level: 'info',
          category: 'redux',
          message: action.type,
          data: breadcrumb !== true ? breadcrumb : undefined
        });
      }
      lastAction = action;
      return next(action);
    };
  };
}
function breadcrumbForAction(action) {
  switch (action.type) {
    case 'NOW_PLAYING_CREATE':
    case 'PLAYBACK_STATE_UPDATE':
    case Models.Session.actions.updateToken.type:
      return null;
    case 'SHOW_MODAL':
      return {
        component: action.modal.component.displayName
      };
    default:
      return true;
  }
}