import {
  hotkeyMatches,
  useCaptureHotkeys,
} from '@soundtrack/desktop-shared-components/useHotkeys'
import type { DesktopTypes } from '@soundtrack/desktop-shared-types'
import React from 'react'
import { usePlayback, usePlayerId } from '../playback'
import { playbackSlice } from '../store/reducers'
import { useStore } from '../store/redux'

const trackingContext = { Context: 'Hotkey' }

/* Hotkeys */

const Hotkeys = {
  /** Pause or play the active source. */
  PLAY_PAUSE: { key: ' ' },
} as const satisfies { [Key: string]: DesktopTypes.Hotkey }

const handledHotkeys = Object.values(Hotkeys)

/* Hook */

export function useDesktopHotkeys() {
  const store = useStore()
  const playback = usePlayback()
  const playerId = usePlayerId()

  const handleHotkey = React.useCallback(
    (hotkey: DesktopTypes.Hotkey) => {
      // Play/Pause (bail early if not in player mode)
      if (playback && hotkeyMatches(hotkey, Hotkeys.PLAY_PAUSE)) {
        const playbackState = playbackSlice.selectors.playbackState(
          playerId,
          store.getState(),
        )
        if (playbackState.status === 'playing') {
          playback.pause(trackingContext)
        } else {
          playback.play(trackingContext)
        }
      }
    },
    [playback, playerId, store],
  )

  // Capture hotkeys in this view.
  useCaptureHotkeys(handleHotkey, handledHotkeys)
  // Capture hotkeys relayed from other views.
  React.useEffect(
    () => window.Syb?.onRelayedHotkey?.(handleHotkey),
    [handleHotkey],
  )
}

/* Hook Implementer */

export function HotkeysListener() {
  useDesktopHotkeys()
  return null
}
