import { jwtPayload } from '@soundtrack/auth/jwt'
import { Api, Http, Models } from '@soundtrackyourbrand/capsule'
import * as misc from '#app/store/misc'
import type { Store } from './store'
import { authSlice } from './store/reducers'

export function initializeCapsule(config: { store: Store; canary?: string }) {
  Api.env(SYB.env)
  Api.setGraphqlHeaders({
    'X-Canary': config.canary,
  })
  Http.events.on('headers', (props, req) => {
    if (!Api.isSYBRequest(req)) {
      return
    }

    req.set(
      'X-User-Agent',
      JSON.stringify(misc.selectors.editorialAgent(config.store.getState())),
    )

    // TODO: Move to capsule before release
    // req.set('X-API-Version', '13')

    if (
      props.headers.token ||
      props.headers.Authorization ||
      !authSlice.selectors.loggedIn(config.store.getState())
    ) {
      return
    }
    return config.store
      .dispatch(authSlice.actions.maybeUpdateToken())
      .then((token) => {
        if (token) {
          props.headers.token = token
        }
      })
  })
  Api.init({
    // @ts-ignore
    sockets: {
      query: {
        user: true,
        account: ['sound_zone', 'location', 'device', 'acl'],
        schedule: ['schedule', 'slot_set'],
      },
    },
  })

  /** Mirror capsule token/session against auth/tyson token */
  const setSessionToken = (token?: string) => {
    const state = config.store.getState()
    if (token === undefined) {
      token = authSlice.selectors.token(state)
    }
    const capsuleToken = state.session?.get('token')
    if (token) {
      const payload = jwtPayload(token)
      const userId = payload.sub!
      if (token !== capsuleToken || userId !== state.session?.get('user')) {
        config.store.dispatch(
          Models.Session.actions.setLogin({ token, userId }),
        )
      }
    } else if ((token || null) !== (capsuleToken || null)) {
      if (!token) {
        Api.logout()
      } else {
        Api.token(token, true)
      }
    }
  }

  setSessionToken()
  const tokenSubscription = config.store.observe(
    authSlice.selectors.token,
    setSessionToken,
  )

  const onLogin = () => {
    Api.socket.connect()
  }
  const onLogout = () => {
    Api.socket.disconnect()
  }
  Api.on('login', onLogin)
  Api.on('logout', onLogout)

  return () => {
    Api.off('login', onLogin)
    Api.off('logout', onLogout)
    tokenSubscription()
  }
}
