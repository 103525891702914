/* eslint-disable @typescript-eslint/no-empty-interface,no-restricted-imports */
import { createAsyncThunk as createAsyncThunkOriginal, createSelector as createSelectorOriginal } from '@reduxjs/toolkit';
import '@soundtrackyourbrand/capsule/dist/utils/immutable';
import { connect as connectOriginal, useDispatch as useDispatchOriginal, useSelector as useSelectorOriginal, useStore as useStoreOriginal } from 'react-redux';
// Strongly typed react-redux APIs
export const useStore = useStoreOriginal.withTypes();
export const useDispatch = useDispatchOriginal.withTypes();
export const useSelector = useSelectorOriginal.withTypes();
export const connect = connectOriginal;

/**
 * A pre-typed version of `createSelector`
 *
 * NOTE: Currently this approach only works if input selectors are provided as a single array.
 *
 * @see https://reselect.js.org/api/createselector/#defining-a-pre-typed-createselector
 */
export const createSelector = createSelectorOriginal.withTypes();

/** Local variation, still to be patched */
const _createAsyncThunkTyped = createAsyncThunkOriginal.withTypes();
/**
 * Wrapped `createAsyncThunk()` that passes through original error objects without serializing them.
 */
// @ts-expect-error We don't expose a `.withTypes()` method which is expected by TypeScript
export const createAsyncThunk = (...args) => {
  return _createAsyncThunkTyped(args[0], args[1], {
    serializeError: error => error,
    // Passthrough error instances
    ...args[2]
  });
};