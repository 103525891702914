import { addContext } from '#app/lib/error/reporting'
import overrides from '#app/lib/overrides'

export default class LanguageDetector {
  constructor(services, options = {}) {
    this.init(services, options)
    this.type = 'languageDetector'
  }

  init(services, options = {}, i18nOptions = {}) {
    this.services = services
    this.options = options
    this.i18nOptions = i18nOptions
  }

  detect() {
    const found = [overrides.get('lang')]
    // if (typeof navigator !== 'undefined') {
    //   if (navigator.languages) { navigator.languages.forEach(found.push.bind(found)) }
    //   found.push(navigator.userLanguage, navigator.language)
    // }
    for (let candidate of found) {
      if (typeof candidate !== 'string') {
        continue
      }
      candidate = this.services.languageUtils.formatLanguageCode(candidate)
      if (this.services.languageUtils.isSupportedCode(candidate)) {
        // Simulate forced languageOnly option
        return candidate.split('-')[0]
        // return candidate
      }
    }
    return this.i18nOptions.fallbackLng[0]
  }

  cacheUserLanguage(lang) {
    // TODO: We don't set the `lang` cookie as it would always be set to `en`
    // which would affect `storefront` as it uses the same cookie. When business
    // supports multiple languages we need to set the cookie using the same
    // rules as `storefront`.
    // overrides.set('lang', lang)
    addContext('lang', lang)
  }
}

LanguageDetector.type = 'languageDetector'
