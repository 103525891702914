import anime from 'animejs'
import { Children, Component, cloneElement } from 'react'

anime.easings = {}

// Custom easings
anime.easings.spring = 'spring(1, 80, 10, 0)'
/*
anime.easings.spring = (t) => {
  return -0.5 * (2.71828 ** (-6 * t)) * (-2 * (2.71828 ** (6 * t)) + Math.sin(12 * t) + 2 * Math.cos(12 * t))
}
anime.easings.quad = (t) => {
  return t < 0.5 ? 2*t*t : -1+(4-2*t)*t
}
*/

export default anime

/**
 * Animates child components using anime.js.
 * Configuration is provided as props (exact same API as anime.js).
 * Re-applies animations on when primitive props change (no deep diffing
 * though, so objects are considered unchanged unless they transition to/from
 * other value types).
 */
export class Anime extends Component {
  constructor(props) {
    super(props)
    this.targets = []
  }

  componentDidMount() {
    this.createAnime()
  }

  componentWillUnmount() {
    anime.remove(this.targets)
  }

  componentDidUpdate(prevProps) {
    const keys = Object.keys(this.props)
    const lengthChanged = keys.length !== Object.keys(prevProps).length
    const changed =
      lengthChanged ||
      !keys.every((key) => {
        const next = this.props[key],
          prev = prevProps[key]
        return (
          next === prev ||
          // Don't diff objects, but consider object to non-object transitions as changes
          (typeof next === 'object' &&
            typeof next === typeof prev &&
            !next === !prev)
        )
      })
    if (changed) {
      this.createAnime(this.props)
    }
  }

  createAnime(props = this.props) {
    const animeProps = Object.assign(
      {
        targets: this.targets,
      },
      props,
    )
    delete animeProps.children

    anime.remove(this.targets)
    this.anime = anime(animeProps)
  }

  /**
   * Add DOM node to target list.
   *
   * @param {HTMLElement} newTarget
   */
  addTarget = (newTarget) => {
    this.targets.push(newTarget)
  }

  render() {
    return Children.map(this.props.children, (child) => {
      return cloneElement(child, { ref: this.addTarget })
    })
  }
}
