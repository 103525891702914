import CA_STATES from '@soundtrackyourbrand/capsule/dist/utils/ca-provinces'
import US_STATES from '@soundtrackyourbrand/capsule/dist/utils/us-states'

/*
 * Different address standard sources:
 * 1. MSDN: https://docs.microsoft.com/en-us/previous-versions/cc195167(v=msdn.10)?redirectedfrom=MSDN
 * 2. Address data in JSON format by Google: https://i18napis.appspot.com/address
 *      `fmt` explained: https://unicode.org/review/pri180/
 *            '%O': '{business_name}',
 *            '%A': '{address}\n{address2}',
 *            '%C': '{city}',
 *            '%Z': '{postal_code}',
 *            '%S': '{state}', // Administrative Area
 */

export const COUNTRY_DEFAULT = {
  format: {
    form: '{business_name}\n{address}\n{address2}\n{postal_code}{city}',
    // TODO: We don't use display anywhere yet, keeping here for future reference.
    display:
      '{business_name}\n{address}\n{address2}\n{city}, {postal_code}\n{iso_country}',
  },
  labelKeys: {
    state: 'state',
    postal_code: 'postal_code',
    address: 'address',
    address2: 'address2',
  },
  // In the future we'd want to get these from an API instead (same values found in BO)
  vatLabel: '',
  vatHint: '',
  requiredFields: ['business_name', 'address', 'postal_code', 'city'],

  // Used by Google Places API to determine "state"
  stateAreaLevel: 'administrative_area_level_1',

  // Not yet implemented/used:
  uppercaseFields: ['iso_country'], // Used for displaying address
  postalCodePattern: null, // RegEx
  postalCodeHint: '123 56',
}

const COUNTRY_WITH_STATE = {
  format: {
    form: '{business_name}\n{address}\n{address2}\n{postal_code}{city}\n{state}',
    // TODO: Right now we don't display addresses anywhere in business, but keeping this here for when we do
    display:
      '{business_name}\n{address}\n{address2}\n{city}, {state} {postal_code}\n{iso_country}',
  },
  requiredFields: ['business_name', 'address', 'postal_code', 'city', 'state'],
}

export const COUNTRIES = {
  // Austria
  AT: {
    vatLabel: 'VAT number (Umsatzsteuer-Identifikationsnummer, UID)',
  },

  // Australia
  AU: COUNTRY_WITH_STATE,

  // United States of America
  US: {
    ...COUNTRY_WITH_STATE,
    labelKeys: {
      postal_code: 'zip_code',
    },
    states: US_STATES, // TODO: Should probably move this in here?
  },

  // Canada
  CA: {
    ...COUNTRY_WITH_STATE,
    format: {
      form: '{business_name}\n{address}\n{address2}\n{city}\n{state}{postal_code}',
    },
    labelKeys: {
      state: 'Province',
    },
    states: CA_STATES, // TODO: Should probably move this in here?
  },

  // Switzerland
  CH: {
    ...COUNTRY_WITH_STATE,
    labelKeys: {
      state: 'Canton',
    },
    requiredFields: ['business_name', 'address', 'postal_code', 'city'],
  },

  // China
  CN: COUNTRY_WITH_STATE,

  // Germany
  DE: {
    vatLabel: 'VAT number (Umsatzsteuer, USt-IdNr)',
    vatHint: 'DE123456789',
  },

  // Spain
  ES: {
    vatLabel: 'VAT number (Número de Identificación Fiscal, NIF)',
    vatHint: 'ES01234567A',
  },

  // Finland
  FI: {
    uppercaseFields: ['city'],
    vatLabel: 'VAT number (ALV nro / Momsnummer)',
    vatHint: 'FI12345678',
  },

  // France
  FR: {
    vatLabel: 'VAT number (Numéro de TVA intracommunautaire)',
    vatHint: 'FRXX12345679',
  },

  // United Kingdom
  GB: {
    format: {
      form: '{business_name}\n{address}\n{address2}\n{city}{postal_code}\n{state}',
    },
    labelKeys: {
      postal_code: 'Postcode',
      state: 'County (optional)',
    },
    // For UK the County is on the second level
    stateAreaLevel: 'administrative_area_level_2',
  },

  // Ireland
  IE: {
    format: {
      form: '{business_name}\n{address}\n{address2}\n{city}\n{state}{postal_code}',
    },
    vatHint: 'IE123456XX',
    labelKeys: {
      state: 'County',
      postal_code: 'Postal code (optional)',
    },
    requiredFields: ['business_name', 'address', 'city', 'state'],
    states: [
      { value: 'CW', label: 'Carlow' },
      { value: 'CN', label: 'Cavan' },
      { value: 'CE', label: 'Clare' },
      { value: 'CO', label: 'Cork' },
      { value: 'DL', label: 'Donegal' },
      { value: 'D', label: 'Dublin' },
      { value: 'G', label: 'Galway' },
      { value: 'KY', label: 'Kerry' },
      { value: 'KE', label: 'Kildare' },
      { value: 'KK', label: 'Kilkenny' },
      { value: 'LS', label: 'Laois' },
      { value: 'LM', label: 'Leitrim' },
      { value: 'LK', label: 'Limerick' },
      { value: 'LD', label: 'Longford' },
      { value: 'LH', label: 'Louth' },
      { value: 'MO', label: 'Mayo' },
      { value: 'MH', label: 'Meath' },
      { value: 'MN', label: 'Monaghan' },
      { value: 'OY', label: 'Offaly' },
      { value: 'RN', label: 'Roscommon' },
      { value: 'SO', label: 'Sligo' },
      { value: 'TA', label: 'Tipperary' },
      { value: 'WD', label: 'Waterford' },
      { value: 'WH', label: 'Westmeath' },
      { value: 'WX', label: 'Wexford' },
      { value: 'WW', label: 'Wicklow' },
    ],
  },

  // India
  IN: {
    format: {
      form: '{business_name}\n{address}\n{address2}\n{city}{postal_code}',
    },
    labelKeys: {
      postal_code: 'PIN code',
    },
  },

  // Italy
  IT: {
    ...COUNTRY_WITH_STATE,
    vatLabel: 'VAT number (Partita IVA, P.IVA)',
    vatHint: 'IT12345678901',
    labelKeys: {
      state: 'Province',
    },
    // For Italy the "state" (province) is on the second level
    stateAreaLevel: 'administrative_area_level_2',
    states: [
      { value: 'AG', label: 'Agrigento' },
      { value: 'AL', label: 'Alessandria' },
      { value: 'AN', label: 'Ancona' },
      { value: 'AO', label: 'Aosta' },
      { value: 'AR', label: 'Arezzo' },
      { value: 'AP', label: 'Ascoli Piceno' },
      { value: 'AT', label: 'Asti' },
      { value: 'AV', label: 'Avellino' },
      { value: 'BA', label: 'Bari' },
      { value: 'BT', label: 'Barletta-Andria-Trani' },
      { value: 'BL', label: 'Belluno' },
      { value: 'BN', label: 'Benevento' },
      { value: 'BG', label: 'Bergamo' },
      { value: 'BI', label: 'Biella' },
      { value: 'BO', label: 'Bologna' },
      { value: 'BZ', label: 'Bolzano' },
      { value: 'BS', label: 'Brescia' },
      { value: 'BR', label: 'Brindisi' },
      { value: 'CA', label: 'Cagliari' },
      { value: 'CL', label: 'Caltanissetta' },
      { value: 'CB', label: 'Campobasso' },
      { value: 'CI', label: 'Carbonia-Iglesias' },
      { value: 'CE', label: 'Caserta' },
      { value: 'CT', label: 'Catania' },
      { value: 'CZ', label: 'Catanzaro' },
      { value: 'CH', label: 'Chieti' },
      { value: 'CO', label: 'Como' },
      { value: 'CS', label: 'Cosenza' },
      { value: 'CR', label: 'Cremona' },
      { value: 'KR', label: 'Crotone' },
      { value: 'CN', label: 'Cuneo' },
      { value: 'EN', label: 'Enna' },
      { value: 'FM', label: 'Fermo' },
      { value: 'FE', label: 'Ferrara' },
      { value: 'FI', label: 'Firenze' },
      { value: 'FG', label: 'Foggia' },
      { value: 'FC', label: 'Forlì-Cesena' },
      { value: 'FR', label: 'Frosinone' },
      { value: 'GE', label: 'Genova' },
      { value: 'GO', label: 'Gorizia' },
      { value: 'GR', label: 'Grosseto' },
      { value: 'IM', label: 'Imperia' },
      { value: 'IS', label: 'Isernia' },
      { value: 'AQ', label: "L'Aquila" },
      { value: 'SP', label: 'La Spezia' },
      { value: 'LT', label: 'Latina' },
      { value: 'LE', label: 'Lecce' },
      { value: 'LC', label: 'Lecco' },
      { value: 'LI', label: 'Livorno' },
      { value: 'LO', label: 'Lodi' },
      { value: 'LU', label: 'Lucca' },
      { value: 'MC', label: 'Macerata' },
      { value: 'MN', label: 'Mantova' },
      { value: 'MS', label: 'Massa-Carrara' },
      { value: 'MT', label: 'Matera' },
      { value: 'VS', label: 'Medio Campidano' },
      { value: 'ME', label: 'Messina' },
      { value: 'MI', label: 'Milano' },
      { value: 'MO', label: 'Modena' },
      { value: 'MB', label: 'Monza e Brianza' },
      { value: 'NA', label: 'Napoli' },
      { value: 'NO', label: 'Novara' },
      { value: 'NU', label: 'Nuoro' },
      { value: 'OG', label: 'Ogliastra' },
      { value: 'OT', label: 'Olbia-Tempio' },
      { value: 'OR', label: 'Oristano' },
      { value: 'PD', label: 'Padova' },
      { value: 'PA', label: 'Palermo' },
      { value: 'PR', label: 'Parma' },
      { value: 'PV', label: 'Pavia' },
      { value: 'PG', label: 'Perugia' },
      { value: 'PU', label: 'Pesaro e Urbino' },
      { value: 'PE', label: 'Pescara' },
      { value: 'PC', label: 'Piacenza' },
      { value: 'PI', label: 'Pisa' },
      { value: 'PT', label: 'Pistoia' },
      { value: 'PN', label: 'Pordenone' },
      { value: 'PZ', label: 'Potenza' },
      { value: 'PO', label: 'Prato' },
      { value: 'RG', label: 'Ragusa' },
      { value: 'RA', label: 'Ravenna' },
      { value: 'RC', label: 'Reggio Calabria' },
      { value: 'RE', label: 'Reggio Emilia' },
      { value: 'RI', label: 'Rieti' },
      { value: 'RN', label: 'Rimini' },
      { value: 'RM', label: 'Roma' },
      { value: 'RO', label: 'Rovigo' },
      { value: 'SA', label: 'Salerno' },
      { value: 'SS', label: 'Sassari' },
      { value: 'SV', label: 'Savona' },
      { value: 'SI', label: 'Siena' },
      { value: 'SR', label: 'Siracusa' },
      { value: 'SO', label: 'Sondrio' },
      { value: 'TA', label: 'Taranto' },
      { value: 'TE', label: 'Teramo' },
      { value: 'TR', label: 'Terni' },
      { value: 'TO', label: 'Torino' },
      { value: 'TP', label: 'Trapani' },
      { value: 'TN', label: 'Trento' },
      { value: 'TV', label: 'Treviso' },
      { value: 'TS', label: 'Trieste' },
      { value: 'UD', label: 'Udine' },
      { value: 'VA', label: 'Varese' },
      { value: 'VE', label: 'Venezia' },
      { value: 'VB', label: 'Verbano-Cusio-Ossola' },
      { value: 'VC', label: 'Vercelli' },
      { value: 'VR', label: 'Verona' },
      { value: 'VV', label: 'Vibo Valentia' },
      { value: 'VI', label: 'Vicenza' },
      { value: 'VT', label: 'Viterbo' },
    ],
  },

  // Mexico
  MX: COUNTRY_WITH_STATE,

  // Malaysia
  MY: COUNTRY_WITH_STATE,

  // Norway
  NO: {
    uppercaseFields: ['city'],
  },

  // Netherlands
  NL: {
    vatLabel: 'VAT number (BTW-nr.)',
    vatHint: 'NL123456789B12',
  },

  // Poland
  PL: {
    vatLabel: 'VAT number (Numer identyfikacji podatkowej, NIP)',
    vatHint: 'PL1234567890',
  },

  // Sweden
  SE: {
    format: {
      form: '{business_name}\n{address}\n{address2}\n{postal_code} {city}',
      display:
        '{business_name}\n{address}\n{address2}\n{postal_code}  {city}\n{iso_country}',
    },
    vatLabel: 'Corporate ID (Organisationsnummer)',
    vatHint: '123456-7890',
  },
}
