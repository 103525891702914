import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { log } from '@soundtrack/utils/log'
import moment from 'moment'
import { graphql } from '#app/graphql/gql'
import { t } from '#app/lib/i18n/index'
import authorize from '#app/lib/spotify-authorization'
import toast from '#app/lib/toast'
import tracking, { trackOutcome } from '#app/lib/tracking'
import { type AppThunk } from '.'
import { authSlice } from './auth'
import { createAsyncThunk } from './redux'

const dateFormat = 'YYYYMMDDHHmmss'

export type TokenData = {
  accessToken?: string
  expiresIn?: number
  tokenType?: string
  connectionId?: string
}

export type SliceState = Readonly<{
  expiresAt?: string
  data?: TokenData
}>

const DEFAULT_STATE: SliceState = {} as const

function expiresAt(expiresIn: number) {
  return moment
    .utc()
    .add(expiresIn, 'seconds')
    .subtract(1, 'minute')
    .format(dateFormat)
}

const slice = createSlice({
  name: 'spotifyToken',
  initialState: DEFAULT_STATE,
  reducers: {
    setSpotifyToken: (state, action: PayloadAction<TokenData>) => {
      state.data = action.payload
      if (action.payload.expiresIn) {
        state.expiresAt = expiresAt(action.payload.expiresIn)
      }
      return state
    },
    unsetSpotifyToken: () => DEFAULT_STATE,
  },
  extraReducers: (builder) => {
    builder
      .addCase(authSlice.actions.reset, () => {
        return DEFAULT_STATE
      })
      .addCase(createSpotifyConnection.fulfilled, (state, action) => {
        const { accessToken, expiresIn, tokenType, connectionId } =
          action.payload

        state.data = {
          accessToken,
          expiresIn,
          tokenType,
          connectionId,
        }

        state.expiresAt = expiresAt(expiresIn)
        return state
      })
  },
  selectors: {
    token: (state: SliceState) => {
      const expiresAt = moment.utc(state.expiresAt, dateFormat)
      if (!expiresAt.isValid() || expiresAt.isBefore(moment.utc())) {
        return null
      }
      return state.data
    },
  },
})

const updatePlaybackOrder = createAsyncThunk(
  `${slice.name}/updatePlaybackOrder`,
  (
    {
      playlistId,
      playOrder,
    }: { playlistId: string; playOrder: 'linear' | 'shuffle' },
    { dispatch, extra: { apollo } },
  ) => {
    return apollo.mutate({
      mutation: UpdateSpotifyPlaylistMetadataDoc,
      variables: { playlistId, playOrder },
      fetchPolicy: 'network-only',
    })
  },
)

const createSpotifyConnection = createAsyncThunk(
  `${slice.name}/createSpotifyConnection`,
  (trackingContext: Record<string, any> = {}, { extra: { apollo } }) => {
    return authorize({ trackingContext }).then(({ code, redirectUri }) =>
      apollo
        .mutate({
          mutation: CreateSpotifyConnectionDoc,
          variables: { code, redirectUri },
        })
        .then(
          ...trackOutcome('Spotify Connect - Connect Authorized', {
            ...trackingContext,
          }),
        )
        .then(({ data }) => {
          const { createSpotifyConnection } = data || {}

          if (!createSpotifyConnection)
            throw new Error(
              'No data returned from createSpotifyConnection mutation',
            )

          const { expiresIn, ...rest } = createSpotifyConnection
          const tokenData = {
            ...rest,
            connectionId: createSpotifyConnection.id,
            expiresAt: Date.now() + expiresIn * 1000,
          }

          return {
            accessToken: tokenData.accessToken,
            expiresIn,
            tokenType: tokenData.tokenType,
            connectionId: tokenData.connectionId,
          }
        })
        .catch((error) => {
          toast.error(t('spotifyToken.failure.title'), {
            action: { label: t('common.retry'), onClick: authorize },
          })
          log.error(error)
          throw error
        }),
    )
  },
)

function disconnectSpotify(trackingContext?: Record<string, any>): AppThunk {
  return (dispatch, getState) => {
    const token = selectors.token(getState())
    if (!token) return
    tracking.track('Spotify Connect - Disconnect Intent', {
      ...trackingContext,
    })
    dispatch(slice.actions.unsetSpotifyToken())
  }
}

export const { reducer, selectors } = slice
export const actions = {
  ...slice.actions,
  createSpotifyConnection,
  disconnectSpotify,
  updatePlaybackOrder,
}
export default actions

const CreateSpotifyConnectionDoc = graphql(/* GraphQL */ `
  mutation CreateSpotifyConnection($code: String!, $redirectUri: String!) {
    createSpotifyConnection(code: $code, redirectUri: $redirectUri) {
      accessToken
      tokenType
      expiresIn
      scope
      id
    }
  }
`)

const UpdateSpotifyPlaylistMetadataDoc = graphql(/* GraphQL */ `
  mutation UpdateSpotifyPlaylistMetadata(
    $playlistId: ID!
    $playOrder: PlayOrder
  ) {
    updateSpotifyPlaylistMetadata(
      playlistId: $playlistId
      playOrder: $playOrder
    ) {
      ...PlaybackModePlaylist
    }
  }
`)
