import {
  type Action,
  type ImmutableStateInvariantMiddlewareOptions,
  type SerializableStateInvariantMiddlewareOptions,
  type UnknownAction,
  configureStore,
  isImmutableDefault,
  isPlain,
} from '@reduxjs/toolkit'
import { addBreadcrumb, getGlobalScope } from '@sentry/react'
import { Api, type Store } from '@soundtrackyourbrand/capsule'
import {
  type EnhancedCapsuleStore,
  type RemoteAction,
} from '@soundtrackyourbrand/capsule/dist/types'
import im from 'immutable'
import { capsuleStoreConfig } from './lib/capsule-config'
import * as persistence from './lib/redux-persistence'
import { type AppThunkExtra, extraThunk } from './lib/thunk'
import { middleware as listenerMiddleware } from './middleware/listener'
import sentryMiddleware from './middleware/sentry'
import rootReducer from './reducers'
import {
  createRendererProcessAdapter,
  syncMiddleware,
  syncReducer,
} from './reduxSync'

export type { AppThunkExtra } from './lib/thunk'

type NamespaceKey = keyof ReturnType<typeof rootReducer>

const IMMUTABLE_NAMESPACES = [
  'activation',
  'country',
  'currentAccount',
  'loadedAccounts',
  'notifications',
  'preview',
  'scheduleEditor',
  'searchPersisted',
  'spotifyToken',
] as const satisfies NamespaceKey[]

// Don't make redux-toolkit check immutability/serializability for stores with ImmutableJS
const ignoredPathsForLegacyReducers = [
  ...Object.keys(capsuleStoreConfig.reducer),
  ...IMMUTABLE_NAMESPACES,
]

// Place in its own function so we can extract the return type without creating circular type dependencies
function createStore() {
  return configureStore({
    reducer: syncReducer(rootReducer),
    devTools: {
      maxAge: 200,
    },
    middleware: (getDefaultMiddleware) => {
      let middleware = getDefaultMiddleware({
        thunk: {
          extraArgument: extraThunk,
        },

        serializableCheck: {
          isSerializable: (value) => {
            return (
              isPlain(value) ||
              // We store a lot of Dates in redux, which is an anti-pattern since they can be mutated.
              // In reality we should be storing Dates as strings.
              value instanceof Date
            )
          },
          ignoredPaths: [
            ...ignoredPathsForLegacyReducers,
            'modals',
            /playback\.[^.]+\.optimisticUpdates/,
            'tyson',
          ],
          ignoredActionPaths: ['query', 'data', 'error'],
          ignoredActions: [
            persistence.actions.register.type,
            'SHOW_MODAL',
            'PREVIEW_TRACKS',
            'PREVIEW_FINISHED',
            'PLAYBACK/OPTIMISTIC_UPDATE',
            'ACTIVATION_BEGIN',
          ],
        } satisfies SerializableStateInvariantMiddlewareOptions,

        immutableCheck: {
          isImmutable: (value) => {
            return isImmutableDefault(value) || im.isImmutable(value)
          },
          ignoredPaths: ignoredPathsForLegacyReducers,
        } satisfies ImmutableStateInvariantMiddlewareOptions,
      })
        .prepend(
          // Add capsule middleware before any other middleware as it transforms
          // actions of `REMOTE_ACTION` type into regular actions.
          capsuleStoreConfig.createRemoteActionMiddleware<RootState>(),
          listenerMiddleware,
        )
        .concat(
          sentryMiddleware({
            addBreadcrumb,
            getGlobalScope,
          }),
        )

      if (window.Syb) {
        middleware = middleware.prepend(
          syncMiddleware({
            actions: [
              /^(auth|desktop)\/.+/,
              'PLAYBACK/UPDATE_STATE',
              'TYSON/update',
            ],
            namespaces: ['auth', 'desktop', 'playback', 'tyson'],
            adapter: createRendererProcessAdapter(),
          }),
        ) as any as typeof middleware
      }

      return middleware
    },
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers().prepend(capsuleStoreConfig.enhancer),
  })
}

// RTK doesn't (yet) pick up on the extra props added to the store by the
// capsule enhancer. Here we'll manually set the type of the store to include
// these types, including the modified `store.dispatch()` to understand capsule's
// remote actions.
const _store = createStore()
export const store = _store as EnhancedCapsuleStore<
  typeof _store,
  AppThunkExtra
>

extraThunk.store = store

Api.registerStore(store)

export type Store = typeof store
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

/**
 * Used to provide typing of redux thunk actions
 *
 * @example
 * ```ts
 * const actions = {
 *   thunkAction: (...): AppThunk => async (dispatch, getState) => { ... },
 * }
 * ```
 */
export type AppThunk<ReturnType = any> = {
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    extraArgument: AppThunkExtra,
  ): ReturnType
}

/**
 * Used to provide typing of a redux thunk action that has already been bound.
 *
 * @example
 * ```ts
 * const myAction: BoundThunkDispatch<typeof myWrappedAction> = (...args) => {
 *   return dispatch(myWrappedAction(...args))
 * }
 * ```
 */
export type BoundThunkDispatch<T extends (...a: any) => (...b: any) => any> =
  ReturnType<T> extends AppThunk<infer Result>
    ? (...a: Parameters<T>) => Result
    : never

/**
 * Used to type check redux `actions` definitions.
 *
 * @example
 * ```ts
 * import type { ActionsType } from '.'
 * export const actions = {
 * } satisfies ActionsType
 * ```
 */
export type ActionsType<A extends Action = UnknownAction> = Record<
  string,
  (...args: any[]) => AppThunk<any> | RemoteAction<any, AppDispatch> | A
>

/**
 * Generates a union of all action returned by functions defined in a `actions`
 * object. Note that all returned objects that include a `type` property must be
 * cast to literals using `as const`.
 *
 * Disclaimer: `any` is returned if `actions` contain circular dependencies,
 * such as a function returning the result of another `actions` function call.
 *
 * @example
 * ```ts
 * import { UnknownAction } from '@reduxjs/toolkit'
 * import type { ActionsType, ExtractActions } from '.'
 * export const actions = {
 *   myAction: () => ({ type: 'MY_ACTION' as const }),
 * } satisfies ActionsType
 * export type Action = ExtractActions<typeof actions>
 * export function reducer(state: State, rawAction: UnknownAction): State {
 *   const action = rawAction as Action // referencing `Action` in function signature introduces circular dependency
 * }
 * ```
 */
export type ExtractActions<
  TActions extends Record<string, (...args: any[]) => object> = {},
> = ReturnType<TActions[keyof TActions]> extends infer R
  ? R extends { type: string }
    ? R
    : never
  : never | { type: '' }

/**
 * Generates a arbitrary action type that can be used as a fallback for complex reducers.
 * Use the optional `Type` generic to narrow down the list of untyped actions.
 *
 * @example
 * ```ts
 * type Action = UntypedAction<'MY_ACTION' | 'OTHER_ACTION'>
 * ```
 */
export interface UntypedAction<Type extends string = string>
  extends Action<Type> {
  type: Type
  [extraProps: string]: any
}

/** Standard selector callback that doesn't take any arguments */
export type SelectorType<ReturnType = unknown> = (
  state: RootState,
) => ReturnType

/**
 * Used to type check `selectors` exports.
 *
 * @example
 * ```ts
 * import type { SelectorsType } from '.'
 * export const selectors = { ... } as const satisfies SelectorsType
 * ```
 */
export type SelectorsType = Record<string, SelectorType>

export function hydrateStore(): Promise<void> {
  return (hydrateStore['_promise'] ||= new Promise<void>((resolve) =>
    setTimeout(() => {
      // Run persistStore asynchronously (setTimeout) to make sure that all top level
      // imports have run and that Sentry is loaded and prepared for error reporting
      // when reading previously persisted state.
      persistence.persistStore(store)
      resolve()
    }),
  ))
}
