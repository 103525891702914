import { Models } from '@soundtrackyourbrand/capsule'
import { ucfirst } from '@soundtrackyourbrand/capsule/dist/utils/strings'
import tracking from '#app/lib/tracking'
import { store } from '#app/store/index'

/**
 * Call this whenever checkout might have been completed.
 * Examples: checkout/steps/complete, sonos/pair.
 *
 * TODO: This should be done on the backend really...
 *
 * @param {string} accountId
 * @param {boolean} onboardingEnabled
 * @param {boolean} [recordEvent=true]
 */
export default function checkoutMaybeCompleted(
  accountId,
  onboardingEnabled,
  recordEvent = true,
  plan = '',
) {
  if (onboardingEnabled) {
    // Record completion timing + event
    // Timings trigger events via GTM if successful, but if a timingStart
    // hasn't happened in this session we need to trigger the event manually
    if (recordEvent) {
      const result = tracking.timingEnd('checkout.complete', true, {
        once: true,
        intercom: true,
      })
      if (result === 0) {
        tracking.track('Checkout - Complete', {
          intercom: true,
          hotjar: true,
          plan: ucfirst(plan),
        })
      }
    }

    store.dispatch(
      Models.Account.actions.toggleOnboarding({
        id: accountId,
        enable: false,
      }),
    )
  }
}
