const $$splitComponentImporter = () => import(/* webpackChunkName: "route-logged-out" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/(logged-out)/login.saml.index.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
export const Route = createFileRoute('/(logged-out)/login/saml/')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    showHeader: false,
    showFooter: false,
    popPage: true,
    showSidebarLayout: false,
    renderOnAccountSwitch: true,
    showNotifications: false
  }
});