import type {
  AnyAction,
  Dispatch,
  Middleware,
  MiddlewareAPI,
  Reducer,
} from '@reduxjs/toolkit'

export type SyncOptions = {
  /** Actions to send to other Redux instances */
  actions: Array<string | RegExp>
  /** Keys in the state to rehydrate from other Redux instances */
  namespaces?: string[]
  adapter: SyncAdapter
}

type SyncAdapter = {
  sendAction(action: AnyAction): void
  onAction(cb: (action: AnyAction) => void): void
  register?(api: MiddlewareAPI, options: SyncOptions): void
  requestState?(): Promise<object>
}

export function createRendererProcessAdapter(): SyncAdapter {
  if (!window.Syb) throw Error('Not running in electron')
  return {
    sendAction(action) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (action?.reduxSync) return
      window.Syb!.sendAction(action)
    },
    onAction(cb) {
      window.Syb!.onAction((action) => {
        Object.assign(action, { reduxSync: {} })
        cb(action)
      })
    },
    requestState() {
      return window.Syb!.requestState()
    },
  }
}

const ACTIONS = {
  /**
   * Loading state from other redux instances.
   */
  REHYDRATE: 'sync/REHYDRATE',
} as const

export type SyncMiddleware = Middleware
export function syncMiddleware(options: SyncOptions) {
  console.debug('[redux] starting synchronization', options)
  return <S = any, D extends Dispatch = Dispatch>(api: MiddlewareAPI<D, S>) => {
    options.adapter.register?.(api, options)
    options.adapter.onAction((action) => {
      api.dispatch(action)
    })

    if (options.adapter.requestState) {
      options.adapter.requestState().then((state: any) => {
        if (state) {
          api.dispatch({
            type: ACTIONS.REHYDRATE,
            state,
          })
        }
      })
    }

    return (next: Dispatch<AnyAction>) => (action: any) => {
      if (typeof action === 'object' && typeof action?.type === 'string') {
        if (
          options.actions.some((pattern) =>
            typeof pattern === 'string'
              ? pattern === action.type
              : pattern.test(action.type),
          )
        ) {
          options.adapter.sendAction(action)
        }
      }
      return next(action)
    }
  }
}

export function syncReducer<T extends Reducer>(reducer: T) {
  return (state: any, action: any) => {
    if (action?.type === ACTIONS.REHYDRATE) {
      state = { ...state, ...action.state }
    }
    return reducer(state, action)
  }
}
