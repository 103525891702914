import { apollo, graphql } from '#app/apollo/index'

export function tellFlagsAPIAboutSpotifyChoice(choice: string) {
  return tellFlagsAPIAboutKeyChoice(
    'account.spotify-import-during-onboarding',
    choice,
  )
}

// This will subsequently be used for opening/music hours and possibly more.
export function tellFlagsAPIAboutKeyChoice(key: string, choice: string) {
  return apollo.query({
    query: FlagsQueryDoc,
    variables: {
      choice,
      key,
    },
    // This query is in actuality a mutation and any cached result is not relevant
    fetchPolicy: 'network-only',
  })
}

// Note: flags is still marked as TEST in the schema
const FlagsQueryDoc = graphql(/* GraphQL */ `
  query FlagsQuery($key: String!, $choice: String!) {
    flags(properties: { stored: [{ key: $key, value: $choice }] }) {
      expiresAt
    }
  }
`)
