const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-settings" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/settings/users.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { allowedOr } from '#app/lib/authenticated';
export const Route = createFileRoute('/accounts/$accountId/settings/users')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: async () => {
    await allowedOr(['user.create'], '/accounts/$accountId/settings');
  },
  staticData: {
    meta: 'account.settings.tabs.users.meta'
  }
});