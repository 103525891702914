import { Models, Utils } from '@soundtrackyourbrand/capsule'
import type {
  SoundZone,
  ZoneStatusEnum,
} from '@soundtrackyourbrand/capsule/dist/models/types'
import { type ImmutableEntity } from '@soundtrackyourbrand/capsule/dist/utils/immutable'
import type * as im from 'immutable'
import moment from 'moment'

// If any of these errors are present on a zone they'll be displayed in this order.
const ORDER_BY_ERROR_CAUSE = [
  'DEVICE_ERROR_UNSUPPORTED_VERSION',
  'DEVICE_ERROR_SOON_UNSUPPORTED_VERSION',
]

/**
 * Sorts zone error based on importance/relevance and keeps the rest of the errors
 * in their original order.
 *
 * @param {im.Map} err - Zone error Map with a "clause" property
 */
export function zoneErrorsByRelevance(err: ImmutableEntity): number {
  const index = ORDER_BY_ERROR_CAUSE.indexOf(err.get('cause'))
  return index >= 0 ? -index - 1 : err.get('hide') ? -999 : 0 // list hidden errors last
}

/**
 * Returns a status code for the given zone.
 * Meant to be used to display status messages and actions.
 * More elaborate than `Models.SoundZone.statusForZone(zone)`.
 */
export function zoneStatusCode(
  zone: SoundZone,
  /** Exclude statuses that don't prevent playback */
  onlyCritical = false,
): string {
  const paidUntil = Utils.Dates.toUtcMoment(zone.get('iso8601_paid_until'))
  if (!moment(paidUntil).isAfter()) {
    return 'NO_SUBSCRIPTION'
  }

  if (Models.SoundZone.isSetup(zone)) {
    if (!onlyCritical) {
      const errors = zone.get('errors').sort(zoneErrorsByRelevance)
      const cause = errors.getIn([0, 'cause']) as string | undefined
      if (cause === 'Device offline') {
        return 'PLAYER_DISCONNECTED'
      }
      if (cause && !errors.getIn([0, 'hide'])) {
        return cause
      }
    }

    if (Models.SoundZone.isOffline(zone)) {
      return 'PLAYER_DISCONNECTED'
    }
  }

  return zone.getIn(['status', 'message']) as string
}

/**
 * Maps `Models.SoundZone.statusForZone(zone)` return value to a valid
 * `<StatusIndicator>` value.
 */
export function zoneStatusIndicator(status: string | null): ZoneStatusEnum {
  switch (status) {
    case 'ERROR':
      return 'error'
    case 'INACTIVE':
      return 'warning'
    case 'OK':
      return 'ok'
  }
  return 'default'
}

export function zoneIsSonos(zone: SoundZone) {
  return (
    zone.get('vendor_id') === 'SONOS' ||
    zone.get('device_platform', '').toLowerCase() === 'sonos'
  )
}

/**
 * Returns whether or not the subscription on a SoundZone can be paused.
 */
export function canPause(soundZone: SoundZone, account: ImmutableEntity) {
  const isMonthly = account.get('billing_cycle') === 'monthly'
  const pausedUntil = Models.Subscription.getReactivateAt(soundZone)
  return isMonthly && !pausedUntil
}

/**
 * Returns true for zones that are eligible for a 50% discount and don't have a
 * discount yet.
 */
export function canApplyDiscount(
  soundZone: SoundZone,
  account: ImmutableEntity,
) {
  Utils.AssertMapProps('soundZone', soundZone, [
    'pricing',
    'price_list',
    true,
  ] as any)

  const pricing = getPricingForAccount(
    soundZone.getIn(['pricing', 'price_list']) as ImmutableEntity,
    account,
  )
  if (!pricing || !pricing.getIn(['available_discount', 'prices'])) {
    return false
  }

  return canAccountApplyForDiscounts(account)
}

/**
 * Returns true for non-reseller accounts on monthly billing.
 */
export function canAccountApplyForDiscounts(account: ImmutableEntity) {
  return (
    account.get('billing_cycle') === 'monthly' &&
    Models.Account.hasPayment(account)
  )
}

/**
 * A zone's price list can contain multiple prices for different tiers and billing cycles.
 * This method looks for a price plan matching the account's current billing cycle and tier.
 */
export function getPricingForAccount(
  /** A price list as found in soundZone.pricing */
  priceList: ImmutableEntity,
  account: ImmutableEntity,
) {
  return priceList.find(
    (item) =>
      item.get('billing_cycle') === account.get('billing_cycle') &&
      item.get('tier') === account.get('tier'),
  )
}

export function filterZones<T extends im.Collection<any, SoundZone>>(
  zones: T,
  filters: {
    q?: string
    region?: string
    status?: string
  } = {},
): T {
  if (!filters.q && !filters.region && !filters.status) return zones

  const q = filters.q?.toLowerCase()

  return zones.filter((zone) => {
    if (filters.region && zone.get('iso_country') !== filters.region) {
      return false
    }
    if (filters.status && !filterZoneStatus(filters.status, zone)) {
      return false
    }
    return (
      !q ||
      ZONE_FILTER_QUERY_FIELDS.some(
        (field) => zone.get(field, '').toLowerCase().indexOf(q) > -1,
      )
    )
  })
}

/** Used by {@link filterZones} */
const ZONE_FILTER_QUERY_FIELDS = [
  'name',
  'location_name',
  'address',
  'city',
  'state',
] as const

function filterZoneStatus(status: string, zone: SoundZone): boolean {
  if (status === 'NEEDS_UPDATE') {
    return zone
      .get('errors')
      .some((err) => NEEDS_UPDATE_ERRORS.includes(err.get('cause')))
  }
  return status === Models.SoundZone.statusForZone(zone)
}

/** Used by {@link filterZoneStatus} */
const NEEDS_UPDATE_ERRORS = [
  'DEVICE_ERROR_UNSUPPORTED_VERSION',
  'DEVICE_ERROR_SOON_UNSUPPORTED_VERSION',
] as const
