const $$splitComponentImporter = () => import(/* webpackChunkName: "route-create" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/create/station.from-playlist.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensureLoggedIn, ensureNotOnStarterPlan } from '#app/route-lib';
export const Route = createFileRoute('/create/station/from-playlist')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: ({
    context,
    location
  }) => {
    ensureLoggedIn(location);
    ensureNotOnStarterPlan(context.store);
  }
});