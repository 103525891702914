import { type NavigateOptions } from '@tanstack/react-router'
import { getRouter } from '#app/lib/router'

/** Returns current URL pathname & query string (if present), but omits everything else */
export function currentLocation() {
  const loc = getRouter().latestLocation
  return (
    loc.pathname + ((loc.searchStr as unknown) == null ? '' : loc.searchStr)
  )
}

/**
 * Attempt to determine if the previous history (URL) is still within the app.
 * Not 100% reliable, but good enough for our purposes.
 */
export function canGoBack() {
  return getRouter().latestLocation.state.key != null
}

/**
 * Attempt to go back in history. If doing so would likely lead to leaving the
 * app, then go to the fallback URL instead.
 */
export function goBackWithFallback(fallbackNavigation: NavigateOptions) {
  if (canGoBack()) {
    getRouter().history.back()
  } else {
    getRouter().navigate(fallbackNavigation)
  }
}
