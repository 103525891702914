import { findScrollParent as uiFindScrollParent } from '@soundtrackyourbrand/ui'

export function scrollIntoView(element, limit = true) {
  if (!element) {
    return
  }

  const y = element.getBoundingClientRect().top
  if (y < 0 || !limit) {
    try {
      window.scrollTo({
        top: window.pageYOffset + y,
        left: window.pageXOffset,
        behavior: limit ? 'auto' : 'smooth',
      })
    } catch (error) {
      // Handle old browsers
      window.scrollTo(window.pageXOffset, window.pageYOffset + y)
    }
  }
}

export function findScrollParent(element) {
  const target = uiFindScrollParent(element)
  return target === document.documentElement ? document.body : target
}
