import { Forms } from '@soundtrackyourbrand/ui'
import { t } from '#app/lib/i18n/index'

export function countryOptionsFromI18n(i18n) {
  return i18n
    .get('country_names')
    .delete('ZZ')
    .map(Forms.Select.toOptions)
    .valueSeq()
    .sortBy(Forms.Select.labelSort)
    .toArray()
}

export function billingGroupOptions(billing_groups) {
  return billing_groups
    .valueSeq()
    .map((bg) => {
      let label = bg.get('name')
      if (bg.get('default')) {
        label += ' (' + t(`billingGroup.default`, false) + ')'
      }
      return { label, value: bg.get('id') }
    })
    .toArray()
}

const pad = (n) => (n < 10 ? '0' : '') + n

function createHourOptions(start, count) {
  const options = []
  for (let i = 0; i < count; i++) {
    const v = start + i
    options.push({ label: `${pad(v)}:00`, value: v })
  }
  return options
}

export const HourOptions = createHourOptions(0, 24)
