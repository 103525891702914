import { useCallback, useLayoutEffect, useRef } from 'react'

/**
 * Returns a stable function that when called, calls handler from the
 * lastest render.
 *
 * NOTE: Must not be called during render since that will use handler
 * from previous render. Will throw in a future version of React.
 *
 * From React RFC
 * https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
export function useEvent<T extends (...args: any[]) => any>(handler: T): T {
  const handlerRef = useRef<T>(handler)

  useLayoutEffect(() => {
    handlerRef.current = handler
  })

  return useCallback((...args) => {
    const fn = handlerRef.current
    return fn(...args)
  }, []) as any
}
