const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-checkout" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/checkout/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
export const Route = createFileRoute('/accounts/$accountId/checkout')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'checkout.meta',
    accountSwitch: '/home',
    appearance: 'none',
    showFooter: false,
    showNotifications: false,
    showSidebarLayout: false
  }
});