import { Validate } from '@soundtrackyourbrand/capsule'
import memoizeOne from 'memoize-one'
import { t } from '#app/lib/i18n/index'
import { COUNTRIES, COUNTRY_DEFAULT } from './countries-data'

export const getCountry = memoizeOne((isoCountry) => {
  const country = COUNTRIES[isoCountry]
  return {
    ...COUNTRY_DEFAULT,
    ...country,
  }
})

export const getAddressFields = memoizeOne(
  (isoCountry, formatType = 'form') => {
    const country = getCountry(isoCountry)
    const format = (country?.format || COUNTRY_DEFAULT.format)[formatType]

    return format.split('\n').map((line) => {
      const fields = line.match(/{(\w+)}/g)
      if (!fields) {
        return []
      }
      return fields.map((field) => field.replace(/[{}]/g, ''))
    })
  },
)

export function getLabelKeyByCountry(isoCountry, key) {
  const country = getCountry(isoCountry)
  return country.labelKeys[key] || key
}

const optionalValidationDefault = () => true

export function addressValidations(isoCountry, pathPrefix = '') {
  const rules = {}

  // Make all fields not required to overwrite base rules created in capsule, for example for
  // `validationsForType('billing_group')` which currently does not consider country and expects
  // the same fields to be set.
  const fields = [
    'business_name',
    'address',
    'address2',
    'postal_code',
    'state',
    'city',
  ]
  fields.forEach((field) => {
    switch (field) {
      case 'address2':
        rules[pathPrefix + field] = Validate.Validations.maxLength(150)
        break
      default:
        rules[pathPrefix + field] = optionalValidationDefault
    }
  })

  const { requiredFields } = getCountry(isoCountry)
  requiredFields.forEach((fieldName) => {
    const path = pathPrefix + fieldName
    rules[path] = (() => {
      switch (fieldName) {
        case 'postal_code': {
          return Validate.Validations.isPostalCodeIsh
        }
        case 'state': {
          return Validate.Validations.rule(
            (value, model) => {
              const required = requiredFields.includes('state')
              return !required || (value && value.length)
            },
            () => t('signup.form.stateFieldError'),
          )
        }
        case 'address': {
          return [
            Validate.Validations.notEmpty,
            Validate.Validations.maxLength(150),
          ]
        }
        default: {
          return Validate.Validations.notEmpty
        }
      }
    })()
  })

  return rules
}

export default COUNTRIES
