import type { Action } from '@reduxjs/toolkit'
import { type Mutable } from '@soundtrack/utils/types'
import type { FragmentOf } from '@soundtrackyourbrand/apollo-client'
import { getIn } from '@soundtrackyourbrand/object-utils.js'
import { filterTracks } from '#app/components/track-list/filterTracks'
import {
  type TrackFragment,
  TracklistDoc,
} from '#app/components/track-list/graphql'
import type { AlbumPageFragment, IsoCountry } from '#app/graphql/graphql'
import { type SourceWithComposer } from '#app/lib/source'
import type { Identifiable } from '#app/lib/types'
import { createSelector } from '#app/store/redux'
import type { ActionsType, AppThunk, RootState, SelectorsType } from '.'
import { selectors as currentAccountSelectors } from './current-account'
import { type ImmutableReducer } from './redux'

/** Playlist/station/track/album entity to preview - any type supported by `previewPolymorphic()` */
export type PreviewableSource =
  | FragmentOf<SourceWithComposer, 'Playlist'>
  | AlbumPageFragment
  | TrackFragment

export type PreviewableKind =
  | 'Playlist'
  | 'Station'
  | 'Album'
  | 'Track'
  | 'Tracks'

export const actions = {
  previewPolymorphic(source: PreviewableSource): Action | AppThunk {
    switch (source.__typename) {
      case 'Playlist':
        return actions.previewPlaylist(source.id)
      case 'Track':
        return actions.previewTracks([source], source)
      case 'Album':
        return actions.previewAlbum(source)
      default:
        throw new Error(
          `previewPolymorphic: Unsupported source '${(source as any).__typename}'`,
        )
    }
  },

  previewTracks(tracks: TrackFragment[], source?: PreviewableSource): AppThunk {
    return (dispatch, getState) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (source?.__typename !== 'Track' && tracks) {
        const currentState = getState()
        const currentAccount = currentAccountSelectors.account(currentState)
        tracks = filterTracks(tracks, {
          account: currentAccount,
          unpreviewable: false,
          unavailable: false,
          country: currentAccountSelectors.country(getState())!,
        })
      }
      dispatch(actions.previewTracksUnfiltered(tracks, source))
    }
  },

  previewTracksUnfiltered(tracks: TrackFragment[], source?: PreviewableSource) {
    const multiple = tracks.length > 1
    return {
      type: 'PREVIEW_TRACKS' as const,
      tracks,
      source: source || (multiple ? undefined : tracks[0]),
      previewLength: tracks.length > 1 ? 10 : 30,
    }
  },

  previewAlbum(album: AlbumPageFragment): AppThunk {
    return (dispatch) => {
      dispatch(
        actions.previewTracks(
          album.tracks?.edges.map((x) => x.node) || [],
          album,
        ),
      )
    }
  },

  previewPlaylist(playlistId: string): AppThunk {
    return (dispatch, getState, { apollo }) => {
      dispatch({ type: 'PREVIEW_PLAYLIST', playlistId })

      function isStillActive() {
        const state = getState()
        return (
          selectors.sourceId(state) === playlistId &&
          selectors.kind(state) === 'Playlist'
        )
      }

      apollo
        .query({
          query: TracklistDoc,
          variables: {
            playlistId,
            market: currentAccountSelectors.country(getState())! as IsoCountry,
            first: 50,
          },
          fetchPolicy: 'cache-first',
        })
        .then((result) => {
          const { playlist } = result.data
          if (!isStillActive() || !playlist) return
          dispatch(
            actions.previewTracks(
              playlist.tracks?.edges.map((x) => x.node!) || [],
              playlist,
            ),
          )
        })
        .catch((error) => {
          if (!isStillActive()) return
          dispatch({ type: 'PREVIEW_PLAYLIST_FAILURE', error })
        })
    }
  },

  playPause: () => (dispatch, getState) => {
    const state = getState()
    const playbackState = selectors.playbackState(state)

    dispatch(
      playbackState === PLAYBACK_STATES.playing ||
        playbackState === PLAYBACK_STATES.loading
        ? actions.pause()
        : actions.play(),
    )
  },
  play: () => ({ type: 'PREVIEW_PLAY' }),
  pause: () => ({ type: 'PREVIEW_PAUSE' }),
  stop: () => ({ type: 'PREVIEW_STOP' }),
  finished: () => ({ type: 'PREVIEW_FINISHED' }),

  updateFooterOffsetPosition(footerOffsetPosition: number) {
    return {
      type: 'UPDATE_PREVIEW_PLAYER_OFFSET',
      footerOffsetPosition,
    }
  },
  updateMiniPlayerOffsetPosition(miniPlayerOffsetPosition: number) {
    return {
      type: 'UPDATE_MINI_PLAYER_OFFSET',
      miniPlayerOffsetPosition,
    }
  },
} satisfies ActionsType

export default actions

const source = (state: RootState) => state.preview.source
const sourceId = (state: RootState) => state.preview.source?.id
const index = (state: RootState) => state.preview.index
const tracks = (state: RootState) => state.preview.tracks

const track = createSelector(
  [index, tracks],
  (index, tracks) => tracks?.[index],
)
const nextTrack = createSelector(
  [index, tracks],
  (index, tracks) => tracks?.[index + 1],
)

export const selectors = {
  index,
  tracks,
  track,
  nextTrack,
  source,
  sourceId,
  kind: createSelector(
    [source, tracks],
    (source, tracks): PreviewableKind | undefined => {
      const type = source?.__typename
      if (!type) return type
      if (type === 'Playlist' && source.presentAs === 'station')
        return 'Station'
      if (type === 'Track' && (tracks?.length || 0) > 1) return 'Tracks'
      return type
    },
  ),
  footerOffsetPosition: (state: RootState) =>
    state.preview.footerOffsetPosition,
  miniplayerOffsetPosition: (state: RootState) =>
    state.preview.miniplayerOffsetPosition,
  combinedOffsetPosition: (state: RootState) =>
    state.preview.footerOffsetPosition + state.preview.miniplayerOffsetPosition,
  preview: (state: RootState) => state.preview,
  playbackState: (state: RootState) => state.preview.playbackState,
  playing: (state: RootState) =>
    state.preview.playbackState === PLAYBACK_STATES.playing,
  isOpen: (state: RootState) => !!state.preview.source,
  isActive: (state: RootState) => state.preview.isActive,
} as const satisfies SelectorsType

const TRACK_HISTORY_LENGTH = 12

export const PLAYBACK_STATES = {
  stopped: 'stopped',
  loading: 'loading',
  loadingThenPause: 'loadingThenPause',
  playing: 'playing',
  paused: 'paused',
} as const

export type PlaybackState = keyof typeof PLAYBACK_STATES | null

export function playbackStateIsLoading(playbackState: PlaybackState) {
  return (
    playbackState === PLAYBACK_STATES.loading ||
    playbackState === PLAYBACK_STATES.loadingThenPause
  )
}

const INITIAL_STATE = {
  tracks: undefined as TrackFragment[] | undefined,
  index: 0 as number,
  playbackState: PLAYBACK_STATES.stopped as PlaybackState,
  source: undefined as PreviewableSource | undefined,
  error: undefined as Error | undefined,
  trackHistory: [] as string[],
  previewLength: 10 as number, // Length per track, used when previewing a Collection
  isActive: (source: Identifiable | undefined) => false,
  footerOffsetPosition: 0 as number,
  miniplayerOffsetPosition: 0 as number,
} as const

export type PreviewState = typeof INITIAL_STATE

export const reducer: ImmutableReducer<PreviewState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case 'PREVIEW_PLAYLIST': {
      return injectIsActive({
        ...INITIAL_STATE,
        trackHistory: state.trackHistory,
        playbackState: PLAYBACK_STATES.loading,
        source: {
          __typename: 'Playlist',
          id: action.playlistId,
        } as PreviewableSource,
        footerOffsetPosition: state.footerOffsetPosition,
        miniplayerOffsetPosition: state.miniplayerOffsetPosition,
      })
    }

    case 'PREVIEW_PLAYLIST_FAILURE':
      return {
        ...state,
        playbackState: PLAYBACK_STATES.stopped,
        error: action.error,
      }

    case 'UPDATE_PREVIEW_PLAYER_OFFSET':
      return {
        ...state,
        footerOffsetPosition: action.footerOffsetPosition,
      }

    case 'UPDATE_MINI_PLAYER_OFFSET':
      return {
        ...state,
        miniplayerOffsetPosition: action.miniPlayerOffsetPosition,
      }

    case 'PREVIEW_TRACKS':
      const { tracks, source } = action
      const track = tracks?.[state.index]
      return injectIsActive({
        ...INITIAL_STATE,
        source,
        tracks,
        trackHistory: track
          ? state.trackHistory.concat([track]).slice(-TRACK_HISTORY_LENGTH)
          : state.trackHistory,
        playbackState:
          (action.source?.id || false) === state.source?.id &&
          state.playbackState === PLAYBACK_STATES.loadingThenPause
            ? PLAYBACK_STATES.paused
            : PLAYBACK_STATES.playing,
        previewLength: action.previewLength || INITIAL_STATE.previewLength,
        footerOffsetPosition: state.footerOffsetPosition,
        miniplayerOffsetPosition: state.miniplayerOffsetPosition,
      })

    case 'PREVIEW_PLAY':
      if (!state.source) return state
      return {
        ...state,
        playbackState: playbackStateIsLoading(state.playbackState)
          ? PLAYBACK_STATES.loading
          : PLAYBACK_STATES.playing,
      }

    case 'PREVIEW_PAUSE':
      return {
        ...state,
        playbackState: playbackStateIsLoading(state.playbackState)
          ? PLAYBACK_STATES.loadingThenPause
          : PLAYBACK_STATES.paused,
      }

    case 'PREVIEW_STOP':
      return {
        ...INITIAL_STATE,
        trackHistory: state.trackHistory,
        footerOffsetPosition: state.footerOffsetPosition,
        miniplayerOffsetPosition: state.miniplayerOffsetPosition,
      }

    case 'PREVIEW_FINISHED':
      const index = state.tracks ? (state.index + 1) % state.tracks.length : 0
      return {
        ...state,
        index,
        playbackState:
          index === 0 ? PLAYBACK_STATES.stopped : state.playbackState,
      }
  }

  return state
}

function injectIsActive(preview: Mutable<PreviewState>): PreviewState {
  const sourceId = preview.source?.id
  preview.isActive = (input) => {
    return !!sourceId && sourceId === getIn(input, 'id')
  }
  return preview
}
