import type { ImmutableEntity } from '@soundtrackyourbrand/capsule/dist/utils/immutable'
import { Breakpoint } from '@soundtrackyourbrand/ui'
import { useMatches } from '@tanstack/react-router'
import React from 'react'
import { BREAKPOINTS } from '#app/lib/breakpoints'
import overrides from '#app/lib/overrides'
import checkout from '#app/store/checkout'
import * as currentAccount from '#app/store/current-account'
import { useSelector } from '#app/store/redux'
import shallowEquals from './shallow-equals'

export type NavigationAppearance =
  | 'none'
  | 'public'
  | 'signup'
  | 'onboarding'
  | 'full'
  | 'login'

export type LayoutConfig = {
  /** Show notification bar in the top for this route? */
  showNotifications?: boolean
  /** Show app footer? */
  showFooter?: boolean
  /** Show app header? */
  showHeader?: boolean
  /** Show layout with sidebar*/
  showSidebarLayout?: boolean
  /** Use pop page theme? */
  popPage?: boolean
  /** Header variation (controls visible navigation links) */
  appearance?: NavigationAppearance
  /**
   * Route to redirect to when switching account, examples:
   * - `~/zones` => `/accounts/:account/zones`
   * - `/home` => `/home`
   */
  accountSwitch?: string
  /** Render app on account switch */
  renderOnAccountSwitch?: boolean
}

const DEFAULT_CONFIG = {
  appearance: 'public',
  showFooter: !IS_ELECTRON,
  showHeader: true,
  showNotifications: true,
  showSidebarLayout: true,
  popPage: false,
  renderOnAccountSwitch: false,
} as const satisfies LayoutConfig

export const LayoutContext = React.createContext<LayoutConfig>(DEFAULT_CONFIG)

/**
 * Hook to get the layout configuration for the current route.
 *
 * @param isLoggedIn - Whether the user is logged in
 * @param account - The current account
 */
export function useLayoutConfigProvider(
  isLoggedIn: boolean,
  account?: ImmutableEntity,
) {
  const isNotActivated = useSelector(checkout.isNotActivated)

  const isMediumOrBiggerBreakpoint = Breakpoint.useBreakpoint() >= BREAKPOINTS.m

  const activeConfig = React.useRef<LayoutConfig>(DEFAULT_CONFIG)

  const routeConfig = useMatches({
    select: (matches) => {
      const config: LayoutConfig = {}
      matches.forEach((match) => {
        Object.assign(config, match.staticData)
      })

      return config
    },
  })

  const config: LayoutConfig = {
    ...DEFAULT_CONFIG,
    appearance: account
      ? isNotActivated &&
        !currentAccount.hasMissingPaymentMethod(account.get('payment_method'))
        ? 'onboarding'
        : 'full'
      : isLoggedIn
        ? 'full'
        : 'public',
    showSidebarLayout: isMediumOrBiggerBreakpoint,
    ...routeConfig,
  }

  if (overrides.get('chrome') === '0') {
    config.showHeader = config.showFooter = config.showSidebarLayout = false
  }

  if (!shallowEquals(activeConfig.current, config)) {
    activeConfig.current = config
  }

  return activeConfig.current
}

export function useLayoutConfig() {
  return React.useContext(LayoutContext)
}
