import type { DecoratedError } from '.'

export class AppNotFoundError extends Error implements DecoratedError {
  isAppNotFound = true
  isExpected = false
  errorType = 'notFound'
  errorBoundary = 'notFound'
  status = 404
  pathName: string

  constructor(message: string, pathName: string) {
    super(message)
    this.pathName = pathName
  }
}

/** Similar to tanstack-router's isNotFound, but for our own NotFoundError (which includes more useful info) */
export function isAppNotFoundError(error: any): error is AppNotFoundError {
  return !!error?.isAppNotFound
}
