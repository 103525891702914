const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-zones" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/zones/$zoneId.player.$action.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { allowedTo } from '#app/lib/authenticated';
import { allowedActionsSchema, routeParams } from '#app/modules/accounts/zone/subscription/action-route';
export const Route = createFileRoute('/accounts/$accountId/zones/$zoneId/player/$action')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  params: routeParams,
  beforeLoad: ({
    params
  }) => {
    if (!allowedActionsSchema.safeParse(params.action).success || !allowedTo(['zone.activate'], params.accountId)) {
      throw redirect({
        to: '..',
        from: Route.fullPath,
        replace: true
      });
    }
  }
});