import type { ResultOf } from '@graphql-typed-document-node/core'
import { graphql } from '#app/graphql/index'

export type { AddTracksModalTrackFragment as AddTracksModalTrack } from '#app/graphql/graphql'

graphql(/* GraphQL */ `
  fragment AddTracksModalTrack on Track {
    ...TrackInfo
    ...Displayable
    previewUrl
    isAvailable(market: $market)
  }
`)

export const AddTracksModalRecommendationDoc = graphql(/* GraphQL */ `
  query AddTracksModalRecommendation(
    $tracks: [ID!]!
    $market: IsoCountry!
    $first: Int!
    $after: String
  ) {
    result: playlistEditorTrackSuggestions(
      tracks: $tracks
      first: $first
      after: $after
    ) {
      total
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...AddTracksModalTrack
        }
      }
    }
  }
`)

export const AddTracksModalSearchDoc = graphql(/* GraphQL */ `
  query AddTracksModalSearch(
    $query: String!
    $market: IsoCountry!
    $first: Int!
  ) {
    result: search(query: $query, first: $first, type: track, market: $market) {
      edges {
        node {
          ...AddTracksModalTrack
        }
      }
    }
  }
`)

type AddTracksSearchResult = ResultOf<typeof AddTracksModalSearchDoc>
export type AddTracksSearchResultEdge = NonNullable<
  AddTracksSearchResult['result']
>['edges'][number]
export type AddTracksSearchResultTrackEdge = AddTracksSearchResultEdge & {
  node: Extract<AddTracksSearchResultEdge['node'], { __typename: 'Track' }>
}
