import { tokeniseSpotifyUrl } from './spotify'

/* Parsers */

type Parser<T> = {
  matches: string[]
  transform: (data: string, dt?: DataTransfer) => T
}

// Add additional parsers here as needed:
const parsers = {
  file: {
    matches: ['Files', 'files'],
    transform: (_data, dt): File[] => Array.from(dt?.files || []),
  },
  url: {
    matches: ['text/uri-list', 'text/html'],
    transform: (data): string[] | null => (data ? data.split('\n') : null),
  },
  text: {
    matches: ['text', 'text/plain'],
    transform: (data): string => data,
  },
  spotifyUrl: {
    matches: ['url', 'text', 'text/plain', 'text/uri-list'],
    transform: (data) => tokeniseSpotifyUrl(data),
  },
} satisfies { [Parser: string]: Parser<unknown> }

export type ParserType = keyof typeof parsers

export type ParserResult<T extends ParserType> = ReturnType<
  (typeof parsers)[T]['transform']
>

/* Handlers */

/** Attempt to parse the data from a {@link DataTransfer} using a given {@link ParserType}. */
export function getData<T extends ParserType>(
  parser: T,
  dataTransfer: DataTransfer | null,
): ParserResult<T> | null {
  const { matches, transform } = parsers[parser]
  for (const match of matches) {
    // IE11 throws error for unknown types (and doesn't recognize text/plain, derp)
    try {
      const result = dataTransfer?.getData(match)
      if (result) {
        return transform(result, dataTransfer ?? undefined) as ParserResult<T>
      }
    } catch (err) {
      continue
    }
  }
  return null
}

/** Do the datatypes of a {@link DataTransfer} match those listed for a given {@link ParserType}? */
export function matchesDataType<T extends ParserType>(
  parser: T,
  dataTransfer: DataTransfer | null,
): boolean {
  const { matches } = parsers[parser]
  return dataTransfer?.types.some((type) => matches.includes(type)) ?? false
}
