import type React from 'react'
import {
  type ToastOptions as BaseToastOptions,
  toast,
} from 'react-hot-toast/headless'
import { BREAKPOINTS } from '#app/lib/breakpoints'

/** These custom options are added to the Toast object */
export type CustomToastOptions = {
  body?: React.ReactNode
  /** If only `label` is set it will act as a custom dismiss button */
  action?:
    | { label: React.ReactNode }
    | { label: React.ReactNode; onClick: () => void }
    | { label: React.ReactNode; to: string }
  /**
   * Force hide the dismiss "x" button, only do this if there's a custom action
   * or behaviour dismissing the toast for the user
   */
  hideDismissButton?: boolean
  /**
   * Called when the toast closed, with a parameter indicating whether the user explicitly dismissed the toast by pressing "X"
   * @param isDismissedByUser Whether the toast was dismissed by the user
   */
  onClose?: (isDismissedByUser: boolean) => void
  /** Props to be passed to tracking events */
  trackingProps?: Record<string, any>
}

type ToastOptions = BaseToastOptions & CustomToastOptions

export function success(
  message: Parameters<typeof toast.success>[0] | React.ReactChild[],
  options?: ToastOptions,
) {
  return toast.success(message as any, transformOptions(options))
}

export function loading(
  message: Parameters<typeof toast.loading>[0] | React.ReactChild[],
  options?: ToastOptions,
) {
  return toast.loading(message as any, transformOptions(options))
}

export function error(
  message: Parameters<typeof toast.error>[0] | React.ReactChild[],
  options?: ToastOptions,
) {
  return toast.error(message as any, transformOptions(options))
}

export function custom(
  message: Parameters<typeof toast.custom>[0] | React.ReactChild[],
  options?: ToastOptions,
) {
  return toast.custom(message as any, transformOptions(options))
}

/**
 * When passing `Infinity` for duration the toasts aren't properly cleaned up when dismissed (they stay in the store and
 * are kept rendered invisibly). This workaround replaces the duration with a very high finite duration.
 */
const ALMOST_INFINITY = 1_000_000_000

function transformOptions(options?: ToastOptions): ToastOptions | undefined {
  if (options) {
    if (options.duration && !Number.isFinite(options.duration)) {
      options.duration = ALMOST_INFINITY
    }

    // On mobile, force the same space for both left and right toasts as they cannot be shown side-by-side
    if (
      options.position === 'bottom-right' &&
      window.innerWidth < BREAKPOINTS.sm
    ) {
      options.position = 'bottom-left'
    }
  }
  return options
}

export default {
  ...toast,
  success,
  error,
  loading,
  custom,
  // promise: toast.promise,
}
