import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getPairingGuideReturnPath } from '#app/components/pairing-guide/vendors'
import overrides from '#app/lib/overrides'
import type { OnboardingStepName } from '#app/modules/accounts/onboarding/common/definitions'
import { createSelector } from '#app/store/redux'
import { type SelectorsType } from '.'

// If onboarding is aborted and restarted we could get previousMusicProvider via the accounts property in GQL.
// Idea: We could also save onboarding progress to the growth api.
//   Needs to keep track of what account is currently used.

const INITIAL_STEPS = [
  'trial-select',
  'address',
  'payment-method',
  'setup',
  'tell-us-more',
] as OnboardingStepName[]

const slice = createSlice({
  name: 'onboarding',
  initialState: {
    explicitUIToggle: false as boolean,
    previousMusicProvider: '' as string,
    selectedTrialLength: 0 as number,
    // Below is the intended onboarding step order as imported. This however creates a circular dependency.
    //steps: onboardingStepArray.map((s) => s.name) as OnboardingStepName[],
    steps: [
      ...INITIAL_STEPS,
      'get-started',
      'business-description',
      'spotify-import',
      'explicit-setting',
      'opening-hours',
    ] as OnboardingStepName[],
  },
  reducers: {
    setExplicitUIToggle: (state, action: PayloadAction<boolean>) => {
      state.explicitUIToggle = action.payload
    },
    /**
     * Previous music provider is also set by apollo and is accessible via the account query.
     * It is however async and the info being set is known from the form being submitted.
     * Setting it with this action avoids the race condition.
     */
    setPreviousMusicProvider: (state, action: PayloadAction<string>) => {
      state.previousMusicProvider = action.payload
    },
    setSelectedTrialLength: (state, action: PayloadAction<number>) => {
      state.selectedTrialLength = action.payload
    },
  },
  selectors: {
    explicitUIToggle(state) {
      return state.explicitUIToggle
    },
    previousMusicProvider(state) {
      return state.previousMusicProvider
    },
    selectedTrialLength(state) {
      return state.selectedTrialLength
    },
  },
})

export const { actions, reducer } = slice

export default actions

// Move selectors below into this object once we decide to keep this approach
export const selectors = {
  ...slice.selectors,

  onboardingSteps: createSelector(
    [(state) => state.onboarding.steps],
    (steps) => {
      const inPairingGuide = !!overrides.get('pairing-guide')
      const hasAiPrompt = !!overrides.get('ai-playlist-prompt')
      // Retain original steps reference
      const activeSteps = steps

      // In pairing guide (skip onboarding)
      if (inPairingGuide) {
        // Return all active steps until and including setup step
        const setupIndex = activeSteps.indexOf('setup')
        return activeSteps.splice(setupIndex + 1)
      }
      // Replace feature steps with AI playlist if there's a stored prompt
      if (hasAiPrompt) {
        return [
          ...INITIAL_STEPS,
          'ai-playlist',
          'explicit-setting',
        ] as OnboardingStepName[]
      }
      return activeSteps
    },
  ),
} satisfies SelectorsType

export function getPostOnboardingPath(): string {
  return getPairingGuideReturnPath() ?? '/setup-guide'
}
