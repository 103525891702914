const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-zones" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/zones/$zoneId.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-zones" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/zones/$zoneId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import tracking from '#app/lib/tracking';
import { ensurePaddedId } from '#app/lib/urls';
export const Route = createFileRoute('/accounts/$accountId/zones/$zoneId')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'soundZone.meta'
  },
  beforeLoad: ({
    params
  }) => {
    ensurePaddedId(params, 'zoneId');
  },
  loader: lazyFn($$splitLoaderImporter, 'loader'),
  onEnter: ({
    params
  }) => {
    tracking.track('Zone - View', {
      zone_id: params.zoneId
    });
  },
  pendingMs: 0,
  pendingMinMs: 0
});