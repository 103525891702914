const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-zones" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/zones/$zoneId.subscription.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-zones" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/zones/$zoneId.subscription.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { allowedTo } from '#app/lib/authenticated';
export const Route = createFileRoute('/accounts/$accountId/zones/$zoneId/subscription')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  beforeLoad: ({
    params
  }) => {
    if (!allowedTo(['zone.activate'], params.accountId)) {
      throw redirect({
        from: Route.fullPath,
        to: '/accounts/$accountId/zones/$zoneId',
        replace: true
      });
    }
  },
  loader: lazyFn($$splitLoaderImporter, 'loader')
});