import type { EventAttributes, EventsAPI, Faro } from '@grafana/faro-web-sdk'
import { backgroundTask } from '@soundtrack/utils/backgroundTask'
import { sample } from '@soundtrack/utils/sample'
import { startReduxListening } from '#app/store/middleware/listener'

/** Lazy loaded faro instance initialized via {@link initializeFaro} */
let faroInstance: Faro | undefined

/** Push event to {@link faroInstance} if enabled and initialized */
export const pushFaroEvent: EventsAPI['pushEvent'] = (...args) => {
  if (!faroInstance) return
  backgroundTask(() => {
    faroInstance!.api.pushEvent(...args)
  })
}

export function faroEnabled(): boolean {
  return !!SYB.faro
}

export function initializeFaro(options: typeof SYB.faro) {
  if (!options) return
  import(/* webpackChunkName: 'faro' */ '@grafana/faro-web-sdk').then(
    (faro) => {
      faroInstance = faro.initializeFaro({
        internalLoggerLevel: faro.InternalLoggerLevel.OFF,
        instrumentations: [
          new faro.WebVitalsInstrumentation(),
          new faro.SessionInstrumentation(),
        ],
        transports: [
          new faro.FetchTransport({
            url: options.url,
            apiKey: options.apiKey,
          }),
          // Uncomment to log faro events to console
          // new faro.ConsoleTransport(),
        ],
        app: {
          name: 'business',
          release: SYB.version,
          environment: SYB.env,
        },
        batching: { enabled: true, sendTimeout: 5000 },
      })

      // Track Capsule Action results
      startReduxListening({
        predicate: () => true,
        effect(action) {
          if (
            sample(options.sampleRates.capsule) &&
            typeof action === 'object' &&
            typeof action.type === 'string' &&
            typeof action.query === 'object'
          ) {
            const actionMatch = action.type.match(/^(.*)_(SUCCESS|FAILURE)$/)
            if (!actionMatch) return
            pushFaroEvent(
              'Capsule Action',
              {
                actionName: actionMatch[1]!,
                result: actionMatch[2]!.toLowerCase(),
                status: (action.error as any)?.status?.toString(),
              },
              undefined,
              { skipDedupe: true },
            )
          }
        },
      })

      // Track Image loading success rate + duration
      {
        const imageReport = (report: EventAttributes) => {
          if (sample(options.sampleRates.image)) {
            pushFaroEvent('Image Load', report, undefined, {
              skipDedupe: true,
            })
          }
        }

        document.body.addEventListener(
          'load',
          (error) => {
            if ((error.target as HTMLElement).tagName === 'IMG') {
              imageReport({ result: 'success' })
            }
          },
          { capture: true },
        )
        document.body.addEventListener(
          'error',
          (error) => {
            if ((error.target as HTMLElement).tagName === 'IMG') {
              imageReport({ result: 'failure' })
            }
          },
          { capture: true },
        )

        const performanceObserver = new PerformanceObserver((list) => {
          list.getEntries().forEach((entry_) => {
            const entry = entry_ as PerformanceResourceTiming
            if (entry.initiatorType === 'img') {
              imageReport({ duration: entry.duration.toString() })
            }
          })
        })

        performanceObserver.observe({ type: 'resource', buffered: true })
      }
    },
  )
}
