import type { ResultOf } from '@graphql-typed-document-node/core'
import { graphql } from '#app/apollo/index'

graphql(/* GraphQL */ `
  fragment EditorialPage on EditorialPage {
    id
    sections(first: $first, after: $after) {
      total
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        ...EditorialSectionEdge
      }
    }
  }

  fragment EditorialSectionEdge on EditorialSectionEdge {
    cursor
    density
    node {
      ...EditorialSection
    }
  }

  fragment EditorialSection on EditorialSection {
    id
    title
    component
    items(first: $itemCount) {
      total
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        ...EditorialItemEdge
      }
    }
  }

  fragment EditorialItemEdge on DisplayableEdge {
    cursor
    node {
      ...EditorialItem
    }
  }

  fragment EditorialItem on Displayable {
    ...SimpleEditorialItem
    ...EditorialCard_List

    ... on EditorialCard {
      links {
        id
        display {
          title
        }
      }
      link {
        id
        display {
          title
        }
      }
      item {
        ...SimpleEditorialItem
      }
    }
  }

  fragment SimpleEditorialItem on Displayable {
    __typename
    ...Displayable

    ...BrowseCategory_List
    ...Album_List
    ...Artist_List
    ...Playlist_List
    ...Schedule_List
    ...Track_List
    ...EditorialCard_List
    ... on EditorialLink {
      id
    }
    # Soundtrack is unused but since the API technically could return
    # it we need to include its id in the fragment to avoid requiring
    # a typename check before every access of the item id.
    ... on Soundtrack {
      id
    }
  }

  fragment BrowseCategory_List on BrowseCategory {
    id
    type
  }

  fragment Album_List on Album {
    id
    albumType
    explicit
    artists {
      id
      name
    }
    releaseDate {
      timestamp
    }
  }

  fragment Artist_List on Artist {
    id
  }

  fragment EditorialCard_List on EditorialCard {
    id
    description
  }

  fragment Playlist_List on Playlist {
    id
    presentAs
    composerType
    name
    snapshot
    shortDescription
    curated
    curator {
      accountId
      id
    }
    presets {
      playbackMode
    }
    # trackStatistics(market: $market) {
    #   total
    # }
  }

  fragment Schedule_List on Schedule {
    id
    composerType
    name
    shortDescription
    # Schedule.curator is non-nullable and Playlist.curator is nullable
    # So type-rules in GraphQL prevent them from using the same name
    scheduleCurator: curator {
      id
      accountId
    }
  }

  fragment Track_List on Track {
    id
    title
    explicit
    previewUrl
    isAvailable(market: $market)
    artists {
      id
      name
    }
    album {
      id
      title
    }
  }
`)

export const DisplayableFragmentDoc = graphql(/* GraphQL */ `
  fragment Displayable on Displayable {
    display {
      title
      colors {
        primary {
          hex
        }
      }
      image {
        placeholder
      }
    }
  }
`)

export type DisplayableFragment = ResultOf<typeof DisplayableFragmentDoc>
