import { invariant } from '@tanstack/react-router'
import { createContext, useContext } from 'react'
import type { YourMusicPlaylist } from '#app/lib/your-music'
import type { SourceViewPlaylist } from './graphql'

/**
 * Ambiguous type for the playlist in the `MusicView` context.
 * Can be either a fully loaded playlist ({@link SourceViewPlaylist}),
 * or a partial one ({@link YourMusicPlaylist}) likely to already be cached from the overview page.
 * This union exists so that we can optimistically start rendering the playlist view based on cached data.
 */
export type MusicViewPlaylist = SourceViewPlaylist | YourMusicPlaylist

export const MusicContext = createContext<MusicViewPlaylist | null>(null)

export function useMusicContext() {
  const value = useContext(MusicContext)
  invariant(value, 'MusicContext is not available')
  return value
}

/** Disambiguate whether a {@link MusicViewPlaylist} is a {@link SourceViewPlaylist} */
export const isCompletePlaylist = (
  playlist: MusicViewPlaylist,
): playlist is SourceViewPlaylist => {
  if ('description' in playlist) {
    // ensure that the guard remains correct by checking that the type can actually be narrowed
    playlist satisfies SourceViewPlaylist
    return true
  }
  return false
}
