const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-messaging" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/messaging/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { featuresEnabledOr } from '#app/lib/authenticated';
export const Route = createFileRoute('/accounts/$accountId/messaging')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'account.messaging.meta'
  },
  beforeLoad: async ({
    params
  }) => {
    featuresEnabledOr('messaging_enabled', '/addons/promo/messaging');

    // Set the default `showHeader` for all child routes. Children can set a different value
    // which will be used to determine if the header should be shown
    return {
      showHeader: false
    };
  }
});