import {
  type PayloadAction,
  type UnknownAction,
  createSlice,
} from '@reduxjs/toolkit'
import { Models, Selectors } from '@soundtrackyourbrand/capsule'
import { SUBSCRIPTION_STATES } from '@soundtrackyourbrand/capsule/dist/models/sound-zone'
import im from 'immutable'
import moment from 'moment'
import Checkout from '#app/store/checkout'
import { selectors as currentAccount } from '#app/store/current-account'
import { createSelector } from './redux'

export const NOTIFICATION_TYPES = {
  NO_ZONES: 'noZones',
  ERRORS: 'errors',
  INACTIVE: 'inactive',
  NOT_SETUP: 'notSetup',
  OVERDUE_INVOICE_SPECS: 'overdueInvoiceSpecs',
  PERSISTENT: 'persistent',
} as const

type NotificationType =
  (typeof NOTIFICATION_TYPES)[keyof typeof NOTIFICATION_TYPES]

/** These statuses are related to zones */
const ZONE_STATUSES: NotificationType[] = [
  'errors',
  'inactive',
  'notSetup',
] as const

const ZONE_STATUS_TO_NOTIFICATION_TYPE: Record<string, NotificationType> = {
  ERROR: 'errors',
  INACTIVE: 'inactive',
  NOT_SETUP: 'notSetup',
} as const

/** In what order to prioritize displaying notifications */
export const NOTIFICATION_PRIORITY: NotificationType[] = [
  'persistent',
  'overdueInvoiceSpecs',
  'errors',
  'inactive',
  'noZones',
  'notSetup',
] as const

type SliceState = Record<NotificationType, string[]>

const DEFAULT_STATE: SliceState = {
  errors: [],
  inactive: [],
  notSetup: [],
  noZones: [],
  overdueInvoiceSpecs: [],
  persistent: [],
} as const

type DismissPayload =
  | {
      /** Array of ids */
      ids: string[]
      /** Type to dismiss of ids argument is an array. */
      type: string
    }
  | Record<string, NotificationType>

const slice = createSlice({
  name: 'notifications',

  initialState: DEFAULT_STATE,

  reducers: {
    dismiss(state, action: PayloadAction<DismissPayload>) {
      let idTypeMap: Record<string, NotificationType> = {}

      if ('type' in action.payload) {
        const { ids, type } = action.payload
        if (typeof ids !== 'object') {
          return state
        }
        idTypeMap = ids.reduce((obj, id) => ((obj[id] = type), obj), {})
      } else {
        idTypeMap = action.payload
      }

      NOTIFICATION_PRIORITY.forEach((status) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        state[status] ??= []
      })

      for (const id in idTypeMap) {
        const status = idTypeMap[id]!
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (state[status] && !state[status].includes(id)) {
          state[status].push(id)
        }
      }
    },

    reset() {
      return DEFAULT_STATE
    },
  },

  extraReducers: (builder) => {
    return builder
      .addCase('@@capsule/RESET', () => {
        return DEFAULT_STATE
      })
      .addMatcher(
        (action: UnknownAction): action is { type: string; data: any[] } =>
          action.type === 'SOUND_ZONE_UPDATE' ||
          action.type === 'REQUEST_SOUND_ZONES_FOR_ACCOUNT_SUCCESS',
        (state, action) => {
          const zones = Array.isArray(action.data) ? action.data : [action.data]
          for (const zone of zones) {
            if (!Models.SoundZone.statusForZone(im.fromJS(zone), true)) {
              ZONE_STATUSES.forEach((status) => {
                state[status] = state[status].filter((id) => id !== zone.id)
              })
            }
          }
        },
      )
      .addDefaultCase((state) => {
        // Persistence: Prevent setting a falsey value if value in
        // localStorage is cleared which causes `state` to be `null`
        if (!(state as unknown)) {
          return DEFAULT_STATE
        }
      })
  },
})

export default slice.actions
export const { actions, reducer } = slice

const daysUntilTierChange = createSelector(
  currentAccount.id,
  currentAccount.scheduledTierChange,
  (id, scheduledTierChange) => {
    return (
      !!scheduledTierChange && {
        key: 'scheduledTierChange',
        messageData: {
          upcomingTier: scheduledTierChange.upcomingTier,
          count: moment
            .utc(scheduledTierChange.tierChangeDate)
            .diff(moment.utc(), 'days'),
        },
      }
    )
  },
)

export type NotificationData = {
  key: string
  onAction?: () => void
  linkData?: Record<string, any>
  messageData?: Record<string, any>
}

export type NotificationsMap = Record<
  NotificationType,
  Array<NotificationData | string>
>

/*
 * Get the current notifications
 *
 * @return Object with NotificationTypes keys
 *
 * NotificationType.NO_ZONES, Array holding the current account id if the account has loaded, has not dismissed the notification and has zero zones
 * NotificationType.INACTIVE, Array holding the zone ids of all inactive zones if `newInactiveZones.length > dismissedInactiveZones.length` is true
 * NotificationType.ERROR, Array holding the zone ids of all zones with errors if `newZonesWithErrors.length > dismissedZonesWithErrors.length` is true
 * NotificationType.PERSISTENT, Array holding notifications that can't be dismissed
 */
export const notificationsForCurrentAccount = createSelector(
  [
    (state) => state.notifications,
    (state) => state.loadedAccounts,
    Checkout.checkoutCompleted,
    currentAccount.onboardingEnabled,
    currentAccount.account,
    currentAccount.id,
    currentAccount.soundZones,
    currentAccount.overdueInvoiceSpecs,
    currentAccount.hasMissingPaymentMethod,
    daysUntilTierChange,
    (state) => Selectors.currentUserId(state),
  ],
  (
    dismissed,
    loadedAccounts,
    checkoutCompleted,
    onboardingEnabled,
    currentAccount,
    accountId,
    zones,
    overdueInvoiceSpecs,
    hasMissingPaymentMethod,
    daysUntilTierChange,
    currentUserId,
  ): NotificationsMap => {
    const notifications: NotificationsMap = {
      noZones: [],
      notSetup: [],
      inactive: [],
      errors: [],
      overdueInvoiceSpecs: [],
      persistent: [],
    }

    if (hasMissingPaymentMethod) {
      return notifications
    }

    if (onboardingEnabled) {
      const msg = checkoutCompleted
        ? zones.some((z) => z.get('paired') === 'paired')
          ? null
          : 'onboardingPair'
        : 'onboardingActivate'
      msg && notifications.persistent.push({ key: msg })
    } else if (!currentAccount) {
      notifications.persistent.push({
        key: currentUserId ? 'noAccount' : 'signup',
      })
    } else {
      if (currentAccount.get('suspended')) {
        notifications.persistent.push({ key: 'suspendedOverdue' })
      }

      if (
        accountId &&
        loadedAccounts[accountId] &&
        zones.size === 0 &&
        !dismissed.noZones.includes(accountId)
      ) {
        notifications.noZones.push(accountId)
      }

      let activeZoneCount = 0
      const churnedZoneIds: string[] = []

      // Get all non-dismissed zone notifications
      zones.forEach((zone) => {
        switch (Models.SoundZone.subscriptionState(zone)) {
          case SUBSCRIPTION_STATES.ACTIVE:
          case SUBSCRIPTION_STATES.ACTIVE_CANCELLED:
            activeZoneCount++
            break
          case SUBSCRIPTION_STATES.INACTIVE:
            churnedZoneIds.push(zone.get('id'))
            break
        }

        const zoneStatus = Models.SoundZone.statusForZone(zone, true) as
          | 'NOT_SETUP'
          | 'INACTIVE'
          | 'ERROR'
          | undefined
        const status =
          zoneStatus && ZONE_STATUS_TO_NOTIFICATION_TYPE[zoneStatus]
        const forStatus = status && dismissed[status]
        if (forStatus && !forStatus.includes(zone.get('id'))) {
          notifications[status].push(zone.get('id'))
        }
      })

      // Show notification for churned users to resume their subscription
      if (activeZoneCount === 0 && churnedZoneIds.length > 0) {
        if (churnedZoneIds.length === 1) {
          notifications.persistent.push({
            key: 'churnedSingleLocResume',
            linkData: {
              zone: churnedZoneIds[0],
            },
          })
        } else {
          notifications.persistent.push({ key: 'churnedMultiLocResume' })
        }
      } else if (
        daysUntilTierChange &&
        daysUntilTierChange.messageData.count > 0 &&
        daysUntilTierChange.messageData.count <= 7
      ) {
        notifications.persistent.push(daysUntilTierChange)
      }

      // Only show notifications if the number of items is greater than what has been dismissed
      const zoneIds = zones.map((z) => z.get('id')).toList()
      ZONE_STATUSES.forEach((status) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const history = Array.from(dismissed[status] || []).filter((i) =>
          zoneIds.includes(i),
        )
        if (notifications[status].length > history.length) {
          notifications[status] = notifications[status].concat(history)
        } else {
          notifications[status] = []
        }
      })
    }

    const overdueIds = overdueInvoiceSpecs
      .map((spec) => spec.get('id'))
      .valueSeq()
      .toArray()
    const dismissedOverdue = dismissed.overdueInvoiceSpecs
    if (!overdueIds.every((id) => dismissedOverdue.includes(id))) {
      notifications.overdueInvoiceSpecs = overdueIds
    }

    return notifications
  },
)
