export const imagePreload = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image()

    const cleanup = () => {
      img.onload = null
      img.onerror = null
    }

    img.onload = () => {
      cleanup()
      resolve()
    }

    img.onerror = () => {
      cleanup()
      reject(Object.assign(new Error('Failed to preload image'), { url }))
    }

    img.src = url
  })

export const audioPreload = (context, urlList) =>
  new Promise((resolve, reject) => {
    const bufferList = []
    let loadCount = 0

    const loadBuffer = (url, index) => {
      const request = new XMLHttpRequest()
      request.open('GET', url, true)
      request.responseType = 'arraybuffer'

      request.onload = () => {
        if (request.status >= 400) {
          reject(
            Object.assign(new Error('Failed to preload audio'), {
              url,
              status: request.status,
            }),
          )
          return
        }

        context.decodeAudioData(request.response, (buffer) => {
          if (!buffer) {
            reject(
              Object.assign(new Error('Failed to decode preloaded audio'), {
                url,
              }),
            )
            return
          }
          bufferList[index] = buffer
          loadCount += 1

          if (loadCount === urlList.length) {
            resolve(bufferList)
          }
        })
      }

      request.onerror = (err) => {
        reject(Object.assign(new Error('Failed to preload audio'), { url }))
      }

      request.send()
    }

    for (let i = 0; i < urlList.length; i++) {
      loadBuffer(urlList[i], i)
    }
  })
