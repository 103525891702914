/** Memoize promises for requested assets */
const requested = {}

/**
 * Injects a <script>/<link> tag for the requested asset URL into the document.
 * Returns a promise resolving upon load and rejecting if the asset failed to
 * load for any reason.
 * Requesting a URL that has already been requested will immediately return the
 * old promise, thus avoiding duplicate tags.
 *
 * @param {string} url - Asset URL to load
 * @param {object} [attributes] - Additional element attributes to set
 * @return {Promise<HTMLElement>} - Created element node
 */
export default function loadAsset(url, attributes = {}) {
  if (!requested[url]) {
    requested[url] = new Promise((resolve, reject) => {
      const tag = assetElement(url, attributes)
      tag.addEventListener('load', () => resolve(tag), false)
      tag.addEventListener(
        'error',
        () => reject(new Error(`loadAsset: failed to load '${url}'`)),
        false,
      )
      document.getElementsByTagName('head')[0].appendChild(tag)
      if (!('onload' in tag)) {
        resolve(tag)
      }
    })
  }

  return requested[url]
}

export function assetElement(url, attributes) {
  const ext = url.match(/(js|css)(?:$|\?)/)
  if (!ext) {
    throw new Error(`loadAsset: unsupported extension for '${url}'`)
  }

  if (ext[1] === 'css') {
    return Object.assign(
      document.createElement('link'),
      {
        rel: 'stylesheet',
        href: url,
      },
      attributes,
    )
  }

  return Object.assign(
    document.createElement('script'),
    {
      async: true,
      src: url,
    },
    attributes,
  )
}
