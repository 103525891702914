import type { RegisteredRouter } from '@tanstack/react-router'
import deepEquals from 'fast-deep-equal'
import { t } from '#app/lib/i18n/index'

/**
 * Attribute which is added to meta tags controlled by the tracker.
 */
const SELECTOR_ATTRIBUTE = 'data-document-meta'

/**
 * Regular expression for matching parameter placeholders in paths.
 */
const PARAM_REGEX = /\$[a-zA-Z_][a-zA-Z0-9_]*/g

/**
 * Generates title/meta DOM tags given a @tanstack/react-router routing tree containing
 * `meta` path props to i18n objects.
 *
 * Set up:
 * ```
 * const metadataTracker = new MetadataTracker()
 *
 * router.subscribe('onBeforeNavigate', metadataTracker.beforeUpdate)
 *
 * <Router
 *   ...
 *   onUpdate={() => {
 *     // Callback is optional
 *     metadataTracker.updateRoutes(this.state, () => pageView(this.state.location.pathname))
 *   }}
 * >
 *   <Route ... meta="app.meta" component={...}>
 * </Router>
 * ```
 *
 * Where `some.path.meta` points to an object in the i18n json with
 * localization strings, which may contain interpolated values:
 * ```
 * "meta": { "title": "Some {{value}}" }
 * ```
 *
 * If any of the active routes contain interpolations the tracker will wait for
 * these to be provided by calling the `interpolate()` before updating the DOM
 * tags. `interpolate()` should be called from the `render()` function of the
 * route component (or one of its children).
 *
 * Calling the following would complete the metadata of the example route:
 *
 * ```
 * metadataTracker.interpolate('app.meta', { value: 'interpolated' })
 * ```
 *
 * The meta DOM tags will be updated once all meta paths containing
 * interpolations have been provided. This happens immediately if no
 * interpolation data is required. The callback provided to `updateRoutes()` is
 * called after the update has been applied.
 */
class MetadataTracker {
  private defaults: MetaData
  private onUpdate?: (
    meta: MetaData,
    interpolationData: InterpolationData,
  ) => MetaData | void
  private data: { key: string; meta: MetaData }[] = []
  private interpolationData: InterpolationData = {}
  private incomplete = new Set<string>()
  private pendingKeyUpdates: { [key: string]: boolean } | null = null
  private callback?: () => void
  private callbackTimeout?: NodeJS.Timeout
  private previousParams: { [key: string]: any } = {}
  private appliedMeta?: MetaData

  constructor({
    onUpdate,
    ...defaults
  }: {
    onUpdate?: (
      meta: MetaData,
      interpolationData: InterpolationData,
    ) => MetaData | void
  } = {}) {
    this.defaults = defaults
    this.onUpdate = onUpdate
  }

  /**
   * Call this whenever the active route is about to change.
   */
  beforeUpdate = () => {
    this.pendingKeyUpdates = {}
  }

  /**
   * Call this whenever the active route (or URL) has changed.
   *
   * Attempts to remove no longer referenced keys.
   *
   * @param state - Active react-router state
   * @param callback - Function to call next time metadata is updated
   * @returns True if the data lead to a metadata update
   */
  updateRoutes(router: RegisteredRouter, callback?: () => void): boolean {
    const previousData = this.data
    const previousLength = previousData.length
    this.data = []
    this.callback = callback
    let matchIndex = 0
    let index = 0

    // Clear any pending callback
    clearTimeout(this.callbackTimeout!)

    const { matches } = router.state
    const params = matches[matches.length - 1]!.params

    for (; matchIndex < matches.length; matchIndex++) {
      const match = matches[matchIndex]!
      const def = router.routesById[match.routeId]
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const key = match.staticData?.meta
      if (!key) {
        continue
      }

      // Remove stale data
      if (
        // The old route at this level has been unmounted
        (index < previousLength && previousData[index]!.key !== key) ||
        // OR
        // The current key isn't pending
        ((!this.pendingKeyUpdates || !this.pendingKeyUpdates[key]) &&
          // AND has changed since last update
          this._containsChangedParam(def.path, params))
      ) {
        this._dereferenceKey(key)
      }

      this.data.push({ key, meta: this._lookup(key) })

      index++
    }

    // Bust interpolated data for unmounted routes
    for (; index < previousLength; index++) {
      this._dereferenceKey(previousData[index]!.key)
    }

    this.previousParams = params
    this.pendingKeyUpdates = null

    return this._updateTags()
  }

  /**
   * Expose interpolation data for a specific meta key.
   * Should be called in a components render() function.
   *
   * @param key - Key to provide interpolation data for
   * @param data - Data to interpolate, falsey to mark it as incomplete
   * @returns True if the data lead to a metadata update
   */
  interpolate(key: string, data?: any): boolean {
    this.interpolationData[key] = data
    this.incomplete[data ? 'delete' : 'add'](key)

    if (!data) {
      return false
    }

    if (this.pendingKeyUpdates) {
      this.pendingKeyUpdates[key] = true
    }

    let i = 0
    for (; i < this.data.length; i++) {
      if (this.data[i]!.key === key) {
        this.data[i]!.meta = this._lookup(key, data)
        break
      }
    }

    return this._updateTags()
  }

  private _dereferenceKey(key: string) {
    delete this.interpolationData[key]
    this.incomplete.delete(key)
  }

  private _containsChangedParam(
    path: string | undefined,
    params: { [key: string]: any } | undefined,
  ): boolean {
    if (
      !params ||
      !(this.previousParams as unknown) ||
      typeof path !== 'string' ||
      path.indexOf('$') < 0
    ) {
      return false
    }
    const found = path.match(PARAM_REGEX)
    return (
      !!found &&
      found.some((param) => {
        param = param.substr(1)
        return params[param] !== this.previousParams[param]
      })
    )
  }

  private _lookup(key: string, data?: any): MetaData {
    return t(
      key,
      Object.assign(
        {
          returnObjects: true,
          missingInterpolationHandler: this._missingInterpolationHandler.bind(
            this,
            key,
          ),
        },
        this.interpolationData[key],
      ),
      false,
    ) as unknown as MetaData
  }

  private _missingInterpolationHandler(key: string) {
    this.incomplete.add(key)
  }

  /**
   * Mirrors the current metadata onto the DOM tags, but only if no
   * interpolation data is missing.
   * Calls the latest callback provided to `updateRoutes()` afterwards.
   *
   * @returns true if data was complete (even if nothing changed)
   */
  private _updateTags(): boolean {
    if (!this.data.length || this.incomplete.size) {
      return false
    }

    const meta = this.data.reduce(
      (merged, item) => {
        return (item as unknown) ? Object.assign(merged, item.meta) : merged
      },
      Object.assign({}, this.defaults),
    )

    if (meta.canonical === true) {
      meta.canonical =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname
    }

    // Only update tags when changed
    if (!deepEquals(meta, this.appliedMeta)) {
      document.title = [meta.titlePrefix, meta.title, meta.titleSuffix]
        .filter(Boolean)
        .join(meta.titleSep)

      let finalMeta = meta
      if (typeof this.onUpdate === 'function') {
        const interpolationData = this.data.reduce(
          (merged, item) =>
            Object.assign(merged, this.interpolationData[item.key]),
          {},
        )
        // onUpdate is not allowed to mutate meta directly since that would
        // break change detection
        finalMeta = this.onUpdate(finalMeta, interpolationData) || finalMeta
      }

      updateMetaTags(finalMeta)

      this.appliedMeta = meta
    }

    if (typeof this.callback === 'function') {
      this.callbackTimeout = setTimeout(this.callback, 10)
    }

    return true
  }
}

export default MetadataTracker

/**
 * Updates existing <meta> tag content values, removes tags not present in
 * `values` and adds missing tags.
 *
 * Only touches tags created by the function itself (tracked using
 * `SELECTOR_ATTRIBUTE`).
 *
 * @param values - Key-value pairs of desired meta tags.
 */
export function updateMetaTags(values: MetaData) {
  // Clone values so that we can safely mutate it
  values = Object.assign({}, values)

  // Generate extra meta tags for select values
  values['og:site_name'] = values.titleSuffix || values.titlePrefix
  values['og:title'] = values['twitter:title'] = values.title
  values['og:description'] = values['twitter:description'] = values.description
  values['og:url'] = values['twitter:url'] = values.canonical
  values['og:image'] = values['twitter:image'] = values.image

  delete values.canonical
  delete values.image
  delete values.title
  delete values.titleSep
  delete values.titlePrefix
  delete values.titleSuffix

  const { head } = document
  // TODO: Replace existing tags as well, but store their initial values in
  // defaults to avoid removing them
  const current = Array.prototype.slice.call(head.querySelectorAll('meta'))

  // Update or remove existing tags
  current.forEach((tag) => {
    // Basic reversing approach to getNameAttribute
    const key = tag.name || tag.getAttribute('property')

    if (values[key] != null) {
      // Mark tag as controlled
      tag.setAttribute('content', values[key] as string)
      tag.setAttribute(SELECTOR_ATTRIBUTE, '')
      delete values[key]
    } else if (tag.hasAttribute(SELECTOR_ATTRIBUTE)) {
      head.removeChild(tag)
    }
  })

  // Insert new tags
  for (const key in values) {
    const content = values[key]
    if (Object.prototype.hasOwnProperty.call(values, key) && content != null) {
      const tag = document.createElement('meta')
      tag.setAttribute(key.indexOf('og:') === 0 ? 'property' : 'name', key)
      tag.setAttribute('content', content as string)
      tag.setAttribute(SELECTOR_ATTRIBUTE, '')
      head.appendChild(tag)
    }
  }
}

/**
 * Interface for metadata objects.
 */
interface MetaData {
  track?: string
  trackProps?: any
  title?: string
  titlePrefix?: string
  titleSuffix?: string
  titleSep?: string
  description?: string
  canonical?: string | boolean
  image?: string
  [key: string]: string | boolean | undefined
}

/**
 * Interface for interpolation data objects.
 */
interface InterpolationData {
  [key: string]: any
}
