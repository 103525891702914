const $$splitComponentImporter = () => import(/* webpackChunkName: "route-home" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/home/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensureCanSelectMusicOrRedirectToLibrary } from '#app/lib/authenticated';
export const Route = createFileRoute('/home')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  staticData: {
    meta: 'home.meta'
  },
  beforeLoad: () => {
    ensureCanSelectMusicOrRedirectToLibrary();
  }
});