import { createAuthSlice } from '@soundtrack/auth/slice'
import overrides from '#app/lib/overrides'
import tracking from '#app/lib/tracking'

export const authSlice = createAuthSlice({
  track: tracking.track.bind(tracking),
  /**
   * Temporary helper to enable overriding the behaviour and request "legacy" user IDs
   *
   * Defaults to `true` if the 'legacy-user-id' override isn't set.
   */
  shouldRequestLegacyUserId() {
    const legacyUserId = overrides.get('legacy-user-id')
    return legacyUserId === 'true' || legacyUserId === '1'
  },
})
