import { type ParsedLocation, useRouter } from '@tanstack/react-router'
import * as React from 'react'

/**
 * This file is a modified version of https://github.com/TanStack/router/blob/main/packages/react-router/src/scroll-restoration.tsx
 * The original doesn't properly reset scroll to 0 on non-window scrollable elements when navigating.
 * It also attempts to scroll restore on a lot of things that we don't want scroll restoration on at all anyway.
 */

const useLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

const delimiter = '___'

type CacheValue = Record<string, { scrollY: number }>
type CacheState = {
  cached: CacheValue
  next: CacheValue
}

type Cache = {
  state: CacheState
  set: (updater: (prev: CacheState) => CacheState) => void
}

const sessionStorage = typeof window !== 'undefined' && window.sessionStorage

const cache: Cache = sessionStorage
  ? (() => {
      const storageKey = 'soundtrack-configurable-scroll-restoration'

      const state: CacheState = JSON.parse(
        window.sessionStorage.getItem(storageKey) || 'null',
      ) || { cached: {}, next: {} }

      return {
        state,
        set: (updater) => {
          cache.state = updater(cache.state)
          window.sessionStorage.setItem(storageKey, JSON.stringify(cache.state))
        },
      }
    })()
  : (undefined as any)

export type ScrollRestorationOptions = {
  element: HTMLElement | null
  elementStorageKey: string
  getKey?: (location: ParsedLocation) => string
}

/**
 * The default `getKey` function for `useScrollRestoration`.
 * It returns the `key` from the location state or the `href` of the location.
 *
 * The `location.href` is used as a fallback to support the use case where the location state is not available like the initial render.
 */
const defaultGetKey = (location: ParsedLocation) => {
  return location.state.key! || location.href
}

/**
 * Variant of TSR useScrollRestoration that only cares about scroll restoring a single element:
 * the element in `elementRef`. Set `elementStorageKey` to a unique key for this element, this is what the scroll position will be stored under.
 */
export function useConfigurableTSRScrollRestoration(
  options: ScrollRestorationOptions,
) {
  const router = useRouter()

  const element = options.element
  const storageKey = options.elementStorageKey

  useLayoutEffect(() => {
    const getKey = options.getKey || defaultGetKey

    const { history } = window
    history.scrollRestoration = 'manual'

    // Before navigating away, store the scroll position
    const unsubOnBeforeLoad = router.subscribe('onBeforeLoad', (event) => {
      if (event.pathChanged && element) {
        const restoreKey = getKey(event.fromLocation)
        const scrollTop = element.scrollTop

        cache.set((c) => {
          // Clone to avoid mutating
          const next = { ...c.next }
          delete next[storageKey]

          return {
            ...c,
            next,
            cached: {
              ...c.cached,
              [[restoreKey, storageKey].join(delimiter)]: {
                scrollY: scrollTop,
              },
            },
          }
        })
      }
    })

    // After navigating, restore or reset the scroll position
    const unsubOnBeforeRouteMount = router.subscribe(
      'onBeforeRouteMount',
      (event) => {
        if (event.pathChanged) {
          if (!router.resetNextScroll) {
            return
          }

          const modal = document.querySelector('[data-is-modal=true]')
          if (modal) {
            // When the new route is a modal, scrolling the main content to top on navigate doesn't make sense
            // This is not the ideal solution, it'd be better to properly handle which container should/shouldn't scroll
            // but this is equivalent to the old behaviour and probably good enough for now.
            return
          }

          router.resetNextScroll = true

          const restoreKey = getKey(event.toLocation)
          const cacheKey = [restoreKey, storageKey].join(delimiter)
          const entry = cache.state.cached[cacheKey]

          if (element) {
            element.scrollTop = entry?.scrollY || 0

            // We have some routes that will always render a single frame of a spinner before the actual content.
            // This breaks scroll restoration because there's not enough content height to actually scroll.
            // Redoing the scroll on the next frame just in case could help and doesn't hurt if it's not needed.
            requestAnimationFrame(() => {
              element.scrollTop = entry?.scrollY || 0
            })
          }

          cache.set((c) => ({ ...c, next: {} }))
        }
      },
    )

    return () => {
      unsubOnBeforeLoad()
      unsubOnBeforeRouteMount()
    }
  }, [options.getKey, element, storageKey, router])
}
