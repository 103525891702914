const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-settings" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/settings/billing/invoice-group.edit.$groupId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';
import { ModalErrorView } from '#app/components/error/index';
export const Route = createFileRoute('/accounts/$accountId/settings/billing/invoice-group/edit/$groupId')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  errorComponent: ModalErrorView,
  validateSearch: zodSearchValidator(z.object({
    fromUpdate: z.boolean().optional().catch(undefined)
  }))
});