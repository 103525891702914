const $$splitComponentImporter = () => import(/* webpackChunkName: "route-logged-out" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/(logged-out)/forgot-password.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';
export const Route = createFileRoute('/(logged-out)/forgot-password')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  validateSearch: zodSearchValidator(z.object({
    email: z.string().email().optional().catch(undefined)
  })),
  staticData: {
    meta: 'forgotPassword.meta',
    showHeader: false,
    showFooter: false,
    popPage: true,
    showSidebarLayout: false,
    showNotifications: false
  }
});