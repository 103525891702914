import { expandPlaceholderUri } from '@soundtrack/utils/display'
import { getIn } from '@soundtrackyourbrand/object-utils.js'
import { type ImageType } from '#app/components/music-source/image'

export function getImageSource(
  source,
  size: SizeOrPixels = 'medium',
  type?: ImageType,
) {
  switch (type) {
    case 'release':
      return albumImage(source, size)
    case 'track':
      return trackImage(source, size)
    default:
      return sourceImage(source, size, true)
  }
}

export function displayableImage(
  source: {
    display?: { image?: { placeholder?: string | null } | null } | null
  },
  size: SizeOrPixels = 'medium',
): string | undefined {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const placeholder = source?.display?.image?.placeholder
  return placeholder
    ? expandPlaceholderUri(placeholder, sizeToPixels(size))
    : undefined
}

type SizeOrPixels = number | keyof typeof IMAGE_SIZES

function sizeToPixels(size: SizeOrPixels): number {
  return typeof size === 'number' ? size : IMAGE_SIZES[size].width
}

export function sourceImage(
  source,
  size: SizeOrPixels = 'medium',
  supportSchedules = false,
): string | undefined {
  if (supportSchedules && getIn(source, 'type') === 'Schedule') {
    const image = sizedImage(getIn(source, 'images'), sizeToPixels(size))
    return getIn(image, 'url')
  }

  return (
    displayableImage(source, size) ||
    getIn(source, [
      'display',
      'image',
      sizeToPixels(size) > 400 ? 'hero' : 'thumbnail',
    ]) ||
    getIn(source, ['presentation', 'image', size, 'url']) ||
    getIn(source, ['presentation', 'soundtrack', 'image', size, 'url'])
  )
}

/* This how the Track data looks like when querying from the REST API */
function trackImage(source, size: SizeOrPixels = 0) {
  const pixels = sizeToPixels(size)
  if (source.display) {
    return displayableImage(source, pixels)
  }
  if (source.album?.display) {
    return displayableImage(source.album, pixels)
  }
  // Legacy syb-core representations
  const images = getIn(source, ['album', 'images'])
  const image = sizedImage(images, pixels)
  return (
    getIn(image, 'url') ||
    getIn(source, ['display', 'image', 'sizes', 'thumbnail']) ||
    getIn(source, ['album', 'display', 'image', 'sizes', 'thumbnail']) ||
    getIn(source, ['album', 'album_image', 'url'])
  )
}

function albumImage(source, size: SizeOrPixels = 200) {
  const pixels = sizeToPixels(size)
  if (source.display) {
    return displayableImage(source, pixels)
  }
  const albumImage = sizedImage(getIn(source, 'images'), pixels)
  return getIn(albumImage, 'url')
}

function sizedImage(images, preferredPixels = 200) {
  return images?.reduce((best, candidate) =>
    Math.abs(getIn(candidate, 'width') - preferredPixels) <
    Math.abs(getIn(best, 'width') - preferredPixels)
      ? candidate
      : best,
  )
}

export const IMAGE_SIZES = {
  large: { width: 1600, height: 1600 },
  medium: { width: 800, height: 800 },
  small: { width: 400, height: 400 },
  tiny: { width: 200, height: 200 },
}
