import { type AnyAction } from '@reduxjs/toolkit'
import { Selectors } from '@soundtrackyourbrand/capsule'
import { createSelector } from '#app/store/redux'
import type { ActionsType, ExtractActions, SelectorsType } from '.'

export const actions = {
  begin: (schedule: string | undefined) => ({
    type: 'SCHEDULE_EDITOR_BEGIN' as const,
    schedule,
  }),
  reset: () => ({
    type: 'SCHEDULE_EDITOR_RESET' as const,
  }),
  interact: (
    activeSlot?: string,
    interaction?: string,
    interactionData?: any,
  ) => ({
    type: 'SLOT_INTERACTION' as const,
    activeSlot,
    interaction,
    interactionData,
  }),
  showCollection: (id: string | undefined) => ({
    type: 'SCHEDULE_EDITOR_SHOW_COLLECTION' as const,
    id,
  }),
} satisfies ActionsType

export type Action = ExtractActions<typeof actions>

export type State = Readonly<{
  /** Schedule ID being edited */
  schedule?: string
  lastUpdate?: Date
  /** ID of collection currently shown in full screen detail mode */
  shownCollection?: string
  /** ID of currently selected slot (with weekday suffix) */
  activeSlot?: string
  /** Current interaction (relates to `activeSlot`) */
  interaction?: string
  /** Additional metadata for current interaction */
  interactionData?: any
}>

export function reducer(state: State = {}, rawAction: AnyAction): State {
  const action = rawAction as Action
  switch (action.type) {
    case 'SCHEDULE_EDITOR_BEGIN':
      return {
        ...state,
        schedule: action.schedule,
        lastUpdate: new Date(),
      }

    case 'SCHEDULE_EDITOR_RESET':
      return {}

    case 'SCHEDULE_EDITOR_SHOW_COLLECTION':
      return {
        ...state,
        shownCollection: action.id,
      }

    case 'SLOT_INTERACTION':
      return {
        ...state,
        activeSlot: action.activeSlot,
        interaction: action.interaction,
        interactionData: action.interactionData || undefined,
      }
  }

  return state
}

export const selectors = {
  startOfWeek: createSelector([Selectors.currentUser], (user) => {
    const v = user && user.get('start_of_week', 1)
    return typeof v === 'number' ? v : 1
  }),
  shownCollection: (state) => state.scheduleEditor.shownCollection,
  lastUpdate: (state) => state.scheduleEditor.lastUpdate,
} satisfies SelectorsType
