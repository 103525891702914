import {
  type ParsedLocation,
  createRoute,
  redirect,
} from '@tanstack/react-router'
import { tracking } from '#app/lib/tracking'
import { urlForId } from '#app/lib/urls'
import { Route as rootRoute } from '#app/routes/__root'

export function createWildcardRedirectRoutes(
  from: string,
  to: string,
  parentRoute = rootRoute as any,
) {
  let fromRegexPattern = `/(?:${from})(/|$)`
  if (to[0] === '/') {
    // Support absolute `to` paths via leading slash
    fromRegexPattern = '^.+' + fromRegexPattern
    to = to.substr(1)
  }
  if (to.length) {
    to = '/' + to
  }
  const fromRegex = new RegExp(fromRegexPattern)
  const beforeLoad = ({ location }: { location: ParsedLocation }) => {
    const newTo = location.pathname.replace(fromRegex, `${to}$1`)
    tracking.track('App - Redirected Route', {
      'From Route': from,
      'To Route': to,
      'From Path': location.pathname,
      'To Path': newTo,
    })
    throw redirect({ to: newTo, replace: true, _fromLocation: location })
  }

  // Optional params matching isn't supported, so we need to create a route for the direct
  // match, as well as one with a route splat ($)
  return [
    createRoute({
      path: `${from}/`,
      getParentRoute: () => parentRoute,
      beforeLoad,
    }),
    createRoute({
      path: `${from}/$`,
      getParentRoute: () => parentRoute,
      beforeLoad,
    }),
  ]
}

export function createRedirectRoute(
  from: string,
  to: string,
  parentRoute = rootRoute as any,
) {
  return createRoute({
    path: from,
    getParentRoute: () => parentRoute,
    beforeLoad: ({ location }: { location: ParsedLocation }) => {
      tracking.track('App - Redirected Route', {
        'From Route': from,
        'To Route': to,
        'From Path': location.pathname,
        'To Path': to,
      })
      throw redirect({ to, replace: true })
    },
  })
}

export function createParameterizedRedirectRoute(
  from: string,
  to: string,
  parentRoute = rootRoute as any,
) {
  return createRoute({
    path: from,
    getParentRoute: () => parentRoute,
    beforeLoad: ({ location, params }) => {
      tracking.track('App - Redirected Route', {
        'From Route': from,
        'To Route': to,
        'From Path': location.pathname,
      })
      throw redirect({
        to,
        params,
        search: (prevSearch) => prevSearch,
        replace: true,
      })
    },
  })
}

export function redirectUrisToShortIds(params: {
  _splat?: string
  releaseId?: string
}) {
  const raw =
    ('_splat' in params && params._splat) ||
    ('releaseId' in params && params.releaseId) ||
    undefined

  if (!raw) return

  // Clean url string from trailing forward slashes before checking if it is an id or URI
  const clean = raw.replace(/^[/]|[/]$/g, '')
  const parts = clean.split('/')
  const lastPart = parts[parts.length - 1] as string | undefined
  if (lastPart?.startsWith('soundtrack:')) {
    // URIs are prefixed with "soundtrack:" - parse URI as ID path, for consistent route paths
    throw redirect({ to: urlForId(lastPart), replace: true })
  }
  if (raw !== clean) {
    // Allow but redirect when trailing slashes are used
    throw redirect({ to: '/discover/' + clean, replace: true })
  }
}
