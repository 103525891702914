// eslint-disable-next-line no-restricted-imports
import { upperFirst } from 'lodash'
import { getRouter } from '#app/lib/router'
import { type OnboardingSearch } from '#app/modules/accounts/onboarding/index'
import { selectors } from '#app/store/current-account'
import { store } from '#app/store/index'
import { type DescriptionSubSteps } from '.'
import type { OnboardingStepComponentProps } from '../../common/definitions'

/**
 * Constructs a playlist search query using URL parameters.
 * @returns {string} The constructed search query builder.
 */
export function searchQueryBuilderFromParams(search: OnboardingSearch): string {
  const businessSubType = search.type!.toLowerCase()
  const atmospheres = search.atmospheres!
  const genres = search.genres!

  return searchQueryBuilder(businessSubType, genres, atmospheres)
}

/**
 * Constructs a search query based on the provided business, type, genres, and atmospheres.
 *
 * @param businessType - The type of business.
 * @param businessSubType - The more specific type of business.
 * @param genres - An array of genres that the business usually plays.
 * @param atmospheres - An array of atmospheres that describe the business.
 * @returns The constructed search query.
 */
export function searchQueryBuilder(
  businessSubType: string,
  genres: string[],
  atmospheres: string[],
) {
  let query = upperFirst(
    `${prefixWithArticle(atmospheres[0]!)} ${businessSubType}`,
  )

  if (atmospheres.length > 1)
    query += ` with a ${joinWithCommasAnd(atmospheres.slice(1))} feel`

  if (genres.length > 0 && genres[0] !== 'disabled')
    // If user want's us to chose
    query += ` that usually plays ${joinWithCommasAnd(genres)} music`

  return query
}

const vowels = ['a', 'e', 'i', 'o', 'u']

/**
 * Adds an article ('a' or 'an') before a word based on the word's first letter.
 * @param word - The word to prefix with an article.
 * @returns The word prefixed with the appropriate article.
 */
function prefixWithArticle(word: string): string {
  return vowels.includes(word[0]!) ? `an ${word}` : `a ${word}`
}

/**
 * Joins an array of strings with commas and the word "and".
 * If the array has only one element, that element is returned.
 * If the array has two elements, they are joined with " and ".
 * If the array has more than two elements, all elements except the last one are joined with commas,
 * and the last element is joined with " and ".
 *
 * @param array - The array of strings to join.
 * @returns The joined string.
 */
function joinWithCommasAnd(array: string[]): string {
  if (array.length === 1) return array[0]!

  const allButLast = array.slice(0, array.length - 1).join(', ')
  return `${allButLast} and ${array[array.length - 1]}`
}

export function updateQueryParams(
  param: DescriptionSubSteps,
  value: string | string[],
) {
  getRouter().navigate({
    to: '.',
    search: (current) => {
      return { ...current, [param]: value }
    },
    resetScroll: false,
  })
}

export const getSubStep = (
  search: OnboardingStepComponentProps['search'],
): DescriptionSubSteps => {
  const account = selectors.account(store.getState())!
  const lacksSubType = account.get('business_type_description')
  const { type, atmospheres, genres } = search

  if (!lacksSubType && !type) return 'type'
  if (!atmospheres) return 'atmospheres'
  if (!genres) return 'genres'

  return 'playlist'
}
