import { type ApolloError } from '@apollo/client'
import * as React from 'react'
import { graphql, useQuery } from '#app/apollo/index'
import useAbortController from '#app/hooks/use-abort-controller'

export interface IPlaylistSearchData {
  playlists: Array<Record<string, any>>
  /** id used for calls to `writeTasteProfile` to enable tracking */
  trackingId?: string | null
  /** Used to describe the input context. For example `Describe your brand` */
  context?: string | null
}

export default function useMarvinPlaylistSearch(
  prompt: string,
  options: {
    /** Optional context. Used to describe the input context. For example `Describe your brand` */
    context?: string
    /** Optional limit for number of playlist to be returned */
    limit?: number
    onCompleted?: (data: IPlaylistSearchData) => void
    onError?: (error: ApolloError) => void
  } = {},
) {
  const { abortSignal, abort } = useAbortController()
  const [data, setData] = React.useState<IPlaylistSearchData>()

  const { loading, error, refetch } = useQuery(PlaylistsFromPromptDoc, {
    variables: { prompt, context: options.context },
    skip: !prompt.trim(),
    fetchPolicy: 'cache-and-network',
    onError: options.onError,
    onCompleted: (data) => {
      let { playlists = [], trackingId } = data.getMusicFromPrompt

      if (options.limit) {
        playlists = playlists.slice(0, options.limit)
      }

      const searchData = {
        playlists,
        trackingId,
        context: options.context,
      }

      setData(searchData)

      options.onCompleted?.(searchData)
    },
    context: {
      fetchOptions: {
        signal: abortSignal(),
      },
    },
  })

  return {
    loading,
    refetch,
    error,
    data,
    abort,
  }
}

export const PlaylistsFromPromptDoc = graphql(/* GraphQL */ `
  query PlaylistsFromPrompt($prompt: String!, $context: String) {
    getMusicFromPrompt(query: $prompt, context: $context) {
      __typename
      playlists {
        id
        name
        shortDescription
        composerType
        curated
        display {
          title
          colors {
            primary {
              hex
            }
          }
          image {
            placeholder
          }
        }
        presets {
          playbackMode
        }
      }
      trackingId
    }
  }
`)
