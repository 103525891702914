import { captureError } from '../error/reporting'
import i18n from './'
import I18nLanguageDetector from './language-detector'

let initialized = false

// Promise that resolves when
// * Visitor location (country) has been determined
// * Initial i18n language has been loaded
const initializedPromise = new Promise((resolve) => {
  if (i18n.instance.language === 'cimode') {
    initialized = true
    return resolve()
  }
  const eventHandler = (lang) => {
    if (lang) {
      initialized = true
      resolve()
      i18n.instance.off('loaded', eventHandler)
      i18n.instance.off('failedLoading', eventHandler)
    }
  }
  /*
  TODO: Revisit after 2023-11-01
  Intermediate step to collect more data on what goes wrong with loading a few times per week
  When investigation of failed loading is done
  • The eventHandler callback signature for 'loaded' sould be updated to '(loaded: boolean) => void'.
  • The failed loading handler should probably not call eventHandler at all.
  */
  const onFailedLoadingHandler = (lang, ns, msg) => {
    eventHandler(lang)
    captureError(
      Object.assign(
        new Error(`i18n: Failed loading ${lang} in ${ns} with error - ${msg}`),
        {
          lang,
          ns,
        },
      ),
    )
  }
  i18n.instance.on('loaded', eventHandler)
  i18n.instance.on('failedLoading', onFailedLoadingHandler)
})

export default initializedPromise

export function isInitialized() {
  return initialized
}

i18n.instance.use(I18nLanguageDetector)

function init() {
  i18n.instance.init(
    {
      debug: false /* NODE_ENV === 'development', */,
      compatibilityJSON: 'v3',
      lowerCaseLng: true,
      fallbackLng: 'en',
      supportedLngs: ['en', 'svdev'], // keep the dev-suffix until language is complete
      load: 'languageOnly',
      interpolation: {
        escapeValue: false, // react handles this
      },
      backend: {
        loadPath: '',
        cachePrefix: 'business_i18n_',
      },
      detection: {},
      saveMissing: true,
      missingKeyHandler: (lang, namespace, key, res) => {
        if (!initialized) {
          return
        }
        const path = [lang.join(','), namespace, key].join(' ')
        if (NODE_ENV === 'development') {
          console.warn('[i18n] missing key:', path)
        } else {
          captureError(
            Object.assign(new Error(`i18n: Missing translation (${path})`), {
              lang,
              namespace,
              key,
              res,
            }),
          )
        }
      },
    },
    (err) => {
      if (err) {
        // TODO: Handle error?
        console.error('[i18n/init]', err)
      }
    },
  )
}

if (ENABLE_ADMIN) {
  console.info(`[i18n] Loading editable backend`)
  const editableBackend = import('./editable-backend')
  editableBackend.then((module) => {
    i18n.instance.use(module.default)
    init()
  })
} else {
  const backend = import('./backend')
  backend.then((module) => {
    i18n.instance.use(module.default)
    init()
  })
}
