import { type Reducer } from '@reduxjs/toolkit'
import { Models, Utils } from '@soundtrackyourbrand/capsule'
import overrides from '#app/lib/overrides'
import shallowEquals from '#app/lib/shallow-equals'
import { createSelector } from '#app/store/redux'
import type { RootState } from '.'
import {
  selectors as currentAccount,
  hasMissingPaymentMethod,
  parseTier,
} from './current-account'

export type State = Readonly<{
  accountId?: string
  active?: boolean
  tier?: string | number
  billingCycle?: 'monthly' | 'yearly'
  plan?: 'starter' | 'essential' | 'unlimited'
}>

type CheckoutAction = Readonly<{
  type: string
  id?: string
  tier?: string
  billingCycle?: 'monthly' | 'yearly'
  plan?: 'starter' | 'essential' | 'unlimited'
}>

export const STEPS = [
  'details',
  'choose-plan',
  'address',
  'activate',
  'complete',
] as const

export type StepName = (typeof STEPS)[number]

export const actions = {
  enter: (id: string) => ({ type: 'ENTER_CHECKOUT', id }),
  leave: () => ({ type: 'LEAVE_CHECKOUT' }),
  selectTier: (tier: string) => ({ type: 'CHECKOUT_SELECT_TIER', tier }),
  selectBillingCycle: (billingCycle: string) => ({
    type: 'CHECKOUT_SELECT_BILLING_CYCLE',
    billingCycle,
  }),
  selectPlan: (plan: string) => ({ type: 'CHECKOUT_SELECT_PLAN', plan }),
}

export const reducer: Reducer<State, CheckoutAction> = (state, action) => {
  if (!state) {
    // We read the tier from overrides so that `tier` is immediately available which allows the
    // onboarding selector below to correctly assess what step the user is on
    const tier = parseTier(overrides.get('tier') || '')
    state = {
      tier: tier && 'tier-' + tier,
    }
  }

  switch (action.type) {
    case 'ENTER_CHECKOUT':
      return {
        ...state,
        active: true,
        accountId: action.id,
      }
    case 'CHECKOUT_SELECT_TIER':
      overrides.set('tier', action.tier)
      return {
        ...state,
        tier: action.tier,
      }
    case 'CHECKOUT_SELECT_BILLING_CYCLE':
      overrides.set('billing-cycle', action.billingCycle)
      return {
        ...state,
        billingCycle: action.billingCycle,
      }
    case 'CHECKOUT_SELECT_PLAN': {
      overrides.set('plan', action.plan)
      return {
        ...state,
        plan: action.plan,
      }
    }
    case 'LEAVE_CHECKOUT':
      return {
        ...state,
        active: false,
      }
    case 'UNSELECT_ACCOUNT':
    case 'SELECT_ACCOUNT':
      return {}
  }
  return state
}

const detailsCompleted = createSelector(
  [currentAccount.account, currentAccount.soundZones],
  (account, zones) => {
    return (
      account?.get('try_before_you_buy') ||
      (!account?.get('incomplete') && zones.size >= 1)
    )
  },
)

const detailsAvailable = createSelector(
  [currentAccount.onboardingEnabled, detailsCompleted],
  (onboardingEnabled, detailsCompleted) => {
    return onboardingEnabled && !detailsCompleted
  },
)

const choosePlanAvailable = createSelector(
  [detailsCompleted, currentAccount.setupCompleted],
  (detailsCompleted, setupCompleted) => detailsCompleted && setupCompleted,
)

export const tier = createSelector(
  [(state) => state.checkout],
  (checkout) => checkout.tier || '',
)

export const billingCycle = createSelector(
  [currentAccount.account, (state) => state.checkout],
  (account, checkout) => {
    const distributor = account?.get('distributor')
    const hasSybDistributor = Models.Account.isSYB(distributor)

    // For TBYB accounts, we always show the annual billing cycle, except for partner accounts, which default to monthly.
    const defaultBillingCycle = hasSybDistributor ? 'yearly' : 'monthly'

    const billingCycle =
      checkout.billingCycle ||
      overrides.get('billing-cycle') ||
      defaultBillingCycle
    return billingCycle
  },
)

export const plan = createSelector([(state) => state.checkout], (checkout) => {
  return checkout.plan || overrides.get('plan') || ''
})

const addressAvailable = createSelector(
  [currentAccount.paymentMethod, plan],
  (paymentMethod, plan) => {
    return !!(plan || !hasMissingPaymentMethod(paymentMethod))
  },
)

export const hasFullAddress = createSelector(
  [currentAccount.soundZones, currentAccount.defaultBillingGroup],
  (zones, defaultBillingGroup) => {
    const firstZone =
      zones.find(
        (zone) => !Utils.Dates.isZero(zone.get('iso8601_paid_until')),
      ) ??
      zones.sortBy((a) => parseInt(a.get('iso8601_created_at'), 10)).first()

    const hasZoneAddress = firstZone?.get('address') && firstZone.get('city')
    const hasBillingAddress = Boolean(
      defaultBillingGroup?.get('address') && defaultBillingGroup.get('city'),
    )
    const hasAddress = !!(hasZoneAddress && hasBillingAddress)
    return { hasAddress, hasZoneAddress, hasBillingAddress }
  },
)

const activateAvailable = (state: RootState): boolean =>
  hasFullAddress(state).hasAddress

export const checkoutCompleted = createSelector(
  [currentAccount.paymentMethod, currentAccount.soundZones],
  (paymentMethod, zones) => {
    // Checkout, payment
    // Capsule will only return the trial zone when for_onboarding: true
    const zone = zones.first()

    if (!zone || hasMissingPaymentMethod(paymentMethod)) {
      return false
    }

    return zones.some((zone) =>
      Utils.Dates.toUtcMoment(zone.get('iso8601_paid_until')).isAfter(),
    )
  },
)

const STEPS_SELECTORS = {
  details: detailsAvailable,
  'choose-plan': choosePlanAvailable,
  address: addressAvailable,
  activate: activateAvailable,
  complete: checkoutCompleted,
} as const satisfies Record<StepName, Function>

export type SelectorValue = Readonly<{
  /** Name of current (last available) step - `undefined` when checkout has been completed/disabled */
  current: StepName | undefined
  /** Available steps */
  availableSteps: Partial<Record<StepName, boolean>>
}>

let selectorMemoizedValue: SelectorValue

// Returns a memoized SelectorValue that tracks the availabiliy of each steps in `availableSteps`
// and the latest step available in `current`
export const selector = (state: RootState): SelectorValue => {
  const checkout = {
    availableSteps: {},
    current: undefined as StepName | undefined,
  }

  let available = true
  STEPS.forEach((step) => {
    const ignorePrevious = step === 'choose-plan'
    available = (available || ignorePrevious) && STEPS_SELECTORS[step](state)

    checkout.availableSteps[step] = available
    if (available) {
      checkout.current = step
    }
  })

  const memo = selectorMemoizedValue
  if (
    !(memo as unknown) ||
    memo.current !== checkout.current ||
    !shallowEquals(memo.availableSteps, checkout.availableSteps)
  ) {
    selectorMemoizedValue = checkout
  }

  return selectorMemoizedValue
}

export const isNotActivated = createSelector(
  [currentAccount.onboardingEnabled, checkoutCompleted],
  (enabled, checkoutCompleted) => enabled && !checkoutCompleted,
)

export const accountIdToActivate = createSelector(
  [currentAccount.onboardingEnabled, checkoutCompleted, currentAccount.account],
  (enabled, checkoutCompleted, account) =>
    enabled &&
    !checkoutCompleted &&
    account &&
    // TBYB accounts are on a trial that is already activated
    !account.get('try_before_you_buy') &&
    account.get('id'),
)

// Returns account object if NOT currently onboarding
export const account = createSelector(
  [currentAccount.onboardingEnabled, currentAccount.account],
  (isOnboarding, account) => (isOnboarding ? null : account),
)

// Returns account id if NOT currently onboarding
export const accountId = createSelector([account], (account) =>
  account ? account.get('id') : null,
)

export function accountQuery({
  accountId,
  includeBilling = false,
  skipPricing = false,
  isLoggedIn = false,
}: {
  accountId?: string
  includeBilling?: boolean
  skipPricing?: boolean
  isLoggedIn?: boolean
}) {
  includeBilling = !!includeBilling

  return accountId
    ? {
        params: { id: accountId },
        sound_zones: {
          params: {
            for_onboarding: true,
          },
          device: {},
          location: includeBilling && {},
          pricing: includeBilling &&
            !skipPricing && {
              params: {
                all_tiers: true,
                all_billing_cycles: true,
                all_plans: true,
              },
            },
        },
        payment_method: isLoggedIn && {}, // Needed for the TryBeforeYouBuy experiment
        distributor: includeBilling && {},
        billing_groups: includeBilling && {
          vat_exempt_form: {},
        },
        i18n: includeBilling && {},
        account_subscription: isLoggedIn && {}, // Needed for scheduledTierChange notification
      }
    : undefined
}

export default {
  STEPS,
  accountQuery,
  detailsCompleted,
  checkoutCompleted,
  selector,
  account,
  accountId,
  accountIdToActivate,
  actions,
  isNotActivated,
  hasFullAddress,
  billingCycle,
  tier,
  enabled: currentAccount.onboardingEnabled,
  reducer,
  plan,
}
