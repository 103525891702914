export const PLANS_TIERS_LONG = {
  starter: 'tier-1',
  essential: 'tier-1',
  unlimited: 'tier-3',
}

export const PLANS_TIERS = {
  starter: 1,
  essential: 1,
  unlimited: 3,
}

export const DEFAULT_TIER_PLANS = {
  1: 'essential',
  3: 'unlimited',
}

export const DEFAULT_LONG_TIER_PLANS = {
  'tier-1': 'essential',
  'tier-3': 'unlimited',
}

export const SUPPORTED_PLANS = ['starter', 'essential', 'unlimited']
