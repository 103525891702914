import { isTruthy } from '@soundtrack/utils/typePredicates';
import { Models, Utils } from '@soundtrackyourbrand/capsule';
import { t } from '#app/lib/i18n/index';
export function generateNavItems(options) {
  const items = buildNavigationItems(options);
  return items.map(item => processNavItem(item, options.account?.get('id'))).filter(Boolean);
}
export function processNavItem(item, accountId) {
  // Skip items that require an account when no account is available

  const url = item.url || `/accounts/$accountId/${item.target}`;
  if (url.includes('$accountId') && !accountId) {
    return null;
  }
  return {
    ...item,
    // Tanstack Router supports building URLs with parameters, but doesn't provide
    // a way to validate that all parameters are provided, so we just do simple
    // string replacement here.
    url: url.replace('$accountId', accountId || ''),
    icon: item.icon || 'blank',
    label: t(`app.navigation.${item.target}`)
  };
}
function buildNavigationItems({
  isLoggedIn,
  account,
  appearance,
  includePartner = false,
  restrictedDiscoverMusic = false,
  includeSignupAndLogin = true
}) {
  const {
    showSetupGuide,
    showMessaging,
    isMessagingEnabled
  } = getOptions(account);
  switch (appearance) {
    case 'none':
      return [];
    case 'signup':
      return isLoggedIn ? [] : [{
        target: 'login',
        url: '/login'
      }];
  }
  const items = [includePartner && !!account && {
    target: 'partner',
    group: 'partner',
    url: `/partner-portal/$accountId`,
    sidebarIcon: {
      name: 'globe',
      activeName: 'globe'
    }
  }, !restrictedDiscoverMusic && {
    target: 'home',
    url: '/home',
    icon: 'home',
    sidebarIcon: {
      name: 'home-unfilled',
      activeName: 'home-filled'
    }
  }, !restrictedDiscoverMusic && {
    target: 'search',
    url: '/search',
    icon: 'search',
    sidebarIcon: {
      name: 'search-unfilled',
      activeName: 'search-filled'
    }
  }, !restrictedDiscoverMusic && {
    target: 'create',
    url: '/create',
    icon: 'plus',
    sidebarIcon: {
      name: 'plus',
      activeName: 'plus'
    }
  }, !account && includeSignupAndLogin && {
    target: 'login',
    url: '/login'
  }, !account && includeSignupAndLogin && {
    target: 'signup',
    url: '/signup'
  }].filter(isTruthy);
  if (!account || appearance === 'public') {
    return items;
  }
  items.push({
    target: 'your-music',
    url: '/accounts/$accountId/your-music',
    icon: 'library',
    group: 'library',
    sidebarIcon: {
      name: 'library-unfilled',
      activeName: 'library-filled'
    }
  });
  if (!IS_ELECTRON) {
    if (showMessaging) {
      items.push({
        target: 'messaging',
        icon: 'chat',
        url: isMessagingEnabled ? '/accounts/$accountId/messaging' : '/addons/promo/messaging',
        sidebarIcon: {
          name: 'chat-unfilled',
          activeName: 'chat-filled'
        }
      });
    }
    if (appearance === 'onboarding') {
      items.push({
        group: 'setup',
        target: 'checkout',
        icon: 'play',
        url: '/accounts/$accountId/checkout',
        sidebarIcon: {
          name: 'local-convenience-store',
          activeName: 'local-convenience-store'
        }
      });
    } else {
      items.push({
        target: 'zones',
        icon: 'location',
        url: '/accounts/$accountId/zones',
        sidebarIcon: {
          name: 'location-unfilled',
          activeName: 'location-filled'
        }
      });
    }
    if (showSetupGuide) {
      items.push({
        group: 'setup',
        target: 'set-up',
        url: '/setup-guide',
        icon: 'list',
        sidebarIcon: {
          name: 'fact-check',
          activeName: 'fact-check'
        }
      });
    }
  }
  return items;
}
function getOptions(account) {
  const isMessagingEnabled = !!account?.getIn(['feature_flags', 'messaging_enabled']);
  const isDefaultDistributor = Models.Account.isSYB(account?.get('distributor'));
  const trialExpiresAt = account?.getIn(['payment_method', 'trial_expires_at']);
  const isTrial = !Utils.Dates.isZero(trialExpiresAt) && !Utils.Dates.toUtcMoment(trialExpiresAt).isBefore();
  const showMessaging = isMessagingEnabled || !isTrial && isDefaultDistributor;
  return {
    isMessagingEnabled,
    showMessaging,
    showSetupGuide: isTrial
  };
}