const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-your-music" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/your-music/schedules.edit.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-your-music" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/your-music/schedules.edit.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { ModalErrorView } from '#app/components/error/index';
import { EditSchedule } from '#app/modules/accounts/your-music/edit-schedule/index';

// This is the route for creating a new schedule

export const Route = createFileRoute('/accounts/$accountId/your-music/schedules/edit')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  errorComponent: ModalErrorView,
  validateSearch: zodSearchValidator(EditSchedule.searchSchema),
  staticData: {},
  loader: lazyFn($$splitLoaderImporter, 'loader')
});