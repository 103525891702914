import { Hooks } from '@soundtrackyourbrand/capsule';
import '@soundtrackyourbrand/capsule/dist/utils/immutable';
import overrides from '#app/lib/overrides';
export function usePricing() {
  const {
    data,
    loading
  } = Hooks.useQuery({
    country: {
      pricing: {
        params: {
          voucher_code: overrides.get('voucher')
        }
      }
    }
  }, {
    observe: false
  });
  const country = data?.get('country');
  if (!country || country.getIn(['pricing', 'price_list']).size === 0) {
    return {
      data: null,
      loading
    };
  }
  return {
    data: country.get('pricing'),
    loading
  };
}