import { type DependencyList, useMemo, useRef } from 'react'

export default function shallowEquals(a: unknown, b: unknown) {
  if (a === b) {
    return true
  }
  const aType = typeof a
  if (aType !== typeof b || aType !== 'object') {
    return false
  }
  return Array.isArray(a) ? arrays(a, b as any) : objects(a, b)
}
shallowEquals.objects = objects
shallowEquals.arrays = arrays

export function objects(a: unknown, b: unknown, keys?: string[]) {
  if (a === b) {
    return true
  }
  if (!a || !b) {
    return false
  }

  const aKeys = Object.keys(a)
  if (!keys && Object.keys(b).length !== aKeys.length) {
    return false
  }

  keys = keys || aKeys
  const len = keys.length

  for (let i = 0; i < len; i++) {
    const key = keys[i]!
    if (a[key] !== b[key]) {
      return false
    }
  }

  return true
}

export function arrays(a: unknown[] | undefined, b: unknown[] | undefined) {
  if (a === b) {
    return true
  }
  if (!a || !b) {
    return false
  }

  const len = a.length

  if (b.length !== len) {
    return false
  }

  for (let i = 0; i < len; i++) {
    if (a[i] !== b[i]) {
      return false
    }
  }

  return true
}

/**
 * Memoizes result of given function.
 * Unlike {@link useMemo}, objects are compared by shallow equality.
 * @param fn Function to calculate a value
 * @param deps Array of values on which the value depends
 */
export function useShallowMemo<T extends {}>(
  fn: () => T,
  deps: DependencyList,
): T {
  const current = useRef<T>(null as any)
  return useMemo(() => {
    const res = fn()
    const prev = current.current
    if ((prev as unknown) == null) {
      current.current = res
      return res
    }
    if (!shallowEquals(prev, res)) {
      current.current = res
    }
    return current.current
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}
