import type { FetchResult } from '@apollo/client'
import { useCallback, useContext } from 'react'
import { apollo, graphql } from '#app/apollo/index'
import type {
  OnboardingStepStatus,
  SetOnboardingStepMutation,
} from '#app/graphql/graphql'
import { UsageContext, tracking } from '#app/lib/tracking'
import { selectors as accountSelectors } from '#app/store/current-account'
import { useStore } from '#app/store/redux'

/**
 * Returns a hook that is called like this:
 *
 *     setOnboardingStepStatus(`the-step-name`, 'Status')
 *
 * You can set additional tracking properties by passing an object as the third argument:
 *
 *     setOnboardingStepStatus(`the-step-name`, 'Status', { extra: 'props' })
 *
 * Set `Step Name` in tracking props if you need to override the step name used for tracking:
 *
 *     setOnboardingStepStatus(`user/${currentUserId}/qr-app-login`, 'Completed', {
 *        'Step Name': 'qr-app-login',
 *     })
 */
export function useSetOnboardingStepStatus() {
  const store = useStore()
  const trackingContext = useContext(UsageContext)

  return useCallback(
    (
      name: string,
      status: OnboardingStepStatus,
      trackingProps?: Record<string, any>,
    ): Promise<FetchResult<SetOnboardingStepMutation> | void> => {
      return apollo
        .mutate({
          mutation: setOnboardingStepDoc,
          variables: {
            input: {
              account: accountSelectors.id(store.getState())!,
              name,
              status,
            },
          },
          update: (cache, { data }) => {
            const newSteps = data?.setOnboardingStep?.onboardingSteps
            cache.modify({
              id: cache.identify({
                __typename: 'Account',
                id: accountSelectors.id(store.getState())!,
              }),
              fields: {
                onboardingSteps(existingSteps = [], { readField }) {
                  if (newSteps?.length === existingSteps.length) {
                    return existingSteps.map((step) => {
                      if (name === readField('name', step)) {
                        return { ...step, status }
                      }
                      return step
                    })
                  }

                  return newSteps
                },
              },
            })
          },
        })
        .then(() => trackStep(name, status, trackingProps, trackingContext))
    },
    [store], // eslint-disable-line react-hooks/exhaustive-deps
  )
}

function trackStep(name, status, trackingProps, trackingContext) {
  tracking.track(`Onboarding - Step ${status}`, {
    'Step Name': name,
    ...trackingProps,
    ...trackingContext,
  })
}

const setOnboardingStepDoc = graphql(/* GraphQL */ `
  mutation setOnboardingStep($input: OnboardingStepInput!) {
    setOnboardingStep(input: $input) {
      onboardingSteps {
        name
        status
      }
    }
  }
`)
