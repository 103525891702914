import { isApolloError } from '@soundtrackyourbrand/apollo-client'
import { t } from '../i18n'
import type { DecoratedError } from './'
import { captureError } from './reporting'

/**
 * Attempts to infer a user friendly error message for the given error, which is
 * then exposed as a `localized` property on the error.
 *
 * The error message is determined as the first string of the following candidates:
 *  1. i18n lookup for `${prefix}.${key}` for each `key` provided
 *  2. i18n lookup for `${prefix}.${fallbackKey}` (if a fallbackKey is provided)
 *  3. i18n lookup for `fallbackPath`
 *
 * In cases where no `key` maps to an existing translation this function will
 * report the error to Sentry. This can be disabled via
 * `options.fallbackReporting = false`.
 *
 * Translations can access any property of the `error` via t() interpolation syntax.
 *
 * @example Reports failing calls that aren't defined in `someComponent.errors.*` to Sentry and then re-renders
 * ```ts
 * // Call failing action:
 * return action().catch(error => {
 *   errorLocalizer(error, 'someComponent.errors', {
 *     fallbackKey: 'unknown',
 *   })
 *   this.setState({ error })
 *   if (!error.status) { throw error }
 * })
 *
 * // In render() function:
 * if (this.state.error) {
 *   return <Message type="error" children={this.state.error.localized} />
 * }
 * ```
 *
 * @return `true` if the error.localized property is set, otherwise false.
 */
export function errorLocalizer<BaseError extends Error = Error>(
  /** Error to annotate */
  error: DecoratedError<BaseError>,
  /** i18n key prefix (path/namespace) to lookup strings within */
  prefix: string,
  /** Options, including `t()` compatible properties */
  options: {
    /** i18n keys (appended to prefix) to attempt to resolve, `[error.body.error || error.text, error.status]` by default */
    key?: any[]
    /** Optional key to use as fallback */
    fallbackKey?: string
    /** Full i18n path to message used a last resort */
    fallbackPath?: string
    /** Report errors that lack translations to Sentry */
    fallbackReporting?: boolean
    [key: string]: any
  } = {},
): error is DecoratedError<BaseError> {
  if (!(error as unknown) || typeof error !== 'object') {
    return false
  }
  if (error.localized) {
    return true
  }

  let {
    key,
    fallbackKey = 'unknown',
    fallbackPath = 'errors.unknown',
    fallbackReporting = true,
    ...forwardedI18nProps
  } = options

  // Default options
  if (!key) {
    if (error.code) {
      key = [error.code]
    } else if (isApolloError(error)) {
      key = [error.gqlContext.code, error.gqlContext.status]
    } else if (typeof error.body?.error?.type === 'string') {
      // This is an error of the type called 'handlererr' in syb-core
      key = [error.body.error.type]
    } else if (
      'crossDomain' in error &&
      error.crossDomain &&
      error.status === undefined
    ) {
      key = ['network']
    } else {
      key = [error.body ? error.body.error : error.text, error.status]
    }
  } else if (typeof key === 'string') {
    key = [key]
  }

  // Prepare i18n.t() arguments
  const tOptions = Object.assign({}, error, forwardedI18nProps, {
    keySeparator: '%%%',
    nsSeparator: ':::',
    defaultValue: null,
  })

  const escapedPrefix =
    prefix.replace(/\./g, tOptions.keySeparator) + tOptions.keySeparator
  const escapedFallbackPath = fallbackPath.replace(/\./g, tOptions.keySeparator)

  // Localize using i18n.t()
  error.localized = t(
    key.map((k) => escapedPrefix + toKey(k)),
    tOptions,
  )

  if (error.localized) {
    if (fallbackReporting) {
      // Don't report error to Sentry (see Sentry.init() beforeSend callback)
      error.isExpected = true
    }
  } else {
    // Ensure something is returned
    // @ts-expect-error
    delete tOptions.defaultValue
    // Attempt to lookup fallback(s)
    error.localized = t(
      [escapedPrefix + fallbackKey, escapedFallbackPath],
      tOptions,
    )
    // Expose list of attempted paths on error
    error.localizedMissing = key.map((k) => `${prefix}[${toKey(k)}]`)

    if (SERVER_ENV !== 'production') {
      console.warn(
        '[errorLocalizer] No localizations found for paths:\n' +
          error.localizedMissing.join('\n'),
      )
    }

    if (fallbackReporting) {
      captureError(error, (scope) => {
        scope.setTag('logger', 'errorLocalizer')
        scope.setFingerprint(['errorLocalizer', prefix])
        scope.setLevel('error')
        scope.setTag('logger', 'errorLocalizer')
      })
    }
  }

  return true
}

function toKey(input) {
  return String(input).trim()
}
