import { Hooks } from '@soundtrackyourbrand/capsule';
import '@soundtrackyourbrand/capsule/dist/models/sonos-household';
import { useMemo } from 'react';
export function useSonosHouseholds(accountId) {
  const {
    data
  } = Hooks.useQuery({
    sonos_household: {
      params: {
        account: accountId
      }
    }
  }, {
    observe: true,
    staleWhileUpdating: true
  });
  const households = data?.get('sonos_household');
  return households;
}
export function useSonosHouseholdForLocation(accountId, locationId) {
  const households = useSonosHouseholds(accountId);
  const household = useMemo(() => {
    return households?.find(household => {
      return household.getIn(['location', 'id']) === locationId;
    });
  }, [households, locationId]);
  return household || null;
}